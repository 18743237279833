<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Forms' }]">
			Form builder
		</page-header>

		<div class="row-format align-center mb-4">
			<v-text-field
					outlined
					hide-details
					dense
					class="standard-input"
					v-model="search"
					placeholder="Search..."
					style="max-width: 200px;"
					color=""
			>
				<template v-slot:prepend-inner
				><span class="material-symbols-rounded font-gray_50">search</span></template
				>
			</v-text-field>
			<v-menu
					:nudge-bottom="34"
					:elevation="0"
					left
					content-class="add-new-menu"
					transition="slide-y-transition"
			>
				<template v-slot:activator="{ on }">
					<v-btn class="ml-auto super-action" v-on="on"><v-icon size="20">add</v-icon> Add form</v-btn>
				</template>
				<div class="add-new-dropdown">
					<div class="add-new-item" @click="$refs.discovery.saveNewTemplate()">Add new</div>
					<div class="add-new-item" @click="$refs.discovery.aiAssistant()">Form builder ✨Assistant</div>
					<div class="add-new-item" @click="$refs.discovery.templateLibrary()">Search template library</div>
				</div>
			</v-menu>
		</div>

		<discovery-templates
			ref="discovery"
			:modal-state="modalState"
			:in-list-view="true"
			:search="search"
			@folder-list="setFolderList"
			@template-selected="openTemplate"
			@template-list="setTemplateList"
			@download-results="downloadResults($event)"
		></discovery-templates>

		<basic-modal
			v-if="discoveryDialog"
			:dialog="discoveryDialog"
			:persistent="true"
			:fullscreen="true"
			:retain-focus="false"
			@close="handleBuilderClose"
		>
			<discovery-builder
				:template-id="templateId"
				:folders="folders"
				:other-template-names="otherTemplateNames"
				@close="handleBuilderClose"
				@duplicated="handleBuilderClose"
			></discovery-builder>
		</basic-modal>
	</div>
</template>

<script>
	import DiscoveryTemplates from '@/modules/discovery/DiscoveryTemplates';
	import BasicModal from '@/components/BasicModal';
	import DiscoveryBuilder from '@/modules/discovery/DiscoveryBuilder';
	import FormBuilder from '@/modules/forms/FormBuilder';
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'MeetingList',

		props: ['v'],

		components: { DiscoveryTemplates, BasicModal, DiscoveryBuilder,  PageHeader },

		data: function() {
			return {
				pages: [
					{ label: 'Meeting schedulers', value: 'schedulers' },
					{ label: 'Forms', value: 'forms' },
				],

				folders: [],

				discoveryDialog: false,
				templateId: null,
				modalState: {
					inSendMode: false,
				},
				templateList: [],
				otherTemplateNames: [],
				search: null,
				showMenu: false,
				formSubmissionService: new FormSubmissionService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			update() {
				this.$refs.discovery.search = this.search;
			},

			setView: function(view) {
				this.$router.push('/pages/' + view);
			},

			setTemplateList: function(list) {
				this.templateList.splice(0, this.templateList.length);
				this.templateList.push(...list);
			},

			setFolderList: function(folders) {
				this.folders.splice(0);
				this.folders.push(...folders);
			},

			getOtherTemplateNames: function(id) {
				let result = [];
				this.templateList
					.filter((m) => m.id !== id)
					.forEach((m) =>
						result.push(
							m.name
								.replaceAll(' ', '-')
								.toLowerCase()
								.replaceAll('[^a-zA-Z0-9-]', '')
						)
					);
				return result;
			},

			openTemplate: function(template) {
				if (template.schemaVersion === 1) {
					this.discoveryDialog = true;
					this.templateId = template.id;
					this.otherTemplateNames = this.getOtherTemplateNames(template.id);
				} else {
					this.$store.state.globalModalController
						.openModal(FormBuilder, { id: template.id, folders: this.folders }, false, true, false, true)
						.then(() => {
							this.$refs.discovery.getDiscoveryTemplates();
						});
				}
			},

			downloadResults: function(template) {
				this.$store.commit('startLoading');
				this.formSubmissionService
					.downloadFormSubmissionByTemplate(template.id)
					.then((res) => {
						let fileURL = window.URL.createObjectURL(res.data);
						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', `${template.uniqueTemplateId}.xlsx`);
						document.body.appendChild(fileLink);
						fileLink.click();
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleBuilderClose: function() {
				this.discoveryDialog = false;
				this.templateId = null;
				this.$refs.discovery.getDiscoveryTemplates();
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style lang="scss"></style>
