<template>
	<div>
		<v-menu
			:nudge-bottom="34" min-width="150" max-width="150"
			:close-on-content-click="true"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0">
					<span :class="`font-14 ${isFilterActive ? '' : 'font-gray_60'} brand-medium`">{{
							filterLabel
						}}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
				<div class="px-3 py-1">
					<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="filter.group">
						<v-radio label="None" value=""></v-radio>
						<v-radio v-if="!singleProjectMode" label="Client" value="Client"></v-radio>
						<v-radio v-if="!singleProjectMode" label="Project" value="Project"></v-radio>
						<v-radio label="Status" value="Status"></v-radio>
						<v-radio label="Priority" value="Priority"></v-radio>
						<v-radio v-for="field in customGroupOptions" :key="field.id" :label="field.name" :value="`Custom:${field.id}`"></v-radio>
					</v-radio-group>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'ProjectGroup',

		props: ['filter','singleProjectMode','customFields','projectTypeId'],

		data() {
			return {
				showMenu: false,
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				return this.filter.group
			},

			customGroupOptions: function(){
				let deliverableFields = this.$store.getters.getDeliverableFields(this.projectTypeId);
				if(deliverableFields) {
					let result = [... deliverableFields];
					return result.filter(f => f.type === 'Select' || f.type === 'Radio' || f.type === 'Checkbox')
				}else{
					return [];
				}
			},

			filterLabel: function() {
				if (!this.filter.group) {
					return 'Group';
				} else {
					if(this.filter.group.startsWith("Custom:")){
						let id = this.filter.group.split(":")[1];
						let field =  this.customGroupOptions.find(f => f.id === id);
						return field ? field.name : '--';
					}else {
						return 'Group by ' + this.filter.group.toLowerCase();
					}
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
