<template>
	<div v-if="paymentCopy" id="payment-detail">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h3 class="modal-title">{{ paymentHeading }}</h3>

			<v-container fluid class="px-0 mt-2 mb-0">
				<v-row>
					<v-col cols="12" sm="7" class="column-format gap-3">
						<div class="row-format">
							<amount-input
								hide-details
								dense
								outlined
								:label="$t('income.amount-label')"
								:placeholder="$t('income.amount-placeholder')"
								:prefix="currencySymbol"
								type="text"
								v-model="paymentCopy.amount"
								:disabled="paymentCopy.locked"
								persistent-placeholder
							></amount-input>

							<div style="width: 60%" class="ml-3">
								<v-autocomplete
									hide-details
									persistent-placeholder
									dense
									outlined
									:label="$t('account.currency')"
									:items="currencies"
									item-text="label"
									item-value="value"
									v-model="paymentCopy.currency"
									:disabled="paymentCopy.locked"
								>
								</v-autocomplete>
							</div>
						</div>

						<div v-if="paymentCopy.currency !== $store.state.defaultCurrency">
							<v-text-field
								dense
								outlined
								persistent-placeholder
								hide-details
								label="Exchange rate (optional)"
								v-model="paymentCopy.exchangeRate"
								type="number"
								step="0.00001"
							>
								<template v-slot:append>
									<span class="font-12 font-gray_70 text-left pr-2"
										>({{ $formatters.dollars(paymentCopy.localAmount) }}
										{{ $store.state.defaultCurrency }})</span
									>
								</template>
							</v-text-field>
						</div>

						<v-select
							dense
							outlined
							persistent-placeholder
							hide-details
							:label="$t('invoice.mark-paid-modal.payment-type')"
							v-model="paymentCopy.paymentProvider"
							:items="paymentProviders"
							:disabled="paymentCopy.locked"
						></v-select>

						<v-text-field
							dense
							outlined
							hide-details
							:label="$t('income.id-label')"
							:placeholder="$t('income.id-placeholder')"
							v-model="paymentCopy.identifier"
							persistent-placeholder
							:disabled="paymentCopy.locked"
						></v-text-field>

						<v-text-field
							v-if="paymentCopy.locked"
							dense
							outlined
							hide-details
							label="Source"
							v-model="paymentCopy.paymentSource"
							persistent-placeholder
							:disabled="paymentCopy.locked"
						></v-text-field>

						<v-textarea
							dense
							outlined
							hide-details
							:label="$t('income.description')"
							:placeholder="$t('income.description-placeholder')"
							v-model="paymentCopy.description"
							:auto-grow="true"
							rows="1"
							persistent-placeholder
						></v-textarea>

						<date-selector
							clearable
							hide-details
							persistent-placeholder
							:standard="true"
							:label="$t('income.date')"
							:date="paymentCopy.date"
							@change="paymentCopy.date = $event"
							:disabled="paymentCopy.locked"
						></date-selector>

						<div
							class="mt-2 row-format align-center pointer font-primary font-14"
							@click="openReceipt"
							v-if="paymentCopy.receiptUrl"
						>
							<div style="text-transform: capitalize">
								View {{ paymentCopy.paymentProvider.toLowerCase() }} receipt
							</div>
							<v-icon color="primary" size="20">receipt_long</v-icon>
						</div>
					</v-col>
					<v-col cols="12" sm="5">
						<div class="column-format gap-3">
							<v-autocomplete
								dense
								outlined
								hide-details
								persistent-placeholder
								:label="$t('client.client')"
								:placeholder="$t('income.link-to-client')"
								v-model="paymentCopy.clientId"
								:items="clientsPlusClear"
								item-text="name"
								item-value="id"
								@change="clientChanged"
								ref="clientId"
							></v-autocomplete>

							<v-autocomplete
								v-if="paymentCopy.clientId"
								dense
								outlined
								hide-details
								persistent-placeholder
								:label="$t('projects.project')"
								:placeholder="$t('income.link-to-project')"
								:disabled="noClientDisabled"
								v-model="paymentCopy.projectId"
								:items="clientProjectsPlusClear"
								item-text="name"
								item-value="id"
								@change="projectChanged"
								no-data-text="No projects for selected client"
							></v-autocomplete>

							<div v-if="paymentCopy && paymentCopy.id" class="text-left">
								<div class="text-left body-12 gray_80--text mb-4">Attachments</div>
								<file-attachments
									:files="paymentCopy.attachments"
									:file-service="attachmentService"
									:edit-mode="true"
								></file-attachments>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div style="border-top: 1px solid var(--v-gray_30-base)" class="mt-2 pa-2 row-format centered gap-1">
			<v-btn class="super-action" :width="$vuetify.breakpoint.smAndUp ? '160' : '128'" @click="saveIncome">
				{{ $t(paymentCopy.id === null ? 'global.create' : 'global.save') }}
			</v-btn>
			<v-btn icon @click="deletePaymentDialog = true" v-if="paymentCopy.id">
				<v-icon size="20" class="material-symbols-rounded">delete</v-icon>
			</v-btn>
		</div>

		<modal :dialog="deletePaymentDialog" :persistent="true" :max-width="290" @close="deletePaymentDialog = false">
			<div class="pt-8">
				<v-container>
					<v-row>
						<v-col cols="12">
							{{ $t('income.delete-confirm') }}
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="mt-3">
							<v-btn class="primary-action mr-2" @click="deletePaymentDialog = false">
								{{ $t('global.no') }}
							</v-btn>
							<v-btn class="ml-2" elevation="0" @click="deleteIncome">
								{{ $t('global.yes') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</modal>
	</div>
</template>

<script>
	import modal from '@/components/Modal';
	import DateTime from '@/modules/utils/HDateTime';
	import DateSelector from '@/components/DateSelector';
	import FileAttachments from '@/components/FileAttachments';
	import IncomeAttachmentService from '@/modules/accounting/payments/IncomeAttachmentService';
	import IncomeService from '@/modules/accounting/payments/IncomeService';
	import PaymentsMixin from '@/modules/accounting/payments/PaymentsMixin';
	import AmountInput from '@/components/AmountInput.vue';

	export default {
		name: 'PaymentDetail',

		props: ['payment', 'id'],

		mixins: [PaymentsMixin],

		components: { AmountInput, FileAttachments, DateSelector, modal },

		data: function() {
			return {
				paymentCopy: null,
				deletePaymentDialog: false,
				incomeService: new IncomeService(),
				attachmentService: new IncomeAttachmentService(this.payment),
			};
		},

		mounted() {
			//this.paymentCopy = this.initialize();
			this.initialize().then((res) => {
				this.paymentCopy = res;
				this.initRelatedData();
				this.setExchangeRate();

				if (!this.paymentCopy.id) {
					this.createIncome();
				}

				if (this.paymentCopy.clientId) {
					this.getProjects();
				}
			});
		},

		beforeDestroy() {},

		methods: {
			setExchangeRate: function() {
				console.log(this.paymentCopy);
				if (!this.paymentCopy.exchangeRate && this.paymentCopy.localAmount && this.paymentCopy.amount) {
					console.log('are we doing it?');
					this.paymentCopy.exchangeRate = Number(this.paymentCopy.localAmount / this.paymentCopy.amount).toFixed(6);
				}
			},

			fixupAmount: function(event) {
				console.log(event);
			},

			initRelatedData: function() {
				this.getClients();
			},

			initialize: function() {
				return new Promise((resolve) => {
					if (this.id) {
						this.incomeService.getIncome(this.id).then((res) => resolve(res.data));
					} else {
						let result;
						if (this.payment) {
							result = JSON.parse(JSON.stringify(this.payment));
						} else {
							result = {
								id: null,
								clientId: null,
								projectId: null,
								amount: 0,
								currency: this.$store.state.defaultCurrency,
								date: DateTime.now().toISODate(),
								paymentProvider: 'CHECK',
								description: null,
								identifier: null,
								attachments: [],

								client: null,
								project: null,
							};
						}

						if (result.currency) {
							result.currency = result.currency.toUpperCase();
						}

						resolve(result);
					}
				});
			},

			openReceipt: function() {
				window.open(this.paymentCopy.receiptUrl, '_blank');
			},

			saveIncome: function() {
				if (this.paymentCopy.id === null) {
					this.createIncome();
				} else {
					this.updateIncome();
				}
			},

			createIncome: function() {
				this.incomeService
					.createIncome(this.paymentCopy)
					.then((res) => {
						this.paymentCopy = res.data;
						this.attachmentService.setIncome(this.paymentCopy);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			updateIncome: function() {
				this.incomeService
					.updateIncome(this.paymentCopy.id, this.paymentCopy)
					.then((res) => {
						this.paymentCopy = res.data;
						this.setExchangeRate();
						this.$store.commit('success', 'Payment updated');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			deleteIncome: function() {
				if (this.paymentCopy.id === null) {
					this.$emit('result');
				} else {
					this.incomeService
						.deleteIncome(this.paymentCopy.id)
						.then(() => {
							this.$emit('result', { id: this.paymentCopy.id, action: 'DELETED' });
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			handleClose: function() {
				this.$emit('close');
				this.$emit('result', this.paymentCopy);
			},
		},

		watch: {
			'paymentCopy.currency': function(preVal, newVal) {
				if (preVal && newVal && preVal.toUpperCase() !== newVal.toUpperCase()) {
					this.paymentCopy.exchangeRate = null;
				}
			},
		},

		computed: {
			paymentHeading: function() {
				if (!this.payment) {
					return this.$t('income.create-payment');
				} else {
					return this.$t('income.update-payment');
				}
			},
		},
	};
</script>

<style lang="scss">
	#payment-detail {
		max-width: 684px;
		background-color: var(--v-white-base);
	}
</style>
