<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.xero.desc') }}</div>
				<div v-if="paidAccount">
					<connected v-if="isConnected"></connected>
					<div v-else class="pointer" @click="initializeOAuth"><img alt="xero-connect" src="/images/apps/xero-connect.svg"></div>
				</div>
				<div v-else>
					<div class="font-14 pointer font-primary" @click="$router.push('/subscription')"><v-icon class="material-symbols-rounded">lock</v-icon>This integration is not available on your plan.  If you would like to connect your Xero account, please upgrade to Pro or Teams.</div>
				</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected && paidAccount">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div class="mb-6 font-14 py-2 px-2" style="background-color: var(--v-white-base); border-radius: 8px">
				<div class="mb-2"><span class="brand-medium">Xero company:</span> {{ xero.connection.tenantName }}</div>
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Sales account"
						:items="salesAccounts"
						item-text="name"
						item-value="accountID"
						return-object
						v-model="xero.connection.salesAccount"
						@change="saveConfig"
				></v-autocomplete>
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Clearing account"
						:items="clearingAccounts"
						item-text="name"
						item-value="accountID"
						return-object
						v-model="xero.connection.clearingAccount"
						@change="saveConfig"
				></v-autocomplete>
				<!--v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Payment processing expense account"
						:items="expenseAccounts"
						item-text="name"
						item-value="accountID"
						return-object
						v-model="xero.connection.expenseAccount"
						@change="saveConfig"
				></v-autocomplete-->
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Default sales tax account"
						:items="taxRates"
						item-text="name"
						item-value="taxType"
						return-object
						v-model="xero.connection.salesTaxAccount"
						@change="saveConfig"
				></v-autocomplete>
				<v-autocomplete
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Exempt sales tax account"
						:items="taxRates"
						item-text="name"
						item-value="taxType"
						return-object
						v-model="xero.connection.exemptTaxAccount"
						@change="saveConfig"
				></v-autocomplete>
			</div>
			<div class="row-format align-center gap-2">
				<div class="pointer" @click="confirmDisconnect"><img alt="disconnect-xero" src="/images/apps/xero-disconnect.svg"></div>
				<div class="pointer" @click="initializeOAuth"><img alt="xero-connect" src="/images/apps/xero-connect.svg"></div>
				<v-btn class="secondary-action mr-1" text @click="confirmReset"><v-icon small>lock_reset</v-icon>Reset integration</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import XeroService from "@/modules/integrations/xero/XeroService";

	export default {
		name: 'Xero',

		props: [],

		components: { Connected },

		data: function () {
			return {
				xeroService: new XeroService(),
				isConnected: false,
				expanded: false,
				isReady: false,
				xero: null,
				accounts: [],
				taxRates: [],
			};
		},

		mounted() {
			this.checkConnected();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.isConnected = false;
				this.checkConnected();
			},

			saveConfig: function(){
				this.$store.commit('startLoading');
				this.xeroService.saveSettings(this.xero)
						.then((res) => this.xero = res.data)
						.finally(() => this.$store.commit('stopLoading'));
			},

			checkConnected: function () {
				this.xeroService.getConnection()
					.then((res) => {
						if (res.data) {
							this.isConnected = true;
							this.xero = res.data;
						} else {
							this.isConnected = false;
						}
					})
					.finally(() => (this.isReady = true));
			},

			getAccounts: function () {
				this.xeroService.getAccounts().then((res) => {
					this.accounts.splice(0, this.accounts.length);
					this.accounts.push(...res.data);
				});
			},

			getTaxRates: function () {
				this.xeroService.getTaxRates().then((res) => {
					this.taxRates.splice(0, this.taxRates.length);
					this.taxRates.push(...res.data);
				});
			},

			initializeOAuth: function () {
				let redirectUri = document.location.protocol + '//' + document.location.host + '/integrations/xero/oauth';
				this.xeroService.initializeOAuth(redirectUri).then((res) => {
					window.location.href = res.data;
				});
			},

			confirmReset: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to reset your Xero integration? You should only do this if you have connected a new Xero account.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.xeroService.reset().then(() => {
							this.$store.commit('info','Xero integration has been reset.')
						}).finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			confirmDisconnect: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your Xero account?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.xeroService.revoke().then(() => {
							this.isConnected = false;
							this.expanded = false;
							this.xero = null;
						});
					}
				});
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount && !this.$store.getters.isStarterAccount;
			},

			clearingAccounts: function () {
				return this.accounts.filter((a) => a.enablePaymentsToAccount);
			},

			salesAccounts: function(){
				return this.accounts.filter(a => a.type === 'SALES');
			},

			expenseAccounts: function () {
				return this.accounts.filter((a) => a.type === 'EXPENSE');
			},

			expandedAndConnected: function () {
				return this.expanded && this.isConnected;
			},
		},

		watch: {
			expandedAndConnected: function (val) {
				if (val) {
					this.getAccounts();
					this.getTaxRates();
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
