<template>
	<div id="communicator-channel-filter">
		<v-menu
			max-height="90vh"
			max-width="250"
			v-model="showMenu"
			:close-on-content-click="false"
			:close-on-click="true"
			id="channel-filter"
			nudge-bottom="34"
			content-class="filter-menu-class"
			style="overflow-y: auto"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${channelsSelected ? '' : 'font-gray_60'} brand-medium`"><span v-html="filterLabel"></span></span>
					<v-icon x-small class="mx-1" :color="channelsSelected ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format open-menu">
				<div class="filter-option-box">

					<div>
						<v-checkbox
							v-model="channelsSelected"
							hide-details
							:indeterminate="channelsIndeterminate"
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
									class="client-label row-format pointer"
									style="align-content: center;"
								>
									<div class="tree-label">
										{{ $t('calendar.filter.select-deselect') }}
									</div>
								</div>
							</template>
						</v-checkbox>

						<v-checkbox
							v-model="filter.channels"
							v-for="channel in channelList"
							:value="channel"
							:key="channel"
							hide-details
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
									class="client-label row-format pointer"
									style="align-content: center;"
								>
									<v-icon class="mr-1 material-symbols-rounded">{{getIcon(channel)}}</v-icon>
									<div class="tree-label">{{ channel }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'ChannelFilter',

		props: ['filter'],

		components: {  },

		data: function() {
			return {
				showMenu: false,
				channelList: ['Emails','Messages','Calls']
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			saveFilter: function(){
				this.showMenu = false;
			},

			addAllChannels() {
				this.removeAllChannels();
				this.channelList.forEach(c => this.filter.channels.push(c));
			},

			removeAllChannels() {
				this.filter.channels.splice(0,this.filter.channels.length);
			},

			getIcon: function(channel){
				if(channel === 'Calls'){
					return 'phone';
				}else if(channel === 'Messages'){
					return 'chat';
				}else if(channel === 'Emails'){
					return 'email';
				}else if(channel.includes('requests')){
					return 'description';
				}else{
					return null;
				}
			}
		},

		watch: {
			menu: function(newVal) {
				if (!newVal) {
					this.$emit('updated');
				}
			},
		},

		computed: {
			channelsSelected: {
				set: function(val) {
					if (val) return this.addAllChannels();
					return this.removeAllChannels();
				},
				get: function() {
					if (this.filter.channels.length > 0) return true;
					return false;
				},
			},

			channelsIndeterminate: function() {
				if (this.filter && this.filter.channels && this.filter.channels.length == this.channelList.length) return false;
				if (this.filter && this.filter.channels && this.filter.channels.length == 0) return false;
				return true;
			},

			filterLabel: function() {
				if(!this.isDefaultFilter){
					return this.filter.channels.join(", ");
				}else{
					return 'All channels'
				}
			},

			isDefaultFilter: function(){
				if (!this.filter.channels.length || this.filter.channels.length === this.channelList.length){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style lang="scss">
	#communicator-channel-filter {
		position: relative;
		.clear-filter-in-activator {
			position: absolute;
			right: 6px;
		}

		.active {
			background-color: var(--v-gray_20-base) !important;
			color: var(--v-secondary-base) !important;
			border: 1px solid var(--v-gray_50-base) !important;
		}
	}



	.open-menu {
		background-color: var(--v-white-base);
		max-width: 250px;
		.client-label .tree-label {
			text-overflow: ellipsis !important;
			overflow: hidden;
			white-space: nowrap;
			max-width: 160px;
		}
	}

	.status-color {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		background-color: var(--status-color);
	}
</style>
