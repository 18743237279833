<template>
	<div id="project-selector">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<div v-if="currentView === 'SELECT'">
			<div class="modal-padding">
				<h2 class="modal-title">{{ $t('projects.add-deliverable') }}</h2>
				<div class="modal-subtitle">{{ $t('projects.add-deliverable-sub') }}</div>

				<div class="mt-6">
					<v-text-field
						v-if="projects.length > 5"
						v-model="search"
						placeholder="Filter by project or client"
						persistent-placeholder
						hide-details
						autofocus
						clearable
						outlined
						prepend-inner-icon="$filter"
						class="project-filter"
					>
					</v-text-field>
				</div>
				<div>
					<v-data-table
						id="data-table"
						:items-per-page="5"
						:hide-default-footer="projects.length <= 5"
						:hide-default-header="true"
						:headers="headers"
						:items="projects"
						@click:row="selectProject($event)"
						:search="search"
						:custom-filter="filterList"
						:mobile-breakpoint="0"
					>
						<template v-slot:item.name="{ item }">
							<div class="project-wrapper">
								<client-avatar disable-click :client="item.client"></client-avatar>
								<div class="project-name">{{ item.name }}</div>
							</div>
						</template>
					</v-data-table>
				</div>
				<div class="mt-8" v-if="!isCollaborator">
					<span class="internal-link" @click="createProject()">
						<v-icon size="12" color="primary">$plus</v-icon>
						<span class="ml-2">{{ $t('projects.create') }}</span>
					</span>
				</div>
			</div>
		</div>

		<div v-if="currentView === 'CREATE'">
			<v-form @submit.prevent v-model="formValid">
				<div class="modal-padding column-format gap-3">
					<h2 class="modal-title">
						<v-btn icon @click.native="backToSelect()" v-if="projects.length > 0 && !skipDeliverable">
							<v-icon>$arrowLeft</v-icon>
						</v-btn>
						{{ clientCreate ? $t('projects.create-project-client') : $t('projects.create') }}
					</h2>

					<div class="row-format gap-2">
						<v-text-field
							style="flex: 1"
							dense outlined
							persistent-placeholder
							hide-details
							autofocus
							:rules="nameRules"
							v-model="newProjectName"
							:label="$t('projects.project-name')"
						></v-text-field>

						<v-select
							v-if="!deliverablePackageId"
							style="flex: 1"
							item-text="name"
							item-value="id"
							:items="projectTypes"
							v-model="projectTypeId"
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Project type"
						></v-select>
					</div>

					<v-select
						v-if="isPaid && packageList.length"
						hide-details
						persistent-placeholder
						dense outlined
						:label="$t('templates.start-from-template')"
						:items="packageList"
						item-text="name"
						item-value="id"
						clearable
						v-model="deliverablePackageId"
						@change="handleTemplateChange()"
					>
					</v-select>

					<div v-if="!clientCreate && !clientId">
						<v-autocomplete
								dense outlined
							persistent-placeholder
							hide-details
							v-model="selectedClient"
							:label="$t('projects.select-client')"
							:items="clientItems"
							item-text="name"
							return-object
							:clearable="false"
							:rules="[(v) => !!v || 'Field is required']"
							:menu-props="{ contentClass: 'text-left' }"
							style="font-size: 14px; text-align: left !important;"
							@change="handleSelectedClientChange()"
						></v-autocomplete>
					</div>
					<div v-if="clientCreate && !clientId">
						<v-text-field
								dense outlined
							persistent-placeholder
							hide-details
							:rules="nameRules"
							v-model="newClientName"
							:label="$t('client.create-new-client')"
						></v-text-field>
					</div>
					<div v-if="!clientCreate && !clientId">
						<span class="internal-link" @click="createClient()"
							>{{ $t('projects.create-client') }}
							<v-icon size="12" style="color: var(--v-primary-base)">$plus</v-icon>
						</span>
					</div>
					<div v-if="clientCreate && !clientId">
						<span class="internal-link" @click="selectClient()"
							>{{ $t('projects.or-select-client') }}
							<v-icon size="12" style="color: var(--v-primary-base)">$chevronDown</v-icon>
						</span>
					</div>
				</div>
				<div class="modal-footer mt-6">
					<v-btn class="secondary-action mr-1" width="150" @click="$emit('result')">{{ $t('global.cancel') }}</v-btn>
					<v-btn class="primary-action ml-1" width="150" :disabled="!formValid" @click="initializeItems()">{{
						$t('global.save')
					}}</v-btn>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import ProjectService from './ProjectService';
	import ClientAvatar from '../../components/ClientAvatar';
	import ClientService from '../clients/ClientService';
	import ClientPalette from '../clients/ClientPalette';
	import TemplateService from '@/modules/templates/TemplateService';
	// import SelectClient from '@/modules/clients/SelectClient';

	export default {
		name: 'ProjectSelector',

		props: ['openState', 'isCollaborator', 'skipDeliverable', 'projectFilter', 'clientId'],

		components: {
			// SelectClient,
			ClientAvatar,
		},

		data: function() {
			return {
				projectService: new ProjectService(),
				clientService: new ClientService(),
				clientPalette: new ClientPalette(),
				templateService: new TemplateService(),
				selectedClient: null,
				newProjectName: null,
				newClientName: null,
				projects: [],
				clients: [],
				sortCol: null,
				sortAscending: true,
				search: null,
				currentView: this.skipDeliverable ? 'CREATE' : 'SELECT',
				clientCreate: false,
				formValid: false,
				projectTypeId: null,

				nameRules: [(v) => (v && v.trim().length >= 1) || 'Name is required'],

				headers: [{ text: this.$t('client.project'), name: 'project', value: 'name', sortable: true }],

				packageList: [],
				deliverablePackageId: null,
			};
		},

		mounted() {
			this.projectTypeId = this.projectTypes.find((p) => p.isDefaultProjectType).id;

			if (this.currentView === 'CREATE') {
				this.getPackageList();
			}
			this.projectService
				.getAllActiveProjects()
				.then((res) => {
					this.projects = res.data;
					this.projects.sort(this.sort);

					if (this.projectFilter && this.projectFilter.length) {
						this.projects = this.projects.filter((p) => this.projectFilter.includes(p.id));
					}

					if (this.clientId) {
						this.projects = this.projects.filter((p) => p.clientId === this.clientId);
						this.selectedClient = { id: this.clientId };
					}

					if (this.projects.length === 0 || this.skipDeliverable) {
						this.createProject();
					}
				})
				.catch((err) => {
					this.$store.commit('error', err.response.data.message);
				});
		},

		beforeDestroy() {},

		methods: {
			initializeItems: function() {
				if (this.clientCreate) {
					this.clientService
						.postClient(
							{
								name: this.newClientName,
								color: this.clientPalette.getRandomColor(),
							},
							null
						)
						.then((res) => {
							this.initializeProject(res.data);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				} else {
					this.initializeProject(this.selectedClient);
				}
			},

			getPackageList: function() {
				this.templateService.getDeliverablePackages().then((res) => {
					this.packageList = [];
					this.packageList.push(...res.data);
				});
			},

			initializeProject: function(client) {
				this.$store.commit('startLoading');

				if (this.deliverablePackageId) {
					this.templateService
						.createProject(this.deliverablePackageId, client.id, this.newProjectName)
						.then((res) => {
							let project = res.data;
							project.client = client;
							project.isTemplate = true;
							this.$emit('result', { type: 'project-created', project: project });
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						})
						.finally(() => {
							this.$store.commit('stopLoading');
							this.processing = false;
						});
				} else {
					this.projectService
						.initializeNewProject(client.id, this.newProjectName, this.projectTypeId)
						.then((res) => {
							let project = res.data;
							project.client = client;
							this.$emit('result', { type: 'project-created', project: project });
						})
						.catch((err) => {
							this.$emit('error', err);
							this.$store.commit('error', err.response.data.message);
						})
						.finally(() => {
							this.$store.commit('stopLoading');
							this.processing = false;
						});
				}
			},

			createProject: function() {
				this.getPackageList();
				this.clientService.getClientList().then((res) => {
					this.clients = res.data;
					if (this.clients.length === 0) {
						this.clientCreate = true;
					}
					this.clients.sort(this.sortClient);
					this.currentView = 'CREATE';
				});
			},

			createClient: function() {
				this.clientCreate = true;
			},

			selectClient: function() {
				this.clientCreate = false;
			},

			selectProject: function(row) {
				this.$emit('result', { type: 'project-selected', project: row });
			},

			backToSelect: function() {
				this.currentView = 'SELECT';
				this.selectedClient = null;
				this.newProjectName = null;
				this.newClientName = null;
				this.clientCreate = false;
			},

			filterList: function(value, search, item) {
				if (
					(item.name && item.name.toUpperCase().startsWith(search.toUpperCase())) ||
					(item.client.name && item.client.name.toUpperCase().startsWith(search.toUpperCase()))
				) {
					return true;
				} else {
					return false;
				}
			},

			internalSort: function(a, b) {
				if (a > b) {
					return 1;
				} else if (a < b) {
					return -1;
				} else {
					return 0;
				}
			},

			sortClient: function(a, b) {
				return this.internalSort(a.name, b.name);
			},

			sort: function(a, b) {
				return this.internalSort(a.client.name, b.client.name);
			},

			handleClose() {
				this.$emit('result');
			},

			handleSelectedClientChange() {
				if (this.selectedClient.id === null) this.selectedClient = null;
			},

			handleUpgradeClick() {
				// this.$router.push('/subscription');
				this.$emit('result', { type: 'navigate-to-subscription' });
			},

			handleTemplateChange: function() {
				if (this.deliverablePackageId) {
					let pkg = this.packageList.find((p) => {
						return p.id === this.deliverablePackageId;
					});
					// console.log(pkg);
					if (!this.newProjectName) {
						this.newProjectName = pkg.name;
					}
				}
			},
		},

		computed: {
			clientItems() {
				let items = this.clients;
				if (this.selectedClient) {
					items.unshift({ name: '--Clear--', id: null });
				} else {
					let index = items.findIndex((v) => v.id === null);
					if (index === 0) items.shift();
				}
				return items;
			},

			projectTypes: function() {
				let result = [];
				this.$store.state.projectTypes.forEach((p) => {
					result.push({
						id: p.id,
						name: p.name,
						isDefaultProjectType: p.isDefaultProjectType,
					});
				});
				return result;
			},

			account() {
				return this.$store.getters.getAccount;
			},

			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},
		},

		watch: {
			openState: function(newVal) {
				if (!newVal) {
					this.backToSelect();
				}
			},
			selectedClient: function(val) {
				console.log(val);
			},
		},
	};
</script>

<style lang="scss">
	#project-selector {
		width: 500px;
		@media #{map-get($display-breakpoints, 'sm-and-down')} {
			width: auto;
			max-width: 500px;
		}

		.v-input.project-filter {
			.v-input__slot {
				//border: 1px solid var(--v-gray_50-base);
				//border-radius: 4px;
				min-height: 36px;
				padding-left: 8px;
				padding-right: 8px;
				font-size: 14px;
				.v-input__prepend-inner {
					margin-top: 8px;
				}
				.v-input__append-inner {
					margin-top: 6px;
				}
				&:before,
				&:after {
					border-style: none !important;
				}
			}
		}
		#data-table {
			.text-start {
				//border: 1px solid red;
				padding: 0 !important;
				display: flex;
				align-items: center;
				cursor: pointer;
				justify-items: flex-start;

				.project-wrapper {
					flex: 1 1 auto;
					display: flex;
					align-items: center;
					justify-items: flex-start;
					padding: 0 8px;

					.avatar {
						flex: 0 0 32px;
						margin-right: 12px !important;
					}

					.project-name {
						//border: 1px solid blue;
						font-weight: 500;
						flex: 1 0 auto;
					}
				}
			}
			@media #{map-get($display-breakpoints, 'sm-and-down')} {
				.v-data-footer {
					padding: 0;
					flex-wrap: nowrap;
					justify-content: space-evenly;
					.v-data-footer__pagination {
						margin: 0 16px !important;
					}
				}
			}
		}
		.internal-link {
			cursor: pointer;
			font-weight: 500;
			font-size: 14px;
			color: var(--v-primary-base);
		}
	}
</style>
