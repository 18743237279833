<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Accounting' }]">
			Vendors
		</page-header>

		<div class="row-format align-center mb-4">
			<v-text-field
					outlined
					hide-details
					dense
					class="standard-input"
					v-model="filter"
					placeholder="Search..."
					:style="`max-width: 200px;`"
					color=""
			>
				<template v-slot:prepend-inner
				><span class="material-symbols-rounded font-gray_50">search</span></template
				>
			</v-text-field>
			<v-btn class="ml-auto super-action" @click="createVendor"><v-icon size="20">add</v-icon> Add vendor</v-btn>
		</div>

		<v-data-table
			id="vendors-table"
			v-if="vendors"
			class="list-view"
			fixed-header
			:headers="headers"
			:items="filteredVendors"
			:items-per-page="-1"
			:hide-default-footer="true"
		>
			<template v-slot:item="{ item }">
				<tr style="cursor: pointer" class="text-left" :key="item.id" @click="editVendor(item)">
					<td>{{ item.name }}</td>
					<td>{{ formatVendorContact(item.contact) }}</td>
					<td v-html="formatVendorAddress(item)"></td>
					<td>{{ formatBalanceDue(item) }}</td>
				</tr>
			</template>
		</v-data-table>

		<basic-modal
			v-if="vendorDetailDialog"
			:key="vendorToEdit.id"
			:dialog="vendorDetailDialog"
			@close="vendorDetailDialog = false"
			ref="editModal"
		>
			<vendor-detail
				:vendor="vendorToEdit"
				:vendors="vendors"
				@created="handleCreate($event)"
				@updated="handleUpdate($event)"
				@delete-vendor-dialog="handleDeleteVendor($event)"
				@close="vendorDetailDialog = false"
			></vendor-detail>
		</basic-modal>
		<confirm-dialog
			:dialog="deleteVendorDialog"
			icon="$vendor"
			:heading-text="$t('vendors.delete-confirm-heading')"
			:body-text="$t('vendors.delete-confirm-body')"
			@confirm="
				deleteVendor(deletingVendor);
				deleteVendorDialog = false;
			"
			@close="deleteVendorDialog = false"
		></confirm-dialog>
	</div>
</template>

<script>
	import VendorDetail from '@/modules/accounting/vendors/VendorDetail';
	import VendorMixin from '@/modules/accounting/vendors/VendorMixin';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';
	import BasicModal from '@/components/BasicModal';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'Vendors',

		props: [],

		components: { VendorDetail, BasicModal, ConfirmDialog, PageHeader },
		mixins: [VendorMixin, AccountingMixin],

		data: function() {
			return {
				headers: [
					{ text: this.$t('vendors.table-header.vendor-name'), value: 'name', class: 'nowrap' },
					{ text: this.$t('vendors.table-header.contact'), value: 'category', class: 'nowrap' },
					{ text: this.$t('global.address'), value: 'address', class: 'nowrap', sortable: false },
					{ text: this.$t('vendors.table-header.balance-due'), value: 'paid', class: 'nowrap' },
				],
				itemsPerPage: 15,
				deleteVendorDialog: false,
				deletingVendor: null,
				filter: '',
			};
		},

		mounted() {
			localStorage.setItem('ACCOUNTING_PAGE', 'vendors');
			this.$track.record('page-view', { module: 'accounting-vendors' });
			this.$store.state.eventBus.$on('account-changed', this.getVendors);
			this.getVendors();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getVendors);
		},

		methods: {
			saveFilter(event) {
				console.log(event);
				this.filter = event;
			},
			handleDeleteVendor(vendor) {
				this.deletingVendor = vendor;
				this.deleteVendorDialog = true;
			},
			goToExpenses() {
				this.$router.push('/expenses');
			},
			formatVendorContact(contact) {
				if (!contact) return '';
				if (contact.email) return contact.email;
				return ((contact.firstName ?? '') + ' ' + (contact.lastName ?? '')).trim();
			},
			formatVendorAddress(vendor) {
				let lines = [];
				lines.push(((vendor.address1 ?? '') + ' ' + (vendor.address2 ?? '')).trim());
				let cityState = [];
				if (vendor.city) cityState.push(vendor.city);
				if (vendor.locality) cityState.push(vendor.locality);
				lines.push((cityState.join(', ') + ' ' + (vendor.postal ?? '')).trim());
				return lines
					.filter((line) => line)
					.join('<br>')
					.trim();
			},
			formatBalanceDue(vendor) {
				// console.log(vendor);
				return this.$formatters.dollars(vendor.balanceDue, true, true, this.$store.state.defaultCurrency);
			},
		},

		computed: {
			filteredVendors() {
				return this.vendors.filter((vendor) => {
					// search filter
					if (this.$validations.isEmpty(this.filter) || this.$validations.isEmpty(vendor.name)) {
						return true;
					} else if (vendor.name.toLowerCase().includes(this.filter.toLowerCase())) {
						return true;
					} else if (vendor.contact) {
						if (
							vendor.contact.lastName &&
							vendor.contact.lastName.toLowerCase().includes(this.filter.toLowerCase())
						) {
							return true;
						} else if (
							vendor.contact.email &&
							vendor.contact.email.toLowerCase().includes(this.filter.toLowerCase())
						) {
							return true;
						} else if (vendor.city && vendor.city.toLowerCase().includes(this.filter.toLowerCase())) {
							return true;
						} else if (vendor.postal && vendor.postal.toLowerCase().includes(this.filter.toLowerCase())) {
							return true;
						}
					}
					return false;
				});
			},
		},
	};
</script>

<style lang="scss"></style>
