<template>
	<div v-if="filter" id="calendar-filter">
		<v-menu
				:close-on-content-click="false"
				nudge-bottom="34"
				left
				content-class="filter-menu-class"
				v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${!isDefaultFilter ? '' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</span>
					<v-icon x-small class="mx-1" :color="!isDefaultFilter ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format open-menu">
				<!-- Category Filter -->
				<div class="filter-option-box">
					<div class="row-format" @click="categoryExpanded = !categoryExpanded">
						<div class="font-14">{{ $t('calendar.filter.categories') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
								categoryExpanded ? '$arrowUp' : '$arrowDown'
							}}</v-icon>
					</div>
					<div v-if="categoryExpanded">
						<v-checkbox
								v-model="categorySelected"
								hide-details
								:indeterminate="categoryIndeterminate"
								class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
										class="client-label row-format pointer"
										style="align-content: center;"
								>
									<div class="tree-label">
										{{ $t('calendar.filter.select-deselect') }}
									</div>
								</div>
							</template>
						</v-checkbox>

						<v-checkbox
								v-model="filter.categories"
								v-for="category in categories"
								:value="category.value"
								:key="category.value"
								hide-details
								class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div class="client-label row-format pointer align-center">
									<v-icon size="20" color="gray_80">{{category.icon}}</v-icon>
									<div class="tree-label ml-2">{{ category.label }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>

				<!-- Client Filter -->
				<div class="filter-option-box">
					<div class="row-format" @click="clientExpanded = !clientExpanded">
						<div class="font-14">{{ $t('invoice.filter.client') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							clientExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>
					<div v-if="clientExpanded">
						<v-checkbox
							v-model="clientsSelected"
							hide-details
							:indeterminate="clientsIndeterminate"
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
									class="client-label row-format pointer"
									style="align-content: center;"
								>
									<div class="tree-label">
										{{ $t('calendar.filter.select-deselect') }}
									</div>
								</div>
							</template>
						</v-checkbox>

						<v-checkbox
							v-model="filter.clients"
							v-for="client in clientList"
							:value="client.id"
							:key="client.id"
							hide-details
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div
									class="client-label row-format pointer"
									style="align-content: center;"
								>
									<client-avatar :small="true" left :client="client" :disable-click="true"></client-avatar>
									<div class="tree-label">{{ client.name }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>

				<filter-option
						label="User filter"
						:option-list="userList"
						v-model="filter.users"
				></filter-option>

				<div class="mx-3 mt-3 mb-3">
					<v-btn text block color="secondary" elevation="0" class="filter-clear mb-2" @click="$emit('reset-filter')"
					> <v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn
					>
					<v-btn class="super-action" @click="saveFilter()" color="primary" block>{{
							$t('global.done')
						}}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import FilterOption from "@/components/FilterOption";

	export default {
		name: 'CalendarFilter',

		props: ['filter','categories'],

		components: { ClientAvatar, FilterOption },

		data: function() {
			return {
				showMenu: false,
				categoryExpanded: true,
				clientExpanded: false,
				statusExpanded: false,
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			saveFilter: function(){
				this.showMenu = false;
			},

			addAllCategories(){
				this.removeAllCategories();
				this.categories.forEach(c => this.filter.categories.push(c.value));
			},

			removeAllCategories(){
				this.filter.categories.splice(0,this.filter.categories.length);
			},

			addAllClients() {
				this.removeAllClients();
				this.clientList.forEach(c => this.filter.clients.push(c.id));
			},

			removeAllClients() {
				this.filter.clients.splice(0,this.filter.clients.length);
			},

			sort(a, b) {
				if (a.name > b.name) {
					return 1;
				} else if (a.name < b.name) {
					return -1;
				} else {
					return 0;
				}
			},
		},

		watch: {
			menu: function(newVal) {
				if (!newVal) {
					console.log('updating');
					this.$emit('updated');
				}
			},
		},

		computed: {
			userList: function() {
				let result = [];
				this.$store.state.usersForAccount.forEach((u) => {
					result.push({
						text: u.firstName + ' ' + u.lastName,
						value: u.userId,
					});
				});

				result.sort((a, b) => a.text.localeCompare(b.text));
				return result;
			},

			clientList: function(){
				return this.$store.getters.clients;
			},

			clientsSelected: {
				set: function(val) {
					if (val) return this.addAllClients();
					return this.removeAllClients();
				},
				get: function() {
					if (this.filter.clients.length > 0) return true;
					return false;
				},
			},

			categorySelected: {
				set: function(val) {
					if (val) return this.addAllCategories();
					return this.removeAllCategories();
				},
				get: function() {
					if (this.filter.categories.length > 0) return true;
					return false;
				},
			},

			clientsIndeterminate: function() {
				if (this.filter.clients.length === this.clientList.length) return false;
				if (this.filter.clients.length === 0) return false;
				return true;
			},

			categoryIndeterminate: function() {
				if (this.filter.categories.length === this.categories.length) return false;
				if (this.filter.categories.length === 0) return false;
				return true;
			},

			filterLabel: function() {
				if(!this.isDefaultFilter){
					let text = [];

					if (this.filter.categories.length !== this.categories.length){
						text.push(this.filter.categories.length + ' categories');
					}

					if(this.filter.clients.length && this.filter.clients.length < this.clientList.length){
						text.push(this.filter.clients.length + ' clients');
					}

					return text.join(" &bull; ")
				}else{
					return 'Filter'
				}
			},

			isDefaultFilter: function(){
				if (this.filter.categories.length === this.categories.length && !this.filter.clients.length){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style lang="scss">
	#calendar-filter {
		position: relative;
		.clear-filter-in-activator {
			position: absolute;
			right: 6px;
		}
	}
	.open-menu {
		background-color: var(--v-white-base);
		max-width: 250px;
		.client-label .tree-label {
			text-overflow: ellipsis !important;
			overflow: hidden;
			white-space: nowrap;
			max-width: 160px;
		}
	}

	.status-color {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		background-color: var(--status-color);
	}
</style>
