<template>
	<div class="subscription-blocker">
		<div class="blocker-content">
			<!-- Optional icon using Google Material Symbols (outlined style) -->
			<span class="material-symbols-rounded lock-icon">lock</span>
			<h1 class="title">Feature Unavailable</h1>
			<p class="description">
				Your current subscription tier does not include this feature. Upgrade now to get access!
			</p>
			<div class="button-group">
				<v-btn class="super-action" @click="onUpgrade">Upgrade Subscription</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FeatureNotAvailable',
	mounted() {
		this.$store.state.leftNavExpanded = false;
	},
	methods: {
		onUpgrade() {
			// Trigger the upgrade process
			console.log('Upgrade button clicked');
			// For example, navigate to the upgrade page
			this.$router.push('/subscription');
		},
		onClose() {
			// Handle closing the blocker, e.g., navigating back or hiding the component
			console.log('Close button clicked');
			this.$emit('close');
		},
	},
};
</script>

<style scoped>
.subscription-blocker {
	/* Make sure the component fills the available space in its container */
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	min-height: 100%;
}

.blocker-content {
	padding: 2rem 3rem;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	background-color: var(--v-gray_5-base);
	max-width: 500px;
	width: 100%;
}

.lock-icon {
	font-size: 3rem;
	color: var(--v-primary-base);
	margin-bottom: 1rem;
}

.title {
	font-size: 1.75rem;
	margin-bottom: 1rem;
	color: var(--v-primary-base);
}

.description {
	font-size: 1rem;
	margin-bottom: 2rem;
	color: #555;
}

.button-group {
	display: flex;
	justify-content: center;
	gap: 1rem;
	flex-wrap: wrap;
}
</style>