<template>
	<div class="column-format gap-4">
		<v-autocomplete
				:disabled="emailTemplates.length === 0"
				hide-details
				dense
				outlined
				label="Confirmation email template"
				:items="emailTemplates"
				item-value="id"
				item-text="name"
				clearable
				v-model="confirmationEmailTemplate"
				@change="updated()"
		></v-autocomplete>

		<v-select
				:disabled="pipelineStages.length === 0"
				hide-details
				dense
				outlined
				label="Add submission to pipeline"
				:items="pipelineStages"
				item-value="id"
				item-text="label"
				clearable
				v-model="pipelineStageId"
				@change="updated()"
		>
			<template v-slot:item="{ item }">
				<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
				<div class="ml-2">{{item.label}}</div>
			</template>
			<template v-slot:selection="{ item }">
				<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
				<div class="ml-2">{{item.label}}</div>
			</template>
		</v-select>

		<v-select
				:disabled="ticketTypes.length === 0"
				hide-details
				dense
				outlined
				label="Create ticket from public form"
				:items="ticketTypes"
				item-value="id"
				item-text="type"
				clearable
				v-model="ticketTypeId"
				@change="updated()"
		>
		</v-select>

		<v-text-field
				label="Redirect URL (after submit)"
				placeholder="https://www.yourwebsite.com/confirmation"
				persistent-placeholder
				hide-details
				dense
				outlined
				v-model="confirmationRedirect"
				@input="updated()"
		></v-text-field>

		<div :style="`font-family: ${template.font}`">
			<editor
					ref="textEditor"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="confirmationText"
					:inline="false"
					:init="mceConfigText"
					:spellcheck="true"
					@input="handleTextInput"
			></editor>
		</div>

		<v-switch class="mt-n2" v-model="sendResultsInEmail" @change="updated()" label="Send results in notification email" hide-details dense v-tippy="{content: 'If your form is gathering sensitive information, you can disable this to prevent Moxie from sending the answers via email'}"></v-switch>

		<v-switch hide-details dense label="Link active" v-model="active" class="mt-n2" @change="updated()" v-tippy="{content: 'Determines whether the form is currently accessible via the public link.'}"></v-switch>

		<v-switch hide-details dense label="Embedded ReCaptcha" v-model="reCaptchaEnabled" class="mt-n2" @change="updated()" v-tippy="{content: 'Require Google ReCaptcha when embedding form on external websites.'}"></v-switch>
	</div>
</template>

<script>
	import EmailTemplateService from "@/modules/templates/EmailTemplateService";
	import PipelineSettingsService from "@/modules/pipeline/settings/PipelineSettingsService";
	import CommunicatorTicketService from "@/modules/communicator/CommunicatorTicketService";

	export default {
		name: 'FormSettingsConfig',

		props: ['template'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				confirmationEmailTemplate: this.template.confirmationEmailTemplate,
				pipelineStageId: this.template.pipelineStageId,
				ticketTypeId: this.template.ticketTypeId,
				confirmationRedirect: this.template.confirmationRedirect,
				confirmationText: this.template.confirmationText,
				sendResultsInEmail: this.template.sendResultsInEmail,
				active: this.template.active,
				reCaptchaEnabled: this.template.reCaptchaEnabled,
				emailTemplates: [],
				pipelineStages: [],
				ticketTypes: [],
				mceConfigText: {
					menubar: false,
					inline: false,
					browser_spellcheck: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					height: 500,
					statusbar: false,
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'bold italic underline forecolor | formatting_group | alignleft aligncenter alignright',
					],
					fontsize_formats: "10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 40px 48px",
					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items: 'fontsizeselect fontselect | blockquote bullist numlist indent outdent table | removeformat'
						},
					},
				},
			};
		},

		mounted() {
			this.getEmailTemplates();
			this.getPipelineStages();
			this.getTicketTypes();
		},

		beforeDestroy() {},

		methods: {
			getPipelineStages: function(){
				new PipelineSettingsService().getPipelineStages().then((res) => {
					this.pipelineStages.splice(0,this.pipelineStages.length);
					this.pipelineStages.push(... res.data.stages);
				})
			},

			getTicketTypes: function(){
				new CommunicatorTicketService().getTicketSettings().then((res) => {
					this.ticketTypes.splice(0,this.ticketTypes.length);
					this.ticketTypes.push(... res.data.ticketTypes);
				})
			},

			getEmailTemplates: function () {
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0, this.emailTemplates.length);
					this.emailTemplates.push(...res.data);
					this.emailTemplates.sort((a,b) => {
						let aName = a.name ? a.name : "";
						let bName = b.name ? b.name : "";
						aName.localeCompare(bName);
					});
				});
			},

			updated: function(){
				this.$emit('change',{
					confirmationEmailTemplate: this.confirmationEmailTemplate,
					pipelineStageId: this.pipelineStageId,
					confirmationRedirect: this.confirmationRedirect,
					confirmationText: this.confirmationText,
					sendResultsInEmail: this.sendResultsInEmail,
					ticketTypeId: this.ticketTypeId,
					active: this.active,
					reCaptchaEnabled: this.reCaptchaEnabled
				})
			},

			handleTextInput: function () {
				this.confirmationText = this.$refs.textEditor.editor.getContent();
				this.updated();
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
