<template>
	<div>
		<page-header
			class="pb-6"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ to: '/projects/list', label: 'Project management' },
			]"
		>
			Templates
		</page-header>

		<div class="row-format align-center mb-4">
			<v-text-field
				outlined
				hide-details
				dense
				v-model="search"
				placeholder="Search..."
				class="standard-input"
				style="max-width: 200px; background-color: var(--v-white-base)"
				color="gray_30"
			>
				<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
			</v-text-field>
			<v-btn class="ml-auto super-action" @click="addNew()"><v-icon size="20">add</v-icon> Add template</v-btn>
		</div>

		<v-data-table
			:headers="headers"
			:items="filteredPackagedTemplates"
			:sort-desc="false"
			sort-by="name"
			style="cursor: pointer"
			:items-per-page="-1"
			:hide-default-footer="true"
			@click:row="editPackage($event)"
		>
			<template v-slot:item.defaultTimeRelation="{ item }">
				<v-icon small>$calendarMono</v-icon>
				{{ totalTimeline(item.deliverables) }}
			</template>

			<template v-slot:item.formattedFees="{ item }">
				<span v-html="formattedFees(item)"></span>
			</template>

			<template v-slot:item.action="{ item }">
				<v-menu close-on-content-click close-on-click :nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-1">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click.stop="editPackage(item)">Edit</div>
						<div class="more-menu-item" @click.stop="duplicatePackage(item)">Duplicate</div>
						<div class="more-menu-item" @click.stop="deletePackage(item)">Delete</div>
					</div>
				</v-menu>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import TemplateService from '@/modules/templates/TemplateService';
	import Template from '@/modules/templates/Template';
	import ConfirmModal from '@/components/ConfirmModal';
	import DeliverablePackageEdit from '@/modules/templates/deliverables/DeliverablePackageEdit';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'DeliverablePackageTemplates',

		props: ['id'],

		components: { PageHeader },

		data() {
			return {
				templateService: new TemplateService(),
				isReady: false,
				templateList: [],
				search: null,
			};
		},

		mounted() {
			Template.setLastViewed('packages');
			this.$store.state.eventBus.$on('account-changed', this.getTemplateList);
			this.getTemplateList();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getTemplateList);
		},

		methods: {
			getTemplateList() {
				this.templateService
					.getDeliverablePackages()
					.then((res) => {
						this.templateList = [];
						this.templateList.push(...res.data);
						this.isReady = true;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			addNew: function() {
				let pkg = {};
				this.templateService.postDeliverablePackage(pkg).then((res) => {
					this.templateList.push(res.data);
					this.editPackage(res.data);
				});
			},

			editPackage(pkg) {
				this.$store.state.globalModalController.openModal(DeliverablePackageEdit, { id: pkg.id }).then((res) => {
					if (res) {
						let ix = this.templateList.findIndex((t) => t.id === res.id);
						if (res.action === 'DELETED') {
							this.templateList.splice(ix, 1);
						} else {
							this.templateList.splice(ix, 1, res);
						}
					}
				});
			},

			duplicatePackage(copyTarget) {
				let template = JSON.parse(JSON.stringify(copyTarget));
				template.id = null;
				template.name = 'Copy of: ' + template.name;

				this.templateService
					.postDeliverablePackage(template)
					.then((res) => {
						this.templateList.push(res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			deletePackage(item) {
				let binding = {
					headingText: this.$t('templates.packages.delete-package-title'),
					bodyText: this.$t('templates.packages.delete-package-body', { name: item.name }),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.templateService.deletePackage(item.id).then(() => {
							let ix = this.templateList.findIndex((t) => t.id === item.id);
							if (ix > -1) {
								this.templateList.splice(ix, 1);
							}
						});
					}
				});
			},

			totalTimeline(deliverables) {
				let maxDays = 0;
				for (let i = 0; i < deliverables.length; i++) {
					let deliv = deliverables[i];
					let days;

					if (!deliv.dueDate) continue;
					switch (deliv.dueDate.timeUnit) {
						case 'DAYS':
							days = deliv.dueDate.duration;
							break;
						case 'WEEKS':
							days = 7 * deliv.dueDate.duration;
							break;
						case 'MONTHS':
							days = 30 * deliv.dueDate.duration;
							break;
						default:
							continue;
					}

					if (days > maxDays) {
						maxDays = days;
					}
				}

				if (Math.ceil(maxDays % 30) === 0) {
					return this.$tc('templates.packages.duration.months-total', maxDays / 30);
				}

				if (Math.ceil(maxDays % 7) === 0) {
					return this.$tc('templates.packages.duration.weeks-total', maxDays / 7);
				}

				return this.$tc('templates.packages.duration.days-total', maxDays);
			},

			formattedFees(deliverablePackage) {
				if (deliverablePackage.feeSchedule) {
					let amount = 0;

					if (deliverablePackage.feeSchedule.amount) {
						amount = this.$formatters.dollars(
							deliverablePackage.feeSchedule.amount,
							false,
							true,
							this.$store.state.defaultCurrency
						);
					}

					switch (deliverablePackage.feeSchedule.feeType) {
						case 'Hourly': {
							return amount + '/hr';
						}

						case 'Fixed Price': {
							return amount + '/fixed';
						}

						case 'Retainer': {
							if (deliverablePackage.feeSchedule.retainerSchedule) {
								return amount + '/' + deliverablePackage.feeSchedule.retainerSchedule.toLowerCase();
							} else {
								return amount + '/?';
							}
						}

						case 'Per Item': {
							return 'Per Item Pricing';
						}

						default: {
							return '<span class="font-gray_70">Setup fees</span>';
						}
					}
				} else {
					return '<span class="font-gray_70">Setup fees</span>';
				}
			},
		},

		watch: {},

		computed: {
			headers() {
				return [
					{ text: 'Name', value: 'name' },
					{ text: 'Timeline', value: 'defaultTimeRelation' },
					{ text: 'Project fee type', value: 'formattedFees' },
					{ text: '', value: 'action', align: 'right' },
				];
			},

			filteredPackagedTemplates: function() {
				if (this.search) {
					return this.templateList.filter((t) => {
						let search = this.search.toLowerCase();

						return (
							t.name.toLowerCase().includes(search) ||
							t.deliverables
								.map((d) => d.name)
								.join(' ')
								.toLowerCase()
								.includes(search)
						);
					});
				} else {
					return this.templateList;
				}
			},

			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},
		},
	};
</script>

<style lang="scss"></style>
