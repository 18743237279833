import PortalConfig from "@/modules/portal/PortalConfig";


export default [
	{
		path: '/portal',
		redirect: '/portal/customization'
	},
	{
		path: '/portal/:view',
		name: 'portal',
		component: PortalConfig,
		title: 'Portal',
		props: route => ({
			v: route.params.view,
		})
	}
];
