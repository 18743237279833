import { render, staticRenderFns } from "./Invoices.vue?vue&type=template&id=0520c66a&scoped=true&"
import script from "./Invoices.vue?vue&type=script&lang=js&"
export * from "./Invoices.vue?vue&type=script&lang=js&"
import style0 from "./Invoices.vue?vue&type=style&index=0&id=0520c66a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0520c66a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VBtn,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VPagination})
