import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";
import ProjectSelector from "@/modules/projects/ProjectSelector";
import DeliverableList from "@/modules/calendar/lists/DeliverableList";

export default {

    methods: {
        createDeliverable(dueDate,events){
            let binding = {
                isCollaborator: this.isUserCollaborator,
                skipDeliverable: false
            }
            this.$store.state.globalModalController.openModal(ProjectSelector,binding,true,false).then((res) => {
                if(!res) return;

                if(res.type === 'project-created' || res.type === 'project-selected'){
                    let statusList = this.$store.getters.getDeliverableStatusList(res.project.projectTypeId);
                    let binding = {
                        id: null,
                        project: res.project,
                        client: res.project.client,
                        projectTypeId: res.project.projectTypeId,
                        defaultStatusId: statusList[0].id,
                        defaultDueDate: dueDate.toISODate(),
                    };
                    this.$store.state.globalModalController.openModal(DeliverableDetail,binding).then((dRes) => {
                        if(!dRes || (dRes.action && dRes.action === 'DELETED'))return;

                        let deliverable = {
                            id: dRes.id,
                            eventSource: 'DELIVERABLE',
                            summary: dRes.name,
                            description: dRes.description,
                            start: dRes.dueDate,
                            end: dRes.dueDate,
                            dateOnly: true,
                            meta: {
                                clientId: res.project.client.id,
                                client: res.project.client,
                                project: res.project,
                                id: dRes.id,
                                assignedToList: dRes.assignedToList,
                                statusId: dRes.statusId
                            }
                        }
                        events.push(deliverable);
                    })
                }
            })
        },

        deliverableClicked(meta,events){
            let binding = {
                deliverableId: meta.id,
                client: meta.client,
                project: meta.project
            }
            this.$store.state.globalModalController.openModal(DeliverableDetail,binding,true,false).then((res) => {
                if(!res) return;
                let ix = events.findIndex(e => e.eventSource === 'DELIVERABLE' && e.id === res.id);
                if(ix > -1){
                    if(res.action && res.action === 'DELETED'){
                        events.splice(ix,1);
                    }else{
                        let updated = {
                            id: res.id,
                            eventSource: 'DELIVERABLE',
                            summary: res.name,
                            description: res.description,
                            start: res.dueDate,
                            end: res.dueDate,
                            dateOnly: true,
                            userId: res.assignedToList,
                            meta: {
                                clientId: res.client ? res.client.id : null,
                                client: res.client,
                                project: res.project,
                                id: res.id,
                                assignedToList: res.assignedToList,
                                statusId: res.statusId
                            }
                        }
                        events.splice(ix,1,updated);
                        this.refreshKey++;
                    }
                }
            })
        },

        isUserCollaborator: function () {
            return this.$store.getters.getUserType === 'COLLABORATOR';
        },

        handleDeliverableMetricClicked: function(metric){
            let status;
            if(metric){
                status = this.$store.getters.getDeliverableStatusById(metric.id);
            }
            let binding = {
                startDate: this.startDate,
                endDate: this.endDate,
                events: this.events,
                status: status,
            }

            this.$store.state.globalModalController.openModal(DeliverableList,binding);
        },
    },

    computed: {
        deliverableMetricsByStatus: function(){
            try {
                let start = this.startDate.toJSDate().getTime();
                let end = this.endDate.toJSDate().getTime();
                let events = this.calendarEvents
                    .filter((e) => e.source === 'DELIVERABLE' && e.start.getTime() >= start && e.end.getTime() <= end);

                let statusMap = new Map();
                let result = [];

                this.$store.getters.getGlobalStatusList.forEach(s => {
                    statusMap.set(s.id, 0);
                })

                events.forEach(e => {
                    this.incrementMapByKey(statusMap, e.original.meta.statusId, 1);
                })

                this.$store.getters.getGlobalStatusList.forEach(s => {
                    let v = statusMap.get(s.id);
                    if (v) {
                        result.push({
                            id: s.id,
                            heading: s.label,
                            subheading: v,
                            value: v,
                            color: s.hexColor,
                            percentage: Math.round((v / events.length) * 100)
                        });
                    }
                });

                return {
                    defaultHeading: 'Deliverables',
                    defaultSubheading: events.length,
                    metrics: result
                }
            }catch(err){
                return {
                    defaultHeading: 'Deliverables',
                    defaultSubheading: 0,
                    metrics: []
                }
            }
        }
    }
}