import SchedulerList from "@/modules/meetings/SchedulerList";
import MeetingList from "@/modules/meetings/MeetingList";

export default [
	{
		path: '/meetings',
		redirect: '/meetings/booked'
	},
	{
		path: '/meetings/schedulers',
		name: 'SchedulerList',
		component: SchedulerList,
		title: 'Scheduler List',
	},
	{
		path: '/meetings/booked',
		name: 'MeetingList',
		component: MeetingList,
		title: 'Meeting List',
	},

];
