<template>
	<div>
		<v-menu
			:nudge-bottom="34"
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${isFilterActive ? 'f' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? '' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>
			<div class="column-format gap-4 pa-3" style="min-width: 550px; max-width: 550px">
				<div class="row-format gap-2 align-center" v-for="filter in filters" :key="filter.id">
					<v-autocomplete
						style="min-width: 140px; max-width: 140px"
						:items="fields"
						v-model="filter.mappingKey"
						item-value="mappingKey"
						item-text="name"
						hide-details
						dense
					></v-autocomplete>
					<v-select
						style="min-width: 120px; max-width: 120px"
						:items="conditions"
						v-model="filter.condition"
						hide-details
						dense
					></v-select>
					<v-text-field hide-details dense v-model="filter.value" :disabled="filter.condition === 'Is set' || filter.condition === 'Set'"></v-text-field>
					<v-icon small class="material-symbols-rounded" @click="deleteFilter(filter)">delete</v-icon>
				</div>
				<div class="row-format gap-4" style="align-items: end">
					<div class="pointer brand-medium font-primary font-14" @click="addFilter()">+ Add filter</div>
					<div v-if="filters.length > 0" class="pointer brand-medium font-14" @click="clearFilters()">Clear filters</div>

					<v-select
						v-if="filters.length > 1"
						class="ml-auto mr-5"
						style="max-width: 75px; min-width: 75px;"
						hide-details
						dense
						v-model="operator"
						:items="['And', 'Or']"
					></v-select>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import { v4 as uuid } from 'uuid';

	export default {
		name: 'CustomFieldFilter',

		props: ['fields', 'filter'],

		components: {},

		data: function() {
			return {
				showMenu: false,
				filters: [],
				operator: 'And',
				conditions: ['Equals', 'Not equals', 'Contains', 'Is set', 'Not set', 'Greater than', 'Less than'],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addFilter: function() {
				this.filters.push({
					id: uuid(),
					mappingKey: null,
					condition: 'Equals',
					value: null,
				});
			},

			clearFilters: function(){
				this.filters.splice(0,this.filters.length);
			},

			deleteFilter: function(filter) {
				let ix = this.filters.findIndex((f) => f.id === filter.id);
				this.filters.splice(ix, 1);
			},
		},

		watch: {
			filter: {
				immediate: true,
				handler: function(val) {
					if (val) {
						this.filters = val.filters;
						this.operator = val.operator;
					}
				},
			},
			customFilter: {
				deep: true,
				handler: function() {
					this.$emit('changed', this.customFilter);
				},
			},
		},

		computed: {
			customFilter: function() {
				return {
					filters: this.filters,
					operator: this.operator,
				};
			},

			filterLabel: function() {
				if (this.filters.length === 0) {
					return 'Custom filter';
				} else {
					return this.filters.length + ' custom filters';
				}
			},

			isFilterActive: function() {
				return !!this.filters.length;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
