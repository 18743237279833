<template>
	<div style="height: 100%" class="mt-1" @click="$emit('click',$event)">
		<div class="row-format" style="height: 100%; gap: 4px">
			<div
					:class="`${rounded ? 'status-box-rounded' : 'status-box'}`"
					:content="`${status.label} (${status.deliverableCount})`" v-tippy="{placement : 'top',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"
					v-for="status in deliverablesByStatus"
					:key="status.id"
					:style="`width:${status.percentage}%; background-color: ${status.hexColor}`"
			></div>
		</div>
	</div>
</template>

<script>

export default {
	name: "DeliverableByStatus",

	props: ['project','rounded','deliverables'],

	components: {},

	data: function () {
		return {

		}
	},

	mounted() {

	},

	beforeDestroy() {
	},

	methods: {

	},

	computed: {
		statusList: function () {
			let statusList = JSON.parse(JSON.stringify(this.$store.getters.getDeliverableStatusList(this.project.projectTypeId)));
			let total = 0;

			statusList.forEach((s) => {
				s.deliverableCount = 0;
			});

			this.deliverables.forEach((d) => {
				let ix = statusList.findIndex((s) => s.id === d.statusId);
				if (ix > -1) {
					statusList[ix].deliverableCount++;
					total++;
				}
			});

			statusList.forEach((s) => {
				s.percentage = Math.round((s.deliverableCount / total) * 100);
			});

			return statusList;
		},

		deliverablesByStatus: function () {
			return this.statusList.filter((s) => s.deliverableCount > 0);
		},
	},

}
</script>

<style scoped lang="scss">
	.status-box {
		min-height: 100%;
	}

	.status-box-rounded {
		min-height: 100%;
		border-radius: 4px;
	}
</style>