<template>
  <div class="solid-hr">
    <div v-if="meeting.pageLayout.showLogo" class="pb-6">
      <img
          alt="Image not found"
          :src="this.$store.getters.getAccountLogo"
          style="display: flex; max-width: 80%; max-height: 150px; align-self: start"
      />
    </div>
    <div class="px-4 text-left form-content" @click="activateEditor(true)">
      <editor
          ref="SchedulerTextEditor"
          v-if="editorActive"
          :api-key="$store.getters.getTinyMceKey"
          v-model="meeting.scheduleMeeting.schedulerInfo"
          :inline="true"
          :init="mceConfigText"
          @onblur="activateEditor(false)"
      ></editor>
      <div v-else v-html="tokenizedText(meeting)" style="line-height: 1.8"></div>
    </div>
		<div class="px-4" v-if="meeting.locations.length > 1">
			<v-radio-group v-model="selectedLocation">
				<v-radio  v-for="(location,index) in meeting.locations" :key="index" :value="location">
					<template v-slot:label>
						<div class="row-format align-center gap-2">
							<v-icon class="material-symbols-rounded">{{ getIcon(location) }}</v-icon>
							<div class="brand-medium">{{getLabel(location)}}</div>
						</div>
					</template>
				</v-radio>
			</v-radio-group>
		</div>
  </div>
</template>

<script>
import { DateTime, Settings } from 'luxon';
import timezones from '@/assets/data/timezones.json';

export default {
  name: 'MockScheduler',

  props: ['meeting', 'confirmedTime', 'editMode', 'tokenMap'],

  components: {
    editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
  },

  data: function() {
    return {
			selectedLocation: this.meeting.locations.length ? this.meeting.locations[0] : null,
      editorActive: false,
      viewSelect: true,
      timezones: timezones,
      DateTime: DateTime,
      Settings: Settings,
      selectedDate: null,
      selectedSlot: null,
      monthToShow: DateTime.now().toISODate(),
      filter: null,
      timezoneMenu: false,
      mceConfigText: {
        auto_focus: true,
        menubar: false,
        inline: false,
        paste_as_text: false,
        forced_root_block: 'div',
        paste_data_images: true,
        plugins: ['paste', 'lists', 'link', 'table', 'code', 'hr'],
        contextmenu: 'paste | link | table',
        branding: false,
        height: `400px`,
        width: '500px',
        skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
        content_css: this.$vuetify.theme.dark ? 'dark' : '',
        content_style: ".mce-content-body { line-height: 1.8; }",
        toolbar: [
          'bold italic underline forecolor fontsizeselect | link unlink code',
          'tokenbutton | hr | alignleft aligncenter alignright alignjustify | formatting_group',
        ],
        fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 72px',
        toolbar_groups: {
          formatting_group: {
            icon: 'format',
            tooltip: 'Formatting',
            items: 'blockquote bullist numlist indent outdent table | removeformat',
          },
          media_group: {
            icon: 'embed',
            tooltip: 'Media',
            items: ' link unlink media',
          },
        },
        setup: (editor) => {
          let items = [];
          this.tokens.forEach((t) => {
            items.push({
              type: 'menuitem',
              text: t,
              onAction: function() {
                editor.insertContent('{{' + t + '}}');
              },
            });
          });
          editor.ui.registry.addMenuButton('tokenbutton', {
            text: 'Tokens',
            fetch: function(callback) {
              callback(items);
            },
          });
        },
      },
    };
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
		getLabel: function(location){
				switch(location.type){
					case 'Google':{return 'Google meet'}
					case 'Microsoft':{return 'Microsoft teams'}
					case 'Zoom':{return 'Zoom'}
					case 'PhoneIn':{return `Call ${location.phone}`}
					case 'PhoneOut':{return 'I\'ll call you'}
					case 'InPerson':{return `In person`}
					case 'Custom':{return location.custom}
				}
		},

		getIcon: function(location){
			switch(location.type){
				case 'Google':{return '$googleCalendar'}
				case 'Microsoft':{return '$microsoft'}
				case 'Zoom':{return '$zoomIcon'}
				case 'PhoneIn':{return `$phoneInTalk`}
				case 'PhoneOut':{return '$phoneInTalk'}
				case 'InPerson':{return `$map`}
				case 'Custom':{return `info`}
			}
		},

    activateEditor(value) {
      this.editorActive = value;
    },

    tokenizedText: function (meeting) {
      let result = meeting.scheduleMeeting.schedulerInfo;
      let map = this.tokenMap;

      for (let [key, value] of map) {
        let token = "{{" + key + "}}";
        while (result.includes(token)) {
          result = result.replace(token, value);
        }
      }

      return result;
    },

    setView: function () {
      this.viewSelect = false;
    },

    suffix: function (num) {
      let j = num % 10,
          k = num % 100;
      if (j === 1 && k !== 11) {
        return `${num}st`;
      } else if (j === 2 && k !== 12) {
        return `${num}nd`;
      } else if (j === 3 && k !== 13) {
        return `${num}rd`;
      } else {
        return `${num}th`;
      }
    },
  },

  computed: {
    minDate: function() {
      return DateTime.now().toISODate();
    },

    tokens: function() {
      let scheduler = [
        'Meeting.Name',
        'Meeting.Duration',
        'Meeting.TimeUnit',
        'Meeting.Location',
        'Meeting.LocationInfo',
        'Meeting.ScheduledTime',
        'Meeting.AccountName',
        'Meeting.OwnerName',
        'Meeting.TimeZone',
        'Meeting.Description',
        'Icons.Clock',
        'Icons.Mic',
        'Icons.Event',
        'Icons.Public',
        'Icons.Info',
      ];
      return scheduler;
    },
  },
};
</script>

<style lang="scss">
  .solid-hr {
    hr {
      border-top: 1px solid var(--v-gray_70-base) !important;
    }
  }
</style>