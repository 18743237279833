<template>
	<div v-if="projectType">
		<page-header
			class="pb-6"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ label: 'Project management' },
				{ to: '/projects/settings', label: 'Project types' },
			]"
		>
			{{ projectType.name }}
		</page-header>

		<div class="row-format mb-4 gap-3 flex-wrap">
			<div
				:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
				v-for="page in pages"
				:key="page.value"
				@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
			<v-btn v-if="currentView !== 'overview' && currentView !== 'client notifications'" class="ml-auto super-action" @click="addNew()"
				><v-icon size="20">add</v-icon> Add {{ currentView.toLowerCase() }}</v-btn
			>
		</div>

		<div>
			<div v-if="currentView === 'overview'" class="column-format gap-1 text-left font-14">
				<v-text-field
					v-model="projectType.name"
					hide-details
					dense
					persistent-placeholder
					label="Name"
					outlined
					@change="saveProjectType()"
				></v-text-field>
			</div>
			<deliverable-status
				ref="DeliverableStatus"
				v-if="currentView === 'status'"
				v-model="projectType.statusList"
				@input="saveProjectType()"
			></deliverable-status>
			<custom-field-table
				v-if="currentView === 'project field'"
				ref="ProjectFields"
				v-model="projectType.projectFields"
				:max-per-card="0"
				@input="saveProjectType()"
			></custom-field-table>
			<custom-field-table
					v-if="currentView === 'task field'"
					ref="TaskFields"
					v-model="projectType.deliverableFields"
					:max-per-card="0"
					@input="saveProjectType()"
			></custom-field-table>
			<div v-if="currentView === 'client notifications'" class="column-format gap-3">
				<div class="text-left mt-5">Client notification email templates</div>
				<v-autocomplete
						v-if="emailTemplates"
						:disabled="emailTemplates.length === 0"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Task approval required"
						:items="emailTemplates"
						item-value="id"
						item-text="name"
						clearable
						v-model="projectType.notifications.deliverableApproval"
						@change="saveProjectType"
				></v-autocomplete>
				<v-autocomplete
						v-if="emailTemplates"
						:disabled="emailTemplates.length === 0"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Task approval reminder"
						:items="emailTemplates"
						item-value="id"
						item-text="name"
						clearable
						v-model="projectType.notifications.deliverableApprovalReminder"
						@change="saveProjectType"
				></v-autocomplete>
				<v-autocomplete
						v-if="emailTemplates"
						:disabled="emailTemplates.length === 0"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Task assigned"
						:items="emailTemplates"
						item-value="id"
						item-text="name"
						clearable
						v-model="projectType.notifications.deliverableAssigned"
						@change="saveProjectType"
				></v-autocomplete>
				<v-autocomplete
						v-if="emailTemplates"
						:disabled="emailTemplates.length === 0"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Task comment"
						:items="emailTemplates"
						item-value="id"
						item-text="name"
						clearable
						v-model="projectType.notifications.deliverableComment"
						@change="saveProjectType"
				></v-autocomplete>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '@/components/PageHeader';
	import ProjectTypeService from '@/modules/projects/ProjectTypeService';
	import DeliverableStatus from '@/modules/projects/settings/DeliverableStatus';
	import { DateTime } from 'luxon';
	import CustomFieldTable from '@/modules/fields/CustomFieldTable';
	import EmailTemplateService from "@/modules/templates/EmailTemplateService";

	export default {
		name: 'ProjectTypeDetail',

		props: ['id'],

		components: { DeliverableStatus, PageHeader, CustomFieldTable },

		data: function() {
			return {
				DateTime: DateTime,
				projectTypeService: new ProjectTypeService(),
				projectType: null,
				currentView: 'overview',
				emailTemplates: [],
			};
		},

		mounted() {
			this.getProjectType();
			this.getEmailTemplates();
		},

		beforeDestroy() {},

		methods: {
			getProjectType: function() {
				this.projectTypeService.getProjectType(this.id).then((res) => {
					this.projectType = res.data;
				});
			},
			getEmailTemplates: function() {
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0, this.emailTemplates.length);
					this.emailTemplates.push(...res.data);
					this.emailTemplates.sort((a, b) => a.name.localeCompare(b.name));
				});
			},
			saveProjectType: function(){
				this.projectTypeService.updateProjectType(this.id,this.projectType).then((res) => {
					this.projectType = res.data;
				})
			},
			setView: function(view) {
				this.currentView = view;
			},
			addNew: function() {
				if (this.currentView === 'status') {
					this.$refs.DeliverableStatus.addStatus();
				}else if(this.currentView === 'project field'){
					this.$refs.ProjectFields.addField();
				}else if(this.currentView === 'task field'){
					this.$refs.TaskFields.addField();
				}
			},
		},

		computed: {
			pages: function() {
				let result = [];
				result.push({ label: 'Overview', value: 'overview' });
				result.push({ label: 'Project fields', value: 'project field' });
				result.push({ label: 'Task fields', value: 'task field' });
				result.push({ label: 'Task statuses', value: 'status' });
				result.push({ label: 'Client notifications', value: 'client notifications' });
				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
