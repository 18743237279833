<template>
	<div v-if="$store.state.leftNavExpanded && currentNavRoute && $store.state.navReady" id="sub-nav" class="text-left pl-2 pr-2" style="margin-top: 2px;">
		<div style="width:100%; height: 56px; max-height: 56px; min-height: 56px" class="row-format align-center">
			<div class="font-20 brand-medium pl-1" style="line-height: 1.1">{{ currentNavRoute.title.label }}</div>
		</div>
		<div class="column-format gap-2 font-14" style="width:100%; max-width: 100%; margin-top: 20px">
			<draggable :disabled="!isQuickLink" v-model="children" @end="endDragHandler" class="column-format gap-2">
				<div
					v-for="tab in children"
					:key="tab.uri"
					:class="$route.path.startsWith(tab.uri) ? 'sub-item-active' : ''"
					class="row-format align-center gap-1 sub-item pointer"
					@click="route(tab,$event)"
				>
					<div class="material-symbols-rounded" style="font-size: 16px">{{ tab.icon }}</div>
					<div class="label">{{ tab.label }}</div>
				</div>
			</draggable>

			<div v-if="favorites.length" class="row-format align-center gap-1 sub-item" style="color: var(--v-black-base)!important;">
				<div class="material-symbols-rounded" style="font-size: 16px">star</div>
				<div class="label">Favorites</div>
			</div>

			<div
				v-for="favorite in favorites"
				:key="favorite.route"
				:class="$route.path.startsWith(favorite.uri) ? 'sub-item-active' : ''"
				class="row-format align-center gap-1 sub-item pointer"
				@click="route(favorite,$event)"
			>
				<client-avatar style="margin-left: -2px" x-small :client="favorite.client" :disable-click="true"></client-avatar>
				<div class="label truncate" style="max-width: 140px">{{ favorite.label }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NavConfig from '@/views/nav/NavConfig.json';
	import Draggable from 'vuedraggable';
	import ClientAvatar from "@/components/ClientAvatar";
	export default {
		name: 'SubNav',

		props: [],

		components: { Draggable, ClientAvatar },

		data: function() {
			return {
				isMac: /Mac|iPod|iPhone|iPad/.test(navigator.userAgent),
				children: [],
			};
		},

		mounted() {
			window.addEventListener('keydown', this.keyHandler);
		},

		beforeDestroy() {},

		methods: {
			endDragHandler: function(){
				if(this.isQuickLink) {
					this.$store.state.quickLinks.splice(0, this.$store.state.quickLinks.length);
					this.$store.state.quickLinks.push(...this.children.map(c => c.uri));
					this.$store.state.eventBus.$emit('save-nav-settings');
				}
			},

			isActive: function(path) {
				return this.$route.path.startsWith(path);
			},

			keyHandler(event) {
				if ((this.isMac && event.metaKey && event.key === 'm') || (!this.isMac && event.ctrlKey && event.key === 'm')) {
					if(event.target.id && event.target.id.includes('tiny-vue')){
						return;
					}
					event.preventDefault();
					if(this.currentNavRoute) {
						this.$store.state.leftNavExpanded = !this.$store.state.leftNavExpanded;
					}else{
						this.$store.state.leftNavExpanded = false;
					}
				}
			},

			route: function(tab,event) {
				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(tab.uri, '_blank');
					w.focus();
				} else {
					this.$router.push(tab.uri);
				}
			},
		},

		watch: {
			currentNavRoute: {
					immediate: true,
					handler: function() {
						this.children.splice(0, this.children.length);

						if(!this.currentNavRoute){
							this.$store.state.leftNavExpanded = false;
							return;
						}

						this.children.push(...this.currentNavRoute.tabs);
					}
				}
		},

		computed: {
			isQuickLink: function() {
				return this.$store.state.quickLinksSelected;
			},

			currentBaseRoute: function() {
				return '/' + this.$route.path.split('/')[1];
			},

			currentNavRoute: function() {
				if(this.$store.state.quickLinksSelected && this.quickLinks.tabs.length){
					return this.quickLinks;
				}
				return this.navElements.get(this.currentBaseRoute);
			},

			routeMap: function() {
				let result = new Map();
				this.navElements.forEach((n) => {
					if (n.tabs.length) {
						n.tabs.forEach((t) => {
							let value = {
								label: t.quick ? t.quick : t.label,
								icon: t.icon
							}
							result.set(t.uri, value);
						});
					} else {
						let value = {
							label: n.title.uri,
							icon: n.icon
						}
						result.set(n.title.uri, value);
					}
				});
				return result;
			},

			quickLinks: function(){
				let quickLinks = {
					key: '/quick',
					title: {
						label: 'Quick links',
						icon: 'bookmark_add',
					},
					tabs: [],
				};

				this.$store.state.quickLinks.forEach((q) => {
					let map = this.routeMap.get(q);
					if (map) {
						quickLinks.tabs.push({
							label: map.label,
							uri: q,
							icon: map.icon,
						});
					}
				});

				return quickLinks;
			},

			navElements: function() {
				let result = new Map();
				for (const [key, value] of Object.entries(JSON.parse(JSON.stringify(NavConfig)))) {
					result.set(key, value);
					if (value.altRoutes) {
						value.altRoutes.forEach((a) => {
							result.set(a, value);
						});
					}
				}
				return result;
			},

			isClients: function() {
				return this.$route.path.startsWith('/client');
			},

			favorites: function() {
				if (this.isClients && !this.$store.state.quickLinksSelected) {
					let result = [];
					this.$store.state.favoriteClients.forEach((c) => {
						let client = this.$store.state.clientCache.find((client) => client.id === c);
						if (client) {
							result.push({
								label: client.name,
								uri: `/client/${c}`,
								client: client,
							});
						}
					});
					result.sort((a, b) => a.label.localeCompare(b.label));
					return result;
				} else {
					return [];
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	#sub-nav {
		width: 184px;
		min-width: 184px;
		height: 100vh;
		max-height: 100vh;
		overflow-y: auto;
		padding-bottom: 16px;
		overflow-x: hidden;
		//border-left: 1px solid var(--v-gray_30-base);
		background-color: var(--v-gray_10-base);
	}

	.sub-item-active {
		color: var(--v-primary-base) !important;
		background-color: var(--v-white-base)!important;
		border-radius: 4px;
		border: 1px solid var(--v-gray_30-base)!important;
		box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
	}

	.sub-item {
		padding: 2px 4px;
		border: 1px solid var(--v-gray_10-base);
		&:hover {
			color: var(--v-primary-base);
		}
	}
</style>
