<template>
	<div v-if="filter" class="row-format gap-2">
		<v-text-field
			outlined
			hide-details
			dense
			class="standard-input"
			v-model="filter.search"
			placeholder="Search..."
			:style="`max-width: 200px;`"
			@input="updated"
		>
			<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
		</v-text-field>
		<v-menu
			v-model="menuOpen"
			max-height="90vh"
			max-width="250"
			nudge-bottom="34"
			:close-on-content-click="false"
			:close-on-click="true"
			content-class="filter-menu-class"
			style="overflow-y: auto"
		>
			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					class="standard-action"
					elevation="0"
					outlined
					color=""
				>
					<span
						:class="`font-14 ${!isDefaultFilter ? '' : 'font-gray_60'} brand-medium`"
						v-html="isDefaultFilter ? filterLabel : filterDescription"
					></span>
					<v-icon x-small class="mx-1" :color="!isDefaultFilter ? '' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format open-menu">
				<!-- Client Filter -->
				<div class="filter-option-box">
					<div class="row-format" @click="clientExpanded = !clientExpanded">
						<div class="font-14">{{ $t('invoice.filter.client') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							clientExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>
					<div v-if="clientExpanded">
						<v-checkbox
							v-model="clientsSelected"
							hide-details
							:indeterminate="clientsIndeterminate"
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div class="client-label row-format pointer" style="align-content: center;">
									<div class="tree-label">
										{{ $t('calendar.filter.select-deselect') }}
									</div>
								</div>
							</template>
						</v-checkbox>

						<v-checkbox
							v-model="filter.clients"
							v-for="client in clientList"
							:value="client.id"
							:key="client.id"
							@change="updated()"
							hide-details
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div class="client-label row-format pointer" style="align-content: center;">
									<client-avatar :small="true" left :client="client" :disable-click="true"></client-avatar>
									<div class="tree-label">{{ client.name }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>

				<filter-option
					:label="$t('client.filter.proposal-status')"
					:option-list="proposalStatus"
					v-model="filter.proposalStatus"
					@change="updated"
				></filter-option>

				<filter-option
					:label="$t('client.filter.proposal-type')"
					:option-list="proposalType"
					v-model="filter.proposalType"
					@change="updated"
				></filter-option>

				<div v-if="showDateRange" class="filter-option-box">
					<div class="row-format" @click="dateExpanded = !dateExpanded">
						<div class="font-14">{{ $t('client.filter.date-range') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							dateExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="dateExpanded">
						<v-radio-group v-model="filter.dateSpecifier" @change="updated()">
							<v-radio v-for="option in dateOptions" :key="option.value" :value="option.value">
								<template v-slot:label>
									<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
								</template>
							</v-radio>
						</v-radio-group>

						<div class="" v-if="showEarliest">
							<date-selector
								content-class="my-5"
								:date="filter.earliest"
								@change="
									filter.earliest = $event;
									updated();
								"
								label="Earliest"
							></date-selector>
						</div>

						<div class="" v-if="showLatest">
							<date-selector
								content-class="my-5"
								:date="filter.latest"
								@change="
									filter.latest = $event;
									updated();
								"
								label="Latest"
							></date-selector>
						</div>
					</div>
				</div>

				<div class="mx-3 mt-3 mb-2">
					<v-btn text block color="secondary" elevation="0" class="filter-clear mb-2" @click="clearFilter()"
						><v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn
					>
					<v-btn class="super-action" @click="menuOpen = false" color="primary" block elevation="0">{{
						$t('global.done')
					}}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateSelector from '@/components/DateSelector';
	import FilterOption from '@/components/FilterOption';
	import DateTime from '@/modules/utils/HDateTime';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'ProposalFilter',

		props: ['value'],

		components: { FilterOption, DateSelector, ClientAvatar },

		data: function() {
			return {
				menuOpen: false,
				filter: JSON.parse(JSON.stringify(this.value)),

				dateOptions: FilterHelpers.getDateOptions([
					// 'today',
					// 'yesterday',
					'this-week',
					'last-week',
					'this-month',
					'last-month',
					'past-30-days',
					'past-60-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'all-time',
					'between',
				]),

				proposalStatus: [
					{ text: 'Draft', value: 'Draft' },
					{ text: 'Sent', value: 'Sent' },
					{ text: 'Finalized', value: 'Finalized' },
					{ text: 'Declined', value: 'Declined' },
					{ text: 'Signed', value: 'Signed' },
					{ text: 'Fully executed', value: 'Fully Executed' },
					{ text: 'Voided', value: 'Void' },
					{ text: 'Archived', value: 'Archived' },
				],

				proposalType: [
					{ text: 'Agreement', value: 'Agreement' },
					{ text: 'Proposal [classic]', value: 'Proposal' },
					{ text: 'Contract [classic]', value: 'Contract' },
					{ text: 'Quote [classic]', value: 'Quote' },
				],

				dateExpanded: false,
				proposalStatusExpanded: false,
				clientExpanded: false,
			};
		},

		mounted() {
			// console.log(this.filter);
		},

		beforeDestroy() {},

		methods: {
			clearFilter: function() {
				this.$emit('clear-filter');
			},
			updated: function() {
				this.$emit('input', this.filter);
			},
			addAllClients() {
				this.removeAllClients();
				this.clientList.forEach((c) => this.filter.clients.push(c.id));
			},

			removeAllClients() {
				this.filter.clients.splice(0, this.filter.clients.length);
			},
		},

		watch: {
			value: {
				deep: true,
				handler() {
					// this.filter = JSON.parse(JSON.stringify(val));
					this.$emit('updated', this.filter);
				},
			},
		},

		computed: {
			clientList: function() {
				return this.$store.getters.clients.filter((c) => c.archive === false);
			},
			clientsSelected: {
				set: function(val) {
					if (val) return this.addAllClients();
					return this.removeAllClients();
				},
				get: function() {
					if (!this.filter.clients) return false;
					return this.filter.clients.length > 0;
				},
			},
			clientsIndeterminate: function() {
				if (!this.filter.clients) return false;
				if (this.filter.clients.length === this.clientList.length) return false;
				return this.filter.clients.length !== 0;
			},

			filterLabel() {
				return this.$t('proposals.filter-proposals');
			},

			filterDescription() {
				let l = [];
				let f = this.filter;

				// if (f.search && f.search.length > 0) {
				// 	l.push('"' + f.search + '"');
				// }

				if (f.clients && f.clients.length < this.clientList.length && f.clients.length > 0) {
					l.push(this.$tc('proposals.client-plurals', f.clients.length));
				}

				if (f.dateSpecifier) {
					if (f.dateSpecifier === 'between') {
						return (
							DateTime.fromISO(f.earliest).toLocaleString({ month: 'short', day: 'numeric' }) +
							' - ' +
							DateTime.fromISO(f.latest).toLocaleString({ month: 'short', day: 'numeric' })
						);
					} else {
						l.push(this.dateOptions.find((d) => d.value === f.dateSpecifier).text);
					}
				}

				if (f.proposalStatus && f.proposalStatus.length) {
					l.push(f.proposalStatus.length + ' proposal status');
				}

				if (f.proposalType && f.proposalType.length) {
					l.push(f.proposalType.length + ' proposal types');
				}

				if (l.length) {
					return l.join(' &bull; ');
				} else {
					return null;
				}
			},

			showEarliest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}
				return this.filter.dateSpecifier === 'between';
			},

			showLatest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}
				return this.filter.dateSpecifier === 'between';
			},

			showDateRange: function() {
				return this.currentView !== 'Overview';
			},

			isDefaultFilter() {
				return this.filterDescription === null;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
