<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.plaid.desc') }}</div>
				<div v-if="paidAccount">
					<connected v-if="isConnected"></connected>
					<div v-else class="font-primary brand-medium pointer" @click="linkNewAccount(null)">Connect Plaid</div>
				</div>
				<div v-else>
					<div class="font-14 pointer" @click="$router.push('/subscription')"><v-icon small color="warning">$alert</v-icon>This integration is not available on the Essentials plan.  If you would like to automate your expense tracking, please upgrade to Pro or Teams.</div>
				</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<v-container class="font-14 px-0">
				<v-row v-for="item in items" :key="item.itemId">
					<v-col>{{ item.institutionName }}</v-col>
					<v-col cols="5">{{ item.lastUpdateMessage }}</v-col>
					<v-col style="white-space: nowrap">{{
						DateTime.fromISO(item.lastUpdate).toLocaleString(DateTime.DATETIME_SHORT)
					}}</v-col>
					<v-col align="right">
						<v-btn icon @click="linkNewAccount(item.itemId)"><v-icon>refresh</v-icon></v-btn>
						<v-btn icon @click="unlinkAccount(item.itemId)"><v-icon>$delete</v-icon></v-btn>
					</v-col>
				</v-row>
			</v-container>
			<v-btn class="primary-action mt-5" @click="linkNewAccount(null)">Connect new account</v-btn>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import PlaidService from '@/modules/accounting/accounts/PlaidService';
	import ConfirmModal from '@/components/ConfirmModal';
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'Plaid',

		mixins: [],

		props: [],

		components: { Connected },

		data: function () {
			return {
				plaidService: new PlaidService(),
				items: [],
				expanded: false,
				DateTime: DateTime,
				isReady: false,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.getItems();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.getItems();
			},

			getItems: function () {
				this.plaidService
					.getItems()
					.then((res) => {
						this.items.splice(0, this.items.length);
						this.items.push(...res.data);
					})
					.finally(() => (this.isReady = true));
			},

			linkNewAccount: function (itemId) {
				if(this.$store.getters.isInTrial){
					let binding = {
						bodyText: 'This feature is not available on the free trial.  Please subscribe to link your bank account.',
						hideNo: true
					}
					this.$store.state.globalModalController.openModal(ConfirmModal,binding);
					return false;
				}else if(this.$store.getters.isStarterAccount && this.isConnected){
					let binding = {
						bodyText: 'The starter plan includes 1 linked account.  Please upgrade to Pro to link up to 5 accounts.',
						hideNo: true
					}
					this.$store.state.globalModalController.openModal(ConfirmModal,binding);
					return false;
				}

				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/integrations/plaid/oauth';

				this.plaidService
					.token(url, itemId)
					.then((res) => {
						this.initializePlaidLink(res.data, itemId);
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', err.response.data.message);
					});
			},

			reSync: function(itemId){
				if(itemId){
					this.plaidService.resSync(itemId);
				}
			},

			unlinkAccount: function (itemId) {
				let binding = {
					headingText: 'Confirm?',
					bodyText: 'Are you sure you want to unlink this account?  This will remove all linked transactions.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.plaidService
							.unlinkItem(itemId)
							.then(() => {
								this.getItems();
							})
							.catch((err) => {
								this.$store.commit('error', err.response.data.message);
							})
							.finally(() => {
								this.$store.commit('stopLoading');
							});
					}
				});
			},

			initializePlaidLink: function (token, itemId) {
				localStorage.setItem('PLAID_LINK_TOKEN',token);
				localStorage.setItem('PLAID_LINK_ITEM_ID',itemId);

				this.plaidHandler = window.Plaid.create({
					token: token,
					onSuccess: (token, metadata) => {
						this.plaidSuccess(token, metadata, itemId);
					},
					onLoad: () => {},
					onExit: this.plaidExit,
					onEvent: this.plaidEvent,
					receivedRedirectUri: null,
				});

				this.plaidHandler.open();
			},

			plaidSuccess: function (token, metadata, itemId) {
				console.log('Plaid Success');
				console.log(token);
				console.log(metadata);

				if (itemId) {
					this.reSync(itemId);
					this.$store.commit('success', 'Connected account successfully refreshed.');
				}else{
					this.plaidService
						.exchange(token)
						.then((res) => {
							this.items.push(res.data);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			plaidExit: function (err, metadata) {
				console.log('Plaid Exit');
				console.log(err);
				console.log(metadata);
			},

			plaidEvent: function (eventName, metadata) {
				console.log('Plaid Event');
				console.log(eventName);
				console.log(metadata);
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			isConnected: function () {
				return this.items.length > 0;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
