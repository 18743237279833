<template>
	<div id="deliverable-table-wrapper">
		<input id="copyToClip" name="copyToClip" value="" type="hidden" />
		<table id="deliverable-table" class="deliverable-table table">
			<template v-for="(group, index) in deliverableGroupings">
				<tbody :key="index">
					<tr v-if="group.groupKey">
						<td
							:colspan="(singleProjectMode ? 9 : 10) + customFields.length"
							:style="index > 0 ? 'padding-top: 24px' : ''"
							style="padding-left: 8px!important;"
							class="group-heading"
						>
							{{ group.groupLabel }}
						</td>
					</tr>
					<deliverable-list-header
						:is-sub-task="false"
						:single-project-mode="singleProjectMode"
						:custom-fields="customFields"
						:visible-fields="visibleFields"
						@toggle-checked="toggleAllChecked(group.deliverables, $event)"
					></deliverable-list-header>
					<template v-for="deliverable in group.deliverables">
						<deliverable-list-row
							:key="deliverable.id"
							:deliverable="deliverable"
							:expanded="expanded"
							:is-sub-task="false"
							:single-project-mode="singleProjectMode"
							:status-list="statusList"
							:custom-fields="customFields"
							:all-projects="allProjects"
							:selected-tasks="selectedTaskIds"
							:visible-fields="visibleFields"
							@name-focused="setActiveLabel(group.groupLabel)"
							@name-blurred="setActiveLabel(null)"
							@toggle-expanded="toggleExpanded(deliverable.id)"
							@toggle-checked="toggleChecked(deliverable)"
							@set-name="setName(deliverable, $event)"
							@edit-deliverable="editDeliverable(deliverable)"
							@route-to-project="routeToProject(deliverable.project, $event)"
							@set-status="setStatus(deliverable, $event)"
							@custom-field-change="handleCustomFieldChange(deliverable, $event)"
							@set-priority="setPriority(deliverable)"
							@set-task-priority="setTaskPriority(deliverable, $event)"
							@set-assigned-to="setAssignedToList(deliverable)"
							@set-start-date="setStartDate(deliverable, $event)"
							@set-due-date="setDueDate(deliverable, $event)"
							@set-tasks="setTasks(deliverable, $event)"
							@get-share-link="getShareLink(deliverable)"
							@confirm-duplicate="confirmDuplicate(deliverable)"
							@toggle-archive="toggleArchive(deliverable)"
							@confirm-delete="confirmDeleteDeliverable(deliverable)"
							@add-new-task="addDeliverable(group)"
							@make-task="makeMainTask(deliverable)"
							@move-sub-task="moveSubTask(deliverable)"
						>
						</deliverable-list-row>
						<tr v-if="expanded[deliverable.id]" :key="'expanded-' + deliverable.id">
							<td :colspan="visibleFields.length + 2">
								<div style="width:100%" class="px-5 pt-4 pb-2 row-format centered">
									<table class="deliverable-table table">
										<deliverable-list-header
											:is-sub-task="true"
											:single-project-mode="true"
											:custom-fields="customFields"
											:visible-fields="visibleFields"
											@toggle-checked="toggleAllChecked(deliverable.subtasks, $event)"
										></deliverable-list-header>
										<template v-for="subtask in deliverable.subtasks">
											<deliverable-list-row
												:key="subtask.id"
												:deliverable="subtask"
												:is-subtask="true"
												:single-project-mode="true"
												:status-list="statusList"
												:custom-fields="customFields"
												:all-projects="allProjects"
												:selected-tasks="selectedTaskIds"
												:visible-fields="visibleFields"
												@name-focused="setActiveLabel(deliverable.id)"
												@name-blurred="setActiveLabel(null)"
												@toggle-expanded="toggleExpanded(subtask.id)"
												@toggle-checked="toggleChecked(subtask)"
												@set-name="setName(subtask, $event)"
												@edit-deliverable="editDeliverable(subtask)"
												@route-to-project="routeToProject(subtask.project, $event)"
												@set-status="setStatus(subtask, $event)"
												@custom-field-change="handleCustomFieldChange(subtask, $event)"
												@set-priority="setPriority(subtask)"
												@set-task-priority="setTaskPriority(subtask, $event)"
												@set-assigned-to="setAssignedToList(subtask)"
												@set-start-date="setStartDate(subtask, $event)"
												@set-due-date="setDueDate(subtask, $event)"
												@set-tasks="setTasks(subtask, $event)"
												@get-share-link="getShareLink(subtask)"
												@confirm-duplicate="confirmDuplicate(subtask)"
												@toggle-archive="toggleArchive(subtask)"
												@confirm-delete="confirmDeleteDeliverable(subtask)"
												@add-new-task="addSubTask(deliverable)"
												@make-task="makeMainTask(subtask)"
												@move-sub-task="moveSubTask(subtask)"
											>
											</deliverable-list-row>
										</template>
										<tr>
											<td colspan="8" style="border:none" class="footer">
												<div
													class="pointer mt-1 highlight-hover font-12"
													style="width: fit-content"
													@click="addSubTask(deliverable)"
												>
													<span v-if="activeLabel === deliverable.id"
														><strong>Shift + Enter</strong> to add a new subtask</span
													><span v-else>+ Add subtask</span>
												</div>
											</td>
										</tr>
									</table>
								</div>
							</td>
						</tr>
					</template>
					<tr>
						<td colspan="10" style="border:none" class="footer">
							<div
								class="pointer mt-1 highlight-hover font-12"
								style="width: fit-content"
								@click="addDeliverable(group)"
							>
								<span v-if="activeLabel === group.groupLabel"
									><strong>Shift + Enter</strong> to add a new task</span
								><span v-else>+ Add task</span>
							</div>
						</td>
					</tr>
				</tbody>
			</template>
		</table>
		<div class="column-format centered">
			<bulk-actions
				:tasks="selectedTasks"
				label="Tasks"
				v-if="selectedTaskIds.length"
				:status-list="statusList"
				:user-list="fullAccessUsers"
				@task-updated="bulkTaskUpdated($event)"
			></bulk-actions>
		</div>
	</div>
</template>

<script>
	import KanbanMixin from './ProjectMixin';
	import DateTime from '@/modules/utils/HDateTime';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import DeliverableMixin from '@/modules/projects/deliverable/DeliverableMixin';
	import ConfirmModal from '@/components/ConfirmModal';
	import DeliverableListRow from '@/modules/projects/management/list/DeliverableListRow';
	import DeliverableListHeader from '@/modules/projects/management/list/DeliverableListHeader';
	import BulkActions from '@/modules/projects/bulk/BulkActions';
	import ParentTaskTransfer from "@/modules/projects/management/ParentTaskTransfer";

	export default {
		name: 'DeliverableList',
		mixins: [KanbanMixin, DeliverableMixin],

		props: [
			'deliverables',
			'allProjects',
			'projects',
			'updateFlag',
			'filter',
			'isCollaborator',
			'hasProjects',
			'currentView',
			'headerHeight',
			'tableSort',
			'singleProjectMode',
			'projectTypeId',
			'statusList',
			'customFields',
			'projectType',
			'visibleFields',
		],

		components: {
			DeliverableListHeader,
			DeliverableListRow,
			BulkActions,
		},

		data: function() {
			return {
				deliverableService: new ProjectDeliverableService(),
				expanded: {},
				viewChange: 0,
				dragDeliverableId: null,
				isReady: false,
				DateTime: DateTime,
				startTime: 0,
				selectedTasks: [],
				activeLabel: null,
			};
		},

		beforeMount() {
			this.startTime = Date.now();
		},

		mounted() {
			this.$store.commit('stopLoading');
			this.$track.record('page-view', { module: 'project-list' });
			this.$nextTick(() => this.setupResizer());
		},

		methods: {
			toggleExpanded: function(id) {
				if (Object.hasOwn(this.expanded, id)) {
					this.$delete(this.expanded, id); // Use Vue.delete to remove properties reactively
				} else {
					this.$set(this.expanded, id, true); // Use Vue.set to add properties reactively
				}
			},

			setActiveLabel: function(label) {
				this.activeLabel = label;
			},

			toggleAllChecked: function(deliverables, event) {
				deliverables.forEach((d) => {
					if (event.target.checked) {
						if (this.selectedTasks.findIndex((t) => t.id === d.id) === -1) {
							this.selectedTasks.push(d);
						}
					} else {
						let ix = this.selectedTasks.findIndex((t) => t.id === d.id);
						if (ix > -1) {
							this.selectedTasks.splice(ix, 1);
						}
					}
				});
			},

			toggleChecked: function(deliverable) {
				let ix = this.selectedTasks.findIndex((d) => d.id === deliverable.id);
				if (ix > -1) {
					this.selectedTasks.splice(ix, 1);
				} else {
					this.selectedTasks.push(deliverable);
				}
			},

			routeToProject: function(project, event) {
				event.stopPropagation();
				if (event && (event.ctrlKey || event.metaKey)) {
					window.open(`/project/${project.id}`, '_blank');
				} else {
					this.$router.push(`/project/${project.id}`);
				}
			},

			setupResizer: function() {
				// Query the table
				const table = document.getElementById('deliverable-table');

				// Query all headers
				const cols = table.querySelectorAll('th');

				const createResizableColumn = function(col, resizer) {
					// Track the current position of mouse
					let x = 0;
					let w = 0;

					const mouseDownHandler = function(e) {
						// Get the current mouse position
						x = e.clientX;

						// Calculate the current width of column
						const styles = window.getComputedStyle(col);
						w = parseInt(styles.width, 10);

						// Attach listeners for document's events
						document.addEventListener('mousemove', mouseMoveHandler);
						document.addEventListener('mouseup', mouseUpHandler);
						resizer.classList.add('resizing');
					};

					const mouseMoveHandler = function(e) {
						// Determine how far the mouse has been moved
						const dx = e.clientX - x;

						// Update the width of column
						col.style.width = `${w + dx}px`;
					};

					// When user releases the mouse, remove the existing event listeners
					const mouseUpHandler = function() {
						document.removeEventListener('mousemove', mouseMoveHandler);
						document.removeEventListener('mouseup', mouseUpHandler);
						resizer.classList.remove('resizing');
					};

					resizer.addEventListener('mousedown', mouseDownHandler);
				};

				// Loop over them
				[].forEach.call(cols, function(col) {
					// Create a resizer element
					const resizer = document.createElement('div');
					resizer.classList.add('resizer');

					// Set the height
					resizer.style.height = `${col.offsetHeight}px`;

					// Add a resizer element to the column
					col.appendChild(resizer);

					// Will be implemented in the next section
					createResizableColumn(col, resizer);
				});
			},

			addDeliverable: function(group) {
				let defaults = {
					id: null,
					defaultStatusId: this.projectType.statusList[0].id,
				};

				if (group.groupKey === 'projectId') {
					let project = this.projects.find((p) => p.id === group.groupValue);
					defaults.project = project;
					defaults.client = project.client;
				} else if (group.groupKey === 'clientId') {
					defaults.client = this.$store.getters.getClientById(group.groupValue);
					defaults.project = this.projects.find((p) => p.clientId === group.groupValue);
				} else if (group.groupKey === 'statusId') {
					defaults.defaultStatusId = group.groupValue;
				} else if (group.groupKey === 'assignedTo') {
					defaults.defaultAssignedTo = group.groupValue;
				}

				this.$emit('add-deliverable-defaults', defaults);
			},

			addSubTask: function(deliverable) {
				let defaults = {
					id: null,
					defaultStatusId: this.projectType.statusList[0].id,
					projectTypeId: deliverable.projectTypeId,
					parentTaskId: deliverable.id,
				};

				this.$emit('add-deliverable-defaults', defaults);
			},

			editDeliverable: function(deliverable) {
				this.$emit('edit-deliverable', deliverable);
			},

			setName: function(deliverable, event) {
				if (event.target && event.target.innerText) {
					deliverable.name = event.target.innerText;
					event.target.innerText = deliverable.name;
					this.setPropertyAndUpdate(deliverable, 'name', deliverable.name);
				}
			},

			makeMainTask: function(deliverable){
				this.setPropertyAndUpdate(deliverable,'parentTaskId',null);
			},

			moveSubTask: function(deliverable){
				let binding = {
					deliverable: deliverable,
					parentTasks: this.deliverables
				}
				this.$store.state.globalModalController.openModal(ParentTaskTransfer,binding).then((res) => {
					if(res){
						this.setPropertyAndUpdate(deliverable,'parentTaskId',res);
					}
				})
			},

			setStatus: function(deliverable, status) {
				this.setPropertyAndUpdate(deliverable, 'statusId', status.id);
			},

			toggleArchive: function(deliverable) {
				this.setPropertyAndUpdate(deliverable, 'archived', !deliverable.archived);
			},

			setTaskPriority: function(deliverable, priority) {
				this.setPropertyAndUpdate(deliverable, 'taskPriority', priority);
			},

			setStartDate: function(deliverable, date) {
				this.setPropertyAndUpdate(deliverable, 'startDate', date);
			},

			setDueDate: function(deliverable, date) {
				this.setPropertyAndUpdate(deliverable, 'dueDate', date);
			},

			setTasks: function(deliverable, tasks) {
				this.setPropertyAndUpdate(deliverable, 'tasks', tasks);
			},

			setPriority: function(deliverable) {
				this.setPropertyAndUpdate(deliverable, 'priority', deliverable.priority);
			},

			handleCustomFieldChange: function(deliverable, value) {
				let ix = deliverable.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						deliverable.customValues.splice(ix, 1, value);
					} else {
						deliverable.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					deliverable.customValues.push(value);
				}
				this.deliverableService.updateDeliverable(deliverable.id, deliverable);
			},

			setAssignedToList: function(deliverable) {
				this.setPropertyAndUpdate(deliverable, 'assignedToList', deliverable.assignedToList);
			},

			bulkTaskUpdated: function(deliverable) {
				if (deliverable.parentTaskId) {
					let ix = this.deliverables.findIndex((d) => d.id === deliverable.parentTaskId);

					if (ix > -1) {
						let parent = this.deliverables[ix];
						ix = parent.subtasks.findIndex((d) => d.id === deliverable.id);
						if (ix > -1) {
							parent.subtasks.splice(ix, 1, deliverable);
						}
					}
				} else {
					let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
					if (ix > -1) {
						this.deliverables.splice(ix, 1, deliverable);
					}
				}

				let six = this.selectedTasks.findIndex((s) => s.id === deliverable.id);

				if (six > -1) {
					if (deliverable.archived) {
						this.selectedTaskIds.splice(this.selectedTaskIds.indexOf(deliverable.id), 1);
						this.selectedTasks.splice(six, 1);
					} else {
						this.selectedTasks.splice(six, 1, deliverable);
					}
				}
			},

			setPropertyAndUpdate: function(deliverable, property, value) {
				if (deliverable.parentTaskId) {
					let ix = this.deliverables.findIndex((d) => d.id === deliverable.parentTaskId);

					if (ix > -1) {
						let parent = this.deliverables[ix];
						ix = parent.subtasks.findIndex((d) => d.id === deliverable.id);
						if (ix > -1) {
							let d = parent.subtasks[ix];
							d[property] = value;
							parent.subtasks.splice(ix, 1, d);
						}
					}
				} else {
					let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);

					if (ix > -1) {
						let d = this.deliverables[ix];
						d[property] = value;
						this.deliverables.splice(ix, 1, d);
					}
				}

				this.$emit('pause-sorting', deliverable);

				const patch = [{ op: 'replace', path: `/${property}`, value: value }];
				this.deliverableService.patchDeliverable(deliverable.id, patch).catch((err) => {
					console.log(err);
				});
			},

			incrementView: function() {
				this.viewChange++;
			},

			sortByStatusAndDueDateAndName: function(a, b) {
				let result = this.sortByStatus(a, b);
				if (result === 0) {
					result = this.sortByDueDate(a, b);
					if (result === 0) {
						result = this.sortByName(a, b);
					}
				}
				return result;
			},

			sortByNameAndDueDateAndStatus: function(a, b) {
				let result = this.sortByName(a, b);
				if (result === 0) {
					result = this.sortByDueDate(a, b);
					if (result === 0) {
						result = this.sortByStatus(a, b);
					}
				}
				return result;
			},

			sortByDueDateAndNameAndStatus: function(a, b) {
				let result = this.sortByDueDate(a, b);
				if (result === 0) {
					result = this.sortByName(a, b);
					if (result === 0) {
						result = this.sortByStatus(a, b);
					}
				}
				return result;
			},

			sortByDueDateAndName: function(a, b) {
				let result = this.sortByDueDate(a, b);
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByStatus: function(a, b) {
				let ai = this.statusList.findIndex((c) => c.id === a.statusId);
				let bi = this.statusList.findIndex((c) => c.id === b.statusId);

				if (ai < bi) {
					return -1;
				} else if (ai > bi) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByDueDate: function(a, b) {
				if (a.dueDate && b.dueDate) {
					return a.dueDate.localeCompare(b.dueDate);
				} else if (a.dueDate) {
					return -1;
				} else if (b.dueDate) {
					return 1;
				} else {
					return 0;
				}
			},

			getGroupings: function() {
				let result = [];
				if (!this.filter.group || this.filter.group === 'None') {
					result.push({
						groupKey: null,
						groupValue: null,
						groupLabel: null,
						deliverables: [],
					});
				} else if (this.filter.group === 'Status') {
					this.statusList.forEach((s) => {
						result.push({
							groupKey: 'statusId',
							groupValue: s.id,
							groupLabel: s.label,
							deliverables: [],
						});
					});
				} else if (this.filter.group === 'Priority') {
					['Low','Normal','Medium','High','Urgent'].forEach((p) => {
						result.push({
							groupKey: 'taskPriority',
							groupValue: p,
							groupLabel: p,
							deliverables: [],
						});
					});
				}else if (this.filter.group === 'Client') {
					this.$store.getters.clients.forEach((c) => {
						result.push({
							groupKey: 'clientId',
							groupValue: c.id,
							groupLabel: c.name,
							deliverables: [],
						});
					});

					result.push({
						groupKey: 'clientId',
						groupValue: null,
						groupLabel: '[No client]',
						deliverables: [],
					});
				} else if (this.filter.group === 'Project') {
					let projects = [...this.deliverables.map((d) => d.project)];
					projects = projects.filter((p) => p);
					projects = projects.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
					projects.sort((a, b) => a.name.localeCompare(b.name));
					projects.forEach((p) => {
						result.push({
							groupKey: 'projectId',
							groupValue: p.id,
							groupLabel: p.name,
							deliverables: [],
						});
					});

					result.push({
						groupKey: 'projectId',
						groupValue: null,
						groupLabel: '[No project]',
						deliverables: [],
					});
				} else if (this.filter.group.startsWith('Custom:')) {
					let id = this.filter.group.split(':')[1];
					let field = this.customFields.find((f) => f.id === id);
					if (field) {
						field.options.forEach((o) => {
							result.push({
								groupKey: `${this.filter.group}`,
								groupValue: o,
								groupLabel: o,
								deliverables: [],
							});
						});
						result.push({
							groupKey: `${this.filter.group}`,
							groupValue: null,
							groupLabel: `[No ${field.name}]`,
							deliverables: [],
						});
					}
				}

				return result;
			},

			confirmDeleteDeliverable: function(deliverable) {
				this.$emit('confirm-delete', deliverable);
			},

			confirmDuplicate: function(deliverable) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to duplicate this task?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.processDuplicateDeliverable(deliverable).then((res) => {
							this.deliverables.push(res.data);
						});
					}
				});
			},

			getShareLink: function(deliverable) {
				let shareLink = this.getDeliverableShareLink(deliverable);
				navigator.clipboard.writeText(shareLink);
				this.$store.commit('info', 'Copied to clipboard!');
			},

			processDeliverable(r) {
				r.status = this.statusList.find((s) => s.id === r.statusId);
				r.events = [];
				r.clientId = r.client ? r.client.id : null;
				r.assignedTo = r.assignedTo > 0 || r.assignedTo < 0 ? r.assignedTo : null;

				this.customFields.forEach((f) => {
					if(r.customValues) {
						let val = r.customValues.find((c) => c.fieldId === f.id);
						r[`Custom:${f.id}`] = val ? val.value : null;
					}else{
						console.log(r);
					}
				});
			},
		},

		computed: {
			fullAccessUsers: function() {
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					let simple = { firstName: user.firstName, lastName: user.lastName, userId: user.userId, email: user.email };

					if (user.userType === 'OWNER' || user.userType === 'FULL_USER' || user.userType === 'IMPLEMENTER') {
						users.push(simple);
					} else if (user.userType === 'RESTRICTED_USER' && user.featureAccess && user.featureAccess.projects) {
						users.push(simple);
					}
				}

				return users;
			},

			selectedTaskIds: function() {
				return this.selectedTasks.map((t) => t.id);
			},

			deliverableGroupings: function() {
				let deliverables = JSON.parse(JSON.stringify(this.deliverables));

				deliverables.forEach((r) => {
					this.processDeliverable(r);
					if (r.subtasks) {
						r.subtasks.forEach((s) => {
							this.processDeliverable(s);
						});
					}
				});

				let groupings = this.getGroupings();

				groupings.forEach((g) => {
					g.deliverables.push(...deliverables.filter((d) => !g.groupKey || d[g.groupKey] === g.groupValue));
				});

				return groupings.filter((g) => g.deliverables.length);
			},
		},

		watch: {
			statusFilter: function() {
				setTimeout(this.incrementView, 20);
			},
		},
	};
</script>

<style lang="scss">
	#deliverable-table-wrapper {
		.table th {
			position: relative;
		}

		.resizer {
			/* Displayed at the right side of column */
			position: absolute;
			top: 0;
			right: 0;
			width: 5px;
			cursor: col-resize;
			user-select: none;
		}

		.resizer:hover,
		.resizing {
			background-color: var(--v-primary-base);
		}

		.project-name {
			position: relative;
			display: flex;
			align-items: center;

			.open-icon {
				display: none;
			}

			&:hover {
				.open-icon {
					position: absolute;
					display: flex;
					right: 0;
					z-index: 100;
					background-color: var(--v-gray_5-base);
					color: var(--v-primary-base);
					font-weight: 600;
					padding: 2px 2px 2px 6px;
					height: 100%;
				}
			}
		}

		.edit-task-name {
			&:focus {
				padding: 0 4px;

				~ .open-icon {
					display: none !important;
				}

				~ .subtask-count {
					display: none !important;
				}
			}
		}

		.task-name {
			position: relative;
			display: flex;
			align-items: center;

			.action-box {
				display: none;
			}

			.open-icon {

				color: var(--v-primary-base);
					font-weight: 600;
			}

			&:hover {
				.action-box {
					background-color: var(--v-gray_5-base);
					position: absolute;
					display: flex;
					right: 0;
					z-index: 100;
					padding: 2px 2px 2px 6px;
					height: 100%;
				}
			}
		}

		#deliverable-table-wrapper {
			//border: 1px solid var(--v-gray_30-base);
		}

		.deliverable-table {
			text-align: left;
			border-collapse: collapse;
			width: 100%;

			.heading {
				padding: 4px 8px;
				font-size: 12px;
				font-weight: 600;
				margin: 0;
				border-top: 1px solid var(--v-gray_20-base);
				border-bottom: 1px solid var(--v-gray_20-base);
				border-right: 1px solid var(--v-gray_20-base);
				background-color: var(--v-gray_5-base);
				white-space: nowrap;

				&:last-of-type {
					padding-right: 12px !important;
				}
			}

			.heading-dark {
				background-color: var(--v-gray_20-base) !important;
				border-bottom: 1px solid var(--v-gray_10-base);
				border-right: 1px solid var(--v-gray_10-base);
			}

			.group-heading {
				padding-top: 0;
				padding-bottom: 4px;
				color: var(--v-primary-base);
				font-weight: 600;
				border: none !important;
			}

			.footer {
				color: var(--v-gray_70-base);
				font-size: 14px;
			}

			thead {
				tr {
					th {
						padding: 0 4px;
						font-size: 12px;
						font-weight: 600;
						margin: 0;
						border-bottom: 1px solid var(--v-gray_20-base);
						border-right: 1px solid var(--v-gray_20-base);

						//&:first-of-type {
						//	padding-left: 20px !important;
						//}

						&:last-of-type {
							padding-right: 12px !important;
						}
					}
				}
			}

			tbody {
				tr {
					td {
						padding: 2px 4px;
						margin: 0;
						border-bottom: 1px solid var(--v-gray_20-base);
						border-right: 1px solid var(--v-gray_20-base);

						&:first-of-type {
							border-left: 1px solid var(--v-gray_20-base);
							//padding-left: 12px !important;
						}

						&:last-of-type {
							padding-right: 12px !important;
						}
					}
				}
			}
		}

		.subtask-count {
			background-color: var(--v-gray_10-base);
			border-radius: 4px;
			height: 18px;
			width: 18px;
			font-size: 12px;
		}

		.border-right {
			border-right: 1px solid var(--v-gray_20-base);
		}

		.deliverableStatus {
			white-space: nowrap;

			.statusBox {
				margin-right: 8px;
				width: 14px;
				height: 14px;
				border-radius: 2px;
				white-space: nowrap;
				background-color: var(--status-color);
			}
		}

		.highlight-hover {
			&:hover {
				color: var(--v-primary-base) !important;
			}
		}

		div[contenteditable='true']:focus {
			outline: 1px solid var(--v-gray_30-base);
			background-color: var(--v-white-base);
			padding-right: 4px;
			padding-left: 4px;
		}

		.numberInput {
			margin: 0px;
			padding: 0px;
			max-width: 50px;
			color: var(--v-black-base);
		}

		.numberInput:focus {
			outline: none;
		}
	}
</style>
