<template>
	<div class="pl-2 text-left font-gray_90">
		<div class="row-format align-center">
			<v-icon small color="" class="material-symbols-rounded">filter_alt</v-icon>
			<div class="ml-1" :class="`${event.original.meta.complete ? 'font-strike' : ''}`">{{event.name}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "OppToDoEvent",

	props: ['event','view'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
		console.log(this.event);
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>