<template>
	<div class="panel-modal" style="width: 700px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="cancelEditDeliverable(true, false)">$chevronRight</v-icon>
				<v-icon color="black" class="mr-1">splitscreen</v-icon>
				<div class="brand-medium font-18">
					<div>{{ isSubTask ? 'Subtask ' : 'Task' }} details</div>
				</div>
				<div class="ml-auto">
					<v-btn v-if="isReady" class="super-action" @click="saveAndCloseInternal(true, true)">
						{{ $t('global.save') }}
					</v-btn>
					<v-menu :close-on-content-click="true" :close-on-click="true" bottom nudge-bottom="30">
						<template v-slot:activator="scope">
							<v-btn icon class="ml-1" v-on="scope.on">
								<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</template>
						<div class="more-menu">
							<div class="more-menu-item" @click="getShareLink">Share</div>
							<div class="more-menu-item" @click="confirmDuplicate">Duplicate</div>
							<div class="more-menu-item" @click="archiveDeliverable">
								{{ deliverable.archived ? 'Unarchive' : 'Archive' }}
							</div>
							<div class="more-menu-item" @click="deleteDeliverableDialog = true">Delete</div>
						</div>
					</v-menu>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left">
			<div id="deliverable-detail">
				<input id="deliverableLink" name="deliverableLink" :value="deliverableLink" type="hidden" />
				<div v-if="isSubTask" class="mb-1 brand-medium">{{ parentTaskName ? parentTaskName : '--' }}</div>
				<div class="row-format">
					<v-icon color="black" class="mr-1" v-if="isSubTask">subdirectory_arrow_right</v-icon>
					<v-text-field
						autofocus
						hide-details
						dense
						outlined
						placeholder="Task name"
						v-model="deliverable.name"
					></v-text-field>
				</div>
				<div class="mt-6 mb-4 row-format align-center gap-3">
					<div
						:class="`page-link ${currentView === 'Overview' ? 'page-link-active' : ''}`"
						@click="setView('Overview')"
					>
						<div>Overview</div>
					</div>
					<div :class="`page-link ${currentView === 'Files' ? 'page-link-active' : ''}`" @click="setView('Files')">
						<div>Files</div>
					</div>
					<div
						:class="`page-link ${currentView === 'Comments' ? 'page-link-active' : ''}`"
						@click="setView('Comments')"
					>
						<div>Comments</div>
					</div>
					<div
						v-if="!isSubTask"
						:class="`page-link ${currentView === 'Subtasks' ? 'page-link-active' : ''}`"
						@click="setView('Subtasks')"
					>
						<div>Subtasks</div>
					</div>
				</div>

				<div
					class="show-scrollbar"
					style="height: calc(100vh - 210px); max-height: calc(100vh - 210px); overflow-y: auto"
				>
					<div v-show="currentView === 'Overview'" style="width: 98%">
						<div v-if="deliverable.archived" class="row-format align-center mb-2">
							<v-icon color="secondary">lock</v-icon>
							<div class="ml-2 brand-bold font-secondary">Archived</div>
						</div>
						<div>
							<div class="row-format align-center ">
								<div class="overview-left">
									<v-icon size="20">bolt</v-icon>
									<div class="ml-2">Status</div>
								</div>
								<div class="overview-right">
									<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
										<template v-slot:activator="{ on }">
											<div
												v-on="on"
												class="deliverableStatus row-format align-center pointer"
												:style="`--status-color:${deliverableStatus.hexColor}`"
											>
												<div class="statusBox"></div>
												<div class="text-no-wrap">{{ deliverableStatus.label }}</div>
											</div>
										</template>
										<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
											<div
												v-for="status in statusList"
												:key="status.id"
												class="py-1 pointer"
												@click="deliverable.statusId = status.id"
											>
												<div
													class="deliverableStatus row-format align-center highlight-hover"
													:style="`--status-color:${status.hexColor}`"
												>
													<div class="statusBox"></div>
													<div>{{ status.label }}</div>
												</div>
											</div>
										</div>
									</v-menu>
								</div>
							</div>
							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20">person</v-icon>
									<div class="ml-2">Assigned to</div>
								</div>
								<div class="overview-right">
									<v-menu :close-on-click="true" :close-on-content-click="false" offset-y>
										<template v-slot:activator="{ on }">
											<div v-on="on" class="pointer">
												<div v-if="deliverable.assignedToList.length" class="row-format align-center">
													<assigned-user
														v-on="on"
														v-for="(assignedTo, index) in deliverable.assignedToList"
														:key="assignedTo"
														:assigned-to="assignedTo"
														:show-name="false"
														empty-label="--"
														:style="
															`${
																index > 0 ? 'margin-left: -6px' : ''
															}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
														"
													></assigned-user>
												</div>
												<div v-else>--</div>
											</div>
										</template>
										<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
											<div
												v-for="user in userList"
												:key="user.userId"
												class="row-format align-center text-left py-1 pointer"
											>
												<input
													style="width: 20px; height: 20px; border-radius: 4px"
													type="checkbox"
													:value="user.userId"
													v-model="deliverable.assignedToList"
												/>
												<assigned-user
													class="mx-2"
													v-if="user.userId"
													:assigned-to="user.userId"
													:show-name="true"
												></assigned-user>
											</div>
										</div>
									</v-menu>
								</div>
							</div>
							<div class="row-format align-center py-2" v-if="!isSubTask">
								<div class="overview-left">
									<v-icon size="20" class="material-symbols-rounded">group</v-icon>
									<div class="ml-2">Client</div>
								</div>
								<div class="overview-right">
									<v-menu
										:close-on-click="true"
										:close-on-content-click="false"
										offset-y
										v-model="clientMenu"
										:disabled="$store.getters.isAccountCollaborator"
									>
										<template v-slot:activator="{ on }">
											<div v-on="on" class="pointer">
												<div class="row-format align-center" v-if="assignedClient">
													<client-avatar
														x-small
														:client="assignedClient"
														:disable-click="true"
													></client-avatar>
													<div class="ml-2">{{ assignedClient.name }}</div>
												</div>
												<div v-else>--</div>
											</div>
										</template>
										<div class="column-format pa-2" style="background-color: var(--v-white-base)">
											<v-text-field
												v-model="clientFilter"
												hide-details
												dense
												outlined
												placeholder="Filter..."
												autofocus
											></v-text-field>
											<div
												class="text-left column-format pt-1 mt-2 show-scrollbar"
												style="max-height: 300px; overflow-y: scroll"
											>
												<div
													@click="
														deliverable.clientId = client.id;
														clientMenu = false;
													"
													v-for="client in filteredClientList"
													:key="client.id"
													class="row-format align-center px-3 py-2 pointer hover-gray"
													style="border-bottom: 1px solid var(--v-gray_30-base)"
												>
													<client-avatar :disable-click="true" :client="client" x-small></client-avatar>
													<div class="ml-2 brand-medium">{{ client.name }}</div>
												</div>
											</div>
										</div>
									</v-menu>
								</div>
							</div>

							<div class="row-format align-center py-2" v-if="!isSubTask">
								<div class="overview-left">
									<v-icon size="20" class="material-symbols-rounded">folder</v-icon>
									<div class="ml-2">Project</div>
								</div>
								<div class="overview-right">
									<v-menu :close-on-click="true" :close-on-content-click="false" offset-y v-model="projectMenu">
										<template v-slot:activator="{ on }">
											<div v-on="on" class="pointer">
												<div v-if="assignedProject">{{ assignedProject.name }}</div>
												<div v-else>--</div>
											</div>
										</template>
										<div class="column-format pa-2" style="background-color: var(--v-white-base)">
											<v-text-field
												v-model="projectFilter"
												hide-details
												dense
												outlined
												placeholder="Filter..."
												autofocus
											></v-text-field>
											<div
												class="text-left column-format pt-1 mt-2 show-scrollbar"
												style="max-height: 300px; overflow-y: scroll"
											>
												<div
													@click="setProject(project)"
													v-for="project in filteredProjectList"
													:key="project.id"
													class="row-format align-center px-3 py-2 pointer hover-gray"
													style="border-bottom: 1px solid var(--v-gray_30-base)"
												>
													<client-avatar
														v-if="project.client"
														:client="project.client"
														:disable-click="true"
														x-small
													></client-avatar>
													<div class="ml-2 brand-medium">{{ project.name }}</div>
												</div>
											</div>
										</div>
									</v-menu>
								</div>
							</div>

							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20" class="material-symbols-rounded">confirmation_number</v-icon>
									<div class="ml-2">Ticket</div>
								</div>
								<div class="overview-right">
									<linked-ticket v-model="deliverable.ticketId" :deliverable="deliverable"></linked-ticket>
								</div>
							</div>

							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20">today</v-icon>
									<div class="ml-2">Start date</div>
								</div>
								<div class="overview-right">
									<date-selector
										:micro="true"
										:show-year="true"
										micro-font="font-14"
										:date="deliverable.startDate"
										label="--"
										@change="deliverable.startDate = $event"
									></date-selector>
								</div>
							</div>
							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20">event</v-icon>
									<div class="ml-2">Due date</div>
								</div>
								<div class="overview-right">
									<date-selector
										:micro="true"
										:show-year="true"
										micro-font="font-14"
										:date="deliverable.dueDate"
										label="--"
										@change="deliverable.dueDate = $event"
									></date-selector>
								</div>
							</div>
							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20">priority_high</v-icon>
									<div class="ml-2">Priority</div>
								</div>
								<div class="overview-right">
									<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
										<template v-slot:activator="{ on }">
											<div v-on="on" class="row-format align-center pointer">
												<div class="text-no-wrap">{{ deliverable.taskPriority }}</div>
											</div>
										</template>
										<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
											<div
												v-for="priority in priorityList"
												:key="priority"
												class="py-1 pointer"
												@click="deliverable.taskPriority = priority"
											>
												<div class=" row-format align-center highlight-hover">
													<div>{{ priority }}</div>
												</div>
											</div>
										</div>
									</v-menu>
								</div>
							</div>
							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20">sort</v-icon>
									<div class="ml-2">Rank</div>
								</div>
								<div class="overview-right">
									<input
										type="number"
										class="numberInput"
										oninput="this.value=(parseInt(this.value)||0)"
										v-model="deliverable.priority"
										step="1"
									/>
								</div>
							</div>
							<div class="row-format align-center py-2">
								<div class="overview-left">
									<v-icon size="20" class="material-symbols-rounded">timer</v-icon>
									<div class="ml-2">Time worked</div>
								</div>
								<div class="overview-right">
									<div class="pointer" @click="openTimerDetails" style="width: 60px">
										{{ $formatters.formatSecondsFull(totalTimeWorked + runningTimeSeconds) }}
									</div>
									<v-icon
										size="14"
										@click.stop="createTimerEvent"
										class="pointer ml-1"
										v-tippy="{ content: 'Add a time entry for this task' }"
										>$plus</v-icon
									>
									<v-icon
										size="14"
										@click.stop="startRunningTimer"
										class="pointer ml-1"
										v-if="!currentTimer"
										v-tippy="{ content: 'Start timer for this task' }"
										>$play</v-icon
									>
									<v-icon
										size="14"
										@click.stop="stopRunningTimer"
										color="red"
										class="pointer ml-1"
										v-if="currentTimer"
										v-tippy="{ content: 'Stop running timer for this task' }"
										>$stop</v-icon
									>
								</div>
							</div>
						</div>
						<div class="row-format py-2" v-if="showFeeSection">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">money</v-icon>
								<div class="ml-2">Fees</div>
							</div>
							<div class="overview-right">
								<div class="column-format text=;eft">
									<per-item-pricing
										:hide-icon="true"
										:minimal="true"
										:product="deliverable.product"
										:currency="currency"
										:quantity="deliverable.quantity"
										:editable="!deliverable.invoiceId"
										:hours="perItemHoursWorked"
										@product-selected="handleProductSelected($event)"
										@quantity-updated="handleQuantityUpdated($event)"
									>
									</per-item-pricing>
									<div v-if="deliverable.invoiceId" class="font-12 font-gray_70">
										Included in invoice {{ deliverable.invoiceNumber }}
									</div>
								</div>
							</div>
						</div>
						<div class="row-format py-2" v-for="field in fields" :key="field.id">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">{{ field.icon }}</v-icon>
								<div class="ml-2">{{ field.name }}</div>
							</div>
							<div class="overview-right">
								<custom-field
									:field="field"
									:value="getCustomValue(field.id)"
									@change="handleCustomFieldChange($event)"
								></custom-field>
							</div>
						</div>
						<div class="mt-6">
							<div class="brand-bold mb-4">Description</div>
							<div class="font-14">
								<editor
									v-model="deliverable.description"
									ref="Description"
									:api-key="$store.getters.getTinyMceKey"
									:inline="true"
									:init="mceConfig"
									:spellcheck="true"
									class="no-wrap-editor px-2"
									style="width: 100%"
									@onblur="convertMarkdown()"
								>
								</editor>
							</div>
						</div>
						<div class="my-6">
							<div class="brand-bold mb-4">To-dos</div>
							<div class="font-14">
								<tasks
									ref="tasks"
									v-model="deliverable.tasks"
									:detail-view="true"
									@list-changed="setPropertyAndPatch(deliverable, 'tasks', $event)"
								></tasks>
							</div>
						</div>
						<div class="mb-6" v-if="approvals.length">
							<div class="brand-bold mb-4">Client workflow</div>
							<div class="font-14">
								<ul v-for="approval in approvals" :key="approval.id" class="font-gray_80 font-14 text-left">
									<li>
										{{ approval.approverName }} on
										{{ DateTime.fromISO(approval.approvedAt).toFormat('DD') }} from [{{
											approval.approvalStatus
										}}] status
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div v-show="currentView === 'Files'" style="width: 98%">
						<div class="font-14">
							<file-attachments :deliverable="deliverable" />
						</div>
					</div>
					<div v-show="currentView === 'Comments'" style="width: 98%">
						<div class="font-14">
							<activity
								ref="activity"
								:client="client"
								:project="project"
								:deliverable="deliverable"
								:project-service="projectService"
								:render-index="renderIndex"
								:userList="isInClientApproval ? fullUserList : userList"
							/>
						</div>
					</div>
					<div v-if="!isSubTask" v-show="currentView === 'Subtasks'" style="width: 100%; height: 100%;">
						<subtasks :deliverable="deliverable" :status-list="statusList" :user-list="userList"></subtasks>
					</div>
				</div>
			</div>
		</div>
		<confirm-dialog
			:dialog="deleteDeliverableDialog"
			@close="deleteDeliverableDialog = false"
			@confirm="deleteDeliverable()"
			:heading-text="$t('global.confirm')"
			:body-text="$t('projects.deliverable-delete-confirm')"
			:yes-text="$t('global.delete')"
			:no-text="$t('global.cancel')"
		/>
	</div>
</template>

<script>
	import ProjectDeliverableService from './ProjectDeliverableService';
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import jsonPatch from 'fast-json-patch';
	import ProjectService from '../ProjectService';
	import DateTime from '@/modules/utils/HDateTime';
	import AggregateTimerList from '@/modules/calendar/lists/AggregateTimerList';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import ConfirmModal from '@/components/ConfirmModal';
	import ClientAvatar from '@/components/ClientAvatar';
	import AssignedUser from '@/components/AssignedUser';
	import DateSelector from '@/components/DateSelector';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import FileAttachments from '@/modules/projects/deliverable/FileAttachments';
	import Activity from '@/modules/projects/deliverable/Activity';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import PerItemPricing from '@/modules/projects/deliverable/PerItemPricing';
	import { mapGetters } from 'vuex';
	import DeliverableMixin from '@/modules/projects/deliverable/DeliverableMixin';
	import CustomField from '@/components/CustomField';
	import editor from '@tinymce/tinymce-vue';
	import marked from 'marked';
	import LinkedTicket from '@/modules/projects/deliverable/LinkedTicket';
	import Subtasks from '@/modules/projects/deliverable/Subtasks';

	export default {
		name: 'DeliverableDetail',
		isRightModal: true,

		props: [
			'openStatus',
			'client',
			'project',
			'deliverableId',
			'defaultStatusId',
			'defaultAssignedTo',
			'defaultDueDate',
			'projectTypeId',
			'parentTaskId',
			'isFullPage',
		],

		mixins: [DeliverableMixin],

		components: {
			LinkedTicket,
			editor,
			AssignedUser,
			ClientAvatar,
			DateSelector,
			Tasks,
			FileAttachments,
			Activity,
			PerItemPricing,
			ConfirmDialog,
			CustomField,
			Subtasks,
		},

		data: function() {
			return {
				currentView: 'Overview',
				timeTrackService: new TimeTrackService(),
				projectDeliverableService: new ProjectDeliverableService(),
				projectService: new ProjectService(),
				isSaving: false,
				deliverable: null,
				originalProject: null,
				originalClient: null,
				isReady: false,
				observer: null,
				dueDateMenu: false,
				deleteDeliverableDialog: false,
				autoFocus: false,
				renderIndex: 0,
				// saveDisabled: false, /* Doesn't do anything */
				timerEvents: [],
				wasNewDeliverable: false,
				projectList: [],
				DateTime: DateTime,
				productDialog: false,
				timeRefreshTimer: null,
				runningTimeSeconds: 0,
				clientMenu: false,
				projectMenu: false,
				clientFilter: null,
				projectFilter: null,
				ticketMenu: false,
				parentTaskName: null,
				priorityList: ['Low', 'Normal', 'Medium', 'High', 'Urgent'],
				mceConfig: {
					menubar: false,
					inline: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Add more detail to this task...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'copy | paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					content_style: '.mce-content-body { text-align: start }',
					toolbar: [
						'bold italic underline forecolor | formatting_group | alignleft aligncenter alignright alignjustify | media_group',
					],
					fontsize_formats: '10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 40px 48px',
					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items:
								'fontsizeselect fontselect | blockquote bullist numlist indent outdent table | removeformat | link unlink',
						},
						media_group: {
							icon: 'embed',
							tooltip: 'Media',
							items: ' link unlink media',
						},
					},
					// setup: (editor) => {
					// 	editor.on('keydown', function (event) {
					// 		if ((event.metaKey && event.key === 'k') || (event.ctrlKey && event.key === 'k')) {
					// 			event.stopPropagation();
					// 		}
					// 	});
					// }
				},
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'deliverable-detail' });
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
			this.initialize();
			this.getProjectList();
		},

		beforeDestroy() {
			this.shutDown();
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
			if (this.timeRefreshTimer) {
				clearInterval(this.timeRefreshTimer);
			}
		},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},

			test: function() {
				console.log('hello');
			},

			convertMarkdown: function() {
				if (this.deliverable && this.deliverable.descriptionFormat === 'Markdown' && this.deliverable.description) {
					this.deliverable.description = this.formatMarkdown(this.deliverable.description);
					this.deliverable.descriptionFormat = 'HTML';
				}
			},

			formatMarkdown: function(comment) {
				if (comment) {
					let m = marked(comment, { breaks: true });
					m = m.replaceAll('<a href', '<a target="_blank" href');
					return m;
				} else {
					return null;
				}
			},

			setProject: function(project) {
				this.deliverable.projectId = project.id;
				this.deliverable.projectTypeId = project.projectTypeId;
				this.projectMenu = false;
			},

			handleTimerStopEvent(event) {
				if (this.isTimerInScope(event.message)) {
					let timerEvent = event.message;
					let start = DateTime.fromISO(timerEvent.timerStart);
					let end = DateTime.fromISO(timerEvent.timerEnd);
					timerEvent.duration = Math.round(end.diff(start, ['seconds']).seconds) - timerEvent.pausedSeconds;
					this.timerEvents.push(event.message);
				}
			},

			initialize: function() {
				if (this.$validations.isEmpty(this.deliverableId)) {
					let assignedToList = [];

					if (this.defaultAssignedTo) {
						assignedToList.push(this.defaultAssignedTo);
					} else if (this.$store.state.usersForAccount.length === 1) {
						assignedToList.push(this.$store.getters.getLoggedInUserId);
					}

					this.deliverable = {
						id: null,
						clientId: this.client ? this.client.id : null,
						projectId: this.project ? this.project.id : null,
						name: null,
						description: null,
						assignedToList: assignedToList,
						dueDate: this.defaultDueDate,
						statusId: this.defaultStatusId ? this.defaultStatusId : this.statusList[0].id,
						projectTypeId: this.projectTypeId,
						parentTaskId: this.parentTaskId,
						tasks: [],
						comments: [],
						events: [],
						files: [],
						archived: false,
						product: null,
						quantity: 0,
					};

					this.projectDeliverableService
						.createNewDeliverable(this.deliverable)
						.then((res) => {
							this.$onBoarding.track('projects_create_task');
							this.wasNewDeliverable = true;
							this.initializeDataAndEvents({ deliverable: res.data }, true);
						})
						.catch((err) => {
							console.log(err);
							this.$store.commit('error', err.response.data.message);
						});
				} else {
					this.projectDeliverableService
						.getDeliverableDetail(this.deliverableId)
						.then((res) => {
							this.initializeDataAndEvents(res.data);
							this.timeTrackService
								.getTimerEventsFull(null, null, null, null, null, this.deliverableId)
								.then((res) => {
									this.timerEvents = res.data;
									this.timerEvents.sort(this.sortTimerEvents);
								});
						})
						.catch((err) => {
							console.log(err);
							this.$store.commit('error', err.response.data.message);
						});
				}
			},

			getProjectList() {
				this.projectService
					.getAllActiveProjects()
					.then((res) => {
						this.projectList.splice(0, this.projectList.length);
						this.projectList.push(...res.data);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			setName: function(event) {
				if (event.target && event.target.innerText) {
					this.deliverable.name = event.target.innerText;
					event.target.innerText = this.deliverable.name;
				} else if (event.target) {
					this.deliverable.name = null;
					event.target.innerText = '';
				}
			},

			handleProjectUpdated: function(projectId) {
				if (!this.deliverable) {
					return;
				}
				if (projectId && this.assignedProject) {
					this.deliverable.clientId = this.assignedProject.clientId;
				}
			},

			getCustomValue: function(id) {
				let value = this.deliverable.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.deliverable.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.deliverable.customValues.splice(ix, 1, value);
					} else {
						this.deliverable.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.deliverable.customValues.push(value);
				}
			},

			handleClientUpdated: function(clientId) {
				if (!this.deliverable) {
					return;
				}
				if (clientId) {
					if (this.assignedProject && this.assignedProject.clientId !== clientId) {
						this.deliverable.projectId = null;
					}
				} else {
					this.deliverable.clientId = null;
					this.deliverable.projectId = null;
				}
			},

			handleProductSelected: function(product) {
				this.deliverable.product = product;
			},

			handleQuantityUpdated: function(quantity) {
				this.deliverable.quantity = quantity;
			},

			routeToProject: function() {
				this.$router.push(`/project/${this.deliverable.projectId}`);
			},

			routeToClient: function() {
				this.$router.push(`/client/${this.client.id}`);
			},

			checkClear: function(propName) {
				if (this.deliverable[propName] === '--clear--') {
					this.deliverable[propName] = null;
				}
			},

			initializeDataAndEvents: function(data) {
				this.deliverable = data.deliverable;
				this.originalClient = data.client;

				if (data.project) {
					this.originalProject = data.project;
					this.originalProject.client = data.client;
				}

				if (this.deliverable.parentTaskId) {
					this.projectDeliverableService
						.getDeliverableName(this.deliverable.parentTaskId)
						.then((res) => (this.parentTaskName = res.data));
				}

				this.convertMarkdown();
				this.observer = jsonPatch.observe(this.deliverable);
				this.isReady = true;
				this.$store.state.eventBus.$on(this.getChannelName(), this.eventHandler);
			},

			shutDown: function() {
				this.$store.state.eventBus.$off(this.getChannelName(), this.eventHandler);
			},

			handleClose: function() {
				this.cancelEditDeliverable();
			},

			cancelEditDeliverable: function() {
				this.saveAndCloseInternal();
			},

			setPropertyAndUpdate: function(property, value) {
				const patch = [{ op: 'replace', path: `/${property}`, value: value }];
				this.projectDeliverableService.patchDeliverable(this.deliverable.id, patch).catch((err) => {
					console.log(err);
				});
			},

			saveAndCloseInternal: function(close = true, force = false) {
				// this.saveDisabled = true;
				this.$refs.tasks.clearEmpty();
				this.$refs.activity.checkCommentBeforeSave().then((commentSaved) => {
					this.isSaving = true;
					const patch = this.generatePatchFiltered();

					if (patch.length > 0) {
						this.$store.commit('startLoading');
						this.projectDeliverableService
							.updateDeliverable(this.deliverable.id, this.deliverable)
							.then((res) => {
								this.deliverable = res.data;
								this.deliverable.project = this.assignedProject;
								this.deliverable.client = this.assignedClient;
							})
							.catch()
							.finally(() => {
								this.$store.commit('stopLoading');
								this.wasNewDeliverable = false;
								if (close) {
									this.isSaving = false;
									this.shutDown();
									this.$emit('result', this.deliverable);
								}
							});
					} else {
						if (!force && close && !commentSaved && this.wasNewDeliverable) {
							this.deleteDeliverable(false);
							this.shutDown();
						} else if (close) {
							this.shutDown();
							this.$emit('result');
						}
					}
				});
			},

			saveAndCloseInternalOld: function(close = true, force = false) {
				// this.saveDisabled = true;
				this.$refs.tasks.clearEmpty();
				this.$refs.activity.checkCommentBeforeSave().then((commentSaved) => {
					this.isSaving = true;
					const patch = this.generatePatchFiltered();

					if (patch.length > 0) {
						this.$store.commit('startLoading');
						this.save(patch)
							.then((res) => {
								this.deliverable = res.data;
								this.deliverable.project = this.assignedProject;
								this.deliverable.client = this.assignedClient;
							})
							.catch()
							.finally(() => {
								this.$store.commit('stopLoading');
								this.wasNewDeliverable = false;
								if (close) {
									this.isSaving = false;
									this.shutDown();
									this.$emit('result', this.deliverable);
								}
							});
					} else {
						if (!force && close && !commentSaved && this.wasNewDeliverable) {
							this.deleteDeliverable(false);
							this.shutDown();
						} else if (close) {
							this.shutDown();
							this.$emit('result');
						}
					}
				});
			},

			generatePatchFiltered: function() {
				const patch = jsonPatch.generate(this.observer, false);
				if (patch.length > 0) {
					for (let i = patch.length - 1; i >= 0; i--) {
						let path = patch[i].path;
						if (path.startsWith('/comments/') || path.startsWith('/files/') || path.startsWith('/events/')) {
							patch.splice(i, 1);
						}
					}
					return patch;
				} else {
					return [];
				}
			},

			save: function(patch) {
				this.$refs.tasks.clearEmpty();
				let result = this.projectDeliverableService.patchDeliverable(this.deliverable.id, patch);

				return result
					.then((res) => {
						return Promise.resolve(res);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						return Promise.reject(err);
					})
					.finally(() => {
						this.isSaving = false;
					});
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'Comment') {
					this.handleCommentEvent(event);
				} else if (event.userMetadata === 'EventLog') {
					this.handleEventLogEvent(event);
				} else if (event.userMetadata === 'ProjectDeliverableMini') {
					Object.assign(this.deliverable, event.message);
				}
			},

			handleEventLogEvent(event) {
				event.message.timestamp = new Date().toISOString().split('.')[0] + 'Z';
				let eventLog = event.message;
				this.deliverable.events.push(eventLog);
			},

			handleCommentEvent(event) {
				event.message.timestamp = new Date().toISOString().split('.')[0] + 'Z';
				let comment = event.message;

				if (comment.edited) {
					let ix = this.deliverable.comments.findIndex((c) => c.id === comment.id);
					if (ix > -1) {
						if (comment.comment === '_deleted_') {
							this.deliverable.comments.splice(ix, 1);
						} else {
							this.deliverable.comments.splice(ix, 1, comment);
						}
					}
				} else {
					this.deliverable.comments.push(comment);
				}

				this.renderIndex++;
			},

			deleteDeliverable: function(enableRestore = true) {
				this.projectDeliverableService
					.deleteDeliverable(this.deliverable.id, enableRestore)
					.then(() => {
						this.$emit('result', {
							action: 'DELETED',
							id: this.deliverable.id,
							projectId: this.deliverable.projectId,
						});
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						this.$emit('error', err);
					});
			},

			getChannelName() {
				return (
					this.$store.getters.getMessageChannelPrefix +
					'.pr-' +
					this.deliverable.projectId +
					'.d-' +
					this.deliverable.id
				);
				// return this.$store.getters.getMessageChannelPrefix + '.*.d-' + this.deliverable.id;
			},

			getShareLink() {
				navigator.clipboard.writeText(this.deliverableLink);
				this.$store.commit('success', 'Link copied to clipboard');
			},

			archiveDeliverable() {
				this.deliverable.archived = !this.deliverable.archived;
				this.saveAndCloseInternal(this.deliverable.archived);
			},

			confirmDuplicate: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to duplicate this deliverable?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.duplicateDeliverable();
					}
				});
			},

			duplicateDeliverable: function() {
				let client = this.assignedClient;
				let project = this.assignedProject;

				this.processDuplicateDeliverable(this.deliverable)
					.then((dbRes) => {
						this.shutDown();
						this.resetComponent();

						let result = {
							deliverable: dbRes.data,
							client: client,
							project: project,
						};

						this.$nextTick(() => this.initializeDataAndEvents(result));
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', err.response.data.message);
					});
			},

			resetComponent: function() {
				this.isReady = false;
				this.observer = null;
				this.deliverable = null;
				this.timerEvents.splice(0, this.timerEvents.length);
			},

			handleInput: function(targetProp, refName) {
				this.deliverable[targetProp] = this.$refs[refName].editor.getContent();
			},

			formatTime: function(number, type) {
				if (number < 0) {
					number = number * -1;
					return number + ' ' + type + (number > 1 ? 's' : '') + ' ' + this.$t('global.ago');
				} else {
					return number + ' ' + type + (number > 1 ? 's' : '') + ' ' + this.$t('global.remaining');
				}
			},

			sortTimerEvents: function(a, b) {
				return b.timerStart.localeCompare(a.timerStart);
			},

			openTimerDetails: function() {
				if (this.timerEvents.length) {
					let binding = {
						events: this.timerEvents,
					};
					this.$store.state.globalModalController.openModal(AggregateTimerList, binding, true, false).then((res) => {
						if (!res) return;
						this.timerEvents.splice(0, this.timerEvents.length);
						this.timerEvents.push(...res.filter((e) => e.deliverableId === this.deliverableId));
					});
				}
			},

			createTimerEvent: function() {
				let timerStart = this.$DateTime.local();
				let timerEnd = this.$DateTime.local();

				let timerEvent = {
					id: null,
					userId: this.$store.getters.getLoggedInUserId,
					clientId: this.deliverable.clientId,
					projectId: this.deliverable.projectId,
					projectName: this.project ? this.project.name : null,
					deliverableId: this.deliverable.id,
					deliverableName: this.deliverable.name,
					timerStart: this.$DateTime
						.local()
						.minus({ minutes: 30 })
						.toISO(),
					timerEnd: this.$DateTime.local().toISO(),
					duration: timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']),
				};

				let binding = {
					timerEvent: timerEvent,
					client: this.client,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (res) {
						this.addNewTimer(res);
					}
				});
			},

			addNewTimer: function(event) {
				if (event.deliverableId === this.deliverableId) {
					let start = DateTime.fromISO(event.timerStart);
					let end = DateTime.fromISO(event.timerEnd);
					event.duration = Math.round(end.diff(start, ['seconds']).seconds);
					this.timerEvents.push(event);
				}
			},

			startRunningTimer: function() {
				let defaults = {
					clientId: this.deliverable.clientId,
					projectId: this.deliverable.projectId,
					deliverableId: this.deliverable.id,
				};

				this.$store.state.eventBus.$emit('start-timer', defaults);
			},

			stopRunningTimer: function() {
				this.$store.state.eventBus.$emit('stop-timer');
			},

			isTimerInScope: function(timer) {
				if (!timer || !this.deliverable) {
					return false;
				}

				if (timer.deliverableId === this.deliverable.id) {
					return true;
				} else {
					return false;
				}
			},

			refreshTime: function() {
				const timerStart = this.$DateTime
					.fromISO(this.currentTimer.timerStart)
					.plus({ seconds: this.currentTimer.pausedSeconds });
				let endTime;
				if (this.currentTimer.pausedAt) {
					endTime = this.$DateTime.fromISO(this.currentTimer.pausedAt);
				} else {
					endTime = this.$DateTime.local();
				}

				this.runningTimeSeconds = Math.ceil(endTime.diff(timerStart, ['seconds']).seconds);
			},
		},

		computed: {
			...mapGetters({
				userId: 'getLoggedInUserId',
			}),

			isSubTask: function() {
				return !!this.deliverable?.parentTaskId;
			},

			fields: function() {
				return this.$store.getters.getDeliverableFields(this.deliverable.projectTypeId);
			},

			statusList: function() {
				return this.$store.getters.getDeliverableStatusList(this.deliverable.projectTypeId);
			},

			isFullUser() {
				return this.$store.getters.isAccountFullUser;
			},

			showFeeSection: function() {
				if (!this.$store.getters.hasInvoiceAccess) {
					return false;
				}

				if (this.deliverable.invoiceId) {
					return true;
				}

				if (
					this.assignedProject &&
					this.assignedProject.feeSchedule &&
					this.assignedProject.feeSchedule.feeType === 'Per Item'
				) {
					return true;
				} else if (!this.assignedProject && this.assignedClient) {
					return true;
				} else {
					return false;
				}
			},

			assignedProject: function() {
				if (this.deliverable.projectId) {
					return this.fixedProjectList.find((p) => p.id === this.deliverable.projectId);
				} else {
					return null;
				}
			},

			assignedClient: function() {
				if (this.deliverable.clientId) {
					return this.$store.getters.getClientById(this.deliverable.clientId);
				} else {
					return null;
				}
			},

			fixedClientList: function() {
				let result = [...this.$store.getters.clients];
				if (this.originalClient && result.findIndex((c) => c.id === this.originalClient.id) === -1) {
					result.push(this.originalClient);
				}
				result.sort((a, b) => a.name.localeCompare(b.name));
				result.unshift({ id: null, name: '--' });

				return result;
			},

			filteredClientList: function() {
				if (this.clientFilter) {
					return this.fixedClientList.filter(
						(c) => c.name && c.name.toLowerCase().includes(this.clientFilter.toLowerCase())
					);
				} else {
					return this.fixedClientList;
				}
			},

			fixedProjectList: function() {
				let result = [...this.projectList];

				if (this.originalProject && result.findIndex((p) => p.id === this.originalProject.id) === -1) {
					result.push(this.originalProject);
				}

				result.sort((a, b) => {
					let r = a.client.name.localeCompare(b.client.name);
					if (r === 0) {
						r = a.name.localeCompare(b.name);
					}
					return r;
				});

				if (this.$store.getters.isAccountFullUser) {
					result.unshift({ id: null, name: '--' });
				}

				return result;
			},

			filteredProjectList: function() {
				let list = [...this.fixedProjectList];

				if (this.assignedClient) {
					list = list.filter((p) => p.clientId === this.assignedClient.id);
				}

				if (this.projectFilter) {
					return list.filter((c) => c.name && c.name.toLowerCase().includes(this.projectFilter.toLowerCase()));
				} else {
					return list;
				}
			},

			currentTimer: function() {
				let timer = this.$store.state.currentTimer;
				if (timer && this.isTimerInScope(timer)) {
					return timer;
				} else {
					return null;
				}
			},

			approvals: function() {
				if (this.deliverable.approvals && this.deliverable.approvals.length) {
					let result = [...this.deliverable.approvals];
					return result.reverse();
				} else {
					return [];
				}
			},

			currency: function() {
				if (this.assignedClient && this.assignedClient.currency) {
					return this.assignedClient.currency;
				} else {
					return this.$store.state.defaultCurrency;
				}
			},

			totalTimeWorked: function() {
				return this.timerEvents.reduce((sum, { duration }) => sum + duration, 0);
			},

			perItemTimeWorked: function() {
				return this.timerEvents
					.filter((t) => !t.invoiceId || t.invoiceId === this.deliverable.invoiceId)
					.reduce((sum, { duration }) => sum + duration, 0);
			},

			perItemHoursWorked: function() {
				return Number(this.perItemTimeWorked / 60 / 60).round(2);
			},

			deliverableStatus: function() {
				let deliverableStatus = this.statusList.find((s) => s.id === this.deliverable?.statusId);
				if (deliverableStatus) {
					return deliverableStatus;
				} else {
					return {
						id: this.deliverable?.statusId,
						label: '[Unknown]',
						hexColor: '#eeeeee',
						clientApproval: false,
					};
				}
			},

			isInClientApproval: function() {
				return this.deliverableStatus && this.deliverableStatus.clientApproval;
			},

			// userList: function() {
			// 	if (
			// 		(this.assignedProject &&
			// 			(this.assignedProject.portalAccess === 'Full access' ||
			// 				this.assignedProject.portalAccess === 'Read only')) ||
			// 		this.approvals.length ||
			// 		this.deliverable.approvalRequired
			// 	) {
			// 		return this.fullUserList;
			// 	} else {
			// 		return this.fullUserList.filter((u) => u.userId > 0);
			// 	}
			// },

			userList: function() {
				if (this.assignedProject) {
					return this.fullUserList;
				} else {
					return this.fullUserList.filter((u) => u.userId > 0);
				}
			},

			fullUserList: function() {
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					if (user.userType === 'OWNER' || user.userType === 'FULL_USER' || user.userType === 'IMPLEMENTER') {
						users.push(user);
					} else if (user.userType === 'RESTRICTED_USER' && user.featureAccess && user.featureAccess.projects) {
						users.push(user);
					} else if (user.userType === 'COLLABORATOR' && this.deliverable.projectId) {
						for (let j = 0; j < user.projectAccess.projects.length; j++) {
							if (user.projectAccess.projects[j].projectId === this.deliverable.projectId) {
								users.push(user);
								break;
							}
						}
					}
				}

				if (this.assignedClient) {
					this.$store.getters.contacts
						.filter((c) => c.clientId === this.assignedClient.id && c.portalAccess)
						.forEach((c) => {
							users.push({
								firstName: c.firstName,
								lastName: c.lastName,
								email: c.email,
								userId: c.clientPortalUserId,
							});
						});
				}

				return users;
			},

			deliverableLink: function() {
				return this.getDeliverableShareLink(this.deliverable);
			},

			taxEnabled: function() {
				return this.$store.state.podAccount.accountPreferences.invoiceUseTax;
			},
		},

		watch: {
			'deliverable.projectId': {
				handler: function(val) {
					this.handleProjectUpdated(val);
				},
			},

			'deliverable.clientId': {
				handler: function(val) {
					this.handleClientUpdated(val);
				},
			},

			currentTimer: {
				immediate: true,
				handler: function(val) {
					if (val && !this.timeRefreshTimer) {
						this.timeRefreshTimer = setInterval(this.refreshTime, 1000);
					} else if (!val && this.timeRefreshTimer) {
						clearInterval(this.timeRefreshTimer);
						this.timeRefreshTimer = null;
					}

					if (!val) {
						this.runningTimeSeconds = 0;
					}
				},
			},

			openStatus: function(newVal) {
				if (!newVal) {
					this.resetComponent();
				} else {
					this.initialize();
				}
			},
		},
	};
</script>

<style lang="scss">
	.deliverableStatus {
		white-space: nowrap;

		.statusBox {
			margin-right: 8px;
			width: 20px;
			height: 20px;
			border-radius: 4px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}

	.hover-gray {
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>

<style scoped lang="scss">
	.approval {
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		padding: 4px 8px;
		margin-top: 4px;
		margin-bottom: 4px;
	}

	.numberInput {
		max-width: 50px;
		color: var(--v-black-base);
	}

	.numberInput:focus {
		outline: none;
	}

	#deliverable-detail {
		padding-left: 12px;
		padding-top: 18px;

		.overview-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: fit-content;
			color: var(--v-gray_70-base);
			font-size: 14px;
			font-weight: 500;
			width: 140px;
			min-width: 140px;
			max-width: 140px;
			margin-right: 16px;
		}

		.overview-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 14px;
			font-weight: 500;
		}

		.time-worked {
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			height: 24px;
			padding: 0px 4px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			line-height: 18px;

			&:hover:not(.disabled) {
				background-color: var(--v-gray_10-base);
				cursor: pointer;
			}
		}
	}
</style>
