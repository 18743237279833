<style lang="scss">
	#app-area {
		width: 100%;
		height: 100%;
	}

	#right-section {
		flex: 1;
	}

	#right-bar {
		min-width: var(--v-right-nav-width);
		max-width: var(--v-right-nav-width);
		width: var(--v-right-nav-width);
		transition: all 250ms;
		background-color: var(--v-white-base);
		border-left: 1px solid var(--v-gray_30-base);
	}

	#main-content {
		flex: 1;
		//min-height: calc(var(--vh) - 53px);
		//max-height: calc(var(--vh) - 53px);
		max-height: calc(var(--vh) - 56px);
		min-height: calc(var(--vh) - 56px);
		background-color: var(--v-white-base);

		//background: linear-gradient(180deg,  var(--v-gray_5-base) 70%, var(--v-gray_10-base) 100%);
		//background-image: url('/images/backgrounds/round.webp');
		//background-repeat: repeat;
		overflow: auto;
	}

	#main-app {
		flex: 1;
		//max-height: calc(var(--vh) - 53px);
		//min-height: calc(var(--vh) - 53px);
		max-height: calc(var(--vh) - 56px);
		min-height: calc(var(--vh) - 56px);
		min-width: calc(100vw - var(--v-left-nav-width));
		max-width: calc(100vw - var(--v-left-nav-width));
		background-color: var(--v-gray_5-base);
		overflow-y: auto;
		overflow-x: auto;
	}

	:root {
		--v-recaptcha-visibility: visible;
	}

	.grecaptcha-badge {
		visibility: var(--v-recaptcha-visibility) !important;
	}
</style>

<template>
	<v-app data-app style="height: 100%">
		<on-boarding-controller></on-boarding-controller>
		<div class="column-format fill-all" v-if="$store.state.loggedIn && !$store.state.inWelcomeFlow">
			<div class="row-format" id="app-area" :style="`--v-right-nav-width:${rightNavWidth}`">
				<global-modal-controller ref="gmc"></global-modal-controller>
				<left-nav></left-nav>
				<sub-nav></sub-nav>
				<div id="right-section" class="column-format">
					<top-nav></top-nav>
					<div id="main-app">
						<div class="row-format">
							<div
								id="main-content"
								:class="`px-9 pt-6  ${$store.getters.scroll}`"
								:style="`max-width: ${centerWidth}; overflow-y: auto`"
							>
								<v-main>
									<v-snackbar
										id="main-snackbar"
										v-model="show"
										:color="color"
										:timeout="timeout"
										:bottom="true"
										:right="true"
										style="font-size: 1.1em; margin-right: 16px; margin-bottom: 60px"
									>
										<div :style="color === 'warning' ? 'color: var(--v-black-base)' : 'color: var(--v-white-base)'">{{ message }}</div>
										<v-btn icon @click="show = false" id="main-snackbar-close">
											<v-icon>close</v-icon>
										</v-btn>
									</v-snackbar>
									<safe-delete-handler></safe-delete-handler>
									<object-created-handler></object-created-handler>
									<router-view />
								</v-main>
							</div>
							<div id="right-bar">
								<right-nav v-show="$store.state.rightNavExpanded"></right-nav>
							</div>
						</div>
					</div>
				</div>
				<help-center :is-open="showHelpCenter" v-show="showHelpCenter" @close="showHelpCenter = false"></help-center>
				<contact-state-manager v-if="$store.state.loggedIn"></contact-state-manager>
				<ai-entitlement-state-manager v-if="$store.state.loggedIn"></ai-entitlement-state-manager>
				<notification-state-manager v-if="$store.state.loggedIn"></notification-state-manager>
				<universal-search></universal-search>
				<v-overlay style="z-index: 1000" :value="$store.state.loadingState">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
			</div>
			<div
				class="py-1"
				v-if="showSampleFooter"
				style="border-radius: 8px; width: 500px; position: absolute; bottom: 12px; z-index: 10; color: var(--v-white-base);background-color: var(--v-secondary-base); left: 0; right: 0; margin-right: auto; margin-left: auto"
			>
				<div class="brand-medium row-format centered" style="width: 100%; min-height: 42px">
					<div style="text-align: center" class="font-16 mr-2 brand-semilight">
						When you are done exploring, click here &#128073;
					</div>
					<div
						@click="deleteSampleData"
						style="background-color: var(--v-accent-base); border-radius: 4px"
						class="font-16 py-1 px-2 pointer"
					>
						<span style="color: var(--v-white-base)">Clear sample data</span>
					</div>
				</div>
			</div>
		</div>

		<div v-else style="height: 100%">
			<router-view></router-view>
			<v-overlay style="z-index: 1000" :value="$store.state.loadingState">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-snackbar
				id="main-snackbar"
				width="100%"
				v-model="show"
				:color="color"
				:timeout="timeout"
				:bottom="true"
				style="font-size: 1.1em"
			>
				<div :style="color === 'warning' ? 'color: var(--v-black-base)' : 'color: var(--v-white-base)'">{{ message }}</div>
				<v-btn icon @click="show = false" id="main-snackbar-close">
					<v-icon>close</v-icon>
				</v-btn>
			</v-snackbar>
		</div>
	</v-app>
</template>

<script>
	import PodService from './services/PodService';
	import AuthService from '@/modules/auth/AuthService';
	import SafeDeleteHandler from '@/components/SafeDeleteHandler';
	import GlobalModalController from '@/modules/utils/GlobalModalController';
	import AccountService from '@/modules/account/AccountService';
	import ConfirmModal from '@/components/ConfirmModal';
	import Settings from '@/modules/settings/Settings';
	import ObjectCreatedHandler from '@/components/ObjectCreatedHandler';
	import NotificationStateManager from '@/modules/productivity/notifications/NotificationStateManager';
	import HelpCenter from '@/modules/help/HelpCenter';
	import { Settings as LuxonSettings } from 'luxon';
	import CustomFontService from '@/modules/account/CustomFontService';
	import LeftNav from '@/views/nav/LeftNav';
	import TopNav from '@/views/nav/TopNav';
	import ContactStateManager from '@/modules/communicator/ContactStateManager';
	import RightNav from '@/views/nav/RightNav';
	import {
		PERMISSION_STATES,
		askForPushPermission,
		subscribeUserToPush,
		getExistingPushSubscription,
	} from '@/utils/Notifications';
	import PushSubscriptionService from '@/modules/account/PushSubscriptionService';
	import themeOverrides from './assets/css/theme-overrides';
	import OnBoardingController from '@/modules/onboarding/OnBoardingController';
	import UniversalSearch from '@/modules/search/UniversalSearch';
	import AiEntitlementStateManager from '@/modules/ai/AiEntitlementStateManager';
	import SubNav from "@/views/nav/SubNav";

	export default {
		components: {
			AiEntitlementStateManager,
			OnBoardingController,
			RightNav,
			//TopNav,
			LeftNav,
			SubNav,
			ContactStateManager,
			HelpCenter,
			NotificationStateManager,
			ObjectCreatedHandler,
			GlobalModalController,
			//LeftNav,
			TopNav,
			SafeDeleteHandler,
			UniversalSearch,
		},

		data() {
			return {
				show: false,
				message: '',
				timeout: -1,
				color: 'success',
				timeouts: { error: -1, warning: 10000, info: 5000, success: 2500 },
				podService: new PodService(),
				eventListenerInitialized: false,
				drawerTest: false,
				accountService: new AccountService(),
				darkMediaQuery: null,
				lastCheckTime: new Date().getTime(),
				showHelpCenter: false,
				pushSubscriptionService: new PushSubscriptionService(),
			};
		},

		mounted() {
			if (this.detectMobileDevice()) {
				window.location.href = process.env.VUE_APP_MOBILE_URL;
			} else {
				if (navigator.languages && navigator.languages.length) {
					this.$store.state.locale = navigator.languages[0];
					LuxonSettings.defaultLocale = navigator.languages[0];
				}

				this.sanitizeLocalStorage();
				this.initDarkMode();
				this.initScrollBars();
				this.initWeekStartsOn();
				this.$store.state.eventBus.$on('delete-sample-data', this.deleteSampleData);
				this.$store.state.eventBus.$on('open-settings-modal', this.openSettingsModal);
				this.$store.state.eventBus.$on('user-blocked-exception', this.userBlockedAlert);
				this.$store.state.eventBus.$on('open-help-center', this.openHelp);
				this.$store.state.eventBus.$on('logged-in', this.pushNotifications);

				setInterval(() => this.monitorLongIdleTime(), 1000);

				let showOnBoardingWidget = localStorage.getItem('USER_ONBOARDING_SHOW_WIDGET');
				this.$store.state.showOnBoardingWidget =
					showOnBoardingWidget !== null && showOnBoardingWidget.toLowerCase() === 'true';
			}

			this.setMaxVh();
			window.addEventListener('resize', () => {
				this.setMaxVh();
			});
		},

		methods: {
			disableClickOutside: function() {
				if (this.$store.state.modalOpen) {
					this.$store.state.modalOpen = true;
				}
			},

			setMaxVh: function() {
				let vh = window.innerHeight;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			},

			monitorLongIdleTime: function() {
				let now = new Date().getTime();
				let checkTime = now - this.lastCheckTime;

				if (checkTime > 30000) {
					this.$store.state.eventBus.$emit('long-idle-detected');
				}

				this.lastCheckTime = now;
			},

			// prettier-ignore
			detectMobileDevice: function() {
				try {
					let check = false;
					(function (a) {
						//eslint-disable-next-line
						if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
					})(navigator.userAgent || navigator.vendor || window.opera);
					return check;
				}catch (err){
					console.log(err)
					return false;
				}
			},

			sanitizeLocalStorage: function() {
				let version = localStorage.getItem('app-version');
				if (version !== '2') {
					console.log('Resetting local storage');
					localStorage.clear();
					localStorage.setItem('app-version', '2');
				}
			},

			userBlockedAlert: function() {
				let binding = {
					headingText: 'User account disabled.',
					bodyText: 'Please chat with our support team at https://help.withmoxie.com for more information.',
					hideNo: true,
					yesText: 'OK',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding);
			},

			deleteSampleData: function() {
				this.$store.commit('startLoading');
				this.$track.recordServerSide('clear sample data');
				this.accountService.deleteSampleData().then(() => {
					setTimeout(() => {
						window.location.href = '/home';
					}, 2000);
				});
			},

			initWeekStartsOn() {
				let weekStartsOn = localStorage.getItem('WEEK_STARTS_ON');
				if (weekStartsOn) {
					this.$store.state.weekStartsOn = weekStartsOn;
				}
			},

			initDarkMode() {
				let theme = localStorage.getItem('THEME');

				if (theme) {
					this.$store.state.theme = theme;
				}

				this.darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
				let darkModePref = localStorage.getItem('DARK_MODE');
				this.$store.state.eventBus.$on('dark-mode-changed', this.updateDarkMode);

				if (darkModePref) {
					this.$store.state.darkMode = darkModePref;
				}

				this.updateDarkMode(this.$store.state.darkMode);
			},

			initScrollBars() {
				let showScrollBars = localStorage.getItem('SHOW_SCROLL_BARS');
				if (showScrollBars && JSON.parse(showScrollBars) === true) {
					this.$store.state.showScrollBars = true;
				} else if (!this.$store.state.isMac) {
					this.$store.state.showScrollBars = true;
				}
			},

			updateDarkMode: function(darkModePref) {
				if (darkModePref === 'automatic') {
					this.enableAutomaticDarkMode();
					if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
						setTimeout(() => (this.$vuetify.theme.dark = true), 0);
					} else {
						setTimeout(() => (this.$vuetify.theme.dark = false), 0);
					}
				} else if (darkModePref === 'never') {
					this.disableAutomaticDarkMode();
					setTimeout(() => (this.$vuetify.theme.dark = false), 0);
				} else if (darkModePref === 'always') {
					this.disableAutomaticDarkMode();
					setTimeout(() => (this.$vuetify.theme.dark = true), 0);
				}
			},

			enableAutomaticDarkMode: function() {
				this.darkMediaQuery.addEventListener('change', this.toggleDarkMode);
			},

			disableAutomaticDarkMode: function() {
				this.darkMediaQuery.removeEventListener('change', this.toggleDarkMode);
			},

			toggleDarkMode() {
				this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			},

			handleClientCacheSync(event) {
				let updateChannel = this.$store.getters.getMessageChannelPrefix + '.client-update';
				let createChannel = this.$store.getters.getMessageChannelPrefix + '.client-create';
				let deleteChannel = this.$store.getters.getMessageChannelPrefix + '.client-delete';
				let ix = this.$store.state.clientCache.findIndex((c) => c.id === event.message.id);

				if (event.channel === updateChannel || event.channel === createChannel) {
					if (ix >= 0) {
						this.$store.state.clientCache.splice(ix, 1, event.message);
					} else {
						this.$store.state.clientCache.push(event.message);
					}
				} else if (event.channel === deleteChannel) {
					if (ix >= 0) {
						this.$store.state.clientCache.splice(ix, 1);
					}
				}
			},

			updateProjectType(event) {
				this.$store.commit('setProjectType', event.message);
			},

			deleteProjectType(event){
				this.$store.commit('deleteProjectType', event.message);
			},

			initializeGlobalPubNubEventListeners() {
				if (!this.eventListenerInitialized) {
					this.eventListenerInitialized = true;
					this.$store.state.eventBus.$on('a', this.handleClientCacheSync);
					this.$store.state.eventBus.$on(
						this.$store.getters.getMessageChannelPrefix + '.projectType',
						this.updateProjectType
					);
					this.$store.state.eventBus.$on(
							this.$store.getters.getMessageChannelPrefix + '.projectTypeDeleted',
							this.deleteProjectType
					);
					this.$store.state.eventBus.$on(
						'u-' + this.$store.getters.getLoggedInUserId + '.forceAuthRefresh',
						this.handleForceRefreshRequest
					);
				}
			},

			loadAccountFonts: function() {
				this.$store.state.accountFonts.splice(0, this.$store.state.accountFonts.length);
				let fontService = new CustomFontService();
				fontService
					.getCustomFonts()
					.then((res) => {
						res.data.forEach((font) => {
							this.$store.state.accountFonts.push(font);
							let newFont = new FontFace(font.fontName, `url(${font.fontUrl})`);
							newFont
								.load()
								.then((loadedFont) => {
									document.fonts.add(loadedFont);
									console.log('added font', loadedFont);
								})
								.catch((err) => {
									console.log('error adding font', err);
								});
						});
					})
					.catch((err) => console.log('error fetching fonts', err));
			},

			handleForceRefreshRequest: function() {
				AuthService.refreshToken()
					.then((res) => {
						this.handleRefreshSuccess(res);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						this.forceLogoutOnFailure();
					});
			},

			handleRefreshSuccess(result) {
				this.$store.commit('logIn', result);

				AuthService.getProfile()
					.then((res) => {
						this.$store.dispatch('setLoggedInUser', res);
					})
					.catch((err) => {
						console.log('Something went wrong fetching the user profile.' + err);
						this.forceLogoutOnFailure();
					});
			},

			openSettingsModal: function(focus) {
				this.$store.state.globalModalController.openModal(Settings, { focus: focus }, false, true);
				this.$router.push('/home');
			},

			forceLogoutOnFailure() {
				this.$store.commit('logOut');
				this.$router.push('/login');
			},

			openHelp: function() {
				this.$track.record('click help');
				window.Intercom('show');
			},

			async pushNotifications() {
				console.log('checking push notifications');
				let existingSubscription = await getExistingPushSubscription();

				if (!existingSubscription) {
					const permissionResult = await askForPushPermission();

					if (permissionResult === PERMISSION_STATES.GRANTED) {
						this.existingSubscription = await subscribeUserToPush();
						this.pushSubscriptionService
							.subscribe(this.existingSubscription)
							.then(() => {
								console.log('Push notifications are active');
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				} else {
					console.log('Already subscribed to push notifications.');
				}
			},
		},

		computed: {
			showSampleFooter: function() {
				return this.isSampleMode && this.$route.path !== '/getting-started' && this.$route.path !== '/welcome';
			},

			showLeftNav: function() {
				return this.$store.state.loggedIn && !this.$store.state.newUser;
			},

			leftNavWidth: function() {
				return this.$store.state.leftNavExpanded ? '228px' : '64px';
			},

			rightNavWidth: function() {
				return this.$store.state.rightNavExpanded ? '300px' : '0px';
			},

			centerWidth: function() {
				let left = this.$store.state.leftNavExpanded ? 208 : 44;
				let right = this.$store.state.rightNavExpanded ? 300 : 0;
				return `calc(100vw - ${left + right + 16}px)`;
			},

			isSampleMode: function() {
				return this.$store.getters.isSampleMode;
			},

			accountId() {
				return this.$store.getters.getAccountId;
			},

			alert() {
				return this.$store.state.alert;
			},

			communicatorSubscription: function() {
				return this.$store.state.communicatorSubscription;
			},

			communicatorDocked: function() {
				return this.$store.state.communicator.docked;
			},

			notificationWindow: {
				get: function() {
					return this.$store.state.notificationWindowOpenState;
				},

				set: function(val) {
					this.$store.commit('notificationWindow', val);
				},
			},
		},

		watch: {
			'$store.state.theme': function() {
				const override = themeOverrides[this.$store.state.theme];
				for (const key in override) {
					this.$vuetify.theme.themes.light[key] = override[key];
				}
			},

			alert(newAlert) {
				this.message = newAlert.message;
				this.color = newAlert.type;
				this.show = true;
				this.timeout = this.timeouts[newAlert.type];
			},

			accountId(newAccountId) {
				if (!this.$validations.isEmpty(newAccountId)) {
					this.initializeGlobalPubNubEventListeners();
					this.loadAccountFonts();
				}
			},

			// $route(to, from) {
			// 	console.log('Route changed from ' + from.fullPath + ' to ' + to.fullPath);
			// },
		},
	};
</script>

<style lang="scss">
	@import './assets/css/fonts.css';
	@import './assets/css/vuetify.scss';
	@import './assets/css/brand.scss';
	@import './assets/css/svg.css';

	#main-snackbar .v-snack__wrapper.v-sheet .v-snack__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		div {
			flex: 1 1 auto;
			font-size: 16px;
		}
		#main-snackbar-close {
			flex: 0 0 32px;
			margin: 0;
			margin-right: -12px;
		}
	}

	.sample-data {
		width: 100%;
		position: absolute;
		bottom: 0;
		min-height: 56px;
		border-top: 1px solid var(--v-gray_50-base);
		z-index: 1;
		background-color: var(--v-white-base);
	}

	.help-button {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 138px;
		height: 48px;
		padding: 12px 16px 12px 12px;
		background: var(--v-gray_20-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px 0px 0px 0px;
	}
</style>
