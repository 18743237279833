<template>
	<div id="top-nav-wrapper" ref="topNavWrapper" class="">
		<nav id="top-nav" ref="topNav">
			<div id="left-side" class="row-format align-center">
				<v-btn
					icon
					@click="setLeftNavExpanded()"
					color="gray_90"
					class="mx-1"
					v-tippy
					:content="`${isMac ? 'Cmd' : 'Ctrl'} + m`"
				>
					<span style="font-size: 20px" class="pointer material-symbols-rounded">menu_open</span>
				</v-btn>
				<div
					@click="openSearch"
					style="min-height: 32px; min-width: 200px;  border: 1px solid var(--v-gray_30-base); border-radius: 4px; background-color: var(--v-white-base); color: var(--v-gray_50-base)"
					class="row-format align-center gap-2 px-2 pointer font-14"
				>
					<div class="material-symbols-rounded" style="font-size: 20px">search</div>
					<div>{{ isMac ? 'Cmd' : 'Ctrl' }} + k</div>
				</div>
				<v-menu v-if="favorites.length" bottom nudge-bottom="40">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" color="gray_90" class="mx-1">
							<span style="font-size: 20px" class="pointer material-symbols-rounded">star</span>
						</v-btn>
					</template>
					<div style="background-color: var(--v-gray_5-base)" class="pa-4 column-format gap-2">
						<div
							v-for="favorite in favorites"
							:key="favorite.route"
							class="row-format align-center gap-1 sub-item pointer"
							@click="route(favorite, $event)"
						>
							<client-avatar
								style="margin-left: -2px"
								x-small
								:client="favorite.client"
								:disable-click="true"
							></client-avatar>
							<div class="label truncate" style="max-width: 140px">{{ favorite.label }}</div>
						</div>
					</div>
				</v-menu>
			</div>
			<div id="right-side" ref="rightSide">
				<time-track-widget></time-track-widget>
				<div
					style="justify-content: space-evenly;"
					:class="`mr-2 right ${$store.state.rightNavExpanded ? 'right-big' : 'right-small'}`"
				>
					<div
						v-if="$store.getters.hasFeatureAccess('any')"
						:class="`pointer top-button ${$store.state.rightNavFocus === 'phone' ? 'active-top' : ''}`"
						@click="openRightNav('phone')"
					>
						<v-icon
							size="20"
							:class="$store.state.rightNavFocus === 'phone' ? '' : 'material-symbols-rounded'"
							:color="$store.state.rightNavFocus === 'phone' ? 'primary' : 'black'"
							>smartphone</v-icon
						>
						<div class="top-umi" v-if="$store.getters.unreadCommunicator">
							<div>{{ $store.getters.unreadCommunicator }}</div>
						</div>
					</div>
					<div
						:class="`pointer top-button ${$store.state.rightNavFocus === 'productivity' ? 'active-top' : ''}`"
						@click="openRightNav('productivity')"
					>
						<v-icon
							size="24"
							:class="$store.state.rightNavFocus === 'productivity' ? '' : 'material-symbols-rounded'"
							:color="$store.state.rightNavFocus === 'productivity' ? 'primary' : 'black'"
							>checklist_rtl</v-icon
						>
					</div>
					<div
						:class="`pointer top-button ${$store.state.rightNavFocus === 'notifications' ? 'active-top' : ''}`"
						@click="openRightNav('notifications')"
					>
						<v-icon
							size="20"
							:class="$store.state.rightNavFocus === 'notifications' ? '' : 'material-symbols-rounded'"
							:color="$store.state.rightNavFocus === 'notifications' ? 'primary' : 'black'"
							>notifications_active</v-icon
						>
						<div class="top-umi" v-if="$store.getters.unreadNotificationCount">
							<div>{{ $store.getters.unreadNotificationCount }}</div>
						</div>
					</div>
					<!--div class="pointer top-button" @click="openSearch"><h-icon-3 name="search" size="20" color="var(--v-secondary-base)"/></div-->
					<!--div class="pointer top-button" @click="$store.state.eventBus.$emit('open-help-center')">
						<v-icon color="black" class="material-symbols-rounded">contact_support</v-icon>
					</div-->

					<v-menu content-class="user-menu" offset-y>
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<div
									v-if="profilePicture"
									class="profile-pic"
									:style="`background-image: url(${profilePicture})`"
								></div>
								<div v-else class="profile-pic">
									<span class="white--text">{{ initials }}</span>
								</div>
							</div>
						</template>

						<user-menu></user-menu>
					</v-menu>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
	import NavConfig from '@/views/nav/NavConfig.json';
	import TimeTrackWidget from '@/modules/timetracking/TimeTrackWidget';
	import UserMenu from '@/views/nav/UserMenu';
	import AiChatWidget from '@/modules/ai/AiChatWidget';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'TopNav',
		props: {
			helpLink: { type: String, required: false, default: null },
		},
		emits: ['set-page'],
		components: { UserMenu, TimeTrackWidget, ClientAvatar },
		data() {
			return {
				userMenuOpen: false,
				isOpen: false,
				navConfig: NavConfig,
				currentTabsConfig: { tabs: [], title: '' },
				currentPath: null,
				isMac: /Mac|iPod|iPhone|iPad/.test(navigator.userAgent),
			};
		},

		mounted() {
			this.setNavStateFromLocalStorage();
		},

		beforeDestroy() {},

		methods: {
			openSearch: function() {
				this.$store.state.eventBus.$emit('universal-search');
			},

			route: function(tab, event) {
				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(tab.uri, '_blank');
					w.focus();
				} else {
					this.$router.push(tab.uri);
				}
			},

			openAiWidget: function() {
				this.$store.state.globalModalController.openModal(AiChatWidget, {});
			},

			openRightNav: function(focus) {
				if (this.$store.state.rightNavExpanded && this.$store.state.rightNavFocus === focus) {
					this.$store.state.rightNavExpanded = false;
					this.$store.state.rightNavFocus = null;
				} else {
					this.$store.state.rightNavExpanded = true;
					this.$store.state.rightNavFocus = focus;
				}
				this.saveNavState();
			},

			saveNavState: function() {
				let navState = {
					rightNavExpanded: this.$store.state.rightNavExpanded,
					rightNavFocus: this.$store.state.rightNavFocus,
				};

				localStorage.setItem('RIGHT_NAV_STATE', JSON.stringify(navState));
			},

			setNavStateFromLocalStorage: function() {
				let strNavState = localStorage.getItem('RIGHT_NAV_STATE');
				if (strNavState) {
					let navState = JSON.parse(strNavState);
					this.$store.state.rightNavExpanded = navState.rightNavExpanded;
					this.$store.state.rightNavFocus = navState.rightNavFocus;
				}
			},

			setLeftNavExpanded: function() {
				this.$store.state.leftNavExpanded = !this.$store.state.leftNavExpanded;

				let leftNavState = {
					state: this.$store.state.leftNavExpanded,
				};

				localStorage.setItem('LEFT_NAV_STATE', JSON.stringify(leftNavState));
			},
		},

		watch: {
			$route: {
				deep: true,
				immediate: true,
				handler(newVal) {
					this.currentPath = newVal.fullPath;
					let parts = newVal.path.split('/');
					if (parts.length < 2) {
						Object.assign(this.currentTabsConfig, { tabs: [] });
						return;
					}

					let currentRootPath = '/' + parts[1];
					if (!this.navConfig[currentRootPath]) {
						Object.assign(this.currentTabsConfig, { tabs: [] });
					} else {
						Object.assign(this.currentTabsConfig, this.navConfig[currentRootPath]);
					}
				},
			},
		},

		computed: {
			profilePicture() {
				if (this.$store.state.loggedInUser) {
					// console.log(this.$store.state.loggedInUser);
					if (this.$store.state.loggedInUser.profilePicture) {
						return this.$store.state.loggedInUser.profilePicture;
					}
				}
				return '';
			},
			initials() {
				if (this.$store.state.loggedInUser) {
					let firstName = this.$store.state.loggedInUser.firstName;
					let lastName = this.$store.state.loggedInUser.lastName;
					let initials = '';

					if (firstName) {
						initials = initials + firstName.slice(0, 1).toUpperCase();
					}

					if (lastName) {
						initials = initials + lastName.slice(0, 1).toUpperCase();
					}

					return initials;
				} else {
					return null;
				}
			},
			favorites: function() {
				let result = [];
				this.$store.state.favoriteClients.forEach((c) => {
					let client = this.$store.state.clientCache.find((client) => client.id === c);
					if (client) {
						result.push({
							label: client.name,
							uri: `/client/${c}`,
							client: client,
						});
					}
				});
				result.sort((a, b) => a.label.localeCompare(b.label));
				return result;
			},
			// showMenu() {
			// 	return !isEmpty(this.addNewItems);
			// },
		},
	};
</script>

<style lang="scss">
	.right {
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: width 200ms linear;
	}

	.right-small {
		width: 200px;
	}

	.right-big {
		width: 200px;
	}

	.top-button {
		width: 34px;
		height: 34px;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.top-umi {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		position: absolute;
		top: 4px;
		right: 2px;
		font-weight: 500;
		color: var(--v-black-base);
		background-color: var(--v-accent-base);
		width: 16px;
		height: 16px;
		border-radius: 16px;
	}

	.active-top {
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
	}

	.user-menu {
		border-radius: 4px !important;
		background-color: var(--v-white-base);
	}

	.sub-item {
		//padding: 2px 4px;
		//border: 1px solid var(--v-gray_10-base);
		font-size: 14px;
		&:hover {
			color: var(--v-primary-base);
		}
	}

	#top-nav-wrapper {
		min-height: 54px;
		max-height: 54px;
		width: 100%;
		margin-bottom: 2px;
	}

	#top-nav {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--v-gray_5-base);
		border-bottom: 2px solid var(--v-gray_10-base) !important;

		#left-side {
		}

		#right-side {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			img {
				cursor: pointer;
			}
			//.v-image.v-responsive {
			//	width: 40px !important;
			//	height: 40px !important;
			//}
			.profile-pic {
				cursor: pointer;
				background-position: center center;
				background-size: contain;
				height: 30px;
				width: 30px;
				border-radius: 15px;
				background-color: var(--v-primary-base);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
