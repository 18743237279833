<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Project management' }]" :compact="true">
			<div class="row-format gap-2">
				<div style="font-weight: 400">Tasks</div>
				<div style="font-weight: 400">/</div>
				<v-menu>
					<template v-slot:activator="{on}">
						<div class="pointer font-primary" v-on="on" v-tippy="{content: 'Select project type'}">{{currentProjectType}}</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="setProjectType(projectType)" v-for="projectType in projectTypes" :key="projectType.id">{{projectType.name}}</div>
					</div>
				</v-menu>
			</div>
		</page-header>
		<div
			class="column-format fill-height pb-0 px-0"
		>
			<project-management v-if="isReady" :project-type-id="projectTypeId"></project-management>
		</div>
	</div>
</template>

<script>
	import ProjectManagement from '@/modules/projects/management/ProjectManagement';
	import PageHeader from '@/components/PageHeader';
	export default {
		name: 'ProjectManagementRoute',

		props: [],

		components: { ProjectManagement, PageHeader },

		data: function() {
			return {
				isReady: false,
				projectTypeId: null
			};
		},

		mounted() {
			this.getSavedProjectType();
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {
			setProjectType: function(projectType){
				this.projectTypeId = projectType.id;
				localStorage.setItem(this.projectTypeKey,this.projectTypeId);
			},

			getSavedProjectType: function(){
				let projectType = localStorage.getItem(this.projectTypeKey);
				if(projectType){
					this.projectTypeId = projectType;
				}else{
					this.projectTypeId = this.projectTypes.find((p) => p.isDefaultProjectType).id;
				}
			}
		},

		computed: {
			projectTypeKey: function(){
				return 'PROJECT_TYPE_KEY_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId
			},

			currentProjectType: function(){
				let current = this.projectTypes.find(p => p.id === this.projectTypeId);
				return current ? current.name : '[Unknown]';
			},

			projectTypes: function() {
				let result = [];
				this.$store.state.projectTypes.forEach((p) => {
					result.push({
						id: p.id,
						name: p.name,
						isDefaultProjectType: p.isDefaultProjectType,
					});
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
