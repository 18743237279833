<template>
	<v-container fluid class="pa-0 ma-0 text-left" style="align-items: start; overflow: hidden">
		<v-row dense>
			<v-col cols="12" md="4">
				<div class="pr-4">
					<div class="row-format align-center">
						<v-icon size="50" color="black" class="material-symbols-rounded material-symbols-rounded-thin ml-n1"
							>folder_special</v-icon
						>
						<div class="ml-auto row-format gap-2" v-if="isAccountFullUser">
							<v-btn
								elevation="0" color="white"
								style="height: 40px"
								@click="$emit('edit-project')"
								v-tippy
								content="Edit project info"
							>
								<v-icon class="material-symbols-rounded" style="border-bottom: 1px solid var(--v-gray_70-base)"
									>edit</v-icon
								>
							</v-btn>
						</div>
					</div>

					<div class="column-format gap-3">
						<div class="font-14">
							<span class="font-gray_70">Client</span><br />
							<div class="row-format align-center gap-1 pointer" @click="routeToClient($event)">
								<client-avatar :small="true" :client="project.client" :disable-click="true"></client-avatar>
								<div>{{ project.client.name }}</div>
							</div>
						</div>
						<div class="font-14" v-if="multipleProjectTypes">
							<span class="font-gray_70">Project type</span><br />
							<span class="pointer update-project" @click="$emit('update-project-type')" v-tippy="{content:'Change project type'}">{{ projectType }} <v-icon size="20" color="primary" class="update-icon material-symbols-rounded">sync_alt</v-icon></span>
						</div>
						<div class="font-14">
							<span class="font-gray_70">Timeline</span><br />
							<span>{{ timeline }}</span>
						</div>

						<div class="font-14">
							<div class="font-gray_70">Project owners</div>
							<div style="width: fit-content">
								<div v-if="project.projectOwners.length" class="row-format align-center">
									<assigned-user
											v-for="(assignedTo, index) in project.projectOwners"
											:key="assignedTo"
											:show-name="false"
											:small="true"
											:assigned-to="assignedTo"
											:style="
							`${
								index > 0 ? 'margin-left: -6px' : ''
							}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
						"
									></assigned-user>
								</div>
								<div v-else>
									--
								</div>
							</div>
						</div>

						<div class="font-14" v-if="$store.getters.hasInvoiceAccess">
							<span class="font-gray_70">Fees</span><br />
							<span v-html="fees"></span>
							<span v-if="feesSecondary" class="font-12 font-gray_70"><br />{{ feesSecondary }}</span>
						</div>

						<div class="font-14">
							<span class="font-gray_70">Portal access</span><br />
							<span>{{ project.portalAccess }}</span> <span v-if="project.portalAccessAssignedOnly">(Assigned tasks only)</span>
						</div>

						<div class="column-format font-14" v-for="(customValue, index) in customValues" :key="index">
							<div class="font-gray_70">{{ customValue.label }}</div>
							<div v-if="customValue.isLink" class="text-link truncate" @click="openWebsite(customValue.value)">
								{{ customValue.value }}
							</div>
							<div v-else-if="customValue.type === 'Checkbox'" class="truncate">{{customValue.value.split("||").join(", ")}}</div>
							<div v-else class="truncate">{{ customValue.value }}</div>
						</div>
					</div>

					<div class="text-left font-18 mt-4">Files</div>
					<file-attachments
						:edit-mode="true"
						:files="project.files"
						:file-service="attachmentService"
					></file-attachments>

					<div class="font-18 text-left mt-4">Collaborators</div>
					<project-collaborators :project="project"></project-collaborators>
				</div>
			</v-col>
			<v-col cols="12" md="8" align="center">
				<div
						class="text-left"
				>
					<div class="font-18 text-left mb-2 px-2">Your project insights</div>
					<div class="row-format">
						<div class="col col-6">
							<div class="insight-box column-format" style="height: 100%">
								<div class="pa-4">
									<div class="font-16 brand-medium font-gray_70 mb-2">Task progress</div>
									<div style="height: 115px">
										<deliverable-by-status
											v-if="project.deliverables.length"
											:project="project"
											:rounded="false"
											:deliverables="deliverables"
										></deliverable-by-status>
										<div
											v-else
											class="fill-height fill-width"
											style="border: 1px solid var(--v-gray_50-base); border-radius: 4px"
										></div>
									</div>
								</div>
								<div class="font-16 font-gray_90 brand-medium pa-4">
									<span class="brand-bold font-gray_90" style="font-size: 48px; line-height: 20px">{{
										deliverablesDone
									}}</span>
									of {{ deliverables.length }} tasks completed
								</div>
								<div
									class="pa-4 mt-auto row-format align-center"
									style="background-color: var(--v-gray_5-base); height: 44px; border-top: 1px solid var(--v-gray_30-base)"
								>
									<div
										v-if="nextDueDate"
										class="row-format align-center fill-width flex-grow-1 pointer"
										@click="$emit('view-deliverables')"
									>
										<v-icon size="12" class="mr-2" color="gray_70">$simpleDeliverable</v-icon>
										<div class="font-gray_70 mr-2">
											<span class="font-gray_90 brand-medium">{{ nextDueDate.deliverable.name }}</span> is
											due on <span class="font-gray_90 brand-medium">{{ nextDueDate.dueOn }}</span>
										</div>
										<div class="icon-button ml-auto">
											<v-icon color="white" size="16">arrow_forward</v-icon>
										</div>
									</div>
									<div
										v-else
										class="row-format align-center fill-width flex-grow-1 pointer"
										@click="$emit('view-deliverables')"
									>
										<v-icon size="12" class="mr-2" color="gray_70">$simpleDeliverable</v-icon>
										<div class="mr-2">View tasks</div>
										<div class="icon-button ml-auto">
											<v-icon color="white" size="16">arrow_forward</v-icon>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="column-format fill-width" style="gap: 12px">
							<div class="insight-box" v-if="$store.getters.hasInvoiceAccess">
								<project-payments :project="project" @click="$emit('payment-clicked')"></project-payments>
							</div>
							<div class="insight-box">
								<project-time-worked :project="project" @click="$emit('time-clicked')"></project-time-worked>
							</div>
						</div>
					</div>
					<div class="pl-3 column-format">
						<div class="font-18 text-left mb-2 mt-8">Description</div>
						<div style="background-color: var(--v-white-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="pa-4">
							<editor
								ref="Description"
								:api-key="$store.getters.getTinyMceKey"
								:initial-value="project.description"
								:inline="true"
								:init="mceConfig"
								:spellcheck="true"
								@focusout="handleDescriptionInput()"
							></editor>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';
	import DeliverableByStatus from '@/modules/projects/detail/DeliverableByStatus';
	import ProjectPayments from '@/modules/projects/detail/overview/ProjectPayments';
	import ProjectTimeWorked from '@/modules/projects/detail/overview/ProjectTimeWorked';
	import ProjectAttachmentService from '@/modules/projects/detail/ProjectAttachmentService';
	import FileAttachments from '@/components/FileAttachments';
	import ProjectCollaborators from '@/modules/projects/detail/overview/ProjectCollaborators';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import editor from '@tinymce/tinymce-vue';
	import ClientAvatar from "@/components/ClientAvatar";
	import AssignedUser from "@/components/AssignedUser";

	export default {
		name: 'ProjectOverview',

		props: ['project'],

		components: {
			ClientAvatar,
			ProjectCollaborators,
			DeliverableByStatus,
			ProjectPayments,
			ProjectTimeWorked,
			FileAttachments,
			AssignedUser,
			editor,
		},

		data: function() {
			return {
				deliverableService: new ProjectDeliverableService(),
				deliverables: [],
				attachmentService: new ProjectAttachmentService(this.project),

				mceConfig: {
					menubar: false,
					inline: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					browser_spellcheck: true,
					statusbar: false,
					placeholder: 'Enter your project description here...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'fontsizeselect styleselect forecolor | bold italic underline |  bullist numlist',
						'blockquote alignleft aligncenter alignright alignjustify indent outdent | table link unlink media code',
					],
					init_instance_callback: (editor) => {
						editor.on('blur', () => {
							this.handleDescriptionInput();
						});
					},
					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},
			};
		},

		mounted() {
			this.getDeliverables();
		},

		beforeDestroy() {},

		methods: {
			routeToClient: function(event){
				let uri = `/client/${this.project.client.id}`;

				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(uri, '_blank');
					w.focus();
				} else {
					this.$router.push(uri);
				}
			},

			getDeliverables: function() {
				this.deliverableService.getDeliverablesForAccountByProject(this.project.id).then((res) => {
					this.deliverables.splice(0, this.deliverables.length);
					this.deliverables.push(...res.data);
				});
			},

			openWebsite: function(website) {
				if (!website.toLowerCase().startsWith('http://') && !website.toLowerCase().startsWith('https://')) {
					website = 'https://' + website;
				}
				window.open(website, '_blank');
			},

			handleDescriptionInput: function() {
				this.project.description = this.$refs.Description.editor.getContent();
				this.$emit('change');
			},
		},

		computed: {
			statusList: function(){
				return this.$store.getters.getDeliverableStatusList(this.project.projectTypeId);
			},

			multipleProjectTypes: function(){
				return this.$store.state.projectTypes.length > 1;
			},

			isAccountFullUser: function() {
				if (this.$store.getters.isAccountFullUser) {
					return true;
				} else if (this.$store.getters.isAccountRestrictedUser && this.$store.getters.hasFeatureAccess('projects')) {
					return true;
				}

				return false;
			},

			customValues: function() {
				let result = [];
				let projectFields = this.$store.getters.getProjectType(this.project.projectTypeId).projectFields;

				for (let i = 0; i < projectFields.length; i++) {
					let field = projectFields[i];
					let value = this.project.customValues.find((v) => v.fieldId === field.id);
					if (value && value.value !== null && value.value !== '') {
						let customValue = value.value;

						if (field.type === 'Date') {
							customValue = DateTime.fromISO(customValue).toLocaleString(DateTime.DATE_MED);
						}

						result.push({
							label: field.name,
							value: customValue,
							isLink: field.type === 'Link',
							type: field.type
						});
					}
				}

				return result;
			},

			fees: function() {
				if (this.project.feeSchedule) {
					if (this.project.feeSchedule.feeType === 'Hourly') {
						return (
							this.$formatters.dollars(
								this.project.feeSchedule.amount,
								true,
								true,
								this.project.client.currency,
								4,
								0
							) + '/hr'
						);
					} else if (this.project.feeSchedule.feeType === 'Retainer') {
						let result =
							this.$formatters.dollars(
								this.project.feeSchedule.amount,
								true,
								true,
								this.project.client.currency,
								4,
								0
							) +
							' ' +
							this.project.feeSchedule.retainerSchedule.toLowerCase() +
							' / Recurring';

						if (!this.project.feeSchedule.retainerActive) {
							result =
								result +
								`<br><span class="font-red font-12 font-weight-bold">[Recurring billing has ended]</span>`;
						}

						return result;
					} else if (this.project.feeSchedule.feeType === 'Per Item') {
						return 'Fee per task';
					} else if (this.project.feeSchedule.feeType === 'Fixed Price') {
						return (
							this.$formatters.dollars(
								this.project.feeSchedule.amount,
								true,
								true,
								this.project.client.currency,
								4,
								0
							) + ' Fixed price'
						);
					} else {
						return '--';
					}
				} else {
					return '--';
				}
			},

			feesSecondary: function() {
				if (this.project.feeSchedule && this.project.feeSchedule.feeType === 'Retainer') {
					if (this.project.feeSchedule.estimateMax && this.project.feeSchedule.retainerOverageRate) {
						return (
							'+' +
							this.$formatters.dollars(
								this.project.feeSchedule.retainerOverageRate,
								true,
								true,
								this.project.client.currency
							) +
							'/hr beyond ' +
							this.project.feeSchedule.estimateMax +
							' hours'
						);
					}
				}
				return null;
			},

			deliverablesDone: function() {
				let d = [...this.deliverables];

				let complete = [];
				let foundComplete = false;
				for (let i = 0; i < this.statusList.length; i++) {
					let s = this.statusList[i];
					if (s.complete || foundComplete) {
						complete.push(s.id);
						foundComplete = true;
					}
				}

				d = d.filter((d) => complete.includes(d.statusId));
				return d.length;
			},

			nextDueDate: function() {
				let d = [...this.deliverables];

				let incomplete = [];
				for (let i = 0; i < this.statusList.length; i++) {
					let s = this.statusList[i];
					if (s.complete) {
						break;
					} else {
						incomplete.push(s.id);
					}
				}

				d = d.filter((d) => d.dueDate && incomplete.includes(d.statusId));

				if (!d.length) {
					return null;
				}

				d.sort(function(a, b) {
					if (a.dueDate > b.dueDate) {
						return 1;
					} else if (b.dueDate > a.dueDate) {
						return -1;
					} else {
						return 0;
					}
				});

				return {
					deliverable: d[0],
					dueOn: DateTime.fromISO(d[0].dueDate).toLocaleString({ month: 'short', day: 'numeric' }),
				};
			},

			projectType: function(){
				return this.$store.getters.getProjectType(this.project.projectTypeId).name;
			},

			timeline: function() {
				if (this.project.startDate && this.project.dueDate) {
					let start = DateTime.fromISO(this.project.startDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					let due = DateTime.fromISO(this.project.dueDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					return `${start} to ${due}`;
				} else if (this.project.startDate) {
					let start = DateTime.fromISO(this.project.startDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					return `Starting on ${start}`;
				} else if (this.project.dueDate) {
					let due = DateTime.fromISO(this.project.dueDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					return `Due on ${due}`;
				} else {
					return '--';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.update-project {
		.update-icon {
			visibility: hidden;
		}
		&:hover {
			color: var(--v-primary-base);
			.update-icon {
				visibility: visible;
			}
		}
	}

	.insight-box {
		border: 1px solid var(--v-gray_30-base);
		background-color: var(--v-white-base);
		border-radius: 4px;
		font-size: 14px;
		overflow: hidden;
		flex-grow: 1;
	}
</style>
