var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{staticClass:"pb-6",attrs:{"bread-crumbs":[
			{ to: '/home', label: 'Home' },
			{ to: '/projects/list', label: 'Project management' } ]}},[_vm._v(" Project types ")]),_c('div',{staticClass:"row-format mb-4"},[_c('v-btn',{staticClass:"ml-auto super-action",on:{"click":function($event){return _vm.addProjectType()}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("add")]),_vm._v(" Add project type")],1)],1),_c('div',{staticClass:"mt-2"},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"items":_vm.projectTypes,"headers":_vm.headers,"hide-default-footer":true,"items-per-page":-1},on:{"click:row":function($event){return _vm.routeToProjectType($event)}},scopedSlots:_vm._u([{key:"item.projectFields",fn:function(ref){
		var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.projectFields.length)+" project fields")])]}},{key:"item.deliverableFields",fn:function(ref){
		var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.deliverableFields.length)+" task fields")])]}},{key:"item.statusList",fn:function(ref){
		var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.statusList.length)+" statuses")])]}},{key:"item.isDefaultProjectType",fn:function(ref){
		var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.isDefaultProjectType ? 'Yes' : 'No'))])]}},{key:"item.action",fn:function(ref){
		var item = ref.item;
return [(!item.isDefaultProjectType)?_c('v-icon',{staticClass:"material-symbols-rounded",on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteProjectType(item)}}},[_vm._v("delete")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }