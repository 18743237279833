export default class ExpiringMap {
    constructor() {
        this.store = new Map();
    }

    set(key, value, ttl, onRemoveCallback = null) {
        this._cancelDelete(key);

        let timeoutId = setTimeout(() => {
            this.store.delete(key);

            if (typeof onRemoveCallback === 'function') {
                onRemoveCallback(key,value);
            }
        }, ttl);

        let valueStore = {
            timeoutId: timeoutId,
            value: value
        }

        this.store.set(key,valueStore);
    }

    get(key) {
        let result =  this.store.get(key);
        if(result){
            return result.value;
        }else{
            return null;
        }
    }

    has(key) {
        return this.store.has(key);
    }

    _cancelDelete(key){
        let result =  this.store.get(key);
        if(result){
            clearTimeout(result.timeoutId);
        }
    }
}