<template>
	<div style="height:100%; background-color: var(--v-white-base)">
		<v-container v-if="isReady" style="max-width: 985px!important;" class="mt-4">
			<v-row>
				<v-col cols="12" class="mb-4">
					<div style="font-size: 48px" class="brand-semilight">{{$t('subscription.heading')}}</div>
					<div class="brand-semilight">{{$t('subscription.subheading')}} (All amounts in USD)</div>
				</v-col>
				<v-col align="center" class="" md="4" cols="12" v-for="(planCode, i) in plansInOrder" :key="i">
					<PlanCard
						:ref="planCode"
						:key="planCode"
						:plan-code="planCode"
						:card-plan="planHash[planCode]"
						:plan-hash="planHash"
						:account="account"
						:customer-plan="customerPlan"
						:pending-plan="pendingPlan"
						:is-processing="isProcessing"
						:can-manage-subscription="canManageSubscription"
						:subscription-managed-by="subscriptionManagedBy"
						v-on:plan-click="handlePlanClick($event)"
						v-on:delete-pending="deleteChange($event)"
					>
					</PlanCard>
				</v-col>
			</v-row>
		</v-container>

		<div style="background-color: var(--v-white-base)" class="my-6 px-10">
			<v-container style="max-width: 985px!important;" >
				<v-row style="background-color: var(--v-gray_10-base); border-radius: 12px" v-if="billingInfo && canManageSubscription">
					<v-col cols="12" md="8" class="text-left" order="2" order-md="1">
						<Invoices :pending-plan="pendingPlan" :customer-plan="customerPlan"></Invoices>
					</v-col>
					<v-col  cols="12" md="4" class="text-left" order="1" order-md="2">
						<div class="mb-5">
							<h4 class="d-inline mr-4">{{ $t('subscription.payment-method') }}</h4>
							<v-btn
									color="primary"
									style="font-size: 12px"
									text
									class="py-0 mt-n1"
									@click="
								planCode = null;
								paymentDialog = true;
							"
							>Change</v-btn
							>
						</div>

						<div class="mr-8" style="clear:all:height:42px;width:28px;height:42px;float:left;">
							<payment-icon size="28" :name="billingInfo.paymentMethod.cardType"></payment-icon>
						</div>
						<p class="mb-3" v-html="paymentMethod"></p>
						<p style="font-size: 14px; color: var(--v-gray_70-base)" v-html="paymentMethodExp"></p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<div class="brand-medium font-24 text-left mt-6">Frequently Asked Questions</div>
						<div v-for="(q,index) in faq" :key="index" class="text-left brand-semilight my-4 pa-5" style="background-color: var(--v-gray_10-base); margin-left:-12px; margin-right:-12px; border-radius: 12px">
							<div class="font-20 row-format pointer" @click="isExpanded(index) ? collapseFaq(index) : expandFaq(index)">
								<div>{{q.question}}</div>
								<v-icon class="ml-auto" color="black">{{isExpanded(index) ? '$minus' : '$plus'}}</v-icon>
							</div>
							<div class="mt-3" style="white-space: pre-wrap" v-if="isExpanded(index)">{{q.answer}}</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<Modal
			:dialog="paymentDialog"
			:fullscreen="$vuetify.breakpoint.xsOnly"
			v-on:close="paymentDialog = false"
			ref="paymentModal"
			v-slot="scope"
		>
			<Payment
				:key="paymentDialog"
				v-on:alert="$refs.paymentModal.setAlert($event)"
				v-on:close="paymentDialog = false"
				v-on:plan-change="planChange($event)"
				v-on:get-billing-info="getBillingInfo($event)"
				@alert="scope.setAlert($event)"
				:parent="{
					planHash: planHash,
					customerPlan: customerPlan,
					billingInfo: billingInfo,
					planCode: planCode,
					account: account,
				}"
			></Payment>
		</Modal>
		<Modal
			:dialog="planChangeDialog"
			:fullscreen="$vuetify.breakpoint.xsOnly"
			v-on:close="planChangeDialog = false"
			ref="planChangeModal"
			v-slot="scope"
			:max-width="550"
		>
			<PlanChange
				:key="planChangeDialog"
				v-on:alert="$refs.paymentModal.setAlert($event)"
				v-on:close="planChangeDialog = false"
				v-on:plan-change="planChange($event)"
				@alert="scope.setAlert($event)"
				:parent="{
					planHash: planHash,
					customerPlan: customerPlan,
					planCode: planCode,
				}"
			></PlanChange>
		</Modal>
		<Modal
			:dialog="planCancelDialog"
			v-on:close="planCancelDialog = false"
			ref="planCancelModal"
			:max-width="588"
			v-slot="scope"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<PlanCancel
				:key="planCancelDialog"
				v-on:alert="$refs.planCancelModal.setAlert($event)"
				v-on:close="planCancelDialog = false"
				v-on:plan-change="planChange($event)"
				@alert="scope.setAlert($event)"
				:planHash="planHash"
				:customerPlan="customerPlan"
				:planCode="planCode"
			></PlanCancel>
		</Modal>
	</div>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';
	import PlanCard from './PlanCard';
	import Payment from './Payment';
	import PlanChange from './PlanChange';
	import PlanCancel from './PlanCancel';
	import Modal from '@/components/Modal';
	import Invoices from './Invoices';
	import AuthService from '../../auth/AuthService';
	import PodService from '../../../services/PodService';
	import PaymentIcon from '../../../components/PaymentIcon';

	export default {
		name: 'Subscription',
		components: {PlanCard, Payment, PlanChange, PlanCancel, Modal, Invoices, PaymentIcon },

		props: {
			pastDue: { type: Boolean, default: false },
		},

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				account: null,
				plansInOrder: ['free', 'pro-monthly', 'teams-monthly'], // Plan-codes in a sorted array to control display order
				planHash: null, // Plan objects with planCode as keys for easy lookups
				customerPlan: null, // Customer's current plan
				pendingPlan: { plan: '' }, // The plan to which customer is changing
				cadenceState: 'quarterly', // monthly or quarterly
				billingInfo: null,
				paymentMethod: '',
				paymentMethodExp: '',
				planCode: null,
				paymentDialog: false,
				planChangeDialog: false,
				planCancelDialog: false,
				isProcessing: false,
				expandedFaq: [],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'subscription' });

			if (this.pastDue) {
				this.$store.commit('error', 'Your account is currently past due.  Please update your credit card info.');
			}

			this.subscriptionService
				.getPlans()
				.then((response) => {
					// console.log('plans', response);
					this.buildPlanHash(response);
				})
				.catch((error) => {
					console.log(error);
				});

			this.getCurrent();

			this.getBillingInfo();

			this.account = this.$store.getters.getAccount;

			//console.log('this.account', this.account);
		},

		methods: {
			expandFaq: function(index){
				this.expandedFaq.push(index);
			},

			collapseFaq: function(index){
				let ix = this.expandedFaq.findIndex(f => f === index);
				if(ix > -1){
					this.expandedFaq.splice(ix,1);
				}
			},

			isExpanded: function(index){
				let ix = this.expandedFaq.findIndex(f => f === index);
				return ix > -1;
			},

			cardGraphic(type) {
				return '<img src="/images/payment/' + type.toLowerCase() + '.svg">';
			},
			getBillingInfo() {
				this.subscriptionService
					.getBillingInfo()
					.then((response) => {
						//console.log('billingInfo', response);
						if (response) {
							this.billingInfo = response;
							this.paymentMethod = this.getPaymentMethod(response);
							this.paymentMethodExp = this.getPaymentMethodExp(response);
						} else {
							this.billingInfo = false;
							this.paymentMethod = false;
							this.paymentMethodExp = false;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getPaymentMethod(billingInfo) {
				return (
					billingInfo.paymentMethod.cardType +
					'<span style="margin-left:24px;margin-right:12px">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;</span>' +
					billingInfo.paymentMethod.lastFour
				);
			},

			getPaymentMethodExp(billingInfo) {
				return (
					'<span class="mr-2">Expires</span>' +
					billingInfo.paymentMethod.expMonth +
					' / ' +
					(billingInfo.paymentMethod.expYear - 2000)
				);
			},

			getCurrent() {
				this.subscriptionService
					.getCurrent()
					.then((response) => {
						if (response) {
							this.customerPlan = this.buildCustomerPlan(response.recurlySubscription);
							// console.log('customerPlan', this.customerPlan);
							// this.cadenceState = this.getCadenceState(this.customerPlan);
							if (this.customerPlan.currentTermEndsAt) {
								this.getPendingPlan();
							}
							//console.log('customerPlan', this.customerPlan);
						} else {
							this.customerPlan = this.buildCustomerPlan();
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},

			buildPlanHash(plans) {
				this.planHash = {};
				for (let i = 0; i < plans.length; i++) {
					let [shortPlan, cadence] = plans[i].code.split('-');
					this.planHash[plans[i].code] = {};
					this.planHash[plans[i].code] = plans[i];
					this.planHash[plans[i].code]['shortPlan'] = shortPlan;
					this.planHash[plans[i].code]['cadence'] = cadence ?? 'monthly';
					this.planHash[plans[i].code]['pricePerMonth'] = plans[i]['price'] / plans[i]['interval'];
				}
				return true;
			},

			buildCustomerPlan(customer) {
				if (!customer) {
					customer = {};
					customer.plan = {};
					customer.plan['code'] = 'free';
					customer.plan['shortPlan'] = 'free';
					customer.plan['cadence'] = 'monthly';
					customer.plan['interval'] = 1;
					return customer;
				}
				let [shortPlan, cadence] = customer.plan.code.split('-');
				cadence = cadence ?? 'monthly';
				customer.plan['shortPlan'] = shortPlan;
				customer.plan['cadence'] = cadence;
				customer.plan['interval'] = cadence == 'quarterly' ? 3 : 1;
				return customer;
			},

			// getCadenceState(customerPlan) {
			// 	return this.$validations.isEmpty(customerPlan.plan.cadence) ? 'quarterly' : customerPlan.plan.cadence;
			// },

			handlePlanClick(planCode) {
				this.planCode = planCode;

				if (!this.isFreePlan(planCode) && !this.billingInfo) {
					this.paymentDialog = true;
					return;
				}

				if (this.isFreePlan(planCode)) {
					this.planCancelDialog = true;
					return;
				}

				this.planChangeDialog = true;
				return;
			},

			isFreePlan(planCode) {
				if (planCode == undefined) return true;
				return this.planHash[planCode]['price'] == 0;
			},

			deleteChange() {
				this.isProcessing = true;
				this.subscriptionService
					.deleteChange()
					.then(() => {
						//console.log('deleteChange response', response);
						this.$store.commit('success', 'Pending subscription change cancelled successfully.');
						this.$track.record('Cancel Downgrade');
						this.getPendingPlan();
					})
					.catch((error) => {
						console.log('deleteChange error', error.response);
						this.$store.commit('error', 'An error occurred.');
					})
					.finally(() => (this.isProcessing = false));
			},

			planChange(planCode) {
				this.isProcessing = true;
				let currentIx = this.plansInOrder.findIndex((p) => p == this.customerPlan.plan.code);
				let newIx = this.plansInOrder.findIndex((p) => p == planCode);

				let isUpgrade = currentIx < newIx;

				this.subscriptionService
					.postChange(planCode)
					.then((response) => {
						if (response.status == 'SUCCESS') {
							this.$store.commit(
								'info',
								'Subscription plan changed to ' + response.recurlyResponse.plan.name + '.'
							);
						}
						this.getPendingPlan();
						this.getCurrent();
						this.getNewAuthTokens(isUpgrade);
					})
					.catch((error) => {
						console.log('postChange error', error.response);
						this.$store.commit('error', 'An error occurred.');
					})
					.finally(() => (this.isProcessing = false));
			},

			getPendingPlan() {
				this.subscriptionService
					.getChange()
					.then((response) => {
						// console.log('getPendingPlan', response);
						if (response == '') {
							this.pendingPlan = { plan: '' };
							return;
						} else {
							this.pendingPlan = response;
						}
						let newPlanName = this.planHash[this.pendingPlan.plan.code]['name'];
						if (this.planHash[this.pendingPlan.plan.code]['price'] > 0) {
							newPlanName += ' ' + this.planHash[this.pendingPlan.plan.code]['cadence'];
						}
						this.pendingPlan.notice = this.$t('subscription.card.pending-plan-notice', {
							'new-plan-name': this.$formatters.titleCase(newPlanName),
							'next-date': this.$DateTime.longDate(this.pendingPlan.activateAt),
						});
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getNewAuthTokens(redirectToDashboard = false) {
				let podService = new PodService();

				AuthService.refreshToken(true)
					.then((res) => {
						this.$store.commit('logIn', res);

						AuthService.getProfile()
							.then((res) => {
								this.$store.dispatch('setLoggedInUser', res);
								if (redirectToDashboard) {
									this.$router.push('/getting-started?celebration=true');
								}
							})
							.catch((err) => {
								console.log('Something went wrong fetching the user profile.' + err);
								this.$store.commit('logOut');
								this.$router.push('/login');
							});
					})
					.finally(() => {
						podService.clearCookies();
					});
			},
		},
		computed: {
			isReady: {
				get: function () {
					// return this.planHash && this.customerPlan && this.pendingPlan !== null;
					return this.planHash && this.customerPlan;
				},
			},

			faq: function(){
				return this.$t('subscription.faq')
			},

			bullets() {
				const rawBullets = this.$t('subscription.core-features');
				let bullets = [];
				if (this.$vuetify.breakpoint.smAndDown) {
					bullets[0] = rawBullets;
				} else {
					bullets[0] = rawBullets.slice(0, Math.ceil(rawBullets.length / 2));
					bullets[1] = rawBullets.slice(-Math.ceil(rawBullets.length / 2));
				}
				return bullets;
			},

			canManageSubscription: function () {
				let provider = this.account.subscriptionProvider;
				let state = this.account.subscriptionState;
				let type = this.account.subscriptionType;

				if (provider === 'WEB') {
					return true;
				} else if (state === 'INACTIVE' || type === 'FREE') {
					return true;
				} else {
					return false;
				}
			},

			subscriptionManagedBy: function () {
				if (this.account.subscriptionProvider === 'APPLE') {
					return 'iOS App Store';
				} else if (this.account.subscriptionProvider === 'GOOGLE') {
					return 'Google Play Store';
				} else {
					return null;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	#subscription {
		.flex-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			align-content: center;
			margin-bottom: 36px;
			// border: 1px solid grey;
			&.sm-and-down {
				display: inline-block;
				//border: 1px solid red;
			}
		}
		h1 {
			font-size: 40px;
			line-height: 56px;
		}
		&.sm-and-down {
			h1 {
				font-size: 28px;
				line-height: 32px;
			}
		}
		button.tab-button {
			height: 64px;
			width: 100%;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.2px;
			color: var(--v-gray_70-base);
			background-color: var(--v-gray_20-base);
			&.left-tab-button {
				border-radius: 8px 0 0 0;
			}
			&.right-tab-button {
				border-radius: 0 8px 0 0;
			}
			&.selected {
				transition: background-color 300ms;
				color: var(--v-primary-base);
				background-color: var(--v-white-base);
			}
			&:focus {
				outline: 0;
			}
		}

		h4 {
			font-size: 14px;
			letter-spacing: 0.4px;
		}
		.payment-method span {
			margin-left: 25px;
			margin-right: 15px;
		}
		.core-features {
			h1 {
				margin-top: 32px;
				margin-bottom: 28px;
				font-size: 32px;
				line-height: 40px;
			}
			.v-list-item {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
		&.sm-and-down {
			.core-features {
				h1 {
					margin-top: 32px;
					margin-bottom: 12px;
					font-size: 24px;
					line-height: 28px;
				}
			}
		}
	}
</style>
