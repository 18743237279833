import HttpClient from '../../../services/HttpClient';
import store from '../../../store';

export default class ProjectDeliverableService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(null,true,2);
	}

	getDeliverable(deliverableId) {
		return this.httpClient
			.get(`/deliverables/${deliverableId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSubtasks(deliverableId) {
		return this.httpClient
			.get(`/deliverables/${deliverableId}/subTasks`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	duplicate(deliverableId) {
		return this.httpClient
			.post(`/deliverables/${deliverableId}/duplicate`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverablesForAccount(active = true, projectTypeId = null, globalProjectType = false) {
		// Build the query parameters
		const params = [];
		params.push(`active=${active}`);

		if (projectTypeId) {
			params.push(`projectTypeId=${projectTypeId}`);
		}

		if(globalProjectType) {
			params.push(`globalProjectType=${globalProjectType}`);
		}

		// Join all params with "&"
		const queryString = params.join("&");

		return this.httpClient
			.get(`/deliverables?${queryString}`)
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err));
	}

	getDeliverablesForTicket(ticketId) {
		return this.httpClient
			.get(`/deliverables/forTicket?ticketId=${ticketId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverablesForAccountByDate(start, end) {
		return this.httpClient
			.get(`/deliverables?startDate=${start}&endDate=${end}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverablesForAccountByProject(projectId, active = true) {
		return this.httpClient
			.get(`/deliverables?projectId=${projectId}&active=${active}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverablesForDashboard(today, end) {
		return this.httpClient
			.get(`/deliverables/dashboard?today=${today}&endDate=${end}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateKanbanProperties(updates) {
		return this.httpClient
			.put('/deliverables/kanban', updates)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverablesForProject(projectId) {
		return this.getDeliverablesForAccountByProject(projectId);
	}

	getDeliverableDetail(deliverableId) {
		return this.httpClient
			.get(`/deliverables/${deliverableId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverableName(deliverableId) {
		return this.httpClient
			.get(`/deliverables/${deliverableId}/name`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createNewDeliverable(deliverable) {
		return this.httpClient
			.post(`/deliverables`, deliverable)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateDeliverable(deliverableId, deliverable) {
		return this.httpClient
			.put(`/deliverables/${deliverableId}`, deliverable)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchDeliverable(deliverableId, patchData) {
		return this.httpClient
			.patch(`/deliverables/${deliverableId}`, patchData)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteDeliverable(deliverableId, enableRestore) {
		return this.httpClient
			.delete(`/deliverables/${deliverableId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err))
			.finally(() => {
				if (enableRestore) {
					store.state.eventBus.$emit('safe-delete', {
						type: 'ProjectDeliverable',
						deliverableId: deliverableId,
					});
				}
			});
	}

	cancelDeleteDeliverable(deliverableId) {
		return this.httpClient
			.delete(`/deliverables/${deliverableId}/cancel`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendComment(deliverableId, comment) {
		return this.httpClient
			.post(`/deliverables/${deliverableId}/comment`, comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateComment(deliverableId, comment) {
		return this.httpClient
			.put(`/deliverables/${deliverableId}/comment`, comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteComment(deliverableId, commentId) {
		return this.httpClient
			.delete(`/deliverables/${deliverableId}/comment?commentId=${commentId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteDeliverableAttachment(deliverableId, fileName) {
		return this.httpClient
			.delete(`/deliverables/${deliverableId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(deliverableId, fileName) {
		return this.httpClient
			.get(`/deliverables/${deliverableId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
