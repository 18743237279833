<template>
	<tr>
		<td
				:class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`"
				style="max-width: 20px; padding: 0!important;"
		>
			<div class="column-format align-center px-1">
				<input type="checkbox"  @input="$emit('toggle-checked',$event)" />
			</div>
		</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`"><div :class="isSubTask ? '' : 'pl-1'">Name</div></td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="!singleProjectMode && isVisible('project')">Project</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('status')">Status</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('priority')">Priority</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('assignedTo')">Assigned</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('startDate')">Start</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('dueDate')">Due</td>
		<td
				:class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`"
				v-for="field in filteredCustomFields"
				:key="field.id"
		>
			{{ field.name }}
		</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('rank')">Rank</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('comments')">Comments</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`" v-if="isVisible('toDos')">To-dos</td>
		<td :class="`heading ${$vuetify.theme.dark ? 'heading-dark' : ''}`">&nbsp;</td>
	</tr>
</template>

<script>
export default {
	name: "DeliverableListHeader",

	props: ['singleProjectMode','customFields','isSubTask','visibleFields'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		isVisible: function(fieldType){
			return this.visibleFields.includes(fieldType);
		}
	},

	computed: {
		filteredCustomFields: function(){
			return this.customFields.filter(c => this.isVisible('Custom.' + c.mappingKey));
		}
	},

}
</script>

<style scoped lang="scss">

</style>