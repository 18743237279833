<template>
	<tr class="font-14 text-left content-row">
		<td class="content" style="max-width: 20px">
			<div class="column-format align-center px-1">
				<input :checked="checked" :value="deliverable.id" type="checkbox" @input="$emit('toggle-checked')" />
			</div>
		</td>
		<td class="content" :style="`min-width: ${isSubTask ? '150px' : '200px'};`">
			<div class="row-format align-center task-name">
				<v-icon
					v-if="deliverable.subtasks"
					class="pointer"
					:color="deliverable.subtasks.length ? 'black' : 'gray_20'"
					@click="$emit('toggle-expanded')"
					>{{ expanded[deliverable.id] ? 'keyboard_arrow_down' : 'chevron_right' }}</v-icon
				>
				<v-icon
					color="success"
					small
					v-if="deliverable.invoiceId"
					v-tippy="{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
					>attach_money</v-icon
				>
				<div
					:id="`name-${deliverable.id}`"
					contenteditable="true"
					class="my-1 mx-1 edit-task-name"
					style=" min-width: 20px"
					@blur="nameBlurred($event)"
					@focus="nameFocused()"
					@keydown="handleKeyDown"
				>
					{{ deliverable.name }}
				</div>
				<div class="ml-1 subtask-count row-format centered" v-if="deliverable.subtasks && deliverable.subtasks.length">
					<div>{{ deliverable.subtasks.length }}</div>
				</div>
				<div class="ml-auto row-format align-center action-box">
					<div>
						<div v-if="currentTimer" class="row-format align-center gap-1">
							<v-icon class="material-symbols-rounded" size="20" color="gray_50" @click.stop="stopRunningTimer()">stop_circle</v-icon>
							<div class="font-12 font-gray_70">{{$store.state.currentTimerDisplay}}</div>
						</div>
						<div v-else class="show-on-hover">
							<v-icon class="material-symbols-rounded" size="20" color="gray_30" @click.stop="startRunningTimer()">play_circle</v-icon>
						</div>
					</div>
					<div class="row-format align-center open-icon font-14 pointer" @click="$emit('edit-deliverable')">
						<v-icon size="18" class="material-symbols-rounded" color="primary">expand_content</v-icon>
						<div style="margin-left: 2px" class="font-12 brand-medium">Open</div>
					</div>
				</div>
			</div>
		</td>
		<td v-if="!singleProjectMode && isVisible('project')" class="content" style="min-width: 200px;">
			<div class="row-format align-center project-name">
				<client-avatar :client="deliverable.client" :x-small="true" v-if="deliverable.client"></client-avatar>
				<div class="ml-2">
					{{ deliverable.project ? deliverable.project.name : '' }}
				</div>
				<div
					class="ml-auto row-format align-center open-icon font-14 pointer"
					v-if="deliverable.project"
					@click.stop="$emit('route-to-project', $event)"
				>
					<v-icon size="18" class="material-symbols-rounded" color="primary">expand_content</v-icon>
					<div style="margin-left: 2px" class="font-12 brand-medium">Open project</div>
				</div>
			</div>
		</td>
		<td class="content" v-if="isVisible('status')">
			<div class="row-format align-center gap-2">
				<v-menu :close-on-click="true" :close-on-content-click="true">
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							class="deliverableStatus row-format align-center pointer"
							:style="`--status-color:${deliverable.status.hexColor}`"
						>
							<div class="statusBox"></div>
							<div class="text-no-wrap">{{ deliverable.status.label }}</div>
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
						<div
							v-for="status in statusList"
							:key="status.id"
							class="py-1 pointer"
							@click="$emit('set-status', status)"
						>
							<div
								class="deliverableStatus row-format align-center highlight-hover"
								:style="`--status-color:${status.hexColor}`"
							>
								<div class="statusBox"></div>
								<div>{{ status.label }}</div>
							</div>
						</div>
					</div>
				</v-menu>
				<v-icon v-if="deliverable.archived" class="ml-auto" size="20" color="gray_70" v-tippy="{ content: 'Archived' }"
					>lock</v-icon
				>
			</div>
		</td>
		<td class="content" v-if="isVisible('priority')">
			<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
				<template v-slot:activator="{ on }">
					<div
							v-on="on"
							class="deliverableStatus row-format align-center highlight-hover pr-1 pointer"
							:style="`--status-color:${priorityColor}`"
					>
						<div class="statusBox"></div>
						<div>{{ deliverable.taskPriority }}</div>
					</div>
				</template>
				<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
					<div
							v-for="priority in priorityList"
							:key="priority"
							class="py-1 pointer"
							@click="$emit('set-task-priority', priority)"
					>
						<div class=" row-format align-center highlight-hover">
							<div>{{ priority }}</div>
						</div>
					</div>
				</div>
			</v-menu>
		</td>
		<td class="content" v-if="isVisible('assignedTo')">
			<div style="width: fit-content">
				<v-menu :close-on-click="true" :close-on-content-click="false">
					<template v-slot:activator="{ on }">
						<div class="ml-auto pointer" v-on="on">
							<div v-if="deliverable.assignedToList.length" class="row-format align-center">
								<assigned-user
										v-on="on"
										v-for="(assignedTo, index) in deliverable.assignedToList"
										:key="assignedTo"
										:show-name="false"
										:small="true"
										:assigned-to="assignedTo"
										:style="
										`${
											index > 0 ? 'margin-left: -6px' : ''
										}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
									"
								></assigned-user>
							</div>
							<div v-else>
								--
							</div>
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
						<div
								v-for="user in usersForProject(deliverable.projectId)"
								:key="user.userId"
								class="row-format align-center text-left py-1 pointer"
						>
							<input
									@change="$emit('set-assigned-to')"
									style="width: 20px; height: 20px; border-radius: 4px"
									type="checkbox"
									:value="user.userId"
									v-model="deliverable.assignedToList"
							/>
							<assigned-user
									class="mx-2"
									v-if="user.userId"
									:assigned-to="user.userId"
									:show-name="true"
							></assigned-user>
						</div>
					</div>
				</v-menu>
			</div>
		</td>
		<td class="content" v-if="isVisible('startDate')">
			<date-selector
					:micro="true"
					micro-font="font-14"
					:date="deliverable.startDate"
					label="&nbsp;"
					@change="$emit('set-start-date', $event)"
			></date-selector>
		</td>
		<td class="content" v-if="isVisible('dueDate')">
			<date-selector
					:micro="true"
					micro-font="font-14"
					:date="deliverable.dueDate"
					label="&nbsp;"
					@change="$emit('set-due-date', $event)"
			></date-selector>
		</td>
		<td v-for="field in filteredCustomFields" :key="field.id" class="content">
			<custom-field
				:field="field"
				:value="getCustomValue(deliverable, field.id)"
				@change="$emit('custom-field-change', $event)"
			></custom-field>
		</td>
		<td class="content" v-if="isVisible('rank')">
			<input
				type="number"
				class="numberInput"
				oninput="this.value=(parseInt(this.value)||0)"
				v-model="deliverable.priority"
				step="1"
				@change="$emit('set-priority')"
			/>
		</td>
		<td class="content" v-if="isVisible('comments')">
			<v-menu :close-on-click="true" :close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<div v-on="on" class="pointer" style="position: relative" v-if="deliverable.comments.length">
						<v-icon color="gray_70" size="20">comment</v-icon>
						<div
							class="row-format centered brand-bold"
							style="
												position: absolute;
												top: 0;
												left: 12px;
												width: 16px;
												height: 16px;
												border-radius: 10px;
												color: var(--v-white-base);
												background-color: var(--v-secondary-base);
											"
						>
							<div class="font-12">{{ deliverable.comments.length }}</div>
						</div>
					</div>
					<div v-on="on" class="pointer" v-else>&nbsp;</div>
				</template>
				<div
					style="
										background-color: var(--v-white-base);
										min-width: 400px;
										max-width: 400px;
										min-height: 200px;
									"
					class="pa-4"
				>
					<activity
						:deliverable="deliverable"
						:project="deliverable.project"
						:client="deliverable.client"
						:user-list="usersByProject[deliverable.projectId]"
					></activity>
				</div>
			</v-menu>
		</td>
		<td class="content" v-if="isVisible('toDos')">
			<v-menu :close-on-click="true" :close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<div v-on="on" class="pointer">
						<div v-if="deliverable.tasks.length">{{ getTaskCountLabel(deliverable.tasks) }}</div>
						<div v-else>&nbsp;</div>
					</div>
				</template>
				<div class="pa-4" style="min-width: 300px; max-width: 400px; background-color: var(--v-white-base)">
					<tasks v-model="deliverable.tasks" :detail-view="true" @change="$emit('set-tasks', $event)"></tasks>
				</div>
			</v-menu>
		</td>
		<td class="content">
			<div class="row-format centered gap-2">
				<v-menu :close-on-content-click="true" :close-on-click="true" bottom nudge-bottom="30">
					<template v-slot:activator="scope">
						<v-icon v-on="scope.on" class="pointer highlight-hover" size="18" color="black">{{
							scope.value ? 'arrow_drop_up' : 'more_horiz'
						}}</v-icon>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="$emit('edit-deliverable', deliverable)">
							Open
						</div>
						<div class="more-menu-item" @click="$emit('get-share-link')">Share</div>
						<div class="more-menu-item" @click="$emit('confirm-duplicate')">Duplicate</div>
						<div class="more-menu-item" @click="$emit('make-task')" v-if="deliverable.parentTaskId">Convert to task</div>
						<div class="more-menu-item" @click="$emit('move-sub-task')" v-if="deliverable.parentTaskId">Move sub-task</div>
						<div class="more-menu-item" @click="$emit('move-sub-task')" v-if="!deliverable.parentTaskId">Convert to sub-task</div>
						<div class="more-menu-item" @click="$emit('toggle-archive')">
							{{ deliverable.archived ? 'Unarchive' : 'Archive' }}
						</div>
						<div class="more-menu-item" @click="$emit('confirm-delete')">
							Delete
						</div>
					</div>
				</v-menu>
			</div>
		</td>
	</tr>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import CustomField from '@/components/CustomField';
	import AssignedUser from '@/components/AssignedUser';
	import DateSelector from '@/components/DateSelector';
	import Activity from '@/modules/projects/deliverable/Activity';
	import Tasks from '@/modules/projects/deliverable/Tasks';

	export default {
		name: 'DeliverableListRow',

		props: [
			'deliverable',
			'expanded',
			'singleProjectMode',
			'statusList',
			'customFields',
			'allProjects',
			'isSubTask',
			'selectedTasks',
			'visibleFields',
		],

		components: { ClientAvatar, CustomField, AssignedUser, DateSelector, Activity, Tasks },

		data: function() {
			return {
				priorityList: ['Low', 'Normal', 'Medium', 'High', 'Urgent'],
			};
		},

		mounted() {
			if (!this.deliverable.initialWorkflowComplete) {
				let div = document.getElementById('name-' + this.deliverable.id);
				div.focus();
			}
		},

		beforeDestroy() {},

		methods: {
			isVisible: function(fieldType){
				return this.visibleFields.includes(fieldType);
			},

			handleKeyDown(event) {
				if (event.key === 'Enter' && event.shiftKey) {
					event.preventDefault();
					this.$emit('add-new-task');
				}
			},

			nameFocused: function() {
				this.$emit('name-focused');
			},

			nameBlurred: function(event) {
				this.$emit('name-blurred');
				this.$emit('set-name', event);
			},

			getCustomValue: function(deliverable, id) {
				let value = deliverable.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			getTaskCountLabel: function(tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					for (let i = 0; i < tasks.length; i++) {
						if (tasks[i].complete) {
							completedCount++;
						}
					}

					return `${completedCount}/${taskCount}`;
				}
			},

			usersForProject: function(projectId) {
				let project = this.allProjects.find((p) => p.id === projectId);
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					let simple = { firstName: user.firstName, lastName: user.lastName, userId: user.userId, email: user.email };

					if (user.userType === 'OWNER' || user.userType === 'FULL_USER' || user.userType === 'IMPLEMENTER') {
						users.push(simple);
					} else if (user.userType === 'RESTRICTED_USER' && user.featureAccess && user.featureAccess.projects) {
						users.push(simple);
					} else if (user.userType === 'COLLABORATOR' && project) {
						for (let j = 0; j < user.projectAccess.projects.length; j++) {
							if (user.projectAccess.projects[j].projectId === project.id) {
								users.push(simple);
								break;
							}
						}
					}
				}

				if (project && project.portalAccess === 'Full access') {
					this.$store.getters.contacts
						.filter((c) => c.clientId === project.clientId && c.portalAccess)
						.forEach((c) => {
							users.push({ firstName: c.firstName, lastName: c.lastName, userId: c.clientPortalUserId });
						});
				}

				return users;
			},

			startRunningTimer: function() {
				let defaults = {
					clientId: this.deliverable.clientId,
					projectId: this.deliverable.projectId,
					deliverableId: this.deliverable.id,
					hideMenu: true,
				};

				this.$store.state.eventBus.$emit('start-timer', defaults);
			},

			stopRunningTimer: function() {
				this.$store.state.eventBus.$emit('stop-timer');
			},

			isTimerInScope: function(timer) {
				if (!timer || !this.deliverable) {
					return false;
				}

				return timer.deliverableId === this.deliverable.id;
			},
		},

		computed: {
			filteredCustomFields: function(){
				return this.customFields.filter(c => this.isVisible('Custom.' + c.mappingKey));
			},

			priorityColor: function() {
				const colors = {
					Low: '#69B3F2', // Soft blue
					Normal: '#A8D5BA', // Soft green
					Medium: '#F7E380', // Soft yellow
					High: '#FF9F43', // Bright orange
					Urgent: '#D72638', // Deep crimson
				};

				return colors[this.deliverable.taskPriority] || 'var(--v-white-base)'; // Default to black if priority is not found
			},

			currentTimer: function() {
				let timer = this.$store.state.currentTimer;
				if (timer && this.isTimerInScope(timer)) {
					return timer;
				} else {
					return null;
				}
			},

			checked: function() {
				return this.selectedTasks.includes(this.deliverable.id);
			},

			usersByProject: function() {
				let result = {};

				for (let i = 0; i < this.allProjects.length; i++) {
					result[this.allProjects[i].id] = this.usersForProject(this.allProjects[i]);
				}

				return result;
			},
		},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.content-row {
		.content {
			background-color: var(--v-white-base);
		}

		&:hover {
			.content {
				background-color: var(--v-gray_5-base);
			}
		}
	}
</style>
