<template>
	<div>
		<v-menu
				max-height="90vh"
				max-width="250"
				v-model="menu"
				:close-on-content-click="false"
				:close-on-click="true"
				nudge-bottom="34"
				content-class="filter-menu-class"
				style="overflow-y: auto; "
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="" >
					<span :class="`font-14 ${!isDefault ? '' : 'font-gray_60'}`">{{filter[property]}}</span>
					<v-icon x-small class="mx-1" :color="!isDefault ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>
			<div class="add-new-dropdown">
				<div v-for="item in items" :key="item" class="add-new-item" @click="setValue(item)">{{item}}</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: "GenericFilter",

	props: ['filter','items','property'],

	components: {},

	data: function () {
		return {
			menu: false,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		setValue: function(value){
			this.$set(this.filter,this.property,value);
			this.menu = false;
		},
	},

	computed: {
		isDefault: function(){
			return this.filter[this.property] === this.items[0];
		}
	},

}
</script>

<style scoped lang="scss">
	.active {
		background-color: var(--v-gray_20-base)!important;
		color: var(--v-secondary-base)!important;;
		border: 1px solid var(--v-gray_50-base)!important;;
	}

	.scope-button {
		background-color: var(--v-white-base);
		color: var(--v-gray_80-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 12px;
		padding: 4px 8px;
		width:fit-content;
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
		white-space: nowrap;

		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}
</style>