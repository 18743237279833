<template>
	<div style="height: 100%; position: relative">
		<v-data-table
			id="subtask-table"
			:items="filteredSubtasks"
			:headers="headers"
			:items-per-page="-1"
			:hide-default-footer="true"
		>
			<template v-slot:header.select>
				<div class="column-format align-center">
					<input type="checkbox" style="" @input="allChecked($event)" />
				</div>
			</template>
			<template v-slot:item.select="{ item }">
				<div class="column-format align-center">
					<input v-model="selectedSubtasks" :value="item.id" type="checkbox" />
				</div>
			</template>
			<template v-slot:item.name="{ item }">
				<div class="row-format task-name" style="width:100%; max-width: 200px;">
					<div class="edit-task-name" contenteditable="true" @blur="setName(item, $event)" style="min-width: 100%">
						{{ item.name }}
					</div>
					<div class="ml-auto row-format align-center open-icon font-14 pointer" @click="editSubtask(item)">
						<v-icon size="18" class="material-symbols-rounded" color="black">expand_content</v-icon>
						<div style="margin-left: 2px">Open</div>
					</div>
				</div>
			</template>
			<template v-slot:item.statusId="{ item }">
				<v-menu :close-on-click="true" :close-on-content-click="true">
					<template v-slot:activator="{ on }">
						<div class="row-format">
							<div
								v-on="on"
								class="deliverableStatus row-format align-center pointer"
								:style="`--status-color:${status(item.statusId).hexColor}`"
							>
								<div class="statusBox"></div>
								<div class="text-no-wrap">{{ status(item.statusId).label }}</div>
								<v-icon v-if="item.archived" size="18" class="ml-2" v-tippy="{content:'Archived task'}" @click.stop="unarchiveTask(item)">archived</v-icon>
							</div>
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
						<div v-for="status in statusList" :key="status.id" class="py-1 pointer" @click="setStatus(item, status)">
							<div
								class="deliverableStatus row-format align-center highlight-hover"
								:style="`--status-color:${status.hexColor}`"
							>
								<div class="statusBox"></div>
								<div>{{ status.label }}</div>
							</div>
						</div>
					</div>
				</v-menu>
			</template>
			<template v-slot:item.taskPriority="{item}">
				<v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
					<template v-slot:activator="{ on }">
						<div
								v-on="on"
								class="deliverableStatus row-format align-center highlight-hover pr-1 pointer"
								:style="`--status-color:${priorityColor(item.taskPriority)}`"
						>
							<div class="statusBox"></div>
							<div>{{ item.taskPriority }}</div>
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
						<div
								v-for="priority in priorityList"
								:key="priority"
								class="py-1 pointer"
								@click="setPriority(item, priority)"
						>
							<div class=" row-format align-center highlight-hover">
								<div>{{ priority }}</div>
							</div>
						</div>
					</div>
				</v-menu>
			</template>

			<template v-slot:item.assignedToList="{ item }">
				<v-menu :close-on-click="true" :close-on-content-click="false">
					<template v-slot:activator="{ on }">
						<div class="ml-auto pointer" v-on="on">
							<div v-if="item.assignedToList.length" class="row-format align-center">
								<assigned-user
									v-on="on"
									v-for="(assignedTo, index) in item.assignedToList"
									:key="assignedTo"
									:show-name="false"
									:small="true"
									:assigned-to="assignedTo"
									:style="
										`${
											index > 0 ? 'margin-left: -6px' : ''
										}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
									"
								></assigned-user>
							</div>
							<div v-else>
								--
							</div>
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
						<div v-for="user in userList" :key="user.userId" class="row-format align-center text-left py-1 pointer">
							<input
								@change="setAssignedToList(item, item.assignedToList)"
								style="width: 20px; height: 20px; border-radius: 4px"
								type="checkbox"
								:value="user.userId"
								v-model="item.assignedToList"
							/>
							<assigned-user
								class="mx-2"
								v-if="user.userId"
								:assigned-to="user.userId"
								:show-name="true"
							></assigned-user>
						</div>
					</div>
				</v-menu>
			</template>
			<template v-slot:item.startDate="{ item }">
				<date-selector
					:micro="true"
					micro-font="font-14"
					:date="item.startDate"
					label="&nbsp;"
					@change="setStartDate(item, $event)"
				></date-selector>
			</template>
			<template v-slot:item.dueDate="{ item }">
				<date-selector
					:micro="true"
					micro-font="font-14"
					:date="item.dueDate"
					label="&nbsp;"
					@change="setDueDate(item, $event)"
				></date-selector>
			</template>
		</v-data-table>
		<div class="row-format mt-2 ">
			<div style="width:fit-content" class="font-14 font-gray_50 pointer" @click="createSubtask">
				<v-icon size="14" class="mb-1">$plus</v-icon> Add subtask
			</div>
			<div style="width:fit-content" class="ml-auto font-14 font-gray_50 pointer" @click="showArchived = !showArchived">
				{{ showArchived ? 'Hide' : 'Show' }} archived
			</div>
		</div>
		<div class="column-format centered">
			<bulk-actions
				:tasks="selectedTaskList"
				label="Subtasks"
				v-if="showBulkDialog"
				:status-list="statusList"
				:user-list="userList"
				@task-updated="handleSubtaskUpdated($event)"
			></bulk-actions>
		</div>
	</div>
</template>

<script>
	import AssignedUser from '@/components/AssignedUser';
	import DateSelector from '@/components/DateSelector';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
	import BulkActions from '@/modules/projects/bulk/BulkActions';

	export default {
		name: 'Subtasks',

		props: ['deliverable', 'statusList', 'userList'],

		components: { BulkActions, AssignedUser, DateSelector },

		data: function() {
			return {
				subtasks: [],
				selectedSubtasks: [],
				projectDeliverableService: new ProjectDeliverableService(),
				showArchived: false,
				priorityList: ['Low', 'Normal', 'Medium', 'High', 'Urgent'],
			};
		},

		mounted() {
			this.getSubtasks();
		},

		beforeDestroy() {},

		methods: {
			priorityColor: function(priority) {
				const colors = {
					Low: '#69B3F2', // Soft blue
					Normal: '#A8D5BA', // Soft green
					Medium: '#F7E380', // Soft yellow
					High: '#FF9F43', // Bright orange
					Urgent: '#D72638', // Deep crimson
				};

				return colors[priority] || 'var(--v-white-base)'; // Default to black if priority is not found
			},

			getSubtasks() {
				this.projectDeliverableService.getSubtasks(this.deliverable.id).then((res) => {
					this.subtasks.splice(0);
					this.subtasks.push(...res.data);
				});
			},

			createSubtask() {
				this.$store.commit('startLoading');
				let subTask = {
					id: null,
					clientId: this.deliverable.clientId,
					projectId: this.deliverable.projectId,
					parentTaskId: this.deliverable.id,
					subTaskSort: this.subtasks.length,
					name: null,
					description: null,
					assignedToList: [],
					dueDate: null,
					statusId: this.statusList[0].id,
					projectTypeId: this.projectTypeId,
					tasks: [],
					comments: [],
					events: [],
					files: [],
					archived: false,
					product: null,
					quantity: 0,
				};
				this.projectDeliverableService
					.createNewDeliverable(subTask)
					.then((res) => {
						this.subtasks.push(res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			editSubtask: function(subtask) {
				let binding = {
					deliverableId: subtask.id,
				};

				this.$store.state.globalModalController.openModal(DeliverableDetail, binding).then((res) => {
					let ix = this.subtasks.findIndex((d) => d.id === subtask.id);
					if (ix > -1) {
						if (res.action === 'DELETED') {
							this.subtasks.splice(ix, 1);
						} else {
							this.subtasks.splice(ix, 1, res);
						}
					}
				});
			},

			handleSubtaskUpdated: function(task) {
				let ix = this.subtasks.findIndex((t) => t.id === task.id);
				if (ix > -1) {
					this.subtasks.splice(ix, 1, task);
				}
			},

			allChecked: function(event) {
				if (event.target.checked) {
					this.selectedSubtasks.splice(0);
					this.subtasks.forEach((s) => this.selectedSubtasks.push(s.id));
				} else {
					this.selectedSubtasks.splice(0);
				}
			},

			status: function(statusId) {
				let status = this.statusList.find((s) => s.id === statusId);
				if (status) {
					return status;
				} else {
					return {
						hexColor: '#eeeeee',
						label: '[Unknown]',
					};
				}
			},

			setStatus: function(subtask, status) {
				this.setPropertyAndUpdate(subtask, 'statusId', status.id);
			},

			setPriority: function(subtask, priority) {
				this.setPropertyAndUpdate(subtask, 'taskPriority', priority);
			},

			unarchiveTask: function(subtask){
				this.setPropertyAndUpdate(subtask, 'archived', false);
			},

			setName: function(subtask, event) {
				if (event.target && event.target.innerText) {
					subtask.name = event.target.innerText;
					event.target.innerText = subtask.name;
					this.setPropertyAndUpdate(subtask, 'name', subtask.name);
				}
			},

			setAssignedToList: function(subtask, assignedToList) {
				this.setPropertyAndUpdate(subtask, 'assignedToList', assignedToList);
			},

			setDueDate: function(subtask, date) {
				this.setPropertyAndUpdate(subtask, 'dueDate', date);
			},

			setStartDate: function(subtask, date) {
				this.setPropertyAndUpdate(subtask, 'startDate', date);
			},

			setPropertyAndUpdate: function(subtask, property, value) {
				let ix = this.subtasks.findIndex((d) => d.id === subtask.id);

				if (ix > -1) {
					let d = this.subtasks[ix];
					d[property] = value;
					this.subtasks.splice(ix, 1, d);
				}

				const patch = [{ op: 'replace', path: `/${property}`, value: value }];
				this.projectDeliverableService.patchDeliverable(subtask.id, patch).catch((err) => {
					console.log(err);
				});
			},
		},

		computed: {
			filteredSubtasks: function() {
				let result = [...this.subtasks];
				return result.filter((t) => {
					if (this.showArchived) {
						return true;
					} else {
						return t.archived === false;
					}
				});
			},

			showBulkDialog: function() {
				return this.selectedSubtasks.length > 0;
			},

			selectedTaskList: function() {
				return this.subtasks.filter((s) => this.selectedSubtasks.includes(s.id));
			},

			headers: function() {
				return [
					{ text: '', value: 'select', width: '20px', sortable: false },
					{ text: 'Name', value: 'name', width: '200px' },
					{ text: 'Status', value: 'statusId' },
					{ text: 'Priority', value: 'taskPriority' },
					{ text: 'Assigned', value: 'assignedToList' },
					{ text: 'Start', value: 'startDate' },
					{ text: 'Due', value: 'dueDate' },
				];
			},
		},
	};
</script>

<style scoped lang="scss">
	.deliverableStatus {
		white-space: nowrap;

		.statusBox {
			margin-right: 8px;
			width: 14px;
			height: 14px;
			border-radius: 2px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}

	.edit-task-name {
		&:focus {
			padding: 0 4px;
			+ .open-icon {
				display: none !important;
			}
		}
	}

	.task-name {
		position: relative;
		display: flex;
		align-items: center;
		.open-icon {
			display: none;
		}

		&:hover {
			.open-icon {
				position: absolute;
				display: flex;
				right: 0;
				z-index: 100;
				background-color: var(--v-gray_5-base);
				padding: 2px 2px 2px 6px;
				height: 100%;
			}
		}
	}
</style>

<style lang="scss">
	#subtask-table {
		div[contenteditable='true']:focus {
			outline: 1px solid var(--v-gray_30-base);
			background-color: var(--v-white-base);
		}

		table {
			thead {
				tr {
					th {
						height: 24px !important;
						padding: 6px !important;
					}
					th:first-of-type {
						padding-left: 6px !important;
						padding-right: 2px !important;
					}
				}
			}
			tbody {
				tr {
					td {
						height: 36px !important;
						max-height: 36px !important;
						padding: 6px !important;
					}
					td:first-of-type {
						padding-left: 6px !important;
						padding-right: 2px !important;
					}
				}
			}
		}
	}
</style>
