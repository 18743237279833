import EmailTemplateService from "@/modules/templates/EmailTemplateService";

export default {
    created: function(){
        this.getEmailTemplates();
    },

    data: function () {
        return {
            emailTemplates: []
        }
    },

    methods: {
        emitInput(keyName) {
            this.$nextTick(() => {
                let event = new Event('input', {
                    bubbles: true,
                    cancelable: true,
                });
                this.$refs[keyName].dispatchEvent(event);
            });
        },

        getEmailTemplates: function(){
            new EmailTemplateService().getEmailTemplates().then((res) => {
                this.emailTemplates.splice(0,this.emailTemplates.length);
                this.emailTemplates.push(... res.data);
            });
        }
    },

    computed: {
        client: function(){
            let result =  [
                { label: 'Name', value: 'Name', type: 'Text' },
                { label: 'Address 1', value: 'Address1', type: 'Text' },
                { label: 'Address 2', value: 'Address2', type: 'Text' },
                { label: 'City', value: 'City', type: 'Text' },
                { label: 'Locality', value: 'Locality', type: 'Text' },
                { label: 'Postal', value: 'Postal', type: 'Text' },
                { label: 'Country', value: 'Country', type: 'Text' },
                { label: 'Phone', value: 'Phone', type: 'Text' },
                { label: 'Website', value: 'Website', type: 'Text' },
                { label: 'Record type', value: 'RecordType', type: 'List', options: ['Client','Prospect']}
            ]

            this.$store.state.clientFields.fields.forEach((f) => {
                let type;

                switch (f.type) {
                    case 'Currency': {
                        type = 'Numeric';
                        break;
                    }

                    case 'Select':
                    case 'Radio':
                    case 'Checkbox': {
                        type = 'Text';
                        break;
                    }

                    default: {
                        type = f.type;
                    }
                }

                result.push({
                    label: f.name,
                    value: `Custom.${f.mappingKey}`,
                    type: type,
                });
            });

            return result;
        },

        project: function(){
            let result =  [
                { label: 'Name', value: 'Name', type: 'Text' },
                { label: 'Start date', value: 'StartDate.ISO', type: 'Date' },
                { label: 'Due date', value: 'DueDate.ISO', type: 'Date' },
                { label: 'Complete', value: 'Complete', type: 'List', options: ['Yes','No'] }
            ]

            this.$store.getters.getGlobalProjectFields.forEach((f) => {
                let type;

                switch (f.type) {
                    case 'Currency': {
                        type = 'Numeric';
                        break;
                    }

                    case 'Select':
                    case 'Radio':
                    case 'Checkbox': {
                        type = 'Text';
                        break;
                    }

                    default: {
                        type = f.type;
                    }
                }

                result.push({
                    label: f.name,
                    value: `Custom.${f.mappingKey}`,
                    type: type,
                });
            });

            return result;
        },

        proposal: function(){
            return [
                { label: 'Name', value: 'Name', type: 'Text' },
                { label: 'Date sent', value: 'DateSent', type: 'Date' },
                { label: 'Date signed', value: 'DateSigned', type: 'Date' },
                { label: 'Online URL', value: 'OnlineUrl', type: 'Text' },
                { label: 'Status', value: 'Status', type: 'List', options: ['Draft','Sent','Signed','Fully Executed'] },
                { label: 'Selected package', value: 'SelectedPackage', type: 'Text' },
                { label: 'Total value', value: 'TotalValue', type: 'Numeric' }
            ]
        },

        agreement: function(){
            return [
                { label: 'Name', value: 'Name', type: 'Text' },
                { label: 'Date sent', value: 'DateSent.ISO', type: 'Date' },
                { label: 'Date signed', value: 'DateSigned.ISO', type: 'Date' },
                { label: 'Online URL', value: 'OnlineUrl', type: 'Text' },
                { label: 'Status', value: 'Status', type: 'List', options: ['Draft', 'Finalized', 'Fully Executed'] },
            ]
        },

        invoice: function(){
            return [
                { label: 'Number', value: 'Number', type: 'Text' },
                { label: 'Status', value: 'Status', type: 'List', options: ['Draft','Sent','Partial','Paid','Voided','Write-off'] },
                { label: 'Date sent', value: 'DateSent.ISO', type: 'Date' },
                { label: 'Date due', value: 'DateDue.ISO', type: 'Date' },
                { label: 'Online Url', value: 'OnlineUrl', type: 'Text' },
                { label: 'Pay Url', value: 'PayUrl', type: 'Text' },
                { label: 'Currency', value: 'Currency', type: 'Text' },
                { label: 'Late fee', value: 'LateFee', type: 'Numeric' },
                { label: 'Days past due', value: 'DaysPastDue', type: 'Numeric' },
                { label: 'Total', value: 'Total', type: 'Numeric' },
                { label: 'Amount due', value: 'AmountDue', type: 'Numeric' },
                { label: 'Payment total', value: 'PaymentTotal', type: 'Numeric' }
            ]
        },

        form: function(){
            return [
                { label: 'Form name', value: 'Name', type: 'Text' },
                { label: 'First name', value: 'FirstName', type: 'Text' },
                { label: 'Last name', value: 'LastName', type: 'Text' },
                { label: 'Email', value: 'Email', type: 'Text' },
                { label: 'Phone', value: 'Phone', type: 'Text' },
                { label: 'Business name', value: '', type: 'Text' },
                { label: 'Website', value: 'Website', type: 'Text' },
                { label: 'Address 1', value: 'Address1', type: 'Text' },
                { label: 'Address 2', value: 'Address2', type: 'Text' },
                { label: 'City', value: 'City', type: 'Text' },
                { label: 'Locality', value: 'Locality', type: 'Text' },
                { label: 'Postal', value: 'Postal', type: 'Text' },
                { label: 'Country', value: 'Country', type: 'Text' },
                { label: 'Source Url', value: 'SourceUrl', type: 'Text' },
            ]
        },

        meeting: function(){
          return [
              { label: 'Meeting name', value: 'Name', type: 'Text' },
              { label: 'Scheduler name', value: 'SchedulerName', type: 'Text' },
              { label: 'Location', value: 'Location', type: 'Text' },
              { label: 'Start', value: 'Start.ISO', type: 'Date' },
              { label: 'End', value: 'End.ISO', type: 'Date' },
              { label: 'Duration', value: 'Duration', type: 'Numeric' },
              { label: 'Timezone', value: 'Timezone', type: 'Text' },
              { label: 'First name', value: 'FirstName', type: 'Text' },
              { label: 'Last name', value: 'LastName', type: 'Text' },
              { label: 'Email', value: 'Email', type: 'Text' },
              { label: 'Phone', value: 'Phone', type: 'Text' },
              { label: 'Cancel Url', value: 'CancelUrl', type: 'Text' },
              { label: 'Change Url', value: 'ChangeUrl', type: 'Text' },
          ]
        },

        message: function(){
            return [
                { label: 'Message Reply', value: 'Reply', type: 'Text' }
            ]
        },

        email: function(){
            let result = [];
            this.emailTemplates.forEach(t => {
                result.push({ label: t.name + ': [Reply Status]', value: t.name, type: 'List', options: ['Replied'] });
                result.push({ label: t.name + ': [Reply Subject]', value: t.name + '.subject', type: 'Text' });
                result.push({ label: t.name + ': [Reply Body]', value: t.name + '.body', type: 'Text' });
            })
            return result;
        },

        opportunity: function() {
            let options = [];

            if(this.$store.state.pipelineStages) {
                options = this.$store.state.pipelineStages.map(s => s.label);
            }

            let result = [
                { label: 'Name', value: 'Name', type: 'Text' },
                { label: 'Stage', value: 'Stage', type: 'List', options: options },
                { label: 'Confidence', value: 'Sentiment', type: 'Numeric' },
                { label: 'Value', value: 'Value', type: 'Numeric' },
                { label: 'Est close', value: 'EstCloseDate.ISO', type: 'Date' },
                { label: 'Actual close', value: 'ActualCloseDate.ISO', type: 'Date' },
                { label: 'First name', value: 'FirstName', type: 'Text' },
                { label: 'Last name', value: 'LastName', type: 'Text' },
                { label: 'Full name', value: 'FullName', type: 'Text' },
                { label: 'Email', value: 'Email', type: 'Text' },
                { label: 'Phone', value: 'Phone', type: 'Text' },
                { label: 'Business name', value: 'BusinessName', type: 'Text' },
                { label: 'Website', value: 'Website', type: 'Text' },
                { label: 'Address 1', value: 'Address1', type: 'Text' },
                { label: 'Address 2', value: 'Address2', type: 'Text' },
                { label: 'City', value: 'City', type: 'Text' },
                { label: 'Locality', value: 'Locality', type: 'Text' },
                { label: 'Postal', value: 'Postal', type: 'Text' },
                { label: 'Country', value: 'Country', type: 'Text' },
            ];

            this.$store.state.pipelineFields.fields.forEach((f) => {
                let type;

                switch (f.type) {
                    case 'Currency': {
                        type = 'Numeric';
                        break;
                    }

                    case 'Select':
                    case 'Radio':
                    case 'Checkbox': {
                        type = 'Text';
                        break;
                    }

                    default: {
                        type = f.type;
                    }
                }

                result.push({
                    label: f.name,
                    value: `Custom.${f.mappingKey}`,
                    type: type,
                });
            });

            return result;
        },

        variables: function() {
            let result = [];

            result.push({
                label: 'Opportunity',
                value: 'Opportunity',
                fields: this.opportunity,
                dynamic: false,
            });

            result.push({
                label: 'Client',
                value: 'Client',
                fields: this.client,
                dynamic: false,
            })

            result.push({
                label: 'Proposal (legacy)',
                value: 'Proposal',
                fields: this.proposal,
                dynamic: false,
            })

            result.push({
                label: 'Agreement',
                value: 'Agreement',
                fields: this.agreement,
                dynamic: false,
            })

            result.push({
                label: 'Project',
                value: 'Project',
                fields: this.project,
                dynamic: false,
            })

            result.push({
                label: 'Invoice',
                value: 'Invoice',
                fields: this.invoice,
                dynamic: false,
            })

            result.push({
                label: 'Email',
                value: 'Email',
                fields: this.email,
                dynamic: false,
            })

            result.push({
                label: 'Message',
                value: 'Message',
                fields: this.message,
                dynamic: false,
            })

            result.push({
                label: 'Lead info',
                value: 'Form',
                fields: this.form,
                dynamic: false,
            })

            result.push({
                label: 'Form answers',
                value: 'Form.Answer',
                dynamicKeyLabel: 'Field Id',
                fields: [],
                dynamic: true,
            })

            result.push({
                label: 'Meeting',
                value: 'Meeting',
                fields: this.meeting,
                dynamic: false,
            })

            result.sort((a,b) => a.label.localeCompare(b.label));
            return result;
        },
    }
}