import ProposalsMain from '@/modules/proposals/ProposalsMain';
import ProposalTemplates from "@/modules/templates/proposals/ProposalTemplates";

export default [
	{
		path: '/agreements',
		redirect: '/agreements/list'
	},
	{
		path: '/agreements/list',
		name: 'proposalsMain',
		component: ProposalsMain,
		title: 'Agreements',
		props: (route) => ({
			id: route.query.id,
		}),
	},
	{
		path: '/agreements/settings',
		name: 'agreementSettings',
		component: ProposalTemplates,
		title: 'Agreement Templates',
		props: true
	},
];
