<template>
	<v-container class="font-14 availability">
		<v-row>
			<v-col>
				<v-autocomplete
					persistent-placeholder
					hide-details
					dense
					outlined
					class="mb-4"
					label="Schedule timezone"
					v-model="meeting.availability.schedule.timezone"
					:items="timezones"
				>
				</v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="column-format gap-3">
				<v-select
					:items="availabilityType"
					item-value="value"
					item-text="text"
					v-model="meeting.availability.availabilityType"
					hide-details
					dense
					persistent-placeholder
					outlined
					label="Availability type"
				></v-select>
				<div style="border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="px-3 pt-4 pb-1" v-if="meeting.availability.availabilityType === 'SCHEDULE'">
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base); margin-top: -14px"
						label="Sun"
						:periods="meeting.availability.schedule.sunday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Mon"
						:periods="meeting.availability.schedule.monday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Tue"
						:periods="meeting.availability.schedule.tuesday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Wed"
						:periods="meeting.availability.schedule.wednesday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Thu"
						:periods="meeting.availability.schedule.thursday"
					></period-configuration>
					<period-configuration
						style="border-bottom: 1px solid var(--v-gray_30-base)"
						label="Fri"
						:periods="meeting.availability.schedule.friday"
					></period-configuration>
					<period-configuration label="Sat" :periods="meeting.availability.schedule.saturday"></period-configuration>
				</div>
				<div style="border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="px-3 py-3 text-left column-format gap-3" v-else>
					<div>This feature allows you to use events contained in your connected calendar(s) to dynamically set your availability.  <a href="https://help.withmoxie.com/en/articles/10429892-meeting-scheduler-linked-calendar-availability-blocks" target="_blank">Learn more <v-icon class="material-symbols-rounded" size="16">help</v-icon></a></div>
					<div v-for="(calendarBlock,index) in meeting.availability.calendarTimeBlocks" :key="calendarBlock.id + index" class="row-format gap-3 align-center">
						<v-select style="flex: 1" :items="comparisonTypes" v-model="calendarBlock.comparisonType" item-text="text" item-value="value" hide-details dense outlined></v-select>
						<v-text-field style="flex: 1" v-model="calendarBlock.searchString" hide-details dense outlined placeholder="Enter event title to search for..."></v-text-field>
						<v-icon class="material-symbols-rounded" @click="deleteCalendarBlock(index)">delete</v-icon>
					</div>
					<v-btn class="primary-action" @click="addCalendarBlock">+Add new linked calendar block</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="mt-4" v-if="mappedCalendars.length">
				<div style="border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="px-4 py-3">
					<div class="row-format pointer" @click="showConnectedUsers = !showConnectedUsers">
						<div class="text-left brand-medium">Check availability against connected calendar</div>
						<div class="ml-auto">
							<v-icon small>{{ showConnectedUsers ? '$chevronUp' : '$chevronDown' }}</v-icon>
						</div>
					</div>
					<div v-if="showConnectedUsers" class="mt-2">
						<v-checkbox
							hide-details
							dense
							v-for="user in mappedCalendars"
							:value="user.value"
							:key="user.value"
							v-model="checkAvailabilityUsers"
						>
							<template v-slot:label>
								<div class="row-format align-center">
									<v-icon v-if="user.type === 'Google'">$googleCalendar</v-icon>
									<v-icon v-if="user.type === 'Apple'">$apple</v-icon>
									<v-icon v-if="user.type === 'CalDav'" color="secondary">event</v-icon>
									<v-icon v-if="user.type === 'Microsoft'">$microsoft</v-icon>
									<div class="ml-2">{{ user.text }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="mt-4">
				<div style="border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="px-4 py-3">
					<div class="row-format pointer" @click="showAdvanced = !showAdvanced">
						<div class="brand-medium">Advanced</div>
						<div class="ml-auto">
							<v-icon small>{{ showAdvanced ? '$chevronUp' : '$chevronDown' }}</v-icon>
						</div>
					</div>

					<div v-if="showAdvanced" class="mt-2">
						<div class="text-left">Invitees can schedule...</div>

						<v-radio-group v-model="meeting.availability.limitDaysInFuture" class="mt-1">
							<div class="row-format align-center">
								<v-radio :value="true"></v-radio>
								<div style="width: 50px" class="mb-2">
									<v-text-field
										:disabled="!meeting.availability.limitDaysInFuture"
										persistent-placeholder
										hide-details
										outlined
										dense
										v-model="meeting.availability.maxDaysInFuture"
									></v-text-field>
								</div>
								<div
									class="mb-2 ml-2 font-gray_80 font-14 brand-semilight pointer"
									@click="meeting.availability.limitDaysInFuture = true"
								>
									Days in future
								</div>
							</div>
							<v-radio :value="false">
								<template v-slot:label
									><div class="font-gray_80 font-14 brand-semilight">Don't limit</div></template
								>
							</v-radio>
						</v-radio-group>

						<div class="column-format gap-3">
							<div class="row-format gap-3">
								<v-select
									persistent-placeholder
									hide-details
									dense
									outlined
									style="flex: 1"
									label="Max bookings per day"
									v-model="meeting.availability.maxEventsPerDay"
									:items="maxMeetings"
								>
								</v-select>
								<v-select
									persistent-placeholder
									hide-details
									dense
									outlined
									style="flex: 1"
									label="Max bookings per week"
									v-model="meeting.availability.maxEventsPerWeek"
									:items="maxMeetings"
								>
								</v-select>
								<v-select
									persistent-placeholder
									hide-details
									dense
									outlined
									style="flex: 1"
									label="Max bookings per month"
									v-model="meeting.availability.maxEventsPerMonth"
									:items="maxMeetings"
								>
								</v-select>
							</div>

							<div class="row-format gap-3">
								<duration-selector
									class="flex-grow-1"
									label="Block time before meeting"
									v-model="meeting.availability.bufferBefore"
									:options="bufferOptions"
								></duration-selector>

								<duration-selector
									class="flex-grow-1"
									label="Block time after meeting"
									v-model="meeting.availability.bufferAfter"
									:options="bufferOptions"
								></duration-selector>
							</div>

							<duration-selector
								label="Start time increments"
								v-model="meeting.availability.startIncrement"
								:options="startIncrements"
							></duration-selector>

							<date-selector
								:standard="true"
								label="Don't accept meetings before"
								:date="meeting.availability.startDate"
								@change="meeting.availability.startDate = $event"
							></date-selector>

							<date-selector
								:standard="true"
								label="Stop accepting meetings after"
								:date="meeting.availability.endDate"
								@change="meeting.availability.endDate = $event"
							></date-selector>

							<div class="row-format gap-3">
								<duration-selector
									class="flex-grow-1"
									label="How close to start time can invitees schedule?"
									v-model="meeting.availability.minimumNotice"
									:options="minimumIncrements"
									:units="units"
								></duration-selector>
								<duration-selector
									class="flex-grow-1"
									label="How close to start time can invitees reschedule?"
									v-model="meeting.availability.minimumReschedule"
									:options="minimumIncrements"
									:units="units"
								></duration-selector>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import timezones from '@/assets/data/timezones.json';
	import PeriodConfiguration from '@/components/PeriodConfiguration';
	import DurationSelector from '@/modules/meetings/detail/components/DurationSelector';
	import DateSelector from '@/components/DateSelector';
	import {v4 as uuid} from 'uuid';

	export default {
		name: 'Availability',

		props: ['meeting', 'googleUsers', 'appleUsers', 'microsoftUsers', 'calDavUsers'],

		components: { DateSelector, DurationSelector, PeriodConfiguration },

		data: function() {
			return {
				timezones: timezones,
				showAdvanced: false,
				showConnectedUsers: false,
				checkAvailabilityUsers: [],
				availabilityType: [
					{ value: 'SCHEDULE', text: 'Weekly schedule' },
					{ value: 'CALENDAR_BLOCKS', text: 'Linked calendar blocks' },
				],
				comparisonTypes: [
					{ value: 'EQUALS', text: 'Event name equals' },
					{ value: 'CONTAINS', text: 'Event name contains' },
				],
			};
		},

		mounted() {
			this.initAvailabilityUsers();
		},

		beforeDestroy() {},

		methods: {
			initAvailabilityUsers: function() {
				this.meeting.availability.checkForConflicts.forEach((c) => {
					if (c.subCalendarId) {
						this.checkAvailabilityUsers.push(c.type + '|' + c.userId + '|' + c.calendarId + '|' + c.subCalendarId);
					} else {
						this.checkAvailabilityUsers.push(c.type + '|' + c.userId + '|' + c.calendarId);
					}
				});
			},

			addCalendarBlock: function(){
				this.meeting.availability.calendarTimeBlocks.push({
					id: uuid(),
					comparisonType: 'EQUALS',
					searchString: null
				})
			},

			deleteCalendarBlock: function(index){
					this.meeting.availability.calendarTimeBlocks.splice(index,1);
			}
		},

		computed: {
			mappedCalendars: function() {
				let result = [];

				this.appleUsers.forEach((a) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === a.userId);
					if (user) {
						a.calendars.forEach((c) => {
							if (c.enabled) {
								result.push({
									type: 'Apple',
									userId: user.userId,
									calendarId: c.path,
									text: `${user.firstName} ${user.lastName} - ${c.name} (${a.email})`,
									value: 'Apple|' + user.userId + '|' + c.path,
								});
							}
						});
					}
				});

				this.calDavUsers.forEach((c) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === c.userId);
					if (user) {
						result.push({
							type: 'CalDav',
							userId: user.userId,
							calendarId: c.id,
							subCalendarId: null,
							calendarLabel: c.calendarName,
							text: `${user.firstName} ${user.lastName} - ${c.calendarName}`,
							value: 'CalDav|' + user.userId + '|' + c.id,
						});
					}
				});

				this.googleUsers.forEach((g) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === g.userId);
					if (user) {
						if (g.calendarList) {
							g.calendarList
								.filter((c) => c.enabled)
								.forEach((c) => {
									result.push({
										type: 'Google',
										userId: user.userId,
										calendarId: g.googleUser.email,
										subCalendarId: c.calendarId,
										text: `${g.googleUser.name} - ${c.description} (${g.googleUser.email})`,
										value: 'Google|' + user.userId + '|' + g.googleUser.email + '|' + c.calendarId,
									});
								});
						} else {
							result.push({
								type: 'Google',
								userId: user.userId,
								calendarId: g.googleUser.email,
								text: `${g.googleUser.name} (${g.googleUser.email})`,
								value: 'Google|' + user.userId + '|' + g.googleUser.email,
							});
						}
					}
				});

				this.microsoftUsers.forEach((m) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === m.userId);
					if (user) {
						m.calendarList
							.filter((c) => c.enabled)
							.forEach((c) => {
								result.push({
									type: 'Microsoft',
									userId: user.userId,
									calendarId: m.microsoftUser.id,
									subCalendarId: c.id,
									text: `${m.microsoftUser.name} - ${c.name} (${m.microsoftUser.mail})`,
									value: 'Microsoft|' + user.userId + '|' + m.microsoftUser.id + '|' + c.id,
								});
							});
					}
				});

				return result;
			},

			units: function() {
				return [
					{ label: 'Minutes', value: 'MINUTES' },
					{ label: 'Hours', value: 'HOURS' },
					{ label: 'Days', value: 'DAYS' },
				];
			},

			startIncrements: function() {
				return [
					{ label: '15 minutes', value: '15:MINUTES' },
					{ label: '30 minutes', value: '30:MINUTES' },
					{ label: '60 minutes', value: '60:MINUTES' },
					{ label: '90 minutes', value: '90:MINUTES' },
					{ label: '2 hours', value: '2:HOURS' },
					{ label: '3 hours', value: '3:HOURS' },
					{ label: '4 hours', value: '4:HOURS' },
				];
			},

			minimumIncrements: function() {
				return [
					{ label: '1 Hour', value: '1:HOURS' },
					{ label: '2 Hours', value: '2:HOURS' },
					{ label: '3 Hours', value: '3:HOURS' },
					{ label: '4 Hours', value: '4:HOURS' },
					{ label: '6 Hours', value: '6:HOURS' },
					{ label: '12 Hours', value: '12:HOURS' },
					{ label: '1 Day', value: '1:DAYS' },
					{ label: 'Custom', value: 'CUSTOM' },
				];
			},

			bufferOptions: function() {
				return [
					{ label: 'No buffer', value: '0:MINUTES' },
					{ label: '15 minutes', value: '15:MINUTES' },
					{ label: '30 minutes', value: '30:MINUTES' },
					{ label: '45 minutes', value: '45:MINUTES' },
					{ label: '60 minutes', value: '60:MINUTES' },
					{ label: '90 minutes', value: '90:MINUTES' },
					{ label: '2 hours', value: '2:HOURS' },
					{ label: '3 hours', value: '3:HOURS' },
					{ label: '4 hours', value: '4:HOURS' },
					{ label: '5 hours', value: '5:HOURS' },
					{ label: '6 hours', value: '6:HOURS' },
				];
			},

			maxMeetings: function() {
				let result = [];
				result.push({ text: 'No limit', value: null });

				for (let i = 1; i < 50; i++) {
					result.push({ text: `${i}`, value: i });
				}

				return result;
			},
		},

		watch: {
			checkAvailabilityUsers: function() {
				let checks = [];
				this.checkAvailabilityUsers.forEach((c) => {
					c = c.split('|');

					let check = {
						type: c[0],
						userId: c[1],
						calendarId: c[2],
					};

					if (c.length > 3) {
						check.subCalendarId = c[3];
					}

					checks.push(check);
				});
				this.meeting.availability.checkForConflicts = checks;
			},
		},
	};
</script>

<style scoped lang="scss">
	.availability {
		padding: 50px;
	}
</style>
