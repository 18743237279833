<template>
	<v-row
		dense
		@click="$emit('click', $event)"
		class="project-row"
		:style="index > 0 ? 'border-top: 1px solid var(--v-gray_30-base);' : ''"
	>
		<v-col cols="4" class="row-format align-center gap-2">
			<client-avatar :client="project.client"></client-avatar>
			<div class="column-format text-left text-truncate" style="line-height: 18px;; overflow-y: hidden">
				<div class="font-18 truncate">{{ project.name }}</div>
				<div class="font-12 font-gray_70 truncate">{{ project.client.name }} / {{ getProjectType(project).name }}</div>
			</div>
		</v-col>
		<v-col :cols="hasCollaboratorColumn ? 3 : 5" class="text-left">
			<div class="row-format">
				<div class="font-gray_70 font-12" v-if="project.timeline">
					{{ project.timeline }}
				</div>
				<div
					v-if="project.nextDueDate"
					class="ml-auto font-12 font-gray_70 mr-4"
					style="width: fit-content"
					v-tippy="{ content: project.nextDueDate.name }"
				>
					<div>Next due: {{ project.nextDueDate.date }}</div>
				</div>
			</div>
			<div style="max-height: 12px; height: 12px;" class="mt-2 mr-4">
				<deliverable-by-status
					:project="project"
					:deliverables="project.deliverables"
					v-if="project.deliverables.length"
				></deliverable-by-status>
				<div
					class="mt-1"
					v-else
					style="height: 100%; width:100%; border-radius: 2px; background-color: var(--v-gray_20-base)"
					content="No tasks"
					v-tippy="{
						placement: 'top',
						arrow: true,
						arrowType: 'round',
						animation: 'fade',
						delay: 50,
						boundary: 'window',
					}"
				></div>
			</div>
		</v-col>
		<v-col cols="2" class="text-left">
			<div class="font-12 font-gray_70" v-if="isFullUser">Project fees</div>
			<div class="" v-if="isFullUser">{{ project.fees }}</div>
		</v-col>
		<v-col cols="2" class="text-left row-format gap-3" v-if="hasCollaboratorColumn">
			<div class="column-format" v-if="project.projectOwners.length && isFieldVisible('owners')">
				<div class="font-14 font-gray_70 ml-n1">Owner</div>
				<div class="row-format align-center">
					<assigned-user
						:style="`border-radius: 100px; border: 2px solid var(--v-white-base); z-index: ${5 - index}`"
						v-for="(owner, index) in project.projectOwners"
						:key="owner"
						:assigned-to="owner"
						:show-name="false"
					></assigned-user>
				</div>
			</div>
			<div class="column-format" v-if="collaborators.length && isFieldVisible('collaborators')">
				<div class="font-14 font-gray_70 ml-n1">Collaborator</div>
				<div class="row-format align-center">
					<assigned-user
						:style="`border-radius: 100px; border: 2px solid var(--v-white-base); z-index: ${5 - index}`"
						v-for="(collaborator, index) in collaborators"
						:key="collaborator.userId"
						:assigned-to="collaborator.userId"
						:show-name="false"
					></assigned-user>
				</div>
			</div>
		</v-col>
		<v-col cols="1" class="row-format align-center">
			<v-menu
				bottom
				left
				rounded
				offset-overflow
				offset-y
				:close-on-content-click="true"
				:close-on-click="true"
				v-if="isFullUser"
			>
				<template v-slot:activator="scope">
					<div class="ml-auto">
						<v-btn icon class="menu-activator" v-on="scope.on">
							<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
						</v-btn>
					</div>
				</template>

				<div class="more-menu">
					<div class="more-menu-item" @click.stop="$emit('click')">View details</div>
					<div class="more-menu-item" @click.stop="$emit('edit')">Edit</div>
					<div class="more-menu-item" @click.stop="$emit('mark-complete')">Mark complete</div>
					<div class="more-menu-item" @click.stop="$emit('client-transfer')">Transfer to another client</div>
				</div>
			</v-menu>
		</v-col>
		<v-col cols="12" v-if="extraFields.length" class="row-format gap-2 flex-wrap">
			<template v-for="field in extraFields">
				<div :key="field.value" class="data-chip" v-if="project[field.value]">
					<div v-if="field.value.startsWith('Custom.')">{{ field.text }}: {{ $formatters.customFieldFormat(project[field.value]) }}</div>
					<div v-else class="">{{ project[field.value] }}</div>
				</div>
			</template>
		</v-col>
	</v-row>
</template>

<script>
	import AssignedUser from '@/components/AssignedUser';
	import ClientAvatar from '@/components/ClientAvatar';
	import DeliverableByStatus from '@/modules/projects/detail/DeliverableByStatus';

	export default {
		name: 'ProjectRow',

		props: ['project', 'showCollaborators', 'index', 'visibleFields'],

		components: { ClientAvatar, AssignedUser, DeliverableByStatus },

		data: function() {
			return {};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			getProjectType(project) {
				return this.$store.getters.getProjectType(project.projectTypeId);
			},

			isFieldVisible(fieldKey) {
				return !!this.visibleFields.find((f) => f.value === fieldKey);
			},
		},

		computed: {
			hasCollaboratorColumn: function(){
				return this.showCollaborators && (this.isFieldVisible('owners') || this.isFieldVisible('collaborators'));
			},
			extraFields: function() {
				return this.visibleFields.filter((f) => f.value !== 'owner' && f.value !== 'collaborator');
			},
			collaborators: function() {
				return this.$store.state.usersForAccount.filter(
					(u) =>
						u.userType === 'COLLABORATOR' &&
						u.projectAccess.projects.findIndex((p) => p.projectId === this.project.id) > -1
				);
			},
			isFullUser() {
				return this.$store.getters.isAccountFullUser;
			},
		},
	};
</script>

<style scoped lang="scss">
	.project-row {
		min-height: 60px;
		padding: 12px 24px;
		cursor: pointer;

		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}

	.data-chip {
		background-color: var(--v-gray_5-base);
		border: 1px solid var(--v-gray_10-base);
		padding: 2px 8px;
		border-radius: 4px;
		font-size: 12px;
		max-width: fit-content;
	}
</style>
