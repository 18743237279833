<template>
	<v-menu
		:nudge-top="28"
		min-width="200"
		max-width="200"
		bottom
		right
		rounded
		offset-overflow
		offset-y
		:close-on-content-click="false"
		content-class="filter-menu-class"
		transition="slide-y-transition"
		v-model="showMenu"
	>
		<template v-slot:activator="{ on }">
			<v-icon size="18" color="gray_70" v-on="on" class="material-symbols-rounded">settings</v-icon>
		</template>

		<div class="column-format">
			<div class="filter-option-box">
				<div class="text-left brand-medium">Visible fields</div>
				<v-checkbox
					@change="updated"
					:disabled="!!field.required"
					v-model="selected"
					:value="field.value"
					v-for="field in fields"
					:key="field.value"
					:label="field.text"
					hide-details
					dense
				></v-checkbox>
			</div>
		</div>
	</v-menu>
</template>

<script>
	export default {
		name: 'UserListSettings',

		props: ['fields', 'value'],

		components: {},

		data: function() {
			return {
				showMenu: false,
				selected: [...this.value],
			};
		},

		mounted() {
			this.fields.filter(f => f.required).forEach(f => {
				if(!this.selected.includes(f.value)){
					this.selected.push(f.value);
				}
			});
		},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('input', this.selected);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
