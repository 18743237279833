var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-8"},[_c('page-header',{staticClass:"pb-6",attrs:{"bread-crumbs":[{ to: '/home', label: 'Home' }, { label: 'Accounting' }]}},[_vm._v(" Invoice templates ")]),_c('div',{staticClass:"row-format align-center mb-4"},[_c('v-text-field',{staticClass:"standard-input",style:("max-width: 200px;"),attrs:{"outlined":"","hide-details":"","dense":"","placeholder":"Search...","color":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"material-symbols-rounded font-gray_50"},[_vm._v("search")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-auto super-action",on:{"click":function($event){return _vm.addNew()}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("add")]),_vm._v(" Add new template")],1)],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"items":_vm.filteredInvoiceTemplates,"fixed-header":true,"headers":_vm.headers,"hide-default-footer":true,"items-per-page":-1,"sort-by":"name"},on:{"click:row":function($event){return _vm.editTemplate($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DateTime.fromISO(item.created).toLocaleString(_vm.DateTime.DATE_MED))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('span',[_vm._v(_vm._s(_vm.DateTime.fromISO(item.updated).toLocaleString(_vm.DateTime.DATE_MED)))]):_c('span',[_vm._v("--")])]}},{key:"item.defaultTemplate",fn:function(ref){
var item = ref.item;
return [(item.defaultTemplate)?_c('v-icon',{attrs:{"small":""}},[_vm._v("check")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }