import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";
import DateTime from "@/modules/utils/HDateTime";
import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";

export default {
    data: function () {
        return {
            deliverables: [],
            deliverableService: new ProjectDeliverableService(),
            deliverableChannelName: null,
        }
    },

    methods: {
        handleDeliverableStartup: function(){
            if(this.$store.getters.hasFeatureAccess('projects')) {
                this.deliverableChannelName = this.getDeliverableChannelName();
                this.$store.state.eventBus.$on(this.deliverableChannelName, this.deliverableEventHandler);
                this.getDeliverablesForTwoWeeks();
            }
        },

        handleDeliverableShutdown: function(){
            this.$store.state.eventBus.$off(this.deliverableChannelName, this.deliverableEventHandler);
        },

        handleDeliverableClick: function (deliverable) {
            let binding = {
                deliverableId: deliverable.id
            };
            this.$store.state.globalModalController.openModal(DeliverableDetail, binding, true, false).then(() => {
                //console.log(res);
            });
        },

        getDeliverablesForTwoWeeks: function () {
            this.deliverableService
                .getDeliverablesForDashboard(this.today.toISODate(), this.endTwoWeek.toISODate())
                .then((res) => {
                    this.deliverables.splice(0, this.deliverables.length);
                    this.deliverables.push(...res.data.deliverables);
                    this.deliverables.forEach(d => this.fixupDeliverable(d));
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getDeliverableChannelName() {
            return this.$store.getters.getMessageChannelPrefix + '.pr';
        },

        deliverableEventHandler: function (event) {
            if (event.userMetadata === 'ProjectDeliverableMini') {
                this.processDeliverableDataEvent(event.message);
            } else if (event.userMetadata === 'KanbanUpdate') {
                this.processKanbanUpdate(event.message);
            }
        },

        processDeliverableDataEvent: function (deliverable) {
            let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
            this.fixupDeliverable(deliverable);
            let isDeliverableInScope = this.isDeliverableInScope(deliverable);

            if (!isDeliverableInScope) {
                if (ix > -1) {
                    this.deliverables.splice(ix, 1);
                }
                return;
            }

            if (ix > -1) {
                this.deliverables.splice(ix, 1, deliverable);
            } else {
                this.deliverables.push(deliverable);
            }

            this.deliverables.sort(this.sortDate);
        },

        processKanbanUpdate: function (updates) {
            for (let i = 0; i < updates.length; i++) {
                let ix = this.deliverables.findIndex((d) => d.id === updates[i].deliverableId);

                if (ix > -1) {
                    let deliverable = this.deliverables[ix];
                    deliverable.statusId = updates[i].statusId;
                    this.fixupDeliverable(deliverable);

                    if (this.isDeliverableInScope(deliverable)) {
                        this.deliverables.splice(ix, 1, deliverable);
                    } else {
                        this.deliverables.splice(ix, 1);
                    }
                }
            }
        },

        isDeliverableInScope: function (deliverable) {
            if (deliverable.statusId === '__deleted__' || !deliverable.dueDate) {
                return false;
            }

            let dueDate = DateTime.fromISO(deliverable.dueDate);

            if (dueDate > this.endTwoWeek) {
                return false;
            } else {
                return true;
            }
        },

        fixupDeliverable: function (d) {
            if (d.dueDate) {
                d.luxonDate = DateTime.fromISO(d.dueDate);
            }
            d.status = this.$store.getters.getDeliverableStatusById(d.statusId);
            d.complete = !this.incompleteStatusIds.includes(d.statusId);
            d.statusIndex = this.$store.getters.getDeliverableStatusIndex(d.statusId)
        },

        assignedFilter: function(d){
            if(this.assignedTo === 'Mine'){
                return d.assignedToList.includes(this.$store.state.loggedInUser.userId);
            }else{
                return true;
            }
        },
    },

    computed: {
        statusList: function(){
            return this.$store.getters.getGlobalStatusList;
        },

        filteredDeliverables: function(){
            return this.deliverables.filter(this.assignedFilter);
        },

        incompleteStatusIds: function () {
            return this.incompleteStatusList.map((s) => s.id);
        },

        incompleteStatusList: function () {
            return this.$store.getters.getIncompleteStatusList;
        },
    }
}