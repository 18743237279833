<template>
	<component :key="refreshKey" :is="'Custom' + field.type" :value="value" :field="field" @change="handleChange($event)" :mode="mode"></component>
</template>

<script>
	import CustomText from '@/components/customFields/CustomText';
	import CustomNumeric from '@/components/customFields/CustomNumeric';
	import CustomDate from '@/components/customFields/CustomDate';
	import CustomSelect from '@/components/customFields/CustomSelect';
	import CustomRadio from '@/components/customFields/CustomCheckRadio';
	import CustomCheckbox from '@/components/customFields/CustomCheckRadio';
	import CustomCurrency from "@/components/customFields/CustomCurrency";
	import CustomEmail from "@/components/customFields/CustomEmail";
	import CustomPhone from "@/components/customFields/CustomPhone";
	import CustomLink from "@/components/customFields/CustomLink";

	export default {
		name: 'CustomField',

		props: ['field', 'value','mode'],

		components: { CustomText, CustomNumeric, CustomDate, CustomSelect, CustomRadio, CustomCheckbox, CustomCurrency, CustomEmail, CustomPhone, CustomLink },

		data: function() {
			return {
				refreshKey: 0,
				ourValue: null,
			};
		},

		mounted() {
			this.ourValue = this.value;
		},

		beforeDestroy() {},

		methods: {
			handleChange: function(value){
				this.ourValue = value;
				this.$emit('change',{
					fieldId: this.field.id,
					fieldName: this.field.name,
					value: value,
					type: this.field.type,
					mappingKey: this.field.mappingKey
				})
			}
		},

		watch: {
			value: function(){
				if(this.value !== this.ourValue) {
					this.refreshKey++;
				}
			}
		},

		computed: {
			isValidEmail() {
				return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
			}
		},
	};
</script>

<style scoped lang="scss">
	input:focus {
		outline: 1px solid var(--v-gray_30-base);
	}

	input {
		width:100%;
	}
</style>
