<template>
	<div>
		<page-header
			class="pb-6"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ to: '/projects/list', label: 'Project management' },
			]"
		>
			Project types
		</page-header>

		<div class="row-format mb-4">
			<v-btn class="ml-auto super-action" @click="addProjectType()"><v-icon size="20">add</v-icon> Add project type</v-btn>
		</div>

		<div class="mt-2">
			<v-data-table
				:items="projectTypes"
				:headers="headers"
				style="cursor: pointer"
				@click:row="routeToProjectType($event)"
				:hide-default-footer="true"
				:items-per-page="-1"
			>
				<template v-slot:item.projectFields="{ item }">
					<div>{{item.projectFields.length}} project fields</div>
				</template>
				<template v-slot:item.deliverableFields="{ item }">
					<div>{{item.deliverableFields.length}} task fields</div>
				</template>
				<template v-slot:item.statusList="{ item }">
					<div>{{item.statusList.length}} statuses</div>
				</template>
				<template v-slot:item.isDefaultProjectType="{ item }">
					<div>{{item.isDefaultProjectType ? 'Yes' : 'No'}}</div>
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon class="material-symbols-rounded" @click.stop="confirmDeleteProjectType(item)" v-if="!item.isDefaultProjectType">delete</v-icon>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import PageHeader from '@/components/PageHeader';
	import ProjectTypeService from '@/modules/projects/ProjectTypeService';
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'ProjectTypes',

		props: [],

		components: { PageHeader },

		data: function() {
			return {
				projectTypeService: new ProjectTypeService(),
				projectTypes: [],
				headers: [
					{ text: 'Project type', value: 'name' },
					{ text: 'Project fields', value: 'projectFields' },
					{ text: 'Task fields', value: 'deliverableFields' },
					{ text: 'Status values', value: 'statusList' },
					{ text: 'Default', value: 'isDefaultProjectType' },
					{ text: '', value: 'action' },
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.getProjectTypes);
			this.getProjectTypes();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getProjectTypes);
		},

		methods: {
			addProjectType: function(){
				this.$store.commit('startLoading');
				this.projectTypeService.initializeProjectType().then((res) => {
					this.routeToProjectType(res.data);
				}).finally(() => this.$store.commit('stopLoading'));
			},

			confirmDeleteProjectType: function(projectType){
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to delete the ${projectType.name} project type?  All projects and tasks assigned to ${projectType.name} will be moved to the default project type.`
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res)=>{
					if(res){
						this.projectTypeService.deleteProjectType(projectType.id).then(() => {
							this.$store.commit('success',`${projectType.name} successfully deleted`);
							this.getProjectTypes();
						})
					}
				})
			},

			getProjectTypes: function() {
				this.projectTypeService.getProjectTypesForAccount().then((res) => {
					this.projectTypes.splice(0);
					this.projectTypes.push(...res.data);
				});
			},

			routeToProjectType: function(type) {
				this.$router.push(`/projects/settings/${type.id}`);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
