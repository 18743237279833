<template>
	<div class="column-format fill-height">
		<div class="row-format align-center pb-2">
			<div class="month-name">{{ monthName }}</div>
			<div class="ml-auto row-format align-center">
				<v-btn class="font-12" text @click="showSubtasks = !showSubtasks">{{showSubtasks ? 'Hide' : 'Show'}} subtasks</v-btn>
				<v-btn-toggle>
					<v-btn color="var(--v-gray_70-base)" outlined class="chevron-button" small @click="$refs.calendar.prev()"
					><v-icon small>$chevronLeft</v-icon></v-btn
					>
					<v-btn color="var(--v-black-base)" outlined small class="today-button" @click="today()">{{
							$t('global.today')
						}}</v-btn>
					<v-btn color="var(--v-gray_70-base)" outlined small class="chevron-button" @click="$refs.calendar.next()"
					><v-icon small>$chevronRight</v-icon></v-btn
					>
				</v-btn-toggle>
			</div>
		</div>
		<div style="border: 1px solid var(--v-gray_20-base);">
			<v-calendar
					:locale="$store.state.locale"
					id="calendar"
					ref="calendar"
					v-model="value"
					:weekdays="weekdays"
					@mouseenter:day="mouseMove"
					@mouseup:day="mouseUp"
					@mouseleave.native="mouseUp"
					type="month"
			>
				<!--template v-slot:day-label="{ day }">
					<div class="dayLabel" v-if="day !== todayNumber">
						{{ day }}
					</div>
					<div v-else class="dayLabel">
						<div class="todayLabel">{{ day }}</div>
					</div>
				</template-->

				<template v-slot:day-label="{ day, weekday }">
					<div class="text-left font-12 pl-2">
						<span class="d-label">{{ days[weekday] }}</span> {{ day }}
					</div>
				</template>

				<template v-slot:day-label-header="{ day, weekday }">
					<div class="text-left font-12 pl-2">{{ days[weekday] }} {{ day }}</div>
				</template>


				<template v-slot:day="{ date }">
					<div class="day" :style="`--day-height: ${dayHeight}px;`" @click="addDeliverable(date)">
						<div v-for="(event, index) in events.get(date)" :key="event.id">
							<calendar-item
									v-if="index < maxItems || (index === maxItems && index === events.get(date).length - 1)"
									:event="event"
									@start-drag="startDrag($event)"
									@edit-deliverable="editDeliverable($event)"
							></calendar-item>
						</div>

						<v-menu
								:close-on-content-click="true"
								:nudge-top="dayHeight"
								v-if="events.get(date) && events.get(date).length > maxItems + 1"
						>
							<template v-slot:activator="{ on }">
								<div class="hasMore pointer" v-on="on">+ {{ events.get(date).length - maxItems }} more</div>
							</template>
							<v-container style="background-color: white;">
								<v-row dense>
									<v-col cols="2"></v-col>
									<v-col cols="8" align="center">
										<span style="font-weight: 500;">{{ moment(date).format('ddd') }}</span>
										<h2>{{ moment(date).format('D') }}</h2>
									</v-col>
									<v-col cols="2" align="right"
									><v-icon small class="pointer" color="black">$close</v-icon></v-col
									>
								</v-row>
								<v-row dense>
									<v-col cols="12">
										<div v-for="event in events.get(date)" :key="event.id">
											<calendar-item
													:event="event"
													@start-drag="startDrag($event)"
													@edit-deliverable="editDeliverable($event)"
											></calendar-item>
										</div>
									</v-col>
								</v-row>
							</v-container>
						</v-menu>
					</div>
				</template>
			</v-calendar>
		</div>
	</div>
</template>

<script>
	import KanbanMixin from './ProjectMixin';
	import moment from 'moment';
	import CalendarItem from './CalendarItem';

	export default {
		name: 'ProjectCalendar',

		mixins: [KanbanMixin],

		props: ['deliverables', 'projects', 'updateFlag', 'modalOpen', 'weekends','projectType'],

		components: { CalendarItem },

		data: function() {
			return {
				weekdayOnly: false,
				value: new Date().toISOString().substr(0, 10),
				dragEvent: null,
				internalUpdate: 0,
				showSubtasks: false,
				todayNumber: this.$DateTime.local().toFormat('d'),
				moment: moment,
				days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
				months: [
					'January',
					'February',
					'March',
					'April',
					'May',
					'June',
					'July',
					'August',
					'September',
					'October',
					'November',
					'December',
				],
				clickCount: 0,
				clickTimer: null,

			};
		},

		beforeMount() {
			this.startTime = Date.now();
		},

		mounted() {
			this.$store.commit('stopLoading')
			this.$track.record('page-view', { module: 'project-calendar' });
			window.addEventListener('keydown', this.keyDown);
		},

		beforeDestroy() {
			window.removeEventListener('keydown', this.keyDown);
		},

		methods: {
			keyDown: function(event) {
				if (this.modalOpen) {
					return;
				}

				switch (event.key) {
					case 'ArrowLeft':
						this.$refs.calendar.prev();
						break;
					case 'ArrowRight':
						this.$refs.calendar.next();
						break;
				}
			},

			editDeliverable: function(deliverable) {
				this.dragEvent = null;
				this.$emit('edit-deliverable', deliverable);
			},

			addDeliverable: function(date) {
				this.clickCount++;
				if (this.clickCount === 1) {
					this.clickTimer = setTimeout(() => {
						this.clickCount = 0;
					}, 500);
					return;
				}

				clearTimeout(this.clickTimer);
				this.clickCount = 0;

				this.dragEvent = null;
				this.$emit('add-deliverable-date', date);
			},

			checkAndSetMap: function(map, key, value) {
				if (!map.has(key)) {
					map.set(key, []);
				}

				map.get(key).push(value);
			},

			startDrag: function(event) {
				this.internalUpdate++;
				this.dragEvent = event;
			},

			mouseMove: function(event) {
				if (this.dragEvent) {
					this.internalUpdate++;
					this.dragEvent.dueDate = event.date;
				}
			},

			mouseUp: function() {
				if (this.dragEvent) {
					this.internalUpdate++;
					this.$emit('update-due-date', this.dragEvent);
					this.dragEvent = null;
				}
			},

			today: function() {
				let m = moment();
				this.value = m.format('YYYY-MM-DD');
			},
		},

		computed: {
			weekdays: function() {
				if (this.weekends) {
					if(this.$store.state.weekStartsOn === 'Sunday'){
						return [0, 1, 2, 3, 4, 5, 6];
					}else{
						return [1, 2, 3, 4, 5, 6, 0];
					}
				} else {
					return [1, 2, 3, 4, 5];
				}
			},

			dayHeight: function() {
				return Math.floor((this.$vuetify.breakpoint.height - 380) / this.weekCount);
			},

			maxItems: function() {
				let maxItems = Math.floor(this.dayHeight / 50);
				return maxItems < 1 ? 1 : maxItems;
			},

			weekCount: function() {
				let ourDate = moment(this.value, 'YYYY-MM-DD');

				let firstOfMonth = new Date(ourDate.year(), ourDate.month(), 1);
				let lastOfMonth = new Date(ourDate.year(), ourDate.month() + 1, 0);

				let used = firstOfMonth.getDay() + lastOfMonth.getDate();

				return Math.ceil(used / 7);
			},

			monthName: function() {
				let m = moment(this.value, 'YYYY-MM-DD');
				return m.format('MMMM YYYY');
			},

			events: function() {
				if (this.updateFlag) {
					//do nothing;
				}

				if (this.internalUpdate) {
					//do nothing
				}

				let result = new Map();

				this.deliverables.forEach((d) => {
					if (d.dueDate) {
						this.checkAndSetMap(result, d.dueDate, d);
					}
					if(this.showSubtasks){
						d.subtasks.forEach((s) => {
							if (s.dueDate) {
								this.checkAndSetMap(result, s.dueDate, s);
							}
						})
					}
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.month-name {
		font-size: 20px;
		font-weight: 600;
		color: var(--v-black-base);
	}

	.day {
		min-height: 55px;
		padding: 4px;
		height: var(--day-height);
	}

	.hasMore {
		text-align: left;
		font-size: 12px;
		font-weight: 500;
		color: var(--v-primary-base);
	}

	.dayLabel {
		font-size: 12px;
		font-weight: 500;
		text-align: left;
		padding-left: 6px;
	}

	.todayLabel {
		background-color: var(--v-primary-base);
		color: white;
		display: inline-block;
		width: 17px;
		height: 17px;
		text-align: center;
		border-radius: 10px;
	}

	#calendar ::v-deep .v-calendar-weekly__head-weekday {
		text-transform: none;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: left;
		border-bottom: 1px solid #e0e0e0;
		background-color: white !important;
		color: black;
	}

	#calendar ::v-deep .v-outside {
		background-color: #f0efea;
		opacity: 0.6;
	}
</style>


