<template>
	<div class="column-format pointer py-2 font-14 deliverable" @click="$emit('click',$event)">
		<div class="deliverable-container row-format">
			<div class="deliverable header" style="width:2px; height: 32px"></div>
			<div class="row-format align-center distribute-items px-2">
				<div class="deliverableName brand-medium">{{ deliverable.name }}<v-icon size="14" style="padding-left: 4px; margin-bottom:1px; margin-right:4px" v-if="deliverable.isRecurring">autorenew</v-icon></div>
				<div class="timeline">{{ formattedTimeline(deliverable.dueDate) }}</div>
				<div class="tasks" v-if="!isSubtask">{{ $tc('templates.packages.task-plurals', deliverable.subtasks.length) }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';

	export default {
		name: 'DeliverablePackageTask',

		props: ['deliverablePackage', 'deliverable','isSubtask'],

		data() {
			return {
				DateTime: DateTime,
				tasksExpanded: false,
			};
		},

		methods: {
			formattedTimeline(dueDate) {
				if (!dueDate || !dueDate.timeUnit) return '';
				return `${this.formattedDueDate(dueDate)} after start`;
			},

			formattedDueDate(dueDate) {
				if (!dueDate || !dueDate.timeUnit) return '';
				let unit = dueDate.timeUnit.toLowerCase();
				return this.$tc('templates.packages.duration.' + unit, dueDate.duration);
			},
		},
	};
</script>

<style scoped lang="scss">
	.deliverable {
		border-bottom: 1px solid var(--v-gray_30-base);
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.deliverable-container {
		font-size: 14px;

		.distribute-items {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.deliverableName,
			.timeline,
			.tasks {
				flex: 1;
			}

			.deliverableName {
				text-align: left;
			}

			.timeline {
				text-align: center;
			}

			.tasks {
				text-align: right;
			}
		}
	}
</style>
