<template>
	<div class="contact-card row-format pa-4" @click="$emit('edit')">
		<div class="row-format">
			<div class="column-format text-left gap-2 flex-grow-1">
				<div class="column-format gap-1">
					<div class="font-16 brand-medium" v-if="contact.firstName || contact.lastName">{{ contact.firstName }} {{ contact.lastName }}</div>
					<div class="font-12" style="word-wrap: anywhere" v-if="contact.role && isFieldVisible('role')">{{ contact.role }}</div>
					<div class="font-12" style="word-wrap: anywhere" v-if="contact.email && isFieldVisible('email')">{{ contact.email }}</div>
					<div class="font-12" style="word-wrap: anywhere" v-if="contact.phone && isFieldVisible('phone')">{{ contact.phone }}</div>
					<div class="font-12 column-format gap-1" v-if="filteredCustomFields.length">
						<template v-for="value in contact.customValues">
							<div :key="value.mappingKey" v-if="isFieldVisible('Custom.' + value.mappingKey)">{{ value.fieldName }}: {{ value.value }}</div>
						</template>
					</div>
				</div>
				<div
						class="font-12 brand-medium font-primary row-format align-center gap-1"
						v-if="contact.client && isFieldVisible('client.name')"
						@click="routeToClient"
				>
					<client-avatar :client="contact.client" :x-small="true" :disable-click="false"></client-avatar>
					<div>{{ contact.client.name }}</div>
				</div>
			</div>
		</div>
		<v-menu bottom left rounded nudge-bottom="24" :close-on-content-click="true" :close-on-click="true">
			<template v-slot:activator="scope">
				<div class="ml-auto">
					<v-icon size="20"  v-on="scope.on">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
				</div>
			</template>

			<div class="more-menu">
				<div class="more-menu-item" @click="$emit('edit')">Edit</div>
				<div class="more-menu-item" @click="$emit('send-email')" v-if="contact.email">Send Email</div>
				<div class="more-menu-item" @click="$emit('send-text')" v-if="contact.phone">Send Text</div>
				<div class="more-menu-item" @click="$emit('make-call')" v-if="contact.phone">Make Call</div>
				<div class="more-menu-item" @click="$emit('book-meeting')" v-if="contact.email">Book meeting</div>
				<div class="more-menu-item" @click="$emit('delete')">Delete</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'ContactCard',

		props: ['input','visibleFields','customFields'],

		components: { ClientAvatar },

		data: function() {
			return {
				contact: JSON.parse(JSON.stringify(this.input)),
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {


			isFieldVisible: function(key){
				return this.visibleFields.includes(key);
			},

			routeToClient: function(event) {
				event.stopPropagation();
				this.$router.push(`/client/${this.contact.client.id}`);
			},
		},

		watch: {
			input: function(){
				this.contact = JSON.parse(JSON.stringify(this.input));
			}
		},

		computed: {
			filteredCustomFields: function(){
				return this.customFields.filter(f => this.visibleFields.includes('Custom.' + f.mappingKey));
			}
		},
	};
</script>

<style scoped lang="scss">
	.long-text {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.contact-card {
		width: 300px;
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		margin-right: 2px;
		cursor: pointer;
		background-color: var(--v-white-base);

		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}
</style>
