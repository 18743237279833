<template>
	<div
		@click="more ? $emit('click', $event) : handleClick($event)"
		:class="
			`${route ? 'pointer' : ''} row-format ${expanded ? 'align-center' : 'align-center'}  mx-2 ${
				active ? 'nav-link-active' : ''
			} nav-link${expanded ? '-expanded' : ''}`
		"
	>
		<div v-if="expanded" style="padding-left: 12px; width: 100%" class="column-format">
			<div class="row-format align-center gap-2 pr-2 hover-link" style="width:100%;">
				<v-icon
					:class="!active ? 'material-symbols-rounded' : 'material-symbols-rounded'"
					:color="active ? 'primary' : 'gray_90'"
					size="20"
					>{{ icon }}</v-icon
				>
				<div :class="`nav-label  ${active  ? 'active' : ''}`">{{ label }}</div>
			</div>

		</div>
		<div
			v-else
			class="row-format centered"
			style="padding-left: 12px;"
		>
			<v-icon
				:class="!active ? 'material-symbols-rounded' : 'material-symbols-rounded'"
				:color="active ? 'primary' : 'gray_90'"
				size="20"
				>{{ icon }}</v-icon
			>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'NavLink',

		props: ['label', 'icon', 'route', 'disableActive', 'altRoutes', 'navConfig', 'more', 'expanded'],

		components: { },

		data: function() {
			return {

			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			handleClick: function(event) {
				if(this.route === '/quick'){
					this.$emit('quick-link-clicked');
				}else {
					if (event && (event.ctrlKey || event.metaKey)) {
						let w = window.open(this.route, '_blank');
						w.focus();
					} else {
						this.$router.push(this.route);
					}
					this.$emit('click', event);
				}
			},

	},

		computed: {
			isQuickLink: function() {
				return this.route === '/quick';
			},

			favoriteClients: function() {
				return this.$store.state.favoriteClients;
			},

			path: function() {
				return this.$route.path;
			},

			active: function() {
				if (this.disableActive) {
					return false;
				}if(this.$store.state.quickLinksSelected){
					return this.route === '/quick';
				}

				let active = this.$route.path.startsWith(this.route);

				if (active) {
					return true;
				}

				if (this.children && this.children.length) {
					for (let i = 0; i < this.children.length; i++) {
						if (this.path === this.children[i].uri) {
							return true;
						}
					}
				}

				if (this.altRoutes) {
					for (let i = 0; i < this.altRoutes.length; i++) {
						if (this.$route.path.startsWith(this.altRoutes[i])) {
							return true;
						}
					}
				}

				return false;
			},
		},
	};
</script>

<style scoped lang="scss">
	.nav-label {
		font-size: 14px;
		line-height: 14px;
		color: var(--v-gray_90-base);
		font-weight: 400;
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.child-label {
		color: var(--v-gray_80-base);
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.active {
		color: var(--v-primary-base) !important;
		border: none;
		font-weight: 500;
	}

	.nav-link-active {
		//background-color: var(--v-white-base);
	}

	.nav-link {
		width: 44px;
		border-radius: 4px;
	}

	.nav-link-expanded {
		width: 219px;
		border-radius: 4px;
	}
</style>
