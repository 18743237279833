export default {
    data: function () {
        return {}
    },

    methods: {
        _isInCustomFieldFilters: function (customValues, customFieldFilter) {
            let {filters, operator} = customFieldFilter;
            let hasAnyMatch = false;

            if (!filters || filters.filter(f => !!f.mappingKey).length === 0) {
                return true;
            }

            for (let i = 0; i < filters.length; i++) {
                let filter = filters[i];

                if (!filter.mappingKey || (!filter.value && filter.condition !== 'Not set')) {
                    continue;
                }

                let customValue = customValues.find(c => c.mappingKey === filter.mappingKey);
                let filterMatch = false;
                let value = customValue && customValue.value ? customValue.value : '';

                if (filter.condition === 'Not set' && (!customValue || !customValue.value)) {
                    filterMatch = true;
                } else {
                    switch (filter.condition) {
                        case "Equals": {
                            filterMatch = value.toString().toLowerCase() === filter.value.toLowerCase();
                            break;
                        }
                        case "Not equals": {
                            filterMatch = !value || value.toString().toLowerCase() !== filter.value.toLowerCase();
                            break;
                        }
                        case "Contains": {
                            filterMatch = value.toString().toLowerCase().includes(filter.value.toLowerCase())
                            break;
                        }
                        case "Is set": {
                            filterMatch = !!value;
                            break;
                        }
                        case "Greater than": {
                            filterMatch = value > filter.value;
                            break;
                        }
                        case "Less than": {
                            filterMatch = value < filter.value;
                            break;
                        }
                    }
                }

                if(!hasAnyMatch && filterMatch){
                    hasAnyMatch = true;
                }

                if (filterMatch && operator === 'Or') {
                    return true;
                } else if (!filterMatch && operator !== 'Or') {
                    return false;
                }
            }

            return hasAnyMatch;
        }
    }
}