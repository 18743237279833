import DateTime from "@/modules/utils/HDateTime";
import { Interval, Duration } from 'luxon';

export default {
	/**
	 * Vue
	 * @param {Vue} Vue
	 */
	install(Vue) {
		Object.defineProperties(Vue.prototype, {
			$luxon: {
				get() {
					return { DateTime, Interval, Duration };
				},
			},
			$DateTime: {
				get() {
					return DateTime;
				},
			},
		});
		// Shortcuts for common functions
		Vue.prototype.$DateTime.shortDate = function(date, defaultVal='') {
			if (!date) return defaultVal;
			return this.fromISO(date).toLocaleString(DateTime.DATE_MED);
		};
		Vue.prototype.$DateTime.longDate = function(date, defaultVal='') {
			if (!date) return defaultVal;
			return this.fromISO(date).toLocaleString(DateTime.DATE_FULL);
		};
		Vue.prototype.$DateTime.dateTime = function(date, defaultVal='') {
			if (!date) return defaultVal;
			return this.fromISO(date).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
		};

		// Takes human time input and returns an object comprised of hour, minute, second (or null if we can't understand the input)
		Vue.prototype.$DateTime.parseHumanTime = function(input) {
			let cleaned = ('' + input).trim().replace(/[^0-9: amp]/gi, '');
			let m = cleaned.match(/^(\d{1,2})(:(\d{1,2})|)(:(\d{1,2})|) *((a|p)m?|)$/i);
			if (m) {
				let time = { hour: parseInt(m[1] ?? 0), minute: parseInt(m[3] ?? 0), second: parseInt(m[5] ?? 0) };
				if (m[6].match(/PM?/i) && time.hour < 12) time.hour = time.hour + 12; // m[5] is AM or PM
				if (m[6].match(/AM?/i) && time.hour === 12) time.hour = 0; // m[5] is AM or PM
				return time;
			}
			return;
		};

		Vue.prototype.$DateTime.humanDateTime = function(date, alwaysShowTime = false) {
			if (!date) return '';
			const parsed = this.fromISO(date);
			const relative = parsed.toRelativeCalendar({});

			if (['today', 'yesterday', 'tomorrow'].indexOf(relative) > -1) {
				return relative.slice(0, 1).toUpperCase() + relative.slice(1) + ' ' + parsed.toLocaleString(DateTime.TIME_SIMPLE);
			}

			const local = this.local();
			let interval;
			if (local > parsed) interval = Interval.fromDateTimes(parsed, local);
			else interval = Interval.fromDateTimes(local, parsed);

			const daysDiff = interval.length('days');

			let showTime = alwaysShowTime;
			if (daysDiff > -91 && daysDiff < 91) {
				showTime = true;
			}
			let format = { month: 'short', day: 'numeric' };
			if (local.hasSame(parsed, 'week')) format.week = 'short';
			if (!local.hasSame(parsed, 'year')) format.year = 'numeric';
			if (showTime) {
				format.hour = 'numeric';
				format.minute = '2-digit';
			}

			return parsed.toLocaleString(format);
		};

		/* date can be a date object or ISO */
		Vue.prototype.$DateTime.humanDate = function(date) {
			if (!date) return '';
			if (!this.isDateTime(date)) {
				date = this.fromISO(date);
			}
			const relative = date.toLocal().toRelativeCalendar({});

			if (['today', 'yesterday', 'tomorrow'].indexOf(relative) > -1) {
				return relative.slice(0, 1).toUpperCase() + relative.slice(1);
			}
			// Hide year when date is in current year
			if (this.local().hasSame(date, 'year')) {
				return date.toLocaleString({
					month: 'short',
					day: 'numeric',
				});
			}
			// Otherwise, show year
			return this.fromISO(date).toLocaleString(DateTime.DATE_MED);
		};
	},
};
