<template>
	<div class="column-format">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Communicator' }]">
			{{recordType === 'SENT_LOG' ? 'Email log' : 'Scheduled emails'}}
		</page-header>
		<div class="row-format align-center gap-2">
			<v-text-field
					outlined
					hide-details
					dense
					v-model="filter.search"
					placeholder="Search..."
					style="max-width: 200px; background-color: var(--v-white-base)"
					color=""
					class="standard-input"
			>
				<template v-slot:prepend-inner
				><span class="material-symbols-rounded font-gray_50">search</span></template
				>
			</v-text-field>
			<div class="row-format align-center gap-2 flex-wrap" v-if="recordType === 'SENT_LOG'">
				<scope-button
					scope="today"
					:active-scope="filter.dateSpecifier"
					label="Today"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="this-week"
					:active-scope="filter.dateSpecifier"
					label="This week"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="last-week"
					:active-scope="filter.dateSpecifier"
					label="Last week"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="month-to-date"
					:active-scope="filter.dateSpecifier"
					label="This month"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="last-month"
					:active-scope="filter.dateSpecifier"
					label="Last month"
					@scope-update="updateScope"
				></scope-button>
				<scope-button
					scope="between"
					:active-scope="filter.dateSpecifier"
					label="Custom dates"
					@scope-update="updateScope"
				></scope-button>
				<div v-if="filter.dateSpecifier === 'between'" class="row-format">
					<scope-date-selector :date="filter.earliest" @change="setEarliest($event)"></scope-date-selector>
					<div class="mx-1">-</div>
					<scope-date-selector :date="filter.latest" @change="setLatest($event)"></scope-date-selector>
				</div>
			</div>
		</div>
		<div class="mt-4">
			<v-data-table
					:headers="headers"
					:items="filteredEmails"
					:hide-default-footer="true"
					sort-by="sentAt"
					:sort-desc="true"
					style="cursor: pointer;"
					:items-per-page="-1"
					:no-data-text="recordType === 'SENT_LOG' ? 'No sent emails for this period' : 'No scheduled emails'"
					@click:row="rowClicked($event)"
			>
				<template v-slot:item.to="{ item }">
					{{item.to.map(t => t.address).join(", ")}}
				</template>
				<template v-slot:item.sentAt="{ item }">
					{{(DateTime.fromISO(item.sentAt).toLocaleString(DateTime.DATETIME_MED))}}
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon class="material-symbols-rounded" @click.stop="confirmDelete(item)" v-tippy="{content:'Cancel scheduled send'}" size="20">cancel</v-icon>
				</template>

			</v-data-table>
		</div>
		<basic-modal :dialog="dialogOpen" v-if="dialogOpen" :close-on-escape="true" @close="dialogOpen=false">
			<email-log-detail :email-log="logDetail" @close="dialogOpen=false"></email-log-detail>
		</basic-modal>
	</div>
</template>

<script>
	import ScopeButton from '@/modules/home/insights/ScopeButton';
	import ScopeDateSelector from '@/modules/home/insights/ScopeDateSelector';
	import DateTime from '@/modules/utils/HDateTime';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmailLogService from "@/modules/communicator/emailLog/EmailLogService";
	import BasicModal from "@/components/BasicModal";
	import EmailLogDetail from "@/modules/communicator/emailLog/EmailLogDetail";
	import ConfirmModal from "@/components/ConfirmModal";
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'EmailLogs',

		props: ['recordType'],

		components: {EmailLogDetail, BasicModal, ScopeButton, ScopeDateSelector, PageHeader },

		data: function() {
			return {
				emailLogService: new EmailLogService(),
				emailLogs: [],
				logDetail: null,
				dialogOpen: false,
				DateTime: DateTime,
				filter: {
					search: null,
					dateSpecifier: 'today',
					earliest: null,
					latest: null,
				}
			};
		},

		mounted() {
			this.getEmailLogs();
		},

		beforeDestroy() {},

		methods: {
			getEmailLogs: function() {
				this.emailLogs.splice(0);
				this.$store.commit('startLoading');
				let earliest = null;
				let latest = null;

				if(this.recordType === 'SENT_LOG'){
					earliest = this.earliest.toISO();
					latest = this.latest.toISO();
				}

				this.emailLogService.getEmailLogs(earliest,latest,this.recordType).then((res) => {
					this.emailLogs.push(... res.data);
				}).finally(() => this.$store.commit('stopLoading'));
			},

			confirmDelete: function(item){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to cancel this scheduled email?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.emailLogService.deleteEmailLog(item.id).then(() => {
							let ix = this.emailLogs.findIndex(e => e.id === item.id);
							this.emailLogs.splice(ix,1);
						})
					}
				})
			},

			rowClicked: function(emailLog){
				this.logDetail = emailLog;
				this.dialogOpen = true;
			},

			updateScope: function(scope) {
				if (scope !== this.filter.dateSpecifier) {
					this.filter.dateSpecifier = scope;
					this.getEmailLogs();
				}
			},

			setEarliest: function(earliest) {
				this.filter.earliest = earliest;
				this.getEmailLogs();
			},

			setLatest: function(latest) {
				this.filter.latest = latest;
				this.getEmailLogs();
			},
		},

		watch: {
			recordType: function(){
				this.getEmailLogs();
			}
		},

		computed: {
			headers: function(){
				let result =  [
					{ text: 'To', value: 'to' },
					{ text: 'Subject', value: 'subject' },
					{ text: this.recordType === 'SENT_LOG' ? 'Sent' : 'Scheduled', value: 'sentAt' },
				]

				if(this.recordType === 'SCHEDULED'){
					result.push(
							{text:'', value: 'action'}
					);
				}

				return result;
			},

			filteredEmails: function(){
				if(this.filter.search) {
					return this.emailLogs.filter(e => {
						if(e.to.map(t => t.address).join(", ").toLowerCase().includes(this.filter.search.toLowerCase())) {
							return true;
						}else if(e.subject && e.subject.toLowerCase().includes(this.filter.search.toLowerCase())){
							return true;
						}else{
							return false;
						}
					});
				}else{
					return this.emailLogs;
				}
			},

			earliest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return DateTime.now()
						.minus({ months: 2 })
						.startOf('month');
				}
			},

			latest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest.endOf('day');
					}
				} else {
					return DateTime.now().endOf('month');
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
