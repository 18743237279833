import ClientCreate from "@/modules/clients/ClientCreate";
import NewProject from "@/modules/projects/NewProject";
import NewProposal from "@/modules/proposals/NewProposal";
import ProposalTemplateModal from "@/modules/templates/proposals/ProposalTemplateModal";
import AgreementBuilder from "@/modules/agreements/AgreementBuilder";
import ProposalBuilder from "@/modules/proposals/ProposalBuilder";
import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";
import DateTime from "@/modules/utils/HDateTime";
import TimeTrackEdit from "@/modules/timetracking/TimeTrackEdit";
import ExpenseDetail from "@/modules/accounting/expenses/ExpenseDetail";
import CreateInvoiceModal from "@/modules/invoices/CreateInvoiceModal";
import RecurringInvoiceDetails from "@/modules/invoices/recurring/RecurringInvoiceDetails";
import PaymentPlanDetails from "@/modules/invoices/paymentPlan/PaymentPlanDetails";
import InvoiceDetails from "@/modules/invoices/InvoiceDetails";
import NewEmail from "@/modules/communicator/inbox/email/NewEmail";
import CreateTicket from "@/modules/communicator/inbox/tickets/CreateTicket";
import TicketDetail from "@/modules/communicator/inbox/tickets/TicketDetail";

export default {
    methods: {
        createClient: function(){
            let binding = {
                clientList: this.$store.getters.clients,
                isOpen: true,
            };
            this.$store.state.globalModalController.openModal(ClientCreate, binding).then((res) => {
                setTimeout(() => this.routeToClient(res), 500);
            });
        },

        createProject: function(){
            this.$store.state.globalModalController.openModal(NewProject,{}).then((res) => {
                setTimeout(() => this.routeToProject(res), 500);
            })
        },

        routeToClient: function(client){
            this.$router.push(`/client/${client.id}`);
        },

        routeToProject(project){
            this.$router.push(`/project/${project.id}`);
        },

        createProposal: function() {
            this.$store.state.globalModalController
                .openModal(NewProposal, {  }, true, false)
                .then((res) => {
                    if (!res) {
                        return;
                    }
                    if (res.action === 'TEMPLATES') {
                        return this.openLibrary(res.view, res.clientId);
                    }else if (res.action === 'UPGRADE') {
                        setTimeout(() => {
                            this.$router.push('/subscription');
                        }, 300);
                    }else if (res.action === 'NEW_AGREEMENT') {
                        this.openV2Agreement(res.id);
                    }else{
                        this.openProposalById(res.id, res.clientId);
                    }
                });
        },

        openLibrary: function(view, clientId) {
            let binding = {
                view: view,
                clientId: clientId,
            };
            this.$store.state.globalModalController.openModal(ProposalTemplateModal, binding, true, true).then((res) => {
                if (!res) {
                    return;
                }
                res.client = res.clientMini;
                this.openProposal(res);
            });
        },

        openProposalById: function(proposalId, clientId) {
            this.openProposal({ id: proposalId, clientMini: { id: clientId } });
        },

        openV2Agreement: function(id) {
            this.$store.state.globalModalController.openModal(AgreementBuilder, { id: id }, true, true).then((res) => {
                if (res) {
                    this.$store.state.eventBus.$emit('proposal-created',res);
                }
            });
        },

        openProposal: function(proposal) {
            let clientId = proposal.clientMini ? proposal.clientMini.id : proposal.client.id;
            let binding = {
                proposalId: proposal.id,
                clientId: clientId,
            };
            this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true).then((res) => {
                if (res) {
                    this.$store.state.eventBus.$emit('proposal-created',res);
                }
            });
        },

        createDeliverable: function(){
            let projectType = this.$store.getters.getDefaultProjectType;
            let binding = {
                id: null,
                projectTypeId: projectType.id,
                defaultStatusId: projectType.statusList[0].id,
            };
            this.$store.state.globalModalController.openModal(DeliverableDetail, binding).then((dRes) => {
                if(dRes){
                    this.$store.state.eventBus.$emit('deliverable-created',dRes);
                }
            });
        },

        createTimeEntry: function(){
            let timerStart = DateTime.now();
            let timerEnd = DateTime.now().plus({ minutes: 30 });

            let timerEvent = {
                id: null,
                userId: this.$store.getters.getLoggedInUserId,
                timerStart: timerStart.toISO(),
                timerEnd: timerEnd.toISO(),
                duration: timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']),
            };

            this.$store.state.globalModalController.openModal(TimeTrackEdit, { timerEvent: timerEvent }).then((res) => {
                if (res) {
                    this.$store.state.eventBus.$emit('timer-event-created',res);
                }
            });
        },

        createExpense: function(){
            this.$store.state.globalModalController.openModal(ExpenseDetail, {}).then((res) => {
                if (res) {
                    this.$store.state.eventBus.$emit('expense-created',res);
                }
            });
        },

        createInvoice: function () {
            this.$store.state.globalModalController.openModal(CreateInvoiceModal, {}).then((res) => {
                if(!res){
                    return;
                }
                if(res.createRecurring) {
                    let binding2 = {
                        clientId: res.clientId,
                        id: null
                    }
                    this.$store.state.globalModalController.openModal(RecurringInvoiceDetails, binding2, false, true).then((res) => {
                        if (res) {
                            this.$store.state.eventBus.$emit('invoice-created',res);
                        }
                    });
                }else if(res.createPaymentPlan){
                    let binding = {
                        clientId: res.clientId,
                        id: null,
                    };
                    this.$store.state.globalModalController.openModal(PaymentPlanDetails, binding, false, true).then((res) => {
                        if (res) {
                            this.$store.state.eventBus.$emit('invoice-created',res);
                        }
                    });
                }else{
                    let binding2 = {
                        clientId: res.clientId,
                        id: res.id,
                        quick: false,
                        showDuplicate: true,
                    };
                    this.$store.state.globalModalController.openModal(InvoiceDetails, binding2, false, true).then((res) => {
                        if(res){
                            this.$store.state.eventBus.$emit('invoice-created',res);
                        }
                    });
                }
            });
        },

        createTicket: function(){
            this.$store.state.globalModalController.openModal(CreateTicket,{}).then((res) => {
                if(res){
                    console.log(res);
                    let binding = { id: res.ticket.id };
                    this.$store.state.globalModalController.openModal(TicketDetail, binding);
                }
            })
        },

        sendEmail: function() {
            let toList = [];
            this.$store.state.globalModalController
                .openModal(NewEmail, { toList: toList }, true, false, false, true);
        },
    }
}