<template>
	<div>
		<table id="stages-table" class="table">
			<thead>
				<tr>
					<th style="width: 50px;"></th>
					<th>Label</th>
					<th>
						Client action
						<v-icon class="material-symbols-rounded" small v-tippy="{ content: $t('projects.action-icon') }"
							>help</v-icon
						>
					</th>
					<th>Complete</th>
					<th>Highlight color</th>
					<th style="width: 50px;"></th>
				</tr>
			</thead>
			<draggable
				v-model="statusList"
				group="options"
				tag="tbody"
				@change="saveSettings"
				handle=".drag"
				v-if="statusList"
			>
				<tr v-for="status in statusList" :key="status.id" class="font-14 text-left">
					<td style="width: 50px; text-align: center">
						<v-icon class="drag">drag_handle</v-icon>
					</td>
					<td>
						<div contenteditable="true" class="pa-1" @blur="setName(status, $event)">
							{{ status.label }}
						</div>
					</td>
					<td>
						<div class="row-format align-center">
							<v-switch
								dense
								hide-details
								class="mt-n1"
								v-model="status.clientApproval"
								@change="saveSettings()"
							></v-switch>
							<v-select
								style="max-width: 175px"
								class="ml-auto"
								v-if="status.clientApproval"
								:items="reminderOptions"
								dense
								hide-details
								outlined
								item-value="value"
								item-text="label"
								persistent-placeholder
								label="Reminder frequency"
								@change="saveSettings()"
								v-model="status.clientApprovalReminderDays"
							>
							</v-select>
						</div>
					</td>
					<td>
						<v-switch
							dense
							hide-details
							class="mt-n1"
							v-model="status.complete"
							@change="completeUpdated(status)"
						></v-switch>
					</td>
					<td>
						<v-menu :close-on-click="true" :close-on-content-click="true">
							<template v-slot:activator="{ on }">
								<div v-on="on" class="stage-color pointer" :style="`--stage-color:${status.hexColor}`"></div>
							</template>
							<div id="color-picker-div">
								<v-color-picker
									flat
									mode="hexa"
									show-swatches
									hide-canvas
									hide-mode-switch
									hide-inputs
									class="mx-auto"
									swatches-max-height="200"
									:swatches="clientPalette.palette"
									@input="saveSettings()"
									v-model="status.hexColor"
								></v-color-picker>
							</div>
						</v-menu>
					</td>
					<td style="text-align: center; width: 50px;">
						<v-icon class="material-symbols-rounded" size="20" @click="confirmDelete(status)">delete</v-icon>
					</td>
				</tr>
			</draggable>
		</table>

	</div>
</template>

<script>
	import ClientPalette from '@/modules/clients/ClientPalette';
	import draggable from 'vuedraggable';
	import ConfirmModal from '@/components/ConfirmModal';
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'DeliverableStatus',

		props: ['value'],

		components: {draggable },

		data: function() {
			return {
				statusList: null,
				clientPalette: new ClientPalette(),
				reminderOptions: [
					{ value: -1, label: 'Disabled' },
					{ value: 3, label: 'Every 3 days' },
					{ value: 7, label: 'Weekly' },
					{ value: 14, label: 'Two weeks' },
					{ value: 30, label: 'Monthly' },
				],
			};
		},

		mounted() {

		},

		beforeDestroy() {

		},

		methods: {
			setName: function(status, event) {
				if (event.target && event.target.innerText) {
					status.label = event.target.innerText;
					event.target.innerText = status.label;
					this.saveSettings();
				}
			},

			addStatus: function() {
				let status = {
					id: uuidv4(),
					label: '[New status]',
					hexColor: this.clientPalette.getRandomColor(this.statusList.map((s) => s.hexColor)),
					complete: false,
					clientApproval: false,
				};
				this.statusList.push(status);
				this.saveSettings();
			},

			completeUpdated: function(status) {
				if (status.complete) {
					this.statusList.forEach((s) => {
						if (s.id !== status.id) {
							s.complete = false;
						}
					});
				}
				this.saveSettings();
			},

			saveSettings: function() {
				this.$emit('input',this.statusList);
			},

			confirmDelete: function(status) {
				let binding = {
					headingText: 'Delete status?',
					bodyText: `Are you sure you want to delete the ${status.label} status?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.statusList.findIndex((s) => s.id === status.id);
						this.statusList.splice(ix, 1);
						this.saveSettings();
					}
				});
			},
		},

		watch: {
			value:{
				immediate: true,
				handler: function(){
					this.statusList = this.value;
				}
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.drag {
		cursor: grab;
		&:active {
			cursor: grabbing;
		}
	}

	#stages-table {
		text-align: left;
		border-collapse: collapse;
		width: 100%;

		.heading {
			padding: 8px;
			font-size: 12px;
			font-weight: 600;
			margin: 0px;
			border-bottom: 1px solid var(--v-gray_30-base);
			border-right: 1px solid var(--v-gray_30-base);

			&:first-of-type {
				padding-left: 20px !important;
			}

			&:last-of-type {
				padding-right: 12px !important;
			}
		}

		.stage-color {
			background-color: var(--stage-color);
			width: 100%;
			height: 20px;
			min-width: 100%;
			min-height: 20px;
			border-radius: 4px;
		}

		thead {
			tr {
				th {
					padding: 8px;
					font-size: 12px;
					font-weight: 600;
					margin: 0px;
					border-top: 1px solid var(--v-gray_20-base);
					border-bottom: 1px solid var(--v-gray_20-base);
					border-right: 1px solid var(--v-gray_20-base);

					&:first-of-type {
						border-left: 1px solid var(--v-gray_20-base);
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_20-base);
					border-right: 1px solid var(--v-gray_20-base);

					&:first-of-type {
						border-left: 1px solid var(--v-gray_20-base);
					}
				}

				&:hover {
					td {
						background-color: var(--v-gray_10-base);
					}
				}
			}
		}
	}
</style>
