<template>
	<div class="mt-2">
		<v-data-table :items="emailBoxes" :headers="headers" style="cursor: pointer" @click:row="editEmailBox($event)" :hide-default-footer="true" :items-per-page="-1">
			<template v-slot:item.provider="{ item }">
				<v-icon>{{item.providerInfo.icon}}</v-icon>
			</template>
			<template v-slot:item.sharedInbox="{ item }">
				<div>{{item.sharedInbox ? 'Yes' : 'No'}}</div>
			</template>
			<template v-slot:item.status="{ item }">
				<span v-if="item.enabled">{{item.status}}</span>
				<span v-else>DISABLED</span>
				<span v-if="item.status === 'RUNNING' && item.initialSync" class="font-12 font-italic"><br>Initial mail sync can take up to 2 hours.  Please check back soon.</span>
			</template>
			<template v-slot:item.statusTime="{ item }">
				<span v-if="item.statusTime">{{HDateTime.fromISO(item.statusTime).toLocaleString(HDateTime.DATETIME_SHORT)}}</span>
				<span v-else> -- </span>
			</template>
			<template v-slot:item.nextRunTime="{ item }">
				{{HDateTime.fromISO(item.nextRunTime).toLocaleString(HDateTime.DATETIME_SHORT)}}
			</template>
			<template v-slot:header.action="{}">
				<v-btn icon @click="getEmailBoxes()"><v-icon>refresh</v-icon></v-btn>
			</template>
		</v-data-table>
		<div class="text-left row-format align-center mr-4 mt-4">
			<v-btn color="primary-action" @click="addEmailBox()" v-if="emailBoxes.length < 6">+ Add new email account</v-btn>
			<div class="ml-auto font-12 font-gray_70" v-if="lastUpdated">Last updated: {{lastUpdated.toLocaleString(HDateTime.DATETIME_SHORT)}}</div>
		</div>
		<div class="mt-10 pt-4" style="border-top: 1px solid var(--v-gray_30-base)">
			<sync-domains-config></sync-domains-config>
		</div>
	</div>
</template>

<script>
	import EmailBoxService from '@/modules/communicator/settings/EmailBoxService';
	import providers from './providers.json';
	import HDateTime from "@/modules/utils/HDateTime";
	import MailboxConfig from "@/modules/communicator/settings/MailboxConfig";
	import SyncDomainsConfig from "@/modules/communicator/settings/SyncDomainsConfig";

	export default {
		name: 'Mailboxes',

		props: [],

		components: {SyncDomainsConfig},

		data: function () {
			return {
				emailBoxes: [],
				emailBoxService: new EmailBoxService(),
				providers: providers,
				HDateTime:HDateTime,
				lastUpdated: null,
			};
		},

		mounted() {
			this.getEmailBoxes();
			this.$store.state.eventBus.$on('account-changed', this.getEmailBoxes);
			this.$store.state.eventBus.$on('communicator_onboarding_email',this.handleOnBoardingRequest);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getEmailBoxes);
			this.$store.state.eventBus.$off('communicator_onboarding_email',this.handleOnBoardingRequest);
		},

		methods: {
			handleOnBoardingRequest: function(){
				this.addEmailBox();
			},

			editEmailBox: function(emailBox){
				if(emailBox.userId === this.$store.getters.getLoggedInUserId){
					this.$store.state.globalModalController.openModal(MailboxConfig,{id:emailBox.id}).then(() => this.getEmailBoxes());
				}else{
					console.log('Shared inbox is not yours');
				}
			},

			addEmailBox: function(){
				this.$store.state.globalModalController.openModal(MailboxConfig,{}).then(() => {
					this.$onBoarding.track('communicator_connect_email');
					this.getEmailBoxes()
				});
			},

			getEmailBoxes: function () {
				this.emailBoxService.getEmailBoxes().then((res) => {
					const emailBoxes = res.data;
          emailBoxes.sort((a, b) => (a.defaultEmailBox === b.defaultEmailBox) ? 0 : (a.defaultEmailBox ? -1 : 1));
          this.emailBoxes.splice(0, this.emailBoxes.length);
					this.emailBoxes.push(...emailBoxes);
					this.emailBoxes.forEach((e) => (e.providerInfo = this.getProviderByType(e.provider)));
					this.lastUpdated = HDateTime.now();
				});
			},

			getProviderByType: function (provider) {
				return this.providers.find((p) => p.type === provider);
			},
		},

		computed: {
			headers: function () {
				return [
					{ text: 'Provider', value: 'provider' },
					{ text: 'User', value: 'username' },
					{ text: 'Shared', value: 'sharedInbox' },
					{ text: 'Status', value: 'status' },
					{ text: 'Message', value: 'statusMessage' },
					{ text: 'Updated', value: 'statusTime' },
					{ text: 'Next sync', value: 'nextRunTime' },
					{ text: '', value: 'action', sortable: false, align: 'right'},
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
