<template>
	<div style="width:900px; max-width: 900px" v-if="isReady">
		<div class="modal-padding">
			<div class="row-format mb-2 align-center">
				<div class="brand-medium font-18" v-if="scheduledMeeting">Reschedule meeting</div>
				<div class="brand-medium font-18" v-else>Schedule new meeting</div>
				<v-icon size="20" class="ml-auto pointer" @click="handleCancel">$close</v-icon>
			</div>
			<div class="row-format gap-4">
				<div style="min-width: 350px" class="">
					<div v-if="scheduledMeeting" class="brand-box pa-2 text-left font-14 column-format gap-2">
						<div><span class="font-gray_50 font-12">Name:</span> {{ scheduledMeeting.meetingName }}</div>
						<div style="max-width: 330px" class="truncate">
							<span class="font-gray_50 font-12">Contact:</span> {{ scheduledMeeting.formData.firstName }}
							{{ scheduledMeeting.formData.lastName }}
						</div>
						<div style="max-width: 330px" class="truncate">
							<span class="font-gray_50 font-12">Email:</span> {{ scheduledMeeting.formData.email }}
						</div>
						<div><span class="font-gray_50 font-12">Location:</span> {{ scheduledMeeting.location.type }}</div>
						<div>
							<span class="font-gray_50 font-12">Duration:</span>
							{{
								DateTime.fromISO(scheduledMeeting.confirmedTime.end).diff(
									DateTime.fromISO(scheduledMeeting.confirmedTime.start),
									'minutes'
								).minutes
							}}
							minutes
						</div>
						<div>
							<span class="font-gray_50 font-12" v-if="calculatedStartTime">Current:</span>
							{{
								DateTime.fromISO(scheduledMeeting.confirmedTime.start)
									.setZone(booking.scheduledTimezone)
									.toFormat('ccc') +
									' ' +
									DateTime.fromISO(scheduledMeeting.confirmedTime.start)
										.setZone(booking.scheduledTimezone)
										.toLocaleString(DateTime.DATETIME_MED)
							}}
						</div>
						<div style="border-top: 1px solid var(--v-gray_30-base)"></div>
						<div>
							<span class="font-gray_50 font-12" v-if="calculatedStartTime">New start:</span>
							<span class="brand-medium">
								{{ calculatedStartTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) }}</span
							>
						</div>
						<div>
							<span class="font-gray_50 font-12" v-if="calculatedEndTime">New end:</span>
							<span class="brand-medium">
								{{ calculatedEndTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) }}</span
							>
						</div>
					</div>
					<div v-else class="brand-box pa-2 text-left font-14 column-format gap-2">
						<div><span class="font-gray_50 font-12">Name:</span> {{ scheduler.name }}</div>
						<div>
							<span class="font-gray_50 font-12">Duration:</span> {{ scheduler.meetingLength.duration }}
							{{ scheduler.meetingLength.timeUnit.toLowerCase() }}
						</div>
						<div>
							<span class="font-gray_50 font-12" v-if="calculatedStartTime">Start:</span>
							{{ calculatedStartTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) }}
						</div>
						<div>
							<span class="font-gray_50 font-12" v-if="calculatedEndTime">End:</span>
							{{ calculatedEndTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) }}
						</div>
						<div style="max-width: 330px" class="truncate">
							<span class="font-gray_50 font-12">Contact:</span> {{ contact.firstName }} {{ contact.lastName }}
						</div>
						<div style="max-width: 330px" class="truncate">
							<span class="font-gray_50 font-12">Email:</span> {{ contact.email }}
						</div>
						<div><span class="font-gray_50 font-12" v-if="contact.phone">Phone:</span> {{ contact.phone }}</div>
					</div>
				</div>
				<div class="row-format flex-grow-1">
					<div class="column-format gap-3 flex-grow-1" style="height: fit-content">
						<v-autocomplete
							v-if="!scheduledMeeting"
							:items="schedulers"
							item-value="id"
							item-text="name"
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Meeting scheduler"
							v-model="booking.meetingId"
							@change="getAvailableTimes()"
						></v-autocomplete>
						<v-select
							v-if="!scheduledMeeting"
							:items="scheduler.locations"
							v-model="booking.location"
							outlined
							hide-details
							dense
							persistent-placeholder
							label="Location"
							item-text="type"
							return-object
						>
						</v-select>
						<v-autocomplete
							:items="timezones"
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Timezone"
							v-model="booking.scheduledTimezone"
						></v-autocomplete>
						<date-selector
							:standard="true"
							label="Meeting date"
							:date="meetingDate"
							@change="meetingDateUpdated"
						></date-selector>
						<div
							class="brand-box row-format meeting-time"
							style="position: relative; height: 40px"
							v-if="!viewAvailability"
						>
							<div
								style="position: absolute; top: -10px; left: 8px; background-color: var(--v-white-base)"
								class="font-12 font-gray_60 px-1"
							>
								Meeting time
							</div>
							<vue-timepicker
								v-model="meetingTime"
								:format="format"
								:hide-clear-button="true"
								auto-scroll
								:manual-input="true"
								:hide-dropdown="true"
								drop-direction="up"
								@change="timeUpdated"
							></vue-timepicker>
							<v-icon class="ml-auto material-symbols-rounded" style="padding-right: 10px">schedule</v-icon>
						</div>
						<v-switch
							v-model="viewAvailability"
							label="View availability"
							hide-details
							dense
							class="pa-0 ma-0"
							@change="resetMeetingTime"
						></v-switch>
						<div class="row-format gap-2 flex-wrap" v-if="viewAvailability">
							<v-chip
								v-for="slot in slotsForDay"
								:key="slot.start"
								:color="slot.localTime.equals(calculatedStartTime) ? 'primary' : 'gray_10'"
								class="pointer"
								@click="selectSlot(slot)"
								>{{ slot.localTime.toLocaleString(DateTime.TIME_SIMPLE) }}</v-chip
							>
							<div v-if="slotsForDay.length === 0">No availability for the date selected</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer gap-2 row-format centered">
			<v-btn class="super-action" style="width: 160px" @click="scheduledMeeting ? rescheduleMeeting() : bookMeeting()">
				{{ scheduledMeeting ? 'Reschedule' : 'Schedule' }} meeting
			</v-btn>
			<v-btn class="primary-action" @click="handleCancel">
				{{ $t('global.cancel') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import MeetingService from '@/modules/meetings/MeetingService';
	import ScheduledMeetingService from '@/modules/meetings/ScheduledMeetingService';
	import timezones from '@/assets/data/timezones.json';
	import DateSelector from '@/components/DateSelector';
	import VueTimepicker from 'vue2-timepicker';
	import { DateTime, Settings } from 'luxon';

	export default {
		name: 'BookMeeting',

		props: ['contact', 'opportunityId', 'scheduledMeeting'],

		components: { DateSelector, VueTimepicker },

		data: function() {
			return {
				isReady: false,
				DateTime: DateTime,
				Settings: Settings,
				viewAvailability: false,
				timezones: timezones,
				schedulers: [],
				availableTimes: [],
				meetingService: new MeetingService(),
				scheduledMeetingService: new ScheduledMeetingService(),
				meetingDate: DateTime.now().toISODate(),
				meetingTime: this.formatTime(
					DateTime.now()
						.plus({ hours: 1 })
						.startOf('hour')
				),
				finalDateTime: null,

				booking: {
					meetingId: null,
					contact: this.contact,
					meetingName: null,
					confirmedTime: {
						start: null,
						end: null,
					},
					location: null,
					scheduledTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					scheduledLocale: navigator.languages[0],
					opportunityId: this.opportunityId,
				},
			};
		},

		mounted() {
			if (this.scheduledMeeting) {
				this.getAvailableTimes();
				this.booking.scheduledTimezone = this.scheduledMeeting.scheduledTimezone;
				this.isReady = true;
			} else {
				this.getSchedulers();
			}
		},

		beforeDestroy() {},

		methods: {
			getSchedulers: function() {
				this.meetingService.getMeetingList().then((res) => {
					this.schedulers.splice(0);
					this.schedulers.push(...res.data);
					this.schedulers.sort((a, b) => a.name?.localeCompare(b.name));
					if (this.schedulers.length) {
						this.booking.meetingId = this.schedulers[0].id;
					}
					this.isReady = true;
				});
			},

			rescheduleMeeting: function() {
				let request = {
					start: this.calculatedStartTime.toISO(),
					end: this.calculatedEndTime.toISO(),
				};
				this.$store.commit('startLoading');
				this.scheduledMeetingService
					.rescheduleMeeting(this.scheduledMeeting.id, request)
					.then((res) => {
						this.$store.commit(
							'success',
							`Your meeting with ${
								this.scheduledMeeting.formData.firstName
							} was successfully rescheduled for ${this.calculatedStartTime.toLocaleString(
								DateTime.DATETIME_MED_WITH_WEEKDAY
							)}`
						);
						this.$emit('result', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', `There was an error rescheduling your meeting: ${err.data?.message}`);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			bookMeeting: function() {
				let request = JSON.parse(JSON.stringify(this.booking));
				request.confirmedTime.start = this.calculatedStartTime.toISO();
				request.confirmedTime.end = this.calculatedEndTime.toISO();
				this.$store.commit('startLoading');
				this.scheduledMeetingService
					.bookMeeting(request)
					.then((res) => {
						this.$store.commit(
							'success',
							`Your meeting with ${
								this.contact.firstName
							} was successfully booked for ${this.calculatedStartTime.toLocaleString(
								DateTime.DATETIME_MED_WITH_WEEKDAY
							)}`
						);
						this.$emit('result', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', `There was an error booking your meeting: ${err.data?.message}`);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			getAvailableTimes: function() {
				let meetingId;
				if (this.scheduledMeeting) {
					meetingId = this.scheduledMeeting.meetingId;
				} else {
					meetingId = this.booking?.meetingId;
				}

				if (meetingId) {
					this.scheduledMeetingService
						.getAvailableTimes(meetingId, this.earliest.toISODate(), this.latest.toISODate())
						.then((res) => {
							this.availableTimes.splice(0);
							this.availableTimes.push(...res.data);
						});
				}
			},

			handleCancel: function() {
				this.$emit('result');
			},

			resetMeetingTime: function() {
				this.meetingTime = this.formatTime(this.calculatedStartTime);
			},

			meetingDateUpdated: function(date) {
				this.meetingDate = date;
				this.finalDateTime = date + this.finalDateTime.slice(10);
			},

			timeUpdated: function(val) {
				this.finalDateTime = this.meetingDate + 'T' + val.data.HH + ':' + val.data.mm + ':00';
			},

			selectSlot: function(slot) {
				this.finalDateTime = slot.localTime.toISO().substring(0, 23);
			},

			formatTime: function(startTime) {
				let meetingTime = startTime
					.toLocaleString(DateTime.TIME_SIMPLE)
					.replace(/[.]/gi, '')
					.toUpperCase();

				return meetingTime.replace(/[\u202f]/, ' ');
			},
		},

		watch: {
			'booking.meetingId': function() {
				this.getAvailableTimes();
			},

			scheduler: function() {
				this.booking.location = this.scheduler?.locations[0];
			},

			earliestLatest: function() {
				this.getAvailableTimes();
			},
		},

		computed: {
			scheduler: function() {
				return this.schedulers.find((s) => s.id === this.booking.meetingId);
			},

			calculatedStartTime: function() {
				return DateTime.fromISO(this.finalDateTime, { zone: this.booking.scheduledTimezone });
			},

			calculatedEndTime: function() {
				if (this.meetingLength && this.calculatedStartTime) {
					return this.calculatedStartTime.plus({ minutes: this.meetingLength });
				} else {
					return null;
				}
			},

			meetingLength: function() {
				if (this.scheduledMeeting) {
					return DateTime.fromISO(this.scheduledMeeting.confirmedTime.end).diff(
						DateTime.fromISO(this.scheduledMeeting.confirmedTime.start),
						'minutes'
					).minutes;
				} else {
					return this.scheduler?.meetingLength.asMinutes;
				}
			},

			slotsForDay: function() {
				let times = [...this.availableTimes];
				times.forEach((t) => (t.localTime = DateTime.fromISO(t.start).setZone(this.booking.scheduledTimezone)));
				return times.filter((t) => t.localTime.toISODate() === this.meetingDate);
			},

			earliestLatest: function() {
				return this.earliest.toISO() + this.latest.toISO();
			},

			earliest: function() {
				return DateTime.fromISO(this.meetingDate + 'T00:00:00Z').startOf('month');
			},

			latest: function() {
				return DateTime.fromISO(this.meetingDate + 'T00:00:00Z')
					.plus({ months: 1 })
					.startOf('month');
			},

			format: function() {
				if (this.is12HourFormat) {
					return 'h:mm A';
				} else {
					return 'HH:mm';
				}
			},

			is12HourFormat: function() {
				if (this.meetingTime?.includes('AM') || this.meetingTime?.includes('PM')) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style lang="scss">
	.meeting-time {
		.vue__time-picker input.display-time {
			border: none !important;
			outline: none !important;
			color: var(--v-black-base) !important;
			font-weight: 400 !important;
			font-size: 14px;
			padding-left: 3px;
			padding-top: 8px;

			&:hover {
				border: none !important;
			}
		}
	}
</style>
