import ProjectEdit from "@/modules/projects/detail/ProjectEdit";
import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";
import ProjectService from "@/modules/projects/ProjectService";
import HDateTime from "@/modules/utils/HDateTime";
import ExpiringMap from "@/utils/ExpiringMap";

export default {
    data: function () {
        return {
            deliverables: [],
            projects: [],
            deliverableService: new ProjectDeliverableService(),
            projectService: new ProjectService(),
            newInlineItems: new ExpiringMap(),
            taskPriorityValues: {
                Low: 4,
                Normal: 3,
                Medium: 2,
                High: 1,
                Urgent: 0
            },
            baseTaskFields: [
                {label: 'Name', value: 'name'},
                {label: 'Client/Project', value: 'project'},
                {label: 'Status', value: 'status'},
                {label: 'Assigned', value: 'assignedTo'},
                {label: 'Priority', value: 'priority'},
                {label: 'Start date', value: 'startDate'},
                {label: 'Due date', value: 'dueDate'},
                {label: 'Rank', value: 'rank'},
                {label: 'Comments', value: 'comments'},
                {label: 'To-dos', value: 'toDos'},
            ]
        }
    },

    computed: {
        statusList: function(){
            return this.$store.getters.getGlobalStatusList;
        }
    },

    methods: {
        editProject: function(project, removeInactive = true){
            this.$store.state.globalModalController.openModal(ProjectEdit,{projectId:project.id}).then((p) => {
                if(p){
                    if(p.deleted){
                        this.$store.commit('warn',`${project.name} has been deleted.`);
                        this.projectDeleted(p);
                    }else{
                        this.$store.commit('success',`${project.name} has been updated.`);
                        this.projectUpdated(p,removeInactive);
                    }
                }
            })
        },

        projectUpdated: function (project, removeInactive) {
            let ix = this.projects.findIndex((p) => p.id === project.id);
            this.projects[ix].name = project.name;
            this.projects[ix].feeSchedule = project.feeSchedule;
            this.projects[ix].dueDate = project.dueDate;
            this.projects[ix].startDate = project.startDate;
            this.projects[ix].active = project.active;
            this.projects[ix].hexColor = project.hexColor;

            if (removeInactive && !project.active) {
                this.projects.splice(ix, 1);
                this.removeDeliverablesForProject(project.id);
            }
        },

        projectDeleted: function (project) {
            let ix = this.projects.findIndex((p) => p.id === project.id);
            this.projects.splice(ix, 1);
            this.removeDeliverablesForProject(project.id);
        },

        removeDeliverablesForProject: function (projectId) {
            let deliverables = this.deliverables.filter((d) => d.project.id !== projectId);
            this.deliverables.splice(0, this.deliverables.length);
            this.deliverables.push(...deliverables);
        },

        getUser: function(id){
            if(id > 0){
                return this.$store.getters.getUserById(id);
            }else if( id < 0){
                return this.$store.getters.getContactByClientPortalId(id);
            }else{
                return null;
            }
        },

        sortByName: function (a, b) {
            if(a.name && b.name){
                return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})
            }else if(a.name){
                return 1;
            }else if(b.name){
                return -1;
            }else{
                return 0;
            }
        },

        sortByProject: function(a,b){
            let sortA = (a.project && a.project.name) ? a.project.name : 'zzzzzzzzzzzzzzzzzzzzzzzzzz';
            let sortB = (b.project && b.project.name) ? b.project.name : 'zzzzzzzzzzzzzzzzzzzzzzzzzz';
            return sortA.localeCompare(sortB, undefined, {sensitivity: 'base'});
        },

        sortByClient: function(a,b){
            let sortA = (a.client && a.client.name) ? a.client.name : 'zzzzzzzzzzzzzzzzzzzzzzzzzz';
            let sortB = (b.client && b.client.name) ? b.client.name : 'zzzzzzzzzzzzzzzzzzzzzzzzzz';
            return sortA.localeCompare(sortB, undefined, {sensitivity: 'base'});
        },

        sortByStatus: function(a,b){
            let ai = this.statusList.findIndex((c) => c.id === a.statusId);
            let bi = this.statusList.findIndex((c) => c.id === b.statusId);

            if (ai < bi) {
                return -1;
            } else if (ai > bi) {
                return 1;
            } else {
                return 0;
            }
        },

        sortByPriority: function(a,b){
            return this.taskPriorityValues[a.taskPriority] - this.taskPriorityValues[b.taskPriority];
        },

        sortByRank: function(a,b){
            return a.priority - b.priority;
        },

        sortByKanban: function(a,b){
            return a.kanbanSort - b.kanbanSort;
        },

        sortByAssigned: function(a,b){
            let aUser = this.getUser(a.assignedTo);
            let bUser = this.getUser(b.assignedTo);

            let aName = aUser ? a.firstName + a.lastName : 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';
            let bName = bUser ? b.firstName + b.lastName : 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz';

            if(aName && bName){
                return aName.localeCompare(bName, undefined, {sensitivity: 'base'});
            }else if(aName){
                return 1;
            }else if(bName){
                return -1;
            }else{
                return 0;
            }
        },

        sortByDueDate: function(a,b){
            let aDate = a.dueDate ? HDateTime.fromISO(a.dueDate) : HDateTime.now().plus({years:1000}).startOf('day');
            let bDate = b.dueDate ? HDateTime.fromISO(b.dueDate) : HDateTime.now().plus({years:1000}).startOf('day');
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        },

        sortByStartDate: function(a,b){
            let aDate = a.startDate ? HDateTime.fromISO(a.startDate) : HDateTime.now().plus({years:1000}).startOf('day');
            let bDate = b.startDate ? HDateTime.fromISO(b.startDate) : HDateTime.now().plus({years:1000}).startOf('day');
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        },

        sortEnd: function(a,b){
            let aInitialWorkflowComplete = a.initialWorkflowComplete;
            let bInitialWorkflowComplete = b.initialWorkflowComplete;

            if(this.newInlineItems.has(a.id)){
                aInitialWorkflowComplete = false;
            }

            if(this.newInlineItems.has(b.id)){
                bInitialWorkflowComplete = false;
            }

            if(!aInitialWorkflowComplete && !bInitialWorkflowComplete){
                return a.created.localeCompare(b.created);
            }else if(aInitialWorkflowComplete === bInitialWorkflowComplete){
                return 0;
            }else if(!aInitialWorkflowComplete){
                return 1;
            }else if(!bInitialWorkflowComplete){
                return -1;
            }
        }
    }
}
