<template>
	<v-row justify="center" align="center">
		<div class="text-center ma-12">
			<v-progress-circular :indeterminate="true" size="200" color="light-blue"></v-progress-circular>
		</div>
	</v-row>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';

	export default {
		name: 'Loader',


		mounted() {
			//console.log('In Loader:' + store.state.origRequestedUri);
			AuthService.refreshToken()
					.then((res) => {
						this.handleLoginSuccess(res);
					})
					.catch((err) => {
						console.log('Failed to fetch refresh token:' + err);
						this.handleLoginFailure();
					});
		},

		methods: {
			handleLoginSuccess(result) {
				if(this.$store.state.origRequestedUri === '/welcome'){
					this.$store.state.newUser = true;
				}

				this.$store.commit('logIn', result);
				this.$store.commit('setInitialized', true);

				AuthService.getProfile()
					.then((res) => {
						this.$track.identify(res);
						this.$store.dispatch('setLoggedInUser', res);
						if(this.$store.state.origRequestedUri) {
							this.$router.push(this.$store.state.origRequestedUri).catch((err) => console.log(err));
						}
					})
					.catch((err) => {
						console.log('Something went wrong fetching the user profile.' + err);
						this.$store.commit('logOut');
						this.$store.commit('setInitialized', true);
						this.$router.push('/login').catch((err) => console.log(err));
					});
			},

			handleLoginFailure() {
				this.$store.commit('setInitialized', true);
				this.$router.push('/login');
			},
		},

	};
</script>

<style scoped></style>
