<template>
	<div class="pa-2" style="background-color: var(--v-white-base); max-width: 220px">
		<div class="row-format gap-3 flex-wrap">
			<div
				:class="`page-link ${currentPage === page ? 'page-link-active' : ''}`"
				v-for="page in pages"
				:key="page"
				@click.stop="setView(page)"
			>
				<div>{{ page }}</div>
			</div>
		</div>

		<div class="row-format py-2 gap-2" v-if="currentPage === 'Shift dates'">
			<v-text-field type="numeric" v-model="increment" hide-details dense outlined></v-text-field>
			<v-select v-model="period" :items="['Days', 'Weeks', 'Months']" hide-details dense outlined></v-select>
		</div>

		<div class="row-format py-2 gap-2" v-else>
			<date-selector :standard="true" :date="currentDate" :show-tip="false" @change="currentDate = $event"></date-selector>
		</div>

		<v-btn class="mt-2" block small color="primary" @click="updateDate">Update</v-btn>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	import {DateTime} from 'luxon';

	export default {
		name: 'BulkDate',

		props: [],

		components: { DateSelector },

		data: function() {
			return {
				pages: ['Shift dates', 'Exact date'],
				increment: 1,
				period: 'Days',
				currentDate: DateTime.now().toISODate(),
				currentPage: 'Shift dates'
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setView: function(page) {
				this.currentPage = page;
			},

			updateDate: function(){
				if(this.currentPage === 'Shift dates'){
					this.$emit('shift-date',{
						increment: this.increment,
						period: this.period.toLowerCase()
					})
				}else{
					this.$emit('set-date',{
						date: this.currentDate
					})
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
