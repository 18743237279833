<template>
	<div>
		<div class="row-format align-center mb-4">
			<v-btn class="ml-auto super-action" @click="createNewWorkspace()"><v-icon size="20">add</v-icon> Add workspace</v-btn>

		</div>
		<v-data-table :items="workspaces" :headers="headers" :hide-default-footer="true" :items-per-page="-1">
			<template v-slot:item.account.accountId="{ item }">
				<span>{{item.account.accountId}}</span>
			</template>
			<template v-slot:item.logo="{item}">
				<v-avatar size="32">
					<v-img :src="item.account.accountLogo"></v-img>
				</v-avatar>
			</template>
			<template v-slot:item.accountName="{ item }">
				<span>{{item.account.accountName}}</span>
			</template>
			<template v-slot:item.userType="{ item }">
				<span style="text-transform: capitalize">{{item.userType.replace("_"," ").toLowerCase()}}</span>
			</template>
			<template v-slot:item.account.subscriptionType="{ item }">
				<span>{{getSubscriptionType(item.account)}}</span>
			</template>
			<template v-slot:item.action="{ item }">
				<div v-if="canDoAction">
					<v-btn outlined v-if="item.userType === 'OWNER'" color="warning" @click="confirmDeleteWorkspace(item.account)">Delete workspace</v-btn>
					<v-btn outlined v-else color="primary" @click="confirmLeaveWorkspace(item.account)">Leave workspace</v-btn>
				</div>
				<div v-else>
					--
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import AccountService from '@/modules/account/AccountService';
	import ConfirmModal from "@/components/ConfirmModal";
	import NewWorkspace from "@/modules/account/NewWorkspace";

	export default {
		name: 'DeleteAccount',

		props: [],

		components: {},

		data: function() {
			return {
				workspaces: [],
				accountService: new AccountService(),
				headers: [
					{ text: 'Id', value: 'account.accountId' },
					{ text: 'Brand', value: 'logo', sortable: false},
					{ text: 'Name', value: 'account.accountName'},
					{ text: 'Access type',  value: 'userType'},
					{ text: 'Subscription',  value: 'account.subscriptionType'},
					{ text: '', value: 'action', align: 'right' },
				],
			};
		},

		mounted() {
			this.getWorkspaces();
		},

		beforeDestroy() {},

		methods: {
			checkActiveSubscription: function(workspace){
				if(workspace.subscriptionType && (workspace.subscriptionType === 'PAID' || workspace.subscriptionType === 'TEAM' || workspace.subscriptionType === 'STARTER')){
					return true;
				}else{
					return false;
				}
			},

			getSubscriptionType: function(workspace){
				if(workspace.subscriptionType === 'PAID'){
					return 'Pro'
				}else if(workspace.subscriptionType === 'TEAM'){
					return 'Teams'
				}else if(workspace.subscriptionType === 'STARTER'){
					return 'Starter'
				}else{
					return 'None'
				}
			},

			createNewWorkspace: function(){
				let ids = this.workspaces.map(w => w.account.accountId);

				this.$store.state.globalModalController.openModal(NewWorkspace).then((businessInfo) => {
					if(businessInfo){
						this.$store.commit('startLoading');
						AuthService.provisionWorkspace(businessInfo.businessName).then((authResult) => {
							this.handleRefreshSuccess(authResult);
							AuthService.listWorkspaces().then((workspaceResult) => {
								this.workspaces.splice(0, this.workspaces.length);
								this.workspaces.push(...workspaceResult);
								let newIds = this.workspaces.map(w => w.account.accountId);
								let newAccountId = newIds.filter(i => !ids.includes(i))[0];
								let newWorkspace = this.workspaces.find(w => w.account.accountId === newAccountId);
								let podUrl = newWorkspace.account.pod.podUrl + 'api/v1/' + newAccountId;
								this.updateAccountName(podUrl,businessInfo)
							}).finally(() => this.$store.commit('stopLoading'));
						})
					}
				})
			},

			updateAccountName: function (podUrl,accountInfo) {
				let account = {}
				account.accountName = accountInfo.businessName;
				account.currency = accountInfo.currency;
				account.country = accountInfo.country;
				account.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				return this.accountService.updateAccountByPodUrl(podUrl,account);
			},

			confirmLeaveWorkspace: function(workspace){
				let binding = {
					headingText: 'Leave workspace?',
					bodyText:
							`Please confirm you want to leave the "${workspace.accountName}" workspace.  You will need to be re-invited if you require access again in the future.`
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if(res){
						this.$store.commit('startLoading');
						AuthService.deleteWorkspace(workspace.accountId).then((authResult) => {
							this.handleRefreshSuccess(authResult);
							this.getWorkspaces();
						}).finally(() => this.$store.commit('stopLoading'))
					}
				});
			},

			confirmDeleteWorkspace: function (workspace) {
				let activeSubscription = this.checkActiveSubscription(workspace);

				if(activeSubscription){
					let binding = {
						headingText: 'Cannot delete workspace?',
						bodyText: `This workspace cannot be deleted because it has an active subscription.  Please cancel your subscription prior do deleting your workspace`,
						hideNo: true,
						yesText: 'OK'
					};
					this.$store.state.globalModalController.openModal(ConfirmModal,binding);
				}else {
					console.log('deleting workspace',workspace);
					let binding = {
						headingText: 'Delete workspace?',
						bodyText:
								`Please confirm you want to remove the "${workspace.accountName}" workspace and all associated data by entering the workspace name in the field below.`,
						confirmText: workspace.accountName,
					};
					let binding2 = {
						headingText: `Are you really sure, ${this.$store.state.loggedInUser.firstName}?`,
						bodyText:
								`This operation cannot be undone and will result in permanent data removal from all Moxie servers.`,
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
						if(res) {
							this.$store.state.globalModalController.openModal(ConfirmModal, binding2).then((res2) => {
								if(res2){
									this.$store.commit('startLoading');
									this.accountService.deleteAccountById(workspace.accountId).then(() => {
										AuthService.deleteWorkspace(workspace.accountId).then((authResult) => {
											this.handleRefreshSuccess(authResult);
											this.getWorkspaces();
										}).finally(() => this.$store.commit('stopLoading'));
									})
								}
							})
						}
					});
				}
			},

			getWorkspaces: function() {
				AuthService.listWorkspaces().then((res) => {
					this.workspaces.splice(0, this.workspaces.length);
					this.workspaces.push(...res);
				});
			},

			handleRefreshSuccess(result) {
				this.$store.commit('logIn', result);

				AuthService.getProfile()
						.then((res) => {
							this.$store.dispatch('setLoggedInUser', res);
						})
						.catch((err) => {
							console.log('Something went wrong fetching the user profile.' + err);
							this.forceLogoutOnFailure();
						});
			},

			forceLogoutOnFailure() {
				this.$store.commit('logOut');
				this.$router.push('/login');
			},
		},

		computed: {
			canDoAction: function(){
				return this.workspaces.length > 1
			}
		},
	};
</script>

<style scoped lang="scss"></style>
