<template>
	<div class="project-list">
		<v-data-table
			:headers="headers"
			:items="filteredProjects"
			sort-by="nextDueDate"
			:sort-desc="true"
			:hide-default-footer="true"
			:fixed-header="true"
      :items-per-page="-1"
			@click:row="routeToProject"
			class="h-dense pointer" style="border: none!important; overflow-y: auto; max-height: 300px"
		>
			<template v-slot:item.name="{ item }">
				<span v-if="item.name" class="brand-medium truncate" @click.stop="routeToProject(item,$event)">{{ item.name }}</span>
			</template>

			<template v-slot:item.status="{ item }">
				<div style="height: 16px">
					<deliverable-by-status :project="item" :deliverables="item.deliverables" @click.stop="routeToProject(item,$event)"></deliverable-by-status>
				</div>
			</template>

			<template v-slot:item.startDate="{ item }">
				<span v-if="item.startDate" @click.stop="routeToProject(item,$event)">{{item.startDate.toLocaleString({month: 'short', day: 'numeric'})}}</span>
				<span v-else @click.stop="routeToProject(item,$event)"> -- </span>
			</template>

			<template v-slot:item.dueDate="{ item }">
				<span v-if="item.dueDate" @click.stop="routeToProject(item,$event)">{{item.dueDate.toLocaleString({month: 'short', day: 'numeric'})}}</span>
				<span v-else @click.stop="routeToProject(item,$event)"> -- </span>
			</template>

			<template v-slot:item.nextDue="{ item }">
				<span v-if="item.nextDue" @click.stop="routeToProject(item,$event)">{{item.nextDue.toLocaleString({month: 'short', day: 'numeric'})}}</span>
				<span v-else @click.stop="routeToProject(item,$event)"> -- </span>
			</template>

			<template v-slot:item.fees="{ item }">
				<span >{{fees(item)}}</span>
			</template>

			<template v-slot:item.invoiced="{ item }">
				<span >{{$formatters.dollars(item.paymentHistory.reduce((sum, { localAmount }) => sum + localAmount, 0),false,true,client.currency)}}</span>
			</template>

			<template v-slot:no-data>
				<div class="col col-12 text-left brand-medium">No projects found</div>
			</template>
		</v-data-table>

		<div class="row-format py-2 pointer" style="border-top: 1px solid var(--v-gray_30-base)" v-if="completeProjects.length && !showComplete" @click="showComplete = true">
			<div class="col col-12 font-12 text-left">+ {{ completeProjects.length }} completed projects</div>
		</div>
		<div class="row-format footer-row font-12 pointer" @click="createNewProject()" style="border-top: 1px solid var(--v-gray_30-base)">
			<div class="col col-10 text-left row-format align-center">
				<div class="font-gray_70">Create new</div>
				<h-icon3
					style="margin-left: 4px; margin-right: 2px; padding-bottom: 3px"
					name="projects"
					size="16"
					color="var(--v-secondary-base)"
				></h-icon3>
				<div>Project</div>
			</div>
			<div class="col col-2 text-right"><v-icon size="20" color="secondary">add</v-icon></div>
		</div>
	</div>
</template>

<script>
	import HIcon3 from '@/components/HIcon3';
	import DateTime from '@/modules/utils/HDateTime';
	import DeliverableByStatus from '@/modules/projects/detail/DeliverableByStatus';
	import NewProject from '@/modules/projects/NewProject';
	export default {
		name: 'ProjectList',

		props: ['client', 'projects'],

		components: { DeliverableByStatus, HIcon3 },

		data: function() {
			return {
				showComplete: false,
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			createNewProject: function() {
				let binding = {
					client: this.client,
				};
				this.$store.state.globalModalController.openModal(NewProject, binding).then((res) => {
					if (res) {
						setTimeout(() => this.$router.push(`/project/${res.id}?edit=true`), 500);
					}
				});
			},

			routeToProject: function(project,event){
				if(event && (event.ctrlKey || event.metaKey)){
					window.open(`/project/${project.id}`,'_blank');
				}else{
					this.$router.push(`/project/${project.id}`)
				}
			},

			nextDue: function(project) {
				let d = [...project.deliverables];

				let incomplete = [];
				let statusList = this.$store.getters.getDeliverableStatusList(project.projectTypeId);

				for (let i = 0; i < statusList.length; i++) {
					let s = statusList[i];
					if (s.complete) {
						break;
					} else {
						incomplete.push(s.id);
					}
				}

				d = d.filter((d) => d.dueDate && incomplete.includes(d.statusId));

				if (!d.length) {
					return null;
				}

				d.sort(function(a, b) {
					if (a.dueDate > b.dueDate) {
						return 1;
					} else if (b.dueDate > a.dueDate) {
						return -1;
					} else {
						return 0;
					}
				});

				return DateTime.fromISO(d[0].dueDate);
			},

			startDate: function(project) {
				if (project.startDate) {
					return DateTime.fromISO(project.startDate);
				} else {
					return null;
				}
			},

			dueDate: function(project) {
				if (project.dueDate) {
					return DateTime.fromISO(project.dueDate)
				} else {
					return null;
				}
			},

			fees: function(project) {
				if (project.feeSchedule) {
					if (project.feeSchedule.feeType === 'Hourly') {
						return this.$formatters.dollars(project.feeSchedule.amount, false, true, this.client.currency) + '/hr';
					} else if (project.feeSchedule.feeType === 'Retainer') {
						if (project.feeSchedule.retainerSchedule) {
							return (
									this.$formatters.dollars(project.feeSchedule.amount, false, true, this.client.currency) +
									'/' +
									this.translateSchedule(project.feeSchedule.retainerSchedule)
							);
						} else {
							return '--';
						}
					} else if (project.feeSchedule.feeType === 'Per Item') {
						return 'Fee per task';
					} else if (project.feeSchedule.feeType === 'Fixed Price') {
						return this.$formatters.dollars(project.feeSchedule.amount, false, true, this.client.currency);
					} else {
						return '--';
					}
				} else {
					return '--';
				}
			},

			translateSchedule(schedule){
				switch(schedule){
					case "Weekly": return "wk";
					case "Bi-Weekly": return "2-wk";
					case "Monthly": return "mo";
					case "Quarterly": return "qtr";
					case "B-Annually": return "6-mo";
					case "Annually": return "yr";
					default: return "--"
				}
			},
		},

		computed: {
			headers: function(){
				if(this.$store.getters.hasInvoiceAccess){
					return [
						{ text: 'Name', value: 'name' },
						{ text: 'Fees', value: 'fees' },
						{ text: 'Invoiced', value: 'invoiced' },
						{ text: 'Status', value: 'status', sortable: false },
						{ text: 'Start date', value: 'startDate' },
						{ text: 'Due date', value: 'dueDate' },
						{ text: 'Next due', value: 'nextDue' },
					]
				}else{
					return [
						{ text: 'Name', value: 'name' },
						{ text: 'Status', value: 'status', sortable: false },
						{ text: 'Start date', value: 'startDate' },
						{ text: 'Due date', value: 'dueDate' },
						{ text: 'Next due', value: 'nextDue' },
					]
				}
			},

			completeProjects: function() {
				return this.projects.filter((p) => !p.active);
			},

			filteredProjects: function() {
				let result;
				if (this.showComplete) {
					result = this.projects;
				} else {
					result = this.projects.filter((p) => p.active);
				}

				result.forEach(p => {
					p.nextDue = this.nextDue(p);
					p.dueDate = this.dueDate(p);
					p.startDate = this.startDate(p);
				})

				result.sort((a, b) => {
					if (a.active === b.active) {
						return a.name.localeCompare(b.name);
					} else if (a.active) {
						return -1;
					} else if (b.active) {
						return 1;
					} else {
						return 0;
					}
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.project-list {
		border: 1px solid var(--v-gray_30-base);
		background-color: var(--v-white-base);
		border-radius: 4px;
		font-size: 14px;
		overflow: hidden;
	}

	.content-row {
		border-bottom: 1px solid var(--v-gray_30-base);
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.header-row {
		border-bottom: 1px solid var(--v-gray_30-base);
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.footer-row {
		padding-top: 12px;
		padding-bottom: 12px;
	}
</style>
