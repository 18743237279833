<template>
	<div>
		<v-menu
			:nudge-top="28" min-width="200" max-width="200"
			bottom
			right
			rounded
			offset-overflow
			offset-y
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-icon size="18" color="gray_70" v-on="on" class="material-symbols-rounded">settings</v-icon>
			</template>

			<div class="column-format">
				<div class="filter-option-box">
					<div class="text-left brand-medium">Visible fields</div>
					<v-checkbox @change="updated" :disabled="field.value === 'name'" v-model="selected" :value="field.value" v-for="field in allFields" :key="field.value" :label="field.label" hide-details dense></v-checkbox>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'VisibleFields',

		props: ['visibleFields','allFields'],

		data() {
			return {
				selected: [],
				showMenu: false,
			};
		},

		mounted() {
			this.selected = [... this.visibleFields];
		},

		methods: {
			updated: function() {
				this.$emit('updated', this.selected);
			},
		},

		watch: {
			visibleFields: {
				deep: true,
				handler: function(){
					this.selected.splice(0,this.selected.length);
					this.selected.push(... this.visibleFields);
				}
			}
		},

		computed: {
			isFilterActive: function() {
				return this.filter.sort && this.filter.sort !== 'Status'
			},

			filterLabel: function() {
				if (!this.filter.sort || this.filter.sort === 'Status') {
					return 'Sort';
				} else {
					return 'Sort by ' + this.filter.sort.toLowerCase();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
