var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"items":_vm.emailBoxes,"headers":_vm.headers,"hide-default-footer":true,"items-per-page":-1},on:{"click:row":function($event){return _vm.editEmailBox($event)}},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.providerInfo.icon))])]}},{key:"item.sharedInbox",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.sharedInbox ? 'Yes' : 'No'))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('span',[_vm._v(_vm._s(item.status))]):_c('span',[_vm._v("DISABLED")]),(item.status === 'RUNNING' && item.initialSync)?_c('span',{staticClass:"font-12 font-italic"},[_c('br'),_vm._v("Initial mail sync can take up to 2 hours. Please check back soon.")]):_vm._e()]}},{key:"item.statusTime",fn:function(ref){
var item = ref.item;
return [(item.statusTime)?_c('span',[_vm._v(_vm._s(_vm.HDateTime.fromISO(item.statusTime).toLocaleString(_vm.HDateTime.DATETIME_SHORT)))]):_c('span',[_vm._v(" -- ")])]}},{key:"item.nextRunTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.HDateTime.fromISO(item.nextRunTime).toLocaleString(_vm.HDateTime.DATETIME_SHORT))+" ")]}},{key:"header.action",fn:function(ref){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getEmailBoxes()}}},[_c('v-icon',[_vm._v("refresh")])],1)]}}])}),_c('div',{staticClass:"text-left row-format align-center mr-4 mt-4"},[(_vm.emailBoxes.length < 6)?_c('v-btn',{attrs:{"color":"primary-action"},on:{"click":function($event){return _vm.addEmailBox()}}},[_vm._v("+ Add new email account")]):_vm._e(),(_vm.lastUpdated)?_c('div',{staticClass:"ml-auto font-12 font-gray_70"},[_vm._v("Last updated: "+_vm._s(_vm.lastUpdated.toLocaleString(_vm.HDateTime.DATETIME_SHORT)))]):_vm._e()],1),_c('div',{staticClass:"mt-10 pt-4",staticStyle:{"border-top":"1px solid var(--v-gray_30-base)"}},[_c('sync-domains-config')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }