<template>
	<div class="pb-6">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Client management' }]">
			Contacts
		</page-header>

		<div class="column-format fill-height">
			<div class="pb-4 row-format align-center flex-wrap gap-2">
				<v-text-field
					outlined
					hide-details
					dense
					v-model="search"
					placeholder="Search..."
					style="max-width: 200px; background-color: var(--v-white-base)"
					class="standard-input"
				>
					<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
				</v-text-field>

				<custom-field-filter
					:fields="allContactFields"
					:filter="customFieldFilter"
					@changed="saveCustomFieldFilters($event)"
				></custom-field-filter>

				<div class="ml-auto row-format align-center gap-3">
					<user-list-settings v-if="$store.state.userListSettings" v-model="$store.state.userListSettings.contactList" :fields="fields" @input="$store.dispatch('saveUserListSettings')"></user-list-settings>

					<div class="pointer row-format align-center" v-tippy="{ content: 'List' }" @click="setView('LIST')">
						<span
							class="material-symbols-rounded"
							:style="`font-size: 24px; color: var(--v-${currentView === 'LIST' ? 'primary' : 'gray_60'}-base)`"
							>list</span
						>
					</div>
					<div class="pointer row-format align-center" v-tippy="{ content: 'Card' }" @click="setView('CARD')">
						<span
							class="material-symbols-rounded"
							:style="`font-size: 24px; color: var(--v-${currentView === 'CARD' ? 'primary' : 'gray_60'}-base)`"
							>grid_on</span
						>
					</div>
					<v-btn class="super-action" @click="addNew()"><v-icon size="20">add</v-icon> Add contact</v-btn>
				</div>
			</div>
			<div>
				<div class="row-format flex-wrap gap-3" v-if="currentView === 'CARD'">
					<contact-card
						v-for="contact in filteredContacts"
						:key="contact.id"
						:input="contact"
						:visible-fields="headers.map(h => h.value)"
						:custom-fields="allContactFields"
						@edit="openContact(contact)"
						@delete="confirmDelete(contact)"
						@send-email="sendEmail(contact)"
						@make-call="makeCall(contact)"
						@send-text="sendText(contact)"
						@book-meeting="bookMeeting(contact)"
					></contact-card>
				</div>
				<div
					v-else
					style="background-color: var(--v-white-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px"
				>
					<v-data-table
						:headers="headers"
						:items="filteredContacts"
						:hide-default-footer="true"
						@click:row="openContact($event)"
						class="primary-table"
						:items-per-page="-1"
						style="cursor: pointer;"
					>
						<template v-slot:item.name="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
						<template v-slot:item.client.name="{ item }">
							<div class="row-format align-center" v-if="item.client">
								<client-avatar :client="item.client" :x-small="true" :disable-click="false"></client-avatar>
								<div>{{ item.client.name }}</div>
							</div>
						</template>
						<template v-slot:item.action="{ item }">
							<v-menu bottom left rounded nudge-bottom="24" :close-on-content-click="true" :close-on-click="true">
								<template v-slot:activator="scope">
									<div class="ml-auto">
										<v-btn icon class="menu-activator" v-on="scope.on">
											<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
										</v-btn>
									</div>
								</template>

								<div class="more-menu">
									<div class="more-menu-item" @click="openContact(item)">Edit</div>
									<div class="more-menu-item" @click="sendEmail(item)" v-if="item.email">Send Email</div>
									<div class="more-menu-item" @click="sendText(item)" v-if="item.phone">Send Text</div>
									<div class="more-menu-item" @click="makeCall(item)" v-if="item.phone">Make Call</div>
									<div class="more-menu-item" @click="bookMeeting(item)" v-if="item.email">Book meeting</div>
									<div class="more-menu-item" @click="confirmDelete(item)">Delete</div>
								</div>
							</v-menu>
						</template>
						<template v-slot:no-data>
							<v-btn text @click="addNew">No contact records</v-btn>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContactService from '@/modules/clients/contacts/ContactService';
	import ContactCard from '@/modules/clients/contacts/ContactCard';
	import ContactEdit from '@/modules/clients/contacts/ContactEdit';
	import PageHeader from '@/components/PageHeader';
	import ClientAvatar from '@/components/ClientAvatar';
	import ConfirmModal from '@/components/ConfirmModal';
	import NewEmail from '@/modules/communicator/inbox/email/NewEmail';
	import BookMeeting from '@/modules/meetings/BookMeeting';
	import CustomFieldFilter from '@/components/CustomFieldFilter';
	import CustomFieldFilterMixin from '@/components/CustomFieldFilterMixin';
	import UserListSettings from "@/components/UserListSettings";

	export default {
		name: 'ContactList',

		props: [],

		mixins: [CustomFieldFilterMixin],

		components: {UserListSettings, ClientAvatar, ContactCard, PageHeader, CustomFieldFilter },

		data: function() {
			return {
				contactService: new ContactService(),
				contacts: [],
				search: null,
				customFieldFilter: null,
				showMenu: false,
				currentView: 'LIST',
			};
		},

		mounted() {
			this.getContactList();
			this.getCurrentView();
			this.getCustomFieldFilters();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$on(
				this.$store.getters.getMessageChannelPrefix + '.contact-update',
				this.updateContactHandler
			);
			this.$store.state.eventBus.$on(
				this.$store.getters.getMessageChannelPrefix + '.contact-delete',
				this.deleteContactHandler
			);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$off(
				this.$store.getters.getMessageChannelPrefix + '.contact-update',
				this.updateContactHandler
			);
			this.$store.state.eventBus.$off(
				this.$store.getters.getMessageChannelPrefix + '.contact-delete',
				this.deleteContactHandler
			);
		},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			handleAccountChange: function() {
				this.getContactList();
				this.getSelectedFilters();
			},

			sendText: function(contact) {
				this.$store.state.eventBus.$emit('communicator-send-text', contact);
			},

			makeCall: function(contact) {
				this.$store.state.eventBus.$emit('communicator-place-call', contact.phone);
			},

			bookMeeting: function(contact) {
				this.$store.state.globalModalController.openModal(BookMeeting, { contact: contact });
			},

			sendEmail: function(contact) {
				let toList = [contact.email];
				this.$store.state.globalModalController
					.openModal(NewEmail, { toList: toList, clientId: contact.client?.id }, true, true, false, true)
					.then(() => {});
			},

			setView: function(view) {
				this.currentView = view;
				localStorage.setItem(this.currentViewKey, view);
			},

			getCurrentView: function() {
				let currentView = localStorage.getItem(this.currentViewKey);
				if (currentView) {
					this.currentView = currentView;
				}
			},

			getCustomFieldFilters() {
				try {
					let customFieldFilter = JSON.parse(localStorage.getItem('CUSTOM_' + this.filterStateKey));
					if (customFieldFilter) {
						this.customFieldFilter = customFieldFilter;
					}
				} catch (err) {
					console.log(err);
				}
			},

			saveCustomFieldFilters: function(value) {
				this.customFieldFilter = value;
				try {
					localStorage.setItem('CUSTOM_' + this.filterStateKey, JSON.stringify(this.customFieldFilter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			updateContactHandler: function(event) {
				let contact = event.message;
				let ix = this.contacts.findIndex((c) => c.id === contact.id);
				if (ix > -1) {
					this.contacts.splice(ix, 1, contact);
				} else {
					this.contacts.push(contact);
				}
				this.contacts.sort(this.sortList);
			},

			deleteContactHandler: function(event) {
				let deletedContact = event.message;
				let ix = this.contacts.findIndex((contact) => contact.id === deletedContact.id);
				if (ix > -1) {
					this.contacts.splice(ix, 1);
				}
			},

			getContactList: function() {
				this.contactService.getContactList().then((res) => {
					this.contacts.splice(0, this.contacts.length);
					this.contacts.push(...res.data);
					this.contacts.sort(this.sortList);
				});
			},

			sortList: function(a, b) {
				let aSort = this.getSortString(a);
				let bSort = this.getSortString(b);
				return aSort.localeCompare(bSort);
			},

			getSortString: function(contact) {
				if (contact.firstName) {
					return contact.firstName;
				} else if (contact.lastName) {
					return contact.lastName;
				} else if (contact.email) {
					return contact.email;
				} else {
					return '';
				}
			},

			addNew: function() {
				this.$store.state.globalModalController.openModal(ContactEdit, { id: null }).then((res) => {
					if (res) {
						let ix = this.contacts.findIndex((c) => c.id === res.id);
						if (ix > -1) {
							this.contacts.splice(ix, 1, res);
						} else {
							this.contacts.push(res);
						}
						this.contacts.sort(this.sortList);
					}
				});
			},

			openContact: function(contact) {
				this.$store.state.globalModalController.openModal(ContactEdit, { id: contact.id }).then((res) => {
					if (res) {
						let ix = this.contacts.findIndex((c) => c.id === res.id);
						if (res.deleted) {
							this.contacts.splice(ix, 1);
						} else {
							this.contacts.splice(ix, 1, res);
						}
						this.contacts.sort(this.sortList);
					}
				});
			},

			confirmDelete: function(contact) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this contact?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.contactService.deleteContact(contact.id).then(() => {
							this.$store.state.eventBus.$emit('contact-delete', { message: contact });
							let ix = this.contacts.findIndex((c) => c.id === res.id);
							this.contacts.splice(ix, 1);
						});
					}
				});
			},

			client: function(contact) {
				if (contact.clientId) {
					return this.$store.state.clientCache.find((c) => c.id === contact.clientId);
				} else {
					return null;
				}
			},

			isInCustomFieldFilter: function(contact) {
				if (!this.customFieldFilter) {
					return true;
				} else {
					return this._isInCustomFieldFilters(contact.customValues, this.customFieldFilter);
				}
			},
		},

		computed: {
			filterStateKey: function() {
				return 'CONTACT_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			allContactFields: function() {
				if(this.$store.state.contactFields.fields) {
					let result = [...this.$store.state.contactFields.fields];
					const unique = [...new Map(result.map((item) => [item.mappingKey, item])).values()];
					unique.sort((a, b) => a.name.localeCompare(b.name));
					return unique;
				}else{
					return [];
				}
			},

			fields: function(){
				let result = [
					{ text: 'Client', value: 'client.name' },
					{ text: 'First', value: 'firstName', required: true },
					{ text: 'Last', value: 'lastName', required: true},
					{ text: 'Role', value: 'role'},
					{ text: 'Email', value: 'email' },
					{ text: 'Phone', value: 'phone' },
				];

				this.allContactFields.forEach((c) => {
					result.push({
						text: c.name,
						value: 'Custom.' + c.mappingKey,
					});
				});
				return result;
			},

			headers: function() {
				let result = [... this.fields];

				result.push({
					text: '',
					value: 'action',
					align: 'right',
					required: true,
				});

				if(this.$store.state.userListSettings) {
					return result.filter(r => this.$store.state.userListSettings.contactList.includes(r.value) || r.required);
				}else{
					return result;
				}
			},

			currentViewKey: function() {
				return (
					'CONTACT_LIST_CURRENT_VIEW_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId
				);
			},

			filteredContacts: function() {
				let result;

				result = this.contacts
					.filter((c) => {
						if (this.search) {
							let search = this.search.toLowerCase();
							let numeric = this.search.replace(/\D/g, '');
							let fullName = c.firstName + ' ' + c.lastName;

							if (fullName.toLowerCase().includes(search)) {
								return true;
							} else if (c.email && c.email.toLowerCase().includes(search)) {
								return true;
							} else if (c.phone && numeric && c.phone.toLowerCase().includes(numeric)) {
								return true;
							}

							let customValues = c.customValues;

							for (let j = 0; j < customValues.length; j++) {
								let cv = customValues[j];
								if (
									cv.value &&
									cv.value
										.toString()
										.toLowerCase()
										.includes(this.search.toLowerCase())
								) {
									return true;
								}
							}

							return false;
						} else {
							return true;
						}
					})
					.filter((contact) => this.isInCustomFieldFilter(contact));

				result.forEach((c) => {
					c.client = this.client(c);
					c.customValues.forEach((v) => {
						c['Custom.' + v.mappingKey] = this.$formatters.customFieldFormat(v.value);
					});
				});

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	input::placeholder {
		color: var(--v-gray_60-base);
	}
	textarea:focus,
	input:focus {
		outline: 0;
	}
</style>
