<template>
	<div :id="`comment-wrapper-${uuid}`" style="position: relative">
		<v-textarea
			:id="`comment-${uuid}`"
			dense
			hide-details
			rows="1"
			auto-grow
			outlined
			:placeholder="$t('projects.comment-placeholder')"
			v-model="newComment"
			:spellcheck="true"
			@focus="focusIn=true"
			@blur="focusIn=false"
			@keydown="handleKeydown"
			:style="`font-size: 14px; ${privateComment ? 'background-color: var(--v-yellow_10-base);' : ''}`"
		>
			<template v-slot:append>
				<div class="row-format gap-2 align-center">
					<v-icon v-tippy :content="privateComment ? 'Private comment' : 'Public comment'" class="pointer material-symbols-rounded" color="gray_70" @click="privateComment = !privateComment">{{privateComment ? 'visibility_off' : 'visibility'}}</v-icon>
					<v-icon
						v-if="!commentToEdit"
						color="gray_70"
						@click.native="newComment && newComment.length ? sendComment(): null"
						class="pointer material-symbols-rounded"
						>comment
					</v-icon>
				</div>
			</template>
		</v-textarea>
		<div v-if="commentToEdit" class="row-format mt-2">
			<v-btn class="secondary-action mr-1" @click="cancelEdit()">
				{{ $t('global.cancel') }}
			</v-btn>
			<v-btn class="primary-action ml-1" style="min-width: 100px" @click="saveEdit()">
				{{ $t('global.save') }}
			</v-btn>

		</div>
	</div>
</template>

<script>
	import Tribute from 'tributejs';
	import { v4 as uuid4 } from 'uuid';

	export default {
		name: 'TagUserField',

		props: ['userList', 'commentToEdit'],

		data: function() {
			return {
				newComment: '',
				uuid: uuid4(),
				tribute: null,
				privateComment: false,
				focusIn: false,
				// tributeActive: false,
			};
		},

		mounted() {
			if (this.commentToEdit) {
				this.newComment = this.commentToEdit.comment;
				this.privateComment = this.commentToEdit.privateComment;
			}

			this.tribute = new Tribute({
				values: this.ourUserList,
				containerClass: 'tribute-container',
				selectClass: 'selected',
				fillAttr: 'email',
				menuContainer: document.getElementById('comment-wrapper-' + this.uuid),
				allowSpaces: false,
				selectTemplate: function(item) {
					// console.log(item);
					return item.original.email;
				},
				lookup: function(person) {
					return ((person.firstName ?? '') + ' ' + (person.lastName ?? '')).trim();
				},
			});

			this.tribute.attach(document.getElementById('comment-' + this.uuid));
		},

		methods: {
			saveEdit: function() {
				if (this.tributeActive) return;
				let newCommentObj = Object.assign({}, this.commentToEdit);
				newCommentObj.comment = this.newComment;
				newCommentObj.privateComment = this.privateComment;
				this.$emit('saveEdit', newCommentObj);
			},

			handleKeydown: function(event){
				if (event.key === 'Enter') {
					if (event.shiftKey) {
						//do nothing
					} else {
						// Enter without Shift: call the method and prevent default line break
						event.preventDefault();
						this.sendComment();
					}
				}
			},

			cancelEdit: function() {
				this.$emit('cancelEdit', this.newComment);
			},

			sendComment: function() {
				// If tribute is active, return here because we don't want to send comment.
				// Just selecting the user from list with enter key
				if (this.tribute._isActive) return;

				this.$emit('sendComment', {comment: this.newComment, privateComment: this.privateComment});
				this.newComment = '';
			},

			getPendingComment: function() {
				return {
					comment: this.newComment,
					privateComment: this.privateComment
				}
			},
		},

		watch: {
			userList: function(val){
				this.tribute.collection[0].values.splice(0);
				this.tribute.collection[0].values.push(... val);
			}
		},

		computed: {
			ourUserList: function() {
				let ourList = [...this.userList];
				return ourList;
			},
		},
	};
</script>

<style lang="scss">
	#comment-wrapper {
		width: 100%;
		align-items: center;
	}

	.tribute-container {
		background-color: var(--v-white-base);
		color: var(--v-gray_80-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
		z-index: 100 !important;
		margin-top: 15px;
		padding: 0 !important;
		//padding: 10px;

		ul {
			list-style-type: none; /* Remove bullets */
			padding: 0 !important; /* Remove padding */
			margin: 0 !important; /* Remove margins */
			li {
				padding: 8px 12px;
				margin: 0;
				min-width: 180px;
				cursor: pointer;
				&.selected {
					background-color: var(--v-gray_20-base);
					color: var(--v-black-base);
				}
			}
		}
	}
</style>
