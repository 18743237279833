<template>
	<div>
			<div class="column-format">
				<v-text-field
					@input="updated"
					outlined
					hide-details
					dense
					class="standard-input"
					placeholder="Search..."
					v-model="textFilter"
					style="max-width: 200px"
				>
					<template v-slot:prepend-inner
					><span class="material-symbols-rounded font-gray_50">search</span></template
					>
				</v-text-field>
			</div>
	</div>
</template>

<script>
	export default {
		name: 'AccountUserFilter',

		props: {},

		data() {
			return {
				showMenu: false,
				textFilter: null,
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('filter', this.textFilter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.textFilter = null;
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				if (this.textFilter) {
					return true;
				} else {
					return false;
				}
			},

			filterLabel: function() {
				let label = [];

				if (this.textFilter) {
					label.push('"' + this.textFilter + '"');
				}

				if (label.length === 0) {
					return 'Filter...';
				} else {
					return label.join(' | ');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
