<template>
	<div class="fill-height column-format show-scrollbar" style="height: calc(var(--vh) - 65px); min-height: calc(var(--vh) - 65px); max-height: calc(var(--vh) - 65px); overflow-y: auto">
		<div style="background-color: var(--v-accent-base); border-radius: 4px" class="fill-width pa-3 ma-2 font-14"><v-icon color="black">warning</v-icon>You are viewing the legacy version of the Sales Pipeline.  This version will remain available until March 1, 2023.</div>
		<div v-if="paidAccount || sampleMode" class="fill-all">
			<v-container id="kanban-container" fluid class="d-flex px-4 mt-5" v-if="isReady && !isBrandNew">
				<v-row id="kanban-v-row" justify="center" align-content="start">
					<v-col class="xs-12 sm-2 kanban-v-col" v-for="(column, i) in columns" :key="i">
						<div :class="'kanban-column ' + columnClassState[column.name]" :id="column.name + '-column'">
							<div :class="'column-head ' + column.color">
								<span class="column-head-label">{{ column.label }}</span>
								<v-btn
									v-if="['prospect'].indexOf(column.name) > -1"
									small
									rounded
									icon
									class="float-right"
									@click.stop="handleColumnPlusClick(column.name)"
									><v-icon small tooltip="Add a new client">$plus</v-icon></v-btn
								>
							</div>
							<draggable
								:list="cardsInColumns[column.name]"
								:group="{ name: 'card', pull: 'clone' }"
								tag="section"
								handle=".leadgen-card"
								ghost-class="ghost"
								:move="moveOk"
								@end="handleEnd"
							>
								<div v-for="card in cardsInColumns[column.name]" :key="card.id">
									<lead-gen-card
										:card="card"
										@action-clicked="handleAction"
										@leadgen-dialog="handleLeadGenDialog"
									></lead-gen-card>
								</div>
							</draggable>
						</div>
					</v-col>
				</v-row>
			</v-container>

			<div v-if="isReady && isBrandNew" class="row-format centered" style="flex: 1">
				<empty-view
					header="Never have another client dry spell"
					body="Visualize the prospects you’re talking to, track notes on what they need, pitch them on proposals, and work your way to a signed contract."
					cta="Add a prospect"
					video-header="See how it works"
					video-body="Learn how the pipeline visualizes your sales process. It’s the world’s first CRM (customer relationship management) tool that is simple enough for freelancers, but powerful enough to get results."
					video-cta="Watch the tutorial"
					video-id="RqZypPmS8fg"
					@cta-clicked="handleColumnPlusClick('prospect')"
				></empty-view>
			</div>

			<div v-if="selectedCard">
				<basic-modal
					:dialog="dialog['createDiscovery']"
					:persistent="false"
					@close="
						closeDialogs();
						discoveryModalState.inSendMode = false;
					"
					v-if="dialog['createDiscovery']"
					:max-width="createDiscoveryMaxWidth"
				>
					<discovery-templates
						:client-id="selectedCard.client.id"
						:modal-state="discoveryModalState"
						discoveryOrigin="bizDev"
						@close="
							closeDialogs();
							discoveryModalState.inSendMode = false;
						"
						@template-selected="handleTemplateSelected($event, selectedCard.client)"
					></discovery-templates>
				</basic-modal>
				<basic-modal
					:dialog="dialog['viewDiscovery']"
					:persistent="false"
					@close="dialog['viewDiscovery'] = false"
					@click:outside="closeDialogs()"
					v-if="selectedCard && dialog['viewDiscovery']"
				>
					<discovery-modal
						:client="selectedCard.client"
						:discovery-request-id="selectedCard.id"
						:card-type="selectedCard.type"
						@delete-discovery="deleteCardById($event)"
						@close="closeDialogs()"
					></discovery-modal>
				</basic-modal>
				<basic-modal
					:dialog="dialog['createProposal']"
					:persistent="true"
					@close="dialog['createProposal'] = false"
					v-if="selectedCard && dialog['createProposal']"
				>
					<new-proposal
						:open-state="dialog['createProposal']"
						:client-id="selectedCard.client.id"
						nav-origin="bizDev"
						@result="handleCreateProposalEvent($event)"
					></new-proposal>
				</basic-modal>
				<basic-modal
					v-if="dialog['sendReminder']"
					:dialog="dialog['sendReminder']"
					:persistent="true"
					@close="dialog['sendReminder'] = false"
				>
					<share-send-widget
						style="background-color: var(--v-white-base)"
						v-if="selectedCard.proposal"
						:client-service="clientService"
						:proposal="selectedCard.proposal"
						mode="Send"
						:proposal-status="selectedCard.proposal.proposalStatus"
						:proposal-service="proposalService"
						@finalize="activateProposal"
						@alert="scope.setAlert($event)"
						@cancel="dialog['sendReminder'] = false"
					></share-send-widget>
				</basic-modal>
				<basic-modal
					v-if="dialog['createProject']"
					:dialog="dialog['createProject']"
					:persistent="true"
					@close="dialog['createProject'] = false"
				>
					<new-project
						:open-state="dialog['createProject']"
						:client="selectedCard.client.id ? selectedCard.client : ''"
						:proposal-id="proposalId"
						@created="
							dialog['createProject'] = false;
							getCards();
						"
						@close="dialog['createProject'] = false"
					></new-project>
				</basic-modal>

				<!--			<basic-modal-->
				<!--				v-if="dialog['deleteClient']"-->
				<!--				:dialog="dialog['deleteClient']"-->
				<!--				:persistent="true"-->
				<!--				@close="dialog['deleteClient'] = false"-->
				<!--				:max-width="450"-->
				<!--			>-->
				<!--				<client-delete-->
				<!--					:key="selectedCard.client.id"-->
				<!--					v-on:alert="$refs.deleteClientModal.setAlert($event)"-->
				<!--					v-on:delete-client="deleteClient($event)"-->
				<!--					v-on:close="dialog['deleteClient'] = false"-->
				<!--					:client="selectedCard.client"-->
				<!--				></client-delete>-->
				<!--			</basic-modal>-->

				<basic-modal
					v-if="dialog['createClient']"
					:dialog="dialog['createClient']"
					:persistent="true"
					@close="dialog['createClient'] = false"
				>
					<client-create
						v-on:alert="$refs.deleteClientModal.setAlert($event)"
						v-on:client-created="clientCreated($event)"
						v-on:close="dialog['createClient'] = false"
						:is-open="dialog['createClient']"
						:clientList="$store.getters.clients"
						:leadGen="selectedCard"
					></client-create>
				</basic-modal>

				<confirm-dialog
					v-if="dialog['archiveClient']"
					:dialog="dialog['archiveClient']"
					@close="dialog['archiveClient'] = false"
					@confirm="archiveClient(selectedCard.client.id)"
					heading-text="Confirm"
					yes-text="Yes"
					no-text="No"
					:body-text="'Are you sure you want to hide ' + selectedCard.client.name + ' from the Prospect column?'"
				>
				</confirm-dialog>

				<confirm-dialog
					v-if="dialog['archiveProposal']"
					:dialog="dialog['archiveProposal']"
					@close="dialog['archiveProposal'] = false"
					@confirm="archiveProposal(selectedCard.proposal.id)"
					heading-text="Confirm"
					yes-text="Yes"
					no-text="No"
					:body-text="'Are you sure you want to archive the proposal for ' + selectedCard.client.name + '?'"
				>
				</confirm-dialog>

				<confirm-dialog
					v-if="dialog['deleteProject']"
					:dialog="dialog['deleteProject']"
					@close="dialog['deleteProject'] = false"
					@confirm="deleteProject(selectedCard.id)"
					heading-text="Confirm"
					yes-text="Yes"
					no-text="No"
					:body-text="'Are you sure you want to delete this project, ' + selectedCard.name + '?'"
				>
				</confirm-dialog>

				<confirm-dialog
					v-if="dialog['archiveProject']"
					:dialog="dialog['archiveProject']"
					@close="dialog['archiveProject'] = false"
					@confirm="archiveProject(selectedCard.client.id)"
					heading-text="Confirm"
					yes-text="Yes"
					no-text="No"
					:body-text="'Are you sure you want to archive the project for ' + selectedCard.client.name + '?'"
				>
				</confirm-dialog>

				<confirm-dialog
					v-if="dialog['voidProposal']"
					:dialog="dialog['voidProposal']"
					@close="dialog['voidProposal'] = false"
					@confirm="voidProposal(selectedCard.id)"
					heading-text="Confirm"
					yes-text="Yes"
					no-text="No"
					:body-text="'Are you sure you want to void this proposal for ' + selectedCard.client.name + '?'"
				>
				</confirm-dialog>

				<modal
					:dialog="leadgenDialog && !dialogIsActive"
					:persistent="true"
					@close="leadgenDialog = false"
					:max-width="920"
				>
					<lead-gen-modal :card="selectedCard" :key="selectedCard.id" @action-clicked="handleAction"></lead-gen-modal>
				</modal>

				<basic-modal
					v-if="dialog['discovery']"
					:dialog="dialog['discovery']"
					:persistent="true"
					:fullscreen="true"
					@close="handleBuilderClose"
				>
					<discovery-builder
						:client="client"
						:template-id="templateId"
						@close="handleBuilderClose"
						@sent="handleBuilderClose"
						@duplicated="handleBuilderDuplicated"
					></discovery-builder>
				</basic-modal>
			</div>
		</div>
		<div v-else class="row-format centered mx-8 mt-4">
			<free-tier-card
				style="width:100%; height: 150px"
				message="Sales pipeline is only available on the Pro or Teams plan.  Upgrade now to get control over your business and visualize the flow of opportunities through the pipeline."
				@upgrade-clicked="$router.push('/subscription')"
			></free-tier-card>
		</div>
	</div>
</template>

<script>
	import LeadGenService from './LeadGenService';
	import ClientService from '@/modules/clients/ClientService';
	import LeadGenCard from './LeadGenCard';
	import LeadGenModal from './LeadGenModal';
	import NewProposal from '@/modules/proposals/NewProposal';
	import NewProject from '@/modules/projects/NewProject';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import ConfirmModal from '@/components/ConfirmModal';
	import DiscoveryTemplates from '@/modules/discovery/DiscoveryTemplates';

	import Modal from '@/components/Modal';
	import draggable from 'vuedraggable';
	import { cloneDeep } from 'lodash';
	import LeadGenRules from './LeadGenRules.js';
	import ShareSendWidget from '@/modules/proposals/ShareSendWidget';
	import ProposalService from '@/modules/proposals/ProposalService';
	import ProjectService from '@/modules/projects/ProjectService';
	import EmptyView from '@/components/EmptyView';
	import DiscoveryModal from './DiscoveryModal';
	import DiscoveryBuilder from '@/modules/discovery/DiscoveryBuilder';
	import BasicModal from '@/components/BasicModal';
	import ProposalBuilder from '@/modules/proposals/ProposalBuilder';
	import ClearSampleModal from '@/modules/clients/ClearSampleModal';
	import FreeTierCard from '@/components/FreeTierCard';
	import ProposalTemplateModal from '@/modules/templates/proposals/ProposalTemplateModal';
	import MeetingDetail from '@/modules/meetings/MeetingDetail';
	import FormSubmissionDetail from '@/modules/discovery/FormSubmissionDetail';

	export default {
		name: 'LeadGen',

		components: {
			FreeTierCard,
			LeadGenCard,
			LeadGenModal,
			draggable,
			NewProposal,
			NewProject,
			// ClientDelete,
			ClientCreate,
			Modal,
			ConfirmDialog,

			ShareSendWidget,
			EmptyView,
			DiscoveryModal,
			DiscoveryBuilder,
			DiscoveryTemplates,
			BasicModal,
		},

		props: ['focus', 'cardId'],

		data() {
			return {
				leadGenService: new LeadGenService(),
				leadGenRules: new LeadGenRules(),
				clientService: new ClientService(),
				proposalService: new ProposalService(),
				projectService: new ProjectService(),
				cards: null,
				cardsInit: null,
				createdAt: null,
				columns: null,

				discoveryModalState: {
					inSendMode: false,
				},

				columnClassDefaults: {
					inquiry: '',
					prospect: '',
					discovery: '',
					proposal: '',
					signedproposal: '',
					project: '',
					//idle: '',
				},
				columnClassState: {
					inquiry: '',
					prospect: '',
					discovery: '',
					proposal: '',
					signedproposal: '',
					project: '',
					//idle: '',
				},
				columnDragClasses: {
					prospect: {
						prospect: '',
						discovery: 'target',
						proposal: 'target',
						signedproposal: 'no-target',
						project: 'target',
						// idle: 'no-target',
					},
					discovery: {
						prospect: 'no-target',
						discovery: '',
						proposal: 'target',
						signedproposal: 'no-target',
						project: 'target',
						// idle: 'no-target',
					},
					proposal: {
						prospect: 'no-target',
						discovery: 'no-target',
						proposal: '',
						signedproposal: 'no-target',
						project: 'target',
						// idle: 'no-target',
					},
					signedproposal: {
						prospect: 'no-target',
						discovery: 'no-target',
						proposal: 'no-target',
						signedproposal: '',
						project: 'target',
						// idle: 'no-target',
					},
					project: {
						prospect: 'no-target',
						discovery: 'no-target',
						proposal: 'no-target',
						signedproposal: 'no-target',
						project: '',
						// idle: 'no-target',
					},
					// idle: {
					// 	prospect: 'no-target',
					// 	discovery: 'target',
					// 	proposal: 'target',
					// 	signedproposal: 'no-target',
					// 	project: 'target',
					// 	//idle: '',
					// },
				},
				currentFilterOption: 'active',
				filterOptions: [
					{ text: this.$t('global.states.active'), value: 'active' },
					{ text: this.$t('leadgen.archive'), value: 'archive' },
					{ text: this.$t('global.states.all'), value: 'all' },
				],
				filterText: '',
				filteredList: null,
				dialog: {
					createClient: false,
					createDiscovery: false,
					viewDiscovery: false,
					createProposal: false,
					createProject: false,
					sendReminder: false,
					signProposal: false,
					voidProposal: false,
					archiveProject: false,
					deleteProject: false,
					// deleteClient: false,
					archiveClient: false,
					archiveProposal: false,
				},
				leadgenDialog: false,
				selectedCard: null,
				fromColumn: null,
				toColumn: null,
				func: {},
				clientCount: null,
				templateId: null,
				client: null,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'biz-dev' });
			this.$store.commit('setBreadcrumbs', [{ to: this.$route.path, text: 'Business development' }]);
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$on(this.getProposalEventChannel(this.accountId), this.handleProposalEvent);
			this.initializeLeadGen();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$off(this.getProposalEventChannel(this.accountId), this.handleProposalEvent);
			this.destroyLeadGen();
		},

		methods: {
			handleTemplateSelected(template, client) {
				console.log(template);
				this.closeDialogs();
				this.dialog['discovery'] = true;
				this.templateId = template.id;
				this.client = client;
			},
			handleBuilderClose: function() {
				this.closeDialogs();
				this.templateId = null;
				this.client = null;
				this.getCards();
			},
			handleBuilderDuplicated: function(template) {
				this.templateId = template.id;
			},
			// handleBuilderSent: function() {
			// 	this.closeDialogs();
			// 	this.templateId = null;
			// 	this.client = null;
			// 	this.initialize();
			// },

			handleAccountChange: function() {
				this.destroyLeadGen();
				this.initializeLeadGen();
			},

			handleProposalEvent: function(event) {
				if (event.userMetadata === 'EventLog' && event.message.event === 'Fully Executed') {
					this.getCards();
				}
			},

			getProposalEventChannel: function(accountId) {
				return `a-${accountId}.p`;
			},

			initializeLeadGen: function() {
				this.columns = this.leadGenRules.getColumns();
				this.getCards();

				this.clientService
					.getClientCount()
					.then((res) => {
						this.clientCount = res.data;
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.alert('warn', 'Error [' + status + '] ' + msg);
					});

				try {
					let option = localStorage.getItem('LEADGEN_KANBAN_FILTER_OPTION');

					if (!this.$validations.isEmpty(option)) {
						this.currentFilterOption = option;
					}
				} catch (err) {
					console.log('Error reading preferences from local storage.');
				}

				this.filterText = this.$store.getters.getFilterState('leadgen');
			},

			/**
			 * This method is used to enable "back" buttons returning some type of state.  In the single implementation
			 * we are re-selecting the chosen card and re-posting whatever action gets us to the state we were interested in
			 *
			 */
			handleFocusInput: function() {
				if (this.focus) {
					window.history.pushState({}, document.title, `/bizdev`);

					if (this.focus === 'discovery') {
						for (let i = 0; i < this.cards.length; i++) {
							let card = this.cards[i];
							if (card.cardType === 'Prospect' && card.id === this.cardId) {
								this.handleAction({}, 'createDiscovery', card);
								return;
							}
						}
					}
				}
			},

			destroyLeadGen: function() {
				this.cards = [];
			},

			initSelectedCard() {
				this.selectedCard = { id: '', cardType: '', client: { id: '' } };
			},

			getCards() {
				this.leadGenService
					.getCards()
					.then((response) => {
						this.createdAt = response.createdAt;
						this.cardsInit = response.cards; // Store cards so we can reset after drag as needed
						this.refreshCardsFromCache();
						this.getFilteredList();
						this.handleFocusInput();
					})
					.catch((error) => {
						console.log(error);
						this.alert('error', error.response.data.message);
					});
			},

			refreshCardsFromCache() {
				this.cards = cloneDeep(this.cardsInit); // Store cards so we can reset after drag as needed
			},

			deleteCardByIndex(index) {
				this.cardsInit.splice(index, 1);
				this.refreshCardsFromCache();
			},

			deleteCardById(id) {
				let cardIndex = this.cardsInit.findIndex((v) => v.id === id);
				this.deleteCardByIndex(cardIndex);
			},

			deleteCardsByClientId(clientId) {
				while (this.cardsInit.findIndex((v) => v.client && v.client.id === clientId) > -1) {
					let cardIndex = this.cardsInit.findIndex((v) => v.client && v.client.id === clientId);
					this.cardsInit.splice(cardIndex, 1);
				}
				this.refreshCardsFromCache();
			},

			moveOk(event) {
				this.selectedCard = event.draggedContext.element;
				this.fromColumn = event.from.parentElement.id.replace(/-column$/g, '');
				this.toColumn = event.to.parentElement.id.replace(/-column$/g, '');

				this.columnClassState = this.columnDragClasses[this.fromColumn];
				return this.columnDragClasses[this.fromColumn][this.toColumn] == 'target';
			},

			resetColumnState() {
				this.columnClassState = this.columnClassDefaults;
			},

			handleEnd(/*event*/) {
				this.resetColumnState();
				let dialog;

				if (this.columnDragClasses[this.fromColumn][this.toColumn] == 'target') {
					switch (this.toColumn) {
						case 'discovery':
							dialog = 'createDiscovery';
							break;
						case 'proposal':
							dialog = 'createProposal';
							break;
						case 'signedproposal':
							dialog = 'signProposal';
							break;
						case 'project':
							dialog = 'createProject';
							break;
						default:
					}
					this.dialog[dialog] = true;
				}
				this.refreshCardsFromCache();
				return false;
			},

			handleColumnPlusClick(column) {
				let dialog;

				this.initSelectedCard();
				switch (column) {
					case 'prospect':
						dialog = 'createClient';
						break;
					default:
				}
				this.dialog[dialog] = true;
			},

			clientCreated(/*event*/) {
				this.getCards();
				this.closeDialogs();
			},

			handleAction(event, actionName, card) {
				console.log('handling action...');
				event.cancelBubble = true; // Prevents card click
				this.selectedCard = card;
				console.log(this.selectedCard);
				console.log(event, actionName, card);

				if (actionName == 'viewProposal' || actionName == 'signProposal') {
					this.openProposal(card.client.id, card.id);
				}
				if (actionName == 'viewProject' || actionName == 'completeProject') {
					this.$router.push(`/client/${card.client.id}?v=Projects`);
				}
				if (actionName === 'deleteClient') {
					return this.confirmDeleteClient();
				}

				if(actionName === 'viewDiscovery' && card.type === 'FormSubmission'){
					this.openFormSubmission(card);
					return;
				}

				this.dialog[actionName] = true;
			},

			handleCreateProposalEvent(proposal) {
				// console.log(proposal);
				// console.log(this.selectedCard);
				this.dialog['createProposal'] = false;

				if (proposal.action === 'TEMPLATES') {
					return this.openLibrary(proposal.view);
				}

				this.openProposal(proposal.clientId, proposal.id);
			},

			openLibrary: function(view) {
				let binding = {
					view: view,
					clientId: this.selectedCard.client.id,
				};
				this.$store.state.globalModalController.openModal(ProposalTemplateModal, binding, true, true).then((res) => {
					console.log(res);
					if (!res) {
						return;
					}
					this.openProposal(res.clientId, res.id);
				});
			},

			openProposal(clientId, proposalId) {
				let binding = {
					proposalId: proposalId,
					clientId: clientId,
				};
				this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true).then(() => {
					this.getCards();
				});
			},

			getProposal(clientId, proposalId, actionName) {
				this.proposalService
					.getProposal(clientId, proposalId)
					.then((res) => {
						this.selectedCard.proposal = res.data;
						if (actionName == 'leadgenModal') {
							this.leadgenDialog = true;
						} else {
							this.dialog[actionName] = true;
						}
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', err.result.data.message);
					});
			},

			handleLeadGenDialog(card) {
				this.selectedCard = card;
				if (card.cardType == 'Proposal') {
					return this.getProposal(card.client.id, card.id, 'leadgenModal');
				}
				if (card.cardType == 'Inquiry') {
					if (card.isFormSubmission) {
						return this.openFormSubmission(card);
					}
					if (card.isScheduledMeeting) {
						return this.openMeetingDetail(card);
					}
					// return this.getProposal(card.client.id, card.id, 'leadgenModal');
				}
				this.leadgenDialog = true;
			},

			openFormSubmission(card) {
				let binding = {
					id: card.id,
					fromLeadGen: true,
				};
				this.$store.state.globalModalController.openModal(FormSubmissionDetail, binding).then((res) => {
					if (!res) return;
					this.getCards();
				});
			},

			openMeetingDetail(scheduledMeeting) {
				let binding = {
					id: scheduledMeeting.id,
					fromLeadGen: true,
				};
				this.$store.state.globalModalController.openModal(MeetingDetail, binding, true, false).then((res) => {
					if (!res) return;
					this.getCards();
				});
			},

			deleteClient(id) {
				this.clientService
					.deleteClient(id)
					.then(() => {
						this.alert('success', 'Deleted');
						this.closeDialogs();
						this.deleteCardsByClientId(id);
					})
					.catch((err) => {
						console.log(err);
						this.alert('error', err.response.data.message);
					});
			},

			confirmDeleteClient() {
				if (this.sampleMode) {
					this.$store.state.globalModalController.openModal(ClearSampleModal);
					return;
				}
				let binding = {
					headingText: this.$t('client.delete-client'),
					bodyText: this.$t('client.delete-are-you-sure', { name: this.selectedCard.client.name }),
					confirmText: this.selectedCard.client.name,
					confirmHint: 'Please type the exact client name to confirm',
					yesText: this.$t('global.delete'),
					noText: this.$t('global.cancel'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteClient(this.selectedCard.client.id);
					}
				});
			},

			archiveClient(id) {
				this.leadGenService
					.archive(id, 'client')
					.then(() => {
						this.alert('success', 'Client hidden from column.');
						this.closeDialogs();
						this.deleteCardsByClientId(id);
					})
					.catch((err) => {
						console.log('archiveClient Error', err);
						this.alert('error', err.response.data.message);
					});
			},

			archiveDiscovery(id) {
				this.leadGenService
					.archive(id, 'discoveryRequest')
					.then(() => {
						this.alert('success', 'Discovery request archived.');
						this.closeDialogs();
						this.deleteCardById(id);
					})
					.catch((err) => {
						console.log('archiveDiscovery Error', err);
						this.alert('error', err.response.data.message);
					});
			},

			activateProposal() {
				if (this.selectedCard.proposal.proposalStatus === 'Draft') {
					this.proposalService
						.activateProposal(this.selectedCard.client.id, this.selectedCard.proposal.id)
						.then((res) => {
							this.selectedCard.proposal = res.data;
						})
						.catch((err) => {
							this.$store.commit('error', err.result.data.message);
						});
				} else {
					this.$store.commit('error', this.$t('proposal.error.not-draft'));
				}
			},

			archiveProposal(id) {
				this.leadGenService
					.archive(id, 'proposal')
					.then(() => {
						this.alert('success', 'Proposal archived.');
						this.closeDialogs();
						this.deleteCardById(id);
					})
					.catch((err) => {
						console.log('archiveProposal Error', err);
						this.alert('error', err.response.data.message);
					});
			},

			voidProposal() {
				if (
					this.selectedCard.proposal.proposalStatus === 'Sent' ||
					this.selectedCard.proposal.proposalStatus === 'Declined'
				) {
					this.proposalService
						.voidProposal(this.selectedCard.client.id, this.selectedCard.proposal.id)
						.then((res) => {
							this.selectedCard.proposal = res.data;
						})
						.catch((err) => {
							this.alert('error', err.result.data.message);
						});
				} else {
					this.alert('error', this.$t('proposal.error.not-sent'));
				}
			},

			draftProposal() {
				if (
						this.selectedCard.proposal.proposalStatus === 'Sent' ||
						this.selectedCard.proposal.proposalStatus === 'Declined'
				) {
					this.proposalService
							.draftProposal(this.selectedCard.client.id, this.selectedCard.proposal.id)
							.then((res) => {
								this.selectedCard.proposal = res.data;
							})
							.catch((err) => {
								this.alert('error', err.result.data.message);
							});
				} else {
					this.alert('error', this.$t('proposal.error.not-sent'));
				}
			},

			archiveProject(id) {
				this.leadGenService
					.archive(id, 'project')
					.then(() => {
						this.alert('success', 'Project archived.');
						this.closeDialogs();
						this.deleteCardById(id);
					})
					.catch((err) => {
						console.log('archiveProject Error', err);
						this.alert('error', err.response.data.message);
					});
			},

			deleteProject(id) {
				this.projectService
					.deleteProject(id)
					.then(() => {
						this.alert('success', 'Project deleted.');
						this.closeDialogs();
						this.deleteCardById(id);
					})
					.catch((err) => {
						console.log('deleteProject Error', err);
						this.alert('error', err.response.data.message);
					});
			},

			closeDialogs() {
				this.leadgenDialog = false;
				for (let key in this.dialog) {
					this.dialog[key] = false;
				}
			},

			alert(type, message) {
				this.$store.commit(type, message);
			},

			getFilteredList() {
				if (!this.cards) return;

				this.filteredList = this.cards
					.filter((card) => {
						// search filter
						if (this.$validations.isEmpty(this.filterText) || this.$validations.isEmpty(card.name)) {
							return true;
						} else {
							return (
								card.name.toLowerCase().includes(this.filterText.toLowerCase()) ||
								card.client.name.toLowerCase().includes(this.filterText.toLowerCase())
							);
						}
					})
					.filter((card) => {
						// status filter
						switch (this.currentFilterOption) {
							case 'active':
								return !card.leadGenArchived;
							case 'archive':
								return card.leadGenArchived;
							default:
								return true;
						}
					});
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			accountId() {
				return this.$store.getters.getAccountId;
			},

			subscriptionType() {
				// console.log(this.$store.getters.getAccount.subscriptionType);
				return this.$store.getters.getAccount.subscriptionType;
			},

			cardsInColumns() {
				if (this.cards == null) return;

				let sorted = {};
				for (let i = 0; i < this.filteredList.length; i++) {
					let card = this.filteredList[i];
					let cardType = card.cardType.toLowerCase();
					if (sorted[cardType] == undefined) sorted[cardType] = [];
					sorted[cardType].push(card);
				}
				return sorted;
			},

			dialogIsActive: {
				get: function() {
					for (const property in this.dialog) {
						if (this.dialog[property]) return true;
					}
					return false;
				},
			},

			isReady: {
				get: function() {
					return this.cards !== null && this.columns !== null;
				},
			},

			isBrandNew() {
				return this.isReady && this.cards.length == 0 && this.clientCount == 0;
			},

			proposalId() {
				if (['proposal', 'signedproposal'].indexOf(this.selectedCard.cardType.toLowerCase()) > -1) {
					return this.selectedCard.id;
				}
				return null;
			},

			createDiscoveryMaxWidth: function() {
				if (this.discoveryModalState.inSendMode) {
					return 684;
				} else {
					return this.$vuetify.breakpoint.width - 100;
				}
			},
		},

		watch: {
			accountId: function(newAccountId, oldAccountId) {
				this.$store.state.eventBus.$off(this.getProposalEventChannel(oldAccountId), this.handleProposalEvent);
				this.$store.state.eventBus.$on(this.getProposalEventChannel(newAccountId), this.handleProposalEvent);
			},

			filterText: function(newVal) {
				this.$store.commit('setFilterState', { key: 'leadgen', value: newVal });
				this.getFilteredList();
			},

			cards: function() {
				this.getFilteredList();
			},

			currentFilterOption: function(val) {
				try {
					localStorage.setItem('LEADGEN_KANBAN_FILTER_OPTION', val);
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	#filter-container {
		max-width: 1824px;
		.v-input__icon.v-input__icon--clear {
			.v-icon {
				height: 20px !important;
				width: 20px !important;
			}
		}
	}
	#kanban-container {
		overflow-x: auto;
		width: 1872px;

		#kanban-v-row {
			max-width: 1824px;
			min-width: 1600px;

			.kanban-v-col {
				padding-left: 8px;
				padding-right: 8px;
				//height: 100%;
				.kanban-column {
					//height: 100%;
					background: linear-gradient(var(--v-blue_5-base), var(--v-white-base));
					padding: 8px;
					border-radius: 12px;
					text-align: left;
					filter: none;
					transition: filter 200ms, box-shadow 600ms;
					.column-head {
						display: inline;
						padding: 4px 8px;
						font-size: 14px;
						line-height: 24px;
						font-weight: 600;
						letter-spacing: 0.4px;
						height: 32px;
						border-radius: 4px;
						text-align: left;
						transition: background-color 500ms;
						span.column-head-label {
							transition: color 200ms;
						}
						.regent_20 {
							background-color: var(-v-regent_20-base);
						}
						.dodger_20 {
							background-color: var(-v-dodger_20-base);
						}
						.bright_sun_20 {
							background-color: var(-v-bright_sun_20-base);
						}
						.celery_20 {
							background-color: var(-v-celery_20-base);
						}
						.burnt_sienna_20 {
							background-color: var(-v-burnt_sienna_20-base);
						}
						.white {
							background-color: var(-v-white-base);
						}
					}
					&.target {
						.column-head {
							background-color: transparent !important;
							span.column-head-label {
								color: var(--v-primary-base) !important;
								transition: color 300ms;
							}
							transition: background-color 200ms color 200ms;
						}
					}
					&.no-target {
						.column-head {
							opacity: 0.5;
						}
					}
					section {
						/* Makes targets take entire available height */
						height: 100%;
					}
				}
			}
		}
	}
	.ghost {
		div {
			box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.4);
			background-color: var(--v-white-base);
			opacity: 0.5;
		}
		//transition: visibility 200ms; // Add some kind of transition here! ssf
	}
</style>
