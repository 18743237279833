<template>
	<div class="bulk-actions">
		<div class="count">{{ tasks.length }}</div>
		<div>{{ label }}</div>
		<v-menu :close-on-click="true" :close-on-content-click="false" v-model="startMenu">
			<template v-slot:activator="{ on }">
				<div class="action" v-on="on">
					<v-icon size="24">today</v-icon>
					<div>Set start</div>
				</div>
			</template>
			<bulk-date @shift-date="shiftDate('startDate',$event)" @set-date="setDate('startDate',$event)"></bulk-date>
		</v-menu>
		<v-menu :close-on-click="true" :close-on-content-click="false" v-model="dueMenu">
			<template v-slot:activator="{ on }">
				<div class="action" v-on="on">
					<v-icon size="24">event</v-icon>
					<div>Set due</div>
				</div>
			</template>
			<bulk-date @shift-date="shiftDate('dueDate',$event)" @set-date="setDate('dueDate',$event)"></bulk-date>
		</v-menu>
		<v-menu :close-on-click="true" :close-on-content-click="true" v-model="statusMenu">
			<template v-slot:activator="{ on }">
				<div class="action" v-on="on">
					<v-icon size="24">bolt</v-icon>
					<div>Set status</div>
				</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
				<div v-for="status in statusList" :key="status.id" class="py-1 pointer">
					<div
						class="deliverableStatus row-format align-center highlight-hover"
						:style="`--status-color:${status.hexColor}`"
						@click="setStatus(status)"
					>
						<div class="statusBox"></div>
						<div>{{ status.label }}</div>
					</div>
				</div>
			</div>
		</v-menu>
		<v-menu :close-on-click="true" :close-on-content-click="true" v-model="priorityMenu">
			<template v-slot:activator="{ on }">
				<div class="action" v-on="on">
					<v-icon size="24">priority_high</v-icon>
					<div>Set priority</div>
				</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
				<div v-for="priority in priorityList" :key="priority" class="py-1 pointer">
					<div
							class="row-format align-center highlight-hover"
							@click="setPriority(priority)"
					>
						<div>{{ priority }}</div>
					</div>
				</div>
			</div>
		</v-menu>
		<v-menu :close-on-click="true" :close-on-content-click="false" v-model="assignedMenu">
			<template v-slot:activator="{ on }">
				<div class="action" v-on="on">
					<v-icon size="24">person</v-icon>
					<div>Assigned to</div>
				</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
				<div v-for="user in userList" :key="user.userId" class="row-format align-center text-left py-1 pointer">
					<input
						style="width: 20px; height: 20px; border-radius: 4px"
						type="checkbox"
						:value="user.userId"
						v-model="assignedToList"
					/>
					<assigned-user class="mx-2" v-if="user.userId" :assigned-to="user.userId" :show-name="true"></assigned-user>
				</div>

				<v-btn class="mt-2" block small color="primary" @click="setAssignedTo">Update</v-btn>
			</div>
		</v-menu>
		<div class="action" @click="archiveTasks">
			<v-icon size="24">archive</v-icon>
			<div>Archive</div>
		</div>
	</div>
</template>

<script>
	import AssignedUser from '@/components/AssignedUser';
	import BulkDate from '@/modules/projects/bulk/BulkDate';
	import {DateTime} from 'luxon';
	import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";

	export default {
		name: 'BulkActions',

		props: ['label', 'tasks', 'statusList', 'userList'],

		components: { BulkDate, AssignedUser },

		data: function() {
			return {
				assignedToList: [],
				priorityList: ['Low', 'Normal', 'Medium', 'High', 'Urgent'],
				projectDeliverableService: new ProjectDeliverableService(),
				startMenu: false,
				dueMenu: false,
				assignedMenu: false,
				statusMenu: false,
				priorityMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			async shiftDate(prop, value) {
				this.$store.commit('startLoading');
				for (const t of this.tasks) {
					let date = t[prop] ? DateTime.fromISO(t[prop]) : DateTime.now();
					let duration = { [value.period]: value.increment };
					date = date.plus(duration);
					await this.patchAndUpdate(t.id, prop, date.toISODate());
				}
				this.$store.commit('stopLoading');
				if(prop === 'startDate'){
					this.startMenu = false;
				}else{
					this.dueMenu = false;
				}
			},

			async setDate(prop, value) {
				this.$store.commit('startLoading');
				for (const t of this.tasks) {
					await this.patchAndUpdate(t.id, prop, value.date);
				}
				this.$store.commit('stopLoading');
				if(prop === 'startDate'){
					this.startMenu = false;
				}else{
					this.dueMenu = false;
				}
			},

			async setStatus(status){
				this.$store.commit('startLoading');
				for (const t of this.tasks) {
					await this.patchAndUpdate(t.id, 'statusId', status.id);
				}
				this.$store.commit('stopLoading');
				this.statusMenu = false;
			},

			async setPriority(priority){
				this.$store.commit('startLoading');
				for (const t of this.tasks) {
					await this.patchAndUpdate(t.id, 'taskPriority', priority);
				}
				this.$store.commit('stopLoading');
				this.priorityMenu = false;
			},

			async setAssignedTo(){
				this.$store.commit('startLoading');
				for (const t of this.tasks) {
					await this.patchAndUpdate(t.id, 'assignedToList', this.assignedToList);
				}
				this.$store.commit('stopLoading');
				this.assignedMenu = false;
			},

			async archiveTasks(){
				this.$store.commit('startLoading');
				let tasks = [... this.tasks];
				for (const t of tasks) {
					await this.patchAndUpdate(t.id, 'archived', true);
				}
				this.$store.commit('stopLoading');
			},

			async patchAndUpdate(id,property,value){
				try {
					const patch = [{op: 'replace', path: `/${property}`, value: value}];
					let res = await this.projectDeliverableService.patchDeliverable(id, patch);
					this.$emit('task-updated', res.data);
				}catch(err){
					console.log(err);
				}
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.deliverableStatus {
		white-space: nowrap;

		.statusBox {
			margin-right: 8px;
			width: 14px;
			height: 14px;
			border-radius: 2px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}

	.bulk-actions {
		position: absolute;
		bottom: 24px;
		display: flex;
		flex-direction: row;
		gap: 16px;
		padding-right: 16px;
		align-items: center;
		border: 2px solid var(--v-primary-base);
		border-radius: 8px;
		font-size: 20px;
		width: fit-content;
		background-color: var(--v-white-base);

		.count {
			background-color: var(--v-primary-base);
			color: var(--v-white-base);
			font-size: 36px;
			padding: 4px 12px;
			overflow: hidden;
		}

		.action {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			.v-icon {
				color: var(--v-gray_70-base);
			}

			&:hover {
				cursor: pointer;
				color: var(--v-primary-base);

				.v-icon {
					color: var(--v-primary-base);
				}
			}
		}
	}
</style>
