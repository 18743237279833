<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Accounting' }]">
			Products & services
		</page-header>

		<div class="row-format align-center mb-4">
			<v-text-field
				outlined
				hide-details
				dense
				class="standard-input"
				v-model="search"
				placeholder="Search..."
				:style="`max-width: 200px;`"
				color=""
			>
				<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
			</v-text-field>

			<v-btn class="ml-auto super-action" @click="$refs.ProductTable.createNewProduct()"
				><v-icon size="20">add</v-icon> Add item</v-btn
			>
		</div>

		<products-table ref="ProductTable" :filter="search" :edit-mode="true"></products-table>
	</div>
</template>

<script>
	import ProductsTable from '@/modules/invoices/InvoiceRenderer/ProductsTable';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'ProductList',

		props: [],

		components: { ProductsTable, PageHeader },

		data: function() {
			return {
				search: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
