<template>
	<div class="pb-8">
		<page-header
			class="pb-6"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ to: '/agreements', label: 'Agreements' },
			]"
		>
			Templates
		</page-header>

		<div id="proposal-templates">
			<div class="row-format align-center mb-4">
				<v-text-field
						outlined
						hide-details
						dense
						class="standard-input"
						v-model="search"
						placeholder="Search..."
						style="max-width: 200px"
						color="gray_30"
				>
					<template v-slot:prepend-inner
					><span class="material-symbols-rounded font-gray_50">search</span></template
					>
				</v-text-field>

				<v-menu
					:nudge-bottom="0"
					:elevation="0"
					content-class="add-new-menu"
					bottom
					left
					rounded
					offset-overflow
					offset-y
					transition="slide-y-transition"
				>
					<template v-slot:activator="{ on }">
						<v-btn class="ml-auto super-action" v-on="on"><v-icon size="20">add</v-icon> Add template</v-btn>
					</template>
					<div class="add-new-dropdown">
						<div class="add-new-item" @click="addNew()">New template</div>
						<div class="add-new-item" @click="templateLibrary()">Search template library</div>
					</div>
				</v-menu>
			</div>

			<v-data-table
				:items="filteredTemplates"
				:fixed-header="true"
				:headers="headers"
				@click:row="openTemplate"
				style="cursor: pointer"
				:hide-default-footer="true"
				:items-per-page="-1"
				group-by="folder"
			>
				<template v-slot:group.header="{ group, headers, toggle }">
					<td colspan="4" class="text-left" @click="toggle">
						<div class="row-format align-center gap-2">
							<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ? group : ' -- ' }}
						</div>
					</td>
				</template>

				<template v-slot:item.version="{ item }"> v{{ item.version }} </template>
				<template v-slot:item.action="{ item }">
					<v-menu close-on-content-click close-on-click :nudge-bottom="30">
						<template v-slot:activator="scope">
							<div class="ml-1">
								<v-btn icon class="menu-activator" v-on="scope.on">
									<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
								</v-btn>
							</div>
						</template>
						<div class="more-menu">
							<div class="more-menu-item" @click="openTemplate(item)">Edit</div>
							<div class="more-menu-item" @click="duplicateTemplateMain(item)">Duplicate</div>
							<div class="more-menu-item" @click="confirmDelete(item)">Delete</div>
						</div>
					</v-menu>
				</template>
				<template v-slot:no-data>
					<search-button @click="templateLibrary()"></search-button>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import TemplateService from '@/modules/templates/TemplateService';
	import ProposalTemplateBuilder from '@/modules/templates/proposals/ProposalTemplateBuilder';
	import ClientService from '@/modules/clients/ClientService';
	import NewProposalTemplate from '@/modules/templates/proposals/NewProposalTemplate.vue';
	import defaultContract from '@/modules/proposals/templates/defaultContract.json';
	import defaultQuote from '@/modules/proposals/templates/defaultQuote.json';
	import defaultProposal from '@/modules/proposals/templates/defaultProposal.json';
	import ConfirmModal from '@/components/ConfirmModal';
	import AgreementTemplateBuilder from '@/modules/templates/agreements/AgreementTemplateBuilder';
	import contract from '@/modules/agreements/templates/contract';
	import quote from '@/modules/agreements/templates/quote';
	import proposal from '@/modules/agreements/templates/proposal';
	import baseTemplate from '@/modules/agreements/templates/base';
	import TemplateLibrary from '@/modules/templates/library/TemplateLibrary';
	import SearchButton from '@/modules/templates/library/SearchButton';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'ProposalTemplates',

		props: ['id', 'clientId', 'mode', 'opportunity'],

		components: { SearchButton, PageHeader },
		// mixins: [ProposalMixin],

		data() {
			return {
				templateService: new TemplateService(),
				clientService: new ClientService(),
				isReady: false,
				proposalTemplateList: [],
				agreementTemplateList: [],
				defaultProposal: defaultProposal,
				defaultQuote: defaultQuote,
				defaultContract: defaultContract,
				selectClientDialog: false,
				search: null,
				showMenu: false,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('agreement_template_onboarding', this.handleOnBoardingRequest);
			this.$store.state.eventBus.$on('account-changed', this.getTemplateList);
			this.getTemplateList();
			if (this.$route.query.id) {
				setTimeout(() => {
					this.openTemplate({
						version: Number(this.$route.query.v),
						id: this.$route.query.id,
					});
					this.$router.replace('/templates/agreements');
				}, 500);
			}
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getTemplateList);
			this.$store.state.eventBus.$off('agreement_template_onboarding', this.handleOnBoardingRequest);
		},

		methods: {
			getTemplateList: function(){
				this.getProposalTemplateList();
				this.getAgreementTemplateList();
			},

			handleOnBoardingRequest: function(action) {
				if (action === 'add-new') {
					this.addNew();
				} else {
					this.templateLibrary();
				}
			},

			openTemplate: function(template) {
				if (template.version === 1) {
					this.editTemplate(template);
				} else if (template.version === 2) {
					this.editAgreementTemplate(template);
				}
			},

			getProposalTemplateList() {
				this.templateService
					.getProposalTemplates()
					.then((res) => {
						this.proposalTemplateList.splice(0, this.proposalTemplateList.length);
						this.proposalTemplateList.push(...res.data);
						this.proposalTemplateList.sort(this.sortByName);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			getAgreementTemplateList() {
				this.templateService
					.getAgreementTemplates()
					.then((res) => {
						this.agreementTemplateList.splice(0, this.agreementTemplateList.length);
						this.agreementTemplateList.push(...res.data);
						this.agreementTemplateList.sort(this.sortByName);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			sortByName: function(a, b) {
				if (!a.name) a.name = '';
				if (!b.name) b.name = '';
				let aName = a.name.toLowerCase();
				let bName = b.name.toLowerCase();
				if (aName > bName) {
					return 1;
				} else if (aName < bName) {
					return -1;
				} else {
					return 0;
				}
			},

			editAgreementTemplate(template) {
				let binding = {
					id: template.id,
					folders: this.folders,
				};
				this.$store.state.globalModalController.openModal(AgreementTemplateBuilder, binding, true, true).then((res) => {
					if (res) {
						if (res.action === 'DELETE') {
							let ix = this.agreementTemplateList.findIndex((t) => t.id === res.id);
							if (ix > -1) {
								this.agreementTemplateList.splice(ix, 1);
							}
							return;
						}
						if (res.action === 'DUPLICATE') {
							this.duplicateTemplateMain(template);
						}
						let ix = this.agreementTemplateList.findIndex((t) => t.id === res.id);
						if (ix > -1) {
							delete res.action;
							this.agreementTemplateList.splice(ix, 1, res);
						}
					}
				});
			},

			editTemplate(template) {
				let binding = {
					proposalTemplateId: template.id,
					folders: this.folders,
				};
				this.$store.state.globalModalController.openModal(ProposalTemplateBuilder, binding, true, true).then((res) => {
					if (res) {
						if (res.action === 'DELETE') {
							if (template.version === 1) {
								this.deleteTemplate(res.id);
							}
							return;
						}
						if (res.action === 'DUPLICATE') {
							this.duplicateTemplate(res.id, 'Copy of: ' + res.name);
						}
						let i = this.proposalTemplateList.findIndex((t) => t.id === res.id);
						if (i > -1) {
							delete res.action;
							this.proposalTemplateList.splice(i, 1, res);
						}
					}
				});
			},

			duplicateTemplateMain(template) {
				if (template.version === 1) {
					this.duplicateTemplate(template.id, 'Copy of: ' + template.name);
				} else {
					this.duplicateTemplateV2(template);
				}
			},

			duplicateTemplate(id, name) {
				this.$store.commit('startLoading');
				this.templateService
					.duplicateProposalTemplate(id, name)
					.then((res) => {
						this.proposalTemplateList.push(res.data);
						this.proposalTemplateList.sort(this.sortByName);
						this.editTemplate(res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			duplicateTemplateV2(template) {
				this.$store.commit('startLoading');
				this.templateService
					.duplicateAgreementTemplate(template.id)
					.then((res) => {
						this.agreementTemplateList.push(res.data);
						this.agreementTemplateList.sort(this.sortByName);
						this.editAgreementTemplate(res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			addNew: function() {
				this.$store.state.globalModalController.openModal(NewProposalTemplate).then((res) => {
					if (!res) {
						return;
					}
					if (res.startsWith('Agreement')) {
						this.createAgreementTemplate(res);
					} else {
						let template = this.setDefaultOptions(res);
						this.createAndEdit(template);
					}
				});
			},

			templateLibrary: function() {
				let binding = {
					type: 'Agreement',
				};
				this.$store.state.globalModalController.openModal(TemplateLibrary, binding).then((template) => {
					if (template) {
						this.$onBoarding.track('agreements_create_template');
						this.agreementTemplateList.push(template);
						this.agreementTemplateList.sort(this.sortByName);
						this.editAgreementTemplate(template);
					}
				});
			},

			createAgreementTemplate: function(type) {
				this.$onBoarding.track('agreements_create_template');
				let base = baseTemplate;

				if (type === 'Agreement-Quote') {
					base = quote;
				} else if (type === 'Agreement-Contract') {
					base = contract;
				} else if (type === 'Agreement-Proposal') {
					base = proposal;
				}

				this.templateService.initializeAgreementTemplate(base).then((res) => {
					this.agreementTemplateList.push(res.data);
					let template = {
						id: res.data.id,
						name: res.data.name,
						type: 'Agreement',
						version: 2,
					};
					this.openTemplate(template);
				});
			},

			setDefaultOptions(type) {
				let template = {};

				if (type === 'Proposal') {
					template = this.defaultProposal;
					template.name = 'New Proposal Template';
				} else if (type === 'Quote') {
					template = this.defaultQuote;
					template.name = 'New Quote Template';
				} else if (type === 'Contract') {
					template = this.defaultContract;
					template.name = 'New ContractTemplate';

					let terms = template.contentBlocks.find((c) => c.blockType === 'Terms');
					if (terms) {
						terms.effectiveDate = null;
						terms.clientLegalName = '{{Client.Name}}';
						terms.clientState = '{{Client.Locality}}';
					}
				}
				template.proposalType = type;
				return template;
			},

			createAndEdit(template) {
				this.templateService
					.postProposalTemplate(template)
					.then((res) => {
						this.proposalTemplateList.push(res.data);
						this.editTemplate(res.data);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			confirmDelete: function(template) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this agreement template?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (template.version === 1) {
							this.deleteTemplate(template.id);
						} else if (template.version === 2) {
							this.deleteTemplateAgreement(template.id);
						}
					}
				});
			},

			deleteTemplate(id) {
				this.templateService
					.deleteProposalTemplate(id)
					.then(() => {
						let index = this.proposalTemplateList.findIndex((t) => t.id === id);
						if (index > -1) {
							this.proposalTemplateList.splice(index, 1);
						}
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			deleteTemplateAgreement(id) {
				this.templateService
					.deleteAgreementTemplate(id)
					.then(() => {
						let index = this.agreementTemplateList.findIndex((t) => t.id === id);
						if (index > -1) {
							this.agreementTemplateList.splice(index, 1);
						}
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
		},

		watch: {},

		computed: {
			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},

			headers: function() {
				let result = [
					{ text: 'Name', value: 'name' },
					{ text: 'Type', value: 'type' },
					{ text: 'Version', value: 'version' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				if (this.client) {
					result.splice(1, 1);
				}

				return result;
			},

			normalizedProposals: function() {
				let result = [];

				this.proposalTemplateList.forEach((p) => {
					result.push({
						id: p.id,
						name: p.name,
						type: p.proposalType,
						version: 1,
						folder: p.folder,
					});
				});

				return result;
			},

			normalizedAgreements: function() {
				let result = [];

				this.agreementTemplateList.forEach((a) => {
					result.push({
						id: a.id,
						name: a.name,
						type: 'Agreement',
						version: 2,
						folder: a.folder,
					});
				});

				return result;
			},

			folders: function() {
				return this.filteredTemplates
					.filter((e) => e.folder)
					.map((e) => e.folder)
					.filter((value, index, self) => self.indexOf(value) === index);
			},

			filteredTemplates: function() {
				let result = [];
				result.push(...this.normalizedAgreements);
				result.push(...this.normalizedProposals);

				if (this.search) {
					return result.filter((t) => {
						let search = this.search.toLowerCase();
						return t.name.toLowerCase().includes(search);
					});
				} else {
					return result;
				}
			},
		},
	};
</script>

<style lang="scss"></style>
