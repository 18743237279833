<template>
	<v-container class="overview">
		<v-row>
			<v-col>
				<duration-selector class="mt-4" label="Meeting duration" v-model="meeting.meetingLength"></duration-selector>
				<div class="row-format gap-2 align-center mt-4 mb-2">
					<v-select
						persistent-placeholder
						hide-details
						dense
						outlined
						label="Location"
						placeholder="Add location option"
						v-model="selectedLocation"
						:items="locationOptions"
						item-value="value"
						@change="selectLocation(selectedLocation)"
					>
						<template v-slot:selection="{ item }">
							<div class="row-format my-2">
								<v-icon small :class="item.value === 'PhoneIn' ? 'reverse' : ''" color="gray_80">{{
									item.icon
								}}</v-icon>
								<div class="text-left ml-2">
									<div class="font-16">{{ item.text }}</div>
								</div>
							</div>
						</template>

						<template v-slot:item="{ item }">
							<div class="row-format my-1">
								<v-icon :class="item.value === 'PhoneIn' ? 'reverse' : ''" color="gray_80">{{
									item.icon
								}}</v-icon>
								<div class="text-left ml-3">
									<div class="font-16">{{ item.text }}</div>
									<div class="font-12 font-gray_70">{{ item.description }}</div>
								</div>
							</div>
						</template>
					</v-select>
				</div>

				<div class="brand-medium text-left">Location options:</div>
				<div class="column-format gap-2 text-left font-14 mb-6">
					<div v-if="locations.length === 0" class="font-gray_70 font-12">
						<v-icon color="error" small>warning</v-icon> Please select at least one location option for this meeting
					</div>
					<draggable v-model="locations" handle=".drag-handle">
						<div
							v-for="(location, index) in locations"
							:key="index"
							class="pa-2 row-format align-center gap-2 location-row"
							style="border-radius: 4px"
						>
							<div v-if="location.type === 'Google'">
								<v-icon class="mr-1 drag-handle">$googleCalendar</v-icon> {{ formatGoogleLocation(location) }}
							</div>
							<div v-else-if="location.type === 'Microsoft'">
								<v-icon class="mr-1 drag-handle">$microsoft</v-icon> {{ formatMicrosoftLocation(location) }}
							</div>
							<div v-else-if="location.type === 'Zoom'">
								<v-icon class="mr-1 drag-handle">$zoomIcon</v-icon> {{ formatZoomLocation(location) }}
							</div>
							<div v-else-if="location.type === 'PhoneOut'">
								<v-icon class="mr-1 drag-handle">$phoneInTalk</v-icon> We will ask the invitee for their phone
								number at booking time.
							</div>
							<div v-else-if="location.type === 'InPerson'" class="row-format align-center gap-1 flex-grow-1">
								<v-icon class="drag-handle">$map</v-icon>
								<v-textarea
									style="background-color: var(--v-white-base)"
									persistent-placeholder
									hide-details
									dense
									outlined
									rows="1"
									:auto-grow="true"
									v-model="location.address"
									label="Address of meeting"
								></v-textarea>
							</div>
							<div v-else-if="location.type === 'PhoneIn'" class="row-format align-center gap-1 flex-grow-1">
								<v-icon class="reverse drag-handle">$phoneInTalk</v-icon>
								<v-text-field
									style="background-color: var(--v-white-base)"
									persistent-placeholder
									hide-details
									dense
									outlined
									type="phone"
									class="flex-grow-1"
									placeholder="(555) 555-1212 ext. 123"
									v-model="location.phone"
									label="Phone number for invitee to call"
								></v-text-field>
							</div>
							<div v-else-if="location.type === 'Custom'" class="row-format align-center gap-1 flex-grow-1">
								<v-icon class="drag-handle">$edit</v-icon>
								<v-textarea
									style="background-color: var(--v-white-base)"
									persistent-placeholder
									hide-details
									dense
									outlined
									rows="1"
									:auto-grow="true"
									v-model="location.custom"
									label="Custom meeting instructions"
								></v-textarea>
							</div>
							<v-icon color="gray_70" class="show-on-hover material-symbols-rounded ml-auto pointer" @click="removeLocation(index)"
								>delete</v-icon
							>
						</div>
					</draggable>
				</div>

				<v-textarea
					persistent-placeholder
					hide-details
					dense
					outlined
					rows="6"
					:auto-grow="true"
					class="mt-4"
					v-model="meeting.description"
					label="Brief description of meeting"
				></v-textarea>

				<v-select
					v-if="mappedCalendars.length && hasNonLinkedCalendarOption"
					persistent-placeholder
					hide-details
					dense
					outlined
					class="mt-4"
					label="Add meeting to connected calendar when not using Google Meet or Microsoft Teams"
					:items="mappedCalendars"
					v-model="addToCalendarUser"
				>
					<template v-slot:item="{ item }">
						<div class="row-format align-center">
							<v-icon v-if="item.type === 'Google'" class="mb-1">$googleCalendar</v-icon>
							<v-icon v-if="item.type === 'Apple'" class="mb-1">$apple</v-icon>
							<v-icon v-if="item.type === 'CalDav'" color="secondary">event</v-icon>
							<v-icon v-if="item.type === 'Microsoft'" class="mb-1">$microsoft</v-icon>
							<div class="ml-2">{{ item.text }}</div>
						</div>
					</template>

					<template v-slot:selection="{ item }">
						<div class="row-format align-center">
							<v-icon small v-if="item.type === 'Google'">$googleCalendar</v-icon>
							<v-icon small v-if="item.type === 'Apple'">$apple</v-icon>
							<v-icon small v-if="item.type === 'CalDav'" color="secondary">event</v-icon>
							<v-icon small v-if="item.type === 'Microsoft'">$microsoft</v-icon>
							<div class="ml-2">{{ item.text }}</div>
						</div>
					</template>
				</v-select>

				<multi-email-input
					class="mt-4"
					v-model="meeting.additionalAttendees"
					:known-emails="userEmails"
					label="Additional attendees"
				></multi-email-input>

				<v-text-field
					readonly
					persistent-placeholder
					hide-details
					dense
					outlined
					label="Direct meeting link"
					class="mt-4"
					:value="fullMeetingLink"
				></v-text-field>

				<v-select
					persistent-placeholder
					hide-details
					dense
					outlined
					class="mt-4"
					label="Scheduler owner"
					v-model="meeting.ownerUserId"
					:items="usersForAccount"
					item-value="userId"
				>
					<template v-slot:item="{ item }">
						<div v-if="item.userId">
							{{ item.firstName }} {{ item.lastName }} <span class="font-gray_70"> ({{ item.email }})</span>
						</div>
						<div v-else>-- No owner --</div>
					</template>

					<template v-slot:selection="{ item }">
						<div v-if="item.userId">
							{{ item.firstName }} {{ item.lastName }} <span class="font-gray_70"> ({{ item.email }})</span>
						</div>
						<div v-else>-- No owner --</div>
					</template>
				</v-select>

				<v-select
					:disabled="pipelineStages.length === 0"
					hide-details
					dense
					outlined
					persistent-placeholder
					class="mt-4"
					label="Add meeting to pipeline"
					:items="pipelineStages"
					item-value="id"
					item-text="label"
					clearable
					v-model="meeting.pipelineStageId"
				>
					<template v-slot:item="{ item }">
						<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
						<div class="ml-2">{{ item.label }}</div>
					</template>
					<template v-slot:selection="{ item }">
						<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
						<div class="ml-2">{{ item.label }}</div>
					</template>
				</v-select>

				<v-switch v-model="meeting.active" label="Link active" hide-details style=""></v-switch>
				<v-switch v-model="meeting.showInPortal" label="Show in Client Portal" hide-details style=""></v-switch>
				<v-switch v-model="meeting.reCaptchaEnabled" label="Embedded ReCaptcha" hide-details v-tippy="{content: 'Require Google ReCaptcha when embedding form on external websites.'}"></v-switch>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DurationSelector from '@/modules/meetings/detail/components/DurationSelector';
	import MultiEmailInput from '@/components/MultiEmailInput';
	import Draggable from 'vuedraggable';

	export default {
		name: 'Overview',

		props: [
			'meeting',
			'usedNames',
			'uniqueAccountUrl',
			'podId',
			'zoomUsers',
			'sessionsUsers',
			'googleUsers',
			'appleUsers',
			'calDavUsers',
			'microsoftUsers',
			'sampleMode',
			'pipelineStages',
			'fullMeetingLink'
		],

		components: { MultiEmailInput, DurationSelector, Draggable },

		data: function() {
			return {
				selectedLocation: null,
				locations: [],
				baseUrl: process.env.VUE_APP_MEET_LINK.replace('https://', ''),
				nameIsValid: false,
				nameRules: [(v) => !!v || 'Name is required', (v) => (v && this.isUnique(v)) || 'Name is not unique'],
				addToCalendarUser: this.addToCalendarFormatter(),
			};
		},

		mounted() {
			this.initLocations();
		},

		beforeDestroy() {},

		methods: {
			isUnique: function() {
				if (this.usedNames.includes(this.uniqueMeetingId)) {
					return false;
				} else {
					return true;
				}
			},

			addToCalendarFormatter() {
				let a = this.meeting.addToCalendar;
				if (a) {
					let result = a.type + '|' + a.userId + '|' + a.calendarId;
					if (a.subCalendarId) {
						result = result + '|' + a.subCalendarId;
					}
					return result;
				} else {
					return null;
				}
			},

			initLocations: function() {
				this.locations = [...this.meeting.locations.filter((l) => l.type !== 'Sessions')];
			},

			removeLocation: function(index) {
				this.locations.splice(index, 1);
			},

			selectLocation: function(location) {
				let newLocation = {};
				if (location.startsWith('Google')) {
					let parts = location.split(':');
					newLocation.type = 'Google';
					newLocation.googleUserId = parseInt(parts[1], 10);
					newLocation.calendarId = parts[2];
					if (parts.length > 3) {
						newLocation.subCalendarId = parts[3];
					}
				} else if (location.startsWith('Zoom')) {
					let parts = location.split(':');
					newLocation.type = 'Zoom';
					newLocation.zoomUserId = parseInt(parts[1], 10);
				} else if (location.startsWith('Microsoft')) {
					let parts = location.split(':');
					newLocation.type = 'Microsoft';
					newLocation.microsoftUserId = parseInt(parts[1], 10);
					newLocation.calendarId = parts[2];
					if (parts.length > 3) {
						newLocation.subCalendarId = parts[3];
					}
				} else if (location.startsWith('PhoneIn')) {
					newLocation.type = 'PhoneIn';
					newLocation.phone = null;
				} else if (location.startsWith('PhoneOut')) {
					newLocation.type = 'PhoneOut';
					newLocation.phone = null;
				} else if (location.startsWith('Custom')) {
					newLocation.type = 'Custom';
					newLocation.custom = null;
				} else if (location.startsWith('InPerson')) {
					newLocation.type = 'InPerson';
					newLocation.address = null;
				}
				if (newLocation.type) {
					this.locations.push(newLocation);
				}
				this.selectedLocation = null;
			},

			formatGoogleLocation: function(location) {
				if (location.type === 'Google' && location.googleUserId) {
					let googleUser = this.googleUsers.find(
						(g) => g.userId === location.googleUserId && g.googleUser.email === location.calendarId
					);
					if (!googleUser) {
						return `This Google calendar is not connected - please reconnect`;
					}
					return `${googleUser.googleUser.name}'s Google Meet (${googleUser.googleUser.email})`;
				} else {
					return null;
				}
			},

			formatMicrosoftLocation: function(location) {
				if (location.type === 'Microsoft' && location.microsoftUserId) {
					let microsoftUser = this.microsoftUsers.find(
						(m) => m.userId === location.microsoftUserId && m.microsoftUser.id === location.calendarId
					);
					if (!microsoftUser) {
						return `This Microsoft calendar is not connected - please reconnect`;
					}
					return `${microsoftUser.microsoftUser.name}'s Microsoft Teams (${microsoftUser.microsoftUser.mail})`;
				} else {
					return null;
				}
			},

			formatZoomLocation: function(location) {
				if (location.type === 'Zoom' && location.zoomUserId) {
					let zoomUser = this.zoomUsers.find((z) => z.userId === location.zoomUserId);
					if (!zoomUser) {
						return `This Zoom account is not connected - please reconnect`;
					}
					return `${zoomUser.zoomUser.first_name} ${zoomUser.zoomUser.last_name}'s Zoom account (${zoomUser.zoomUser.email})`;
				} else {
					return null;
				}
			},
		},

		watch: {
			locations: {
				deep: true,
				handler: function(){
					this.meeting.locations.splice(0,this.meeting.locations.length);
					this.meeting.locations.push(... this.locations);
				}
			},

			uniqueMeetingId: function(newVal) {
				this.meeting.uniqueMeetingId = newVal;
			},

			addToCalendarUser: function(val) {
				if (val) {
					let parts = val.split('|');

					this.meeting.addToCalendar = {
						type: parts[0],
						userId: parts[1],
						calendarId: parts[2],
						subCalendarId: parts.length > 3 ? parts[3] : null,
					};
				} else {
					this.meeting.addToCalendar = null;
				}
			},
		},

		computed: {
			hasNonLinkedCalendarOption: function() {
				return (
					this.locations.filter(
						(l) =>
							l.type === 'PhoneIn' ||
							l.type === 'PhoneOut' ||
							l.type === 'InPerson' ||
							l.type === 'Custom' ||
							l.type === 'Zoom'
					).length > 0
				);
			},

			usersForAccount: function() {
				let users = [...this.$store.state.usersForAccount];
				users.splice(0, 0, { userId: null, firstName: null, lastName: null });
				return users;
			},

			userEmails: function() {
				return this.$store.state.usersForAccount.map((u) => u.email);
			},

			uniqueMeetingId: function() {
				if (this.meeting.name) {
					let link = this.meeting.name
						.replaceAll(' ', '-')
						.toLowerCase()
						.replaceAll(/[^a-zA-Z0-9-]/g, '');
					return link;
				} else {
					return '';
				}
			},

			mappedCalendars: function() {
				let result = [];
				let checkOwnerId = this.meeting.ownerUserId ? this.meeting.ownerUserId : null;

				if (this.googleUsers.length || this.appleUsers.length || this.microsoftUsers || this.calDavUsers) {
					result.push({
						text: ' -- Select --',
						value: null,
					});

					this.appleUsers.forEach((a) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === a.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							a.calendars.forEach((c) => {
								if (c.enabled) {
									result.push({
										type: 'Apple',
										userId: user.userId,
										calendarId: c.path,
										subCalendarId: null,
										calendarLabel: c.name,
										text: `${user.firstName} ${user.lastName} - ${c.name} (${a.email})`,
										value: 'Apple|' + user.userId + '|' + c.path,
									});
								}
							});
						}
					});

					this.calDavUsers.forEach((c) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === c.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							result.push({
								type: 'CalDav',
								userId: user.userId,
								calendarId: c.id,
								subCalendarId: null,
								calendarLabel: c.calendarName,
								text: `${user.firstName} ${user.lastName} - ${c.calendarName}`,
								value: 'CalDav|' + user.userId + '|' + c.id,
							});
						}
					});

					this.googleUsers.forEach((g) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === g.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							if (g.calendarList) {
								g.calendarList
									.filter((c) => c.enabled)
									.forEach((c) => {
										result.push({
											type: 'Google',
											userId: user.userId,
											calendarId: g.googleUser.email,
											subCalendarId: c.calendarId,
											calendarLabel: c.description,
											text: `${g.googleUser.name} - ${c.description} (${g.googleUser.email})`,
											value: 'Google|' + user.userId + '|' + g.googleUser.email + '|' + c.calendarId,
										});
									});
							} else {
								result.push({
									type: 'Google',
									userId: user.userId,
									calendarId: g.googleUser.email,
									calendarLabel: g.googleUser.email,
									subCalendarId: 'primary',
									text: `${g.googleUser.name} (${g.googleUser.email})`,
									value: 'Google|' + user.userId + '|' + g.googleUser.email,
								});
							}
						}
					});

					this.microsoftUsers.forEach((m) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === m.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							m.calendarList
								.filter((c) => c.enabled)
								.forEach((c) => {
									result.push({
										type: 'Microsoft',
										userId: user.userId,
										calendarId: m.microsoftUser.id,
										subCalendarId: c.id,
										calendarLabel: c.name,
										text: `${m.microsoftUser.name} - ${c.name} (${m.microsoftUser.mail})`,
										value: 'Microsoft|' + user.userId + '|' + m.microsoftUser.id + '|' + c.id,
									});
								});
						}
					});
				}

				return result;
			},

			locationOptions: function() {
				let result = [];
				let hasGoogleUsers = false;
				let hasZoomUsers = false;
				let hasMicrosoftUsers = false;
				let checkOwnerId = this.meeting.ownerUserId ? this.meeting.ownerUserId : null;

				this.googleUsers.forEach((g) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === g.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasGoogleUsers = true;

						if (g.calendarList) {
							g.calendarList
								.filter((c) => c.enabled)
								.forEach((c) => {
									result.push({
										text: 'Google meet',
										value: `Google:${user.userId}:${g.googleUser.email}:${c.calendarId}`,
										description: `${g.googleUser.name}'s ${c.description} (${g.googleUser.email})`,
										icon: '$googleCalendar',
									});
								});
						} else {
							result.push({
								text: 'Google meet',
								value: `Google:${user.userId}:${g.googleUser.email}`,
								description: `${g.googleUser.name}'s calender connection (${g.googleUser.email})`,
								icon: '$googleCalendar',
							});
						}
					}
				});

				this.microsoftUsers.forEach((m) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === m.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasMicrosoftUsers = true;
						m.calendarList
							.filter((c) => c.enabled && c.allowedOnlineMeetingProviders.includes('teamsForBusiness'))
							.forEach((c) => {
								result.push({
									text: 'Microsoft teams',
									value: 'Microsoft:' + user.userId + ':' + m.microsoftUser.id + ':' + c.id,
									description: `${m.microsoftUser.name}'s - ${c.name} (${m.microsoftUser.mail})`,
									icon: '$microsoft',
								});
							});
					}
				});

				this.zoomUsers.forEach((z) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === z.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasZoomUsers = true;
						result.push({
							text: 'Zoom',
							value: `Zoom:${user.userId}`,
							description: `${z.zoomUser.first_name} ${z.zoomUser.last_name}'s Zoom connection (${z.zoomUser.email})`,
							icon: '$zoomIcon',
						});
					}
				});

				if (!hasGoogleUsers) {
					result.push({
						text: 'Google meet',
						value: 'Google',
						description: 'Please connect a Google Calendar in your Account Settings page.',
						icon: '$googleCalendar',
						disabled: true,
					});
				}

				if (!hasMicrosoftUsers) {
					result.push({
						text: 'Microsoft',
						value: 'Microsoft',
						description: 'Please connect a Microsoft Calendar in your Account Settings page.',
						icon: '$microsoft',
						disabled: true,
					});
				}

				if (!hasZoomUsers) {
					result.push({
						text: 'Zoom',
						value: 'Zoom',
						description: 'Please connect your Zoom account in your Account Settings page.',
						icon: '$zoomIcon',
						disabled: true,
					});
				}

				let otherOptions = [
					{
						text: 'Phone In',
						value: 'PhoneIn',
						description: 'You can provide a phone number for your invitee to call.',
						icon: '$phoneInTalk',
						disabled: false,
					},
					{
						text: 'Phone Out',
						value: 'PhoneOut',
						description: 'We will ask for the invitees phone number so you can call them.',
						icon: '$phoneInTalk',
						disabled: false,
					},
					{
						text: 'In person',
						value: 'InPerson',
						description: 'You can add a physical address where you would like to meet.',
						icon: '$map',
						disabled: false,
					},
					{
						text: 'Custom',
						value: 'Custom',
						description: 'Enter custom instructions for the meeting.',
						icon: '$edit',
						disabled: false,
					},
				];

				result.push(...otherOptions);
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.location-row {
		.show-on-hover {
			visibility: hidden;
		}
		&:hover {
			background-color: var(--v-gray_5-base);
			.show-on-hover {
				visibility: visible;
			}
		}
	}

	.drag-handle {
		cursor: grab;
	}

	.reverse {
		transform: rotate(270deg);
	}

	.overview {
		padding: 50px;
	}
</style>
