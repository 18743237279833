import { render, staticRenderFns } from "./DeliverableLoader.vue?vue&type=template&id=216cc876&scoped=true&"
import script from "./DeliverableLoader.vue?vue&type=script&lang=js&"
export * from "./DeliverableLoader.vue?vue&type=script&lang=js&"
import style0 from "./DeliverableLoader.vue?vue&type=style&index=0&id=216cc876&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216cc876",
  null
  
)

export default component.exports