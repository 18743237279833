<template>
	<div
		id="schedule-view"
		class="column-format ml-5 mr-3 font-14 mt-2 pt-2 show-scrollbar"
		:style="`max-height: ${height}; overflow-y:auto`"
	>
		<div
			:id="day.date"
			v-for="day in days"
			:key="day.date"
			class="row-format pb-8 mb-3"
			style="border-bottom: 1px solid var(--v-gray_50-base)"
		>
			<div style="min-width: 120px" class="font-14 brand-medium mt-1 text-left">
				{{ DateTime.fromISO(day.date).toFormat('ccc') }}
				{{ getNumberWithOrdinal(DateTime.fromISO(day.date).toFormat('d')) }}
			</div>
			<div class="column-format" style="width: 100%">
				<div
					v-for="event in day.events"
					:key="event.uuid"
					:style="`background-color: ${event.backgroundColor}; border-radius: 4px`"
					class=" pointer mx-2 mb-2"
					@click="$emit('event-clicked', event)"
				>
					<deliverable-loader
						class="pl-0 text-left"
						v-if="event.source === 'DELIVERABLE'"
						:event="event"
					></deliverable-loader>
					<component
						style="min-height: 30px"
						v-else-if="event.componentName !== 'CurrentTimeEvent'"
						:is="event.componentName"
						:event="event"
						:timed="event.timed"
						view="Schedule"
					></component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TimerEvent from '@/modules/calendar/events/TimerEvent';
	import DeliverableEvent from '@/modules/calendar/events/DeliverableEvent';
	import GoogleCalendarEvent from '@/modules/calendar/events/GoogleCalendarEvent';
	import NativeCalendarEvent from '@/modules/calendar/events/NativeCalendarEvent';
	import InvoiceEvent from '@/modules/calendar/events/InvoiceEvent';
	import PaymentEvent from '@/modules/calendar/events/PaymentEvent';
	import ProposalEvent from '@/modules/calendar/events/ProposalEvent';
	import ProposalSignedEvent from '@/modules/calendar/events/ProposalSignedEvent';
	import ScheduledMeetingEvent from '@/modules/calendar/events/ScheduledMeetingEvent';
	import DeliverableLoader from '@/modules/calendar/details/DeliverableLoader';
	import CurrentTimeEvent from '@/modules/calendar/events/CurrentTimeEvent';
	import DateTime from '@/modules/utils/HDateTime';
	import AppleCalendarEvent from '@/modules/calendar/events/AppleCalendarEvent';
	import ProjectEvent from '@/modules/calendar/events/ProjectEvent';
	import ToDoEvent from '@/modules/calendar/events/ToDoEvent';
	import MicrosoftCalendarEvent from '@/modules/calendar/events/MicrosoftCalendarEvent';
	import CalDavEvent from '@/modules/calendar/events/CalDavEvent';
	import OppToDoEvent from '@/modules/calendar/events/OppToDoEvent';

	export default {
		name: 'ScheduleView',

		props: ['days', 'hasMetrics'],

		components: {
			DeliverableLoader,
			ProposalEvent,
			ProposalSignedEvent,
			AppleCalendarEvent,
			GoogleCalendarEvent,
			NativeCalendarEvent,
			MicrosoftCalendarEvent,
			DeliverableEvent,
			CalDavEvent,
			TimerEvent,
			InvoiceEvent,
			PaymentEvent,
			ScheduledMeetingEvent,
			CurrentTimeEvent,
			ProjectEvent,
			ToDoEvent,
			OppToDoEvent,
		},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {
			this.initializeView();
		},

		beforeDestroy() {},

		methods: {
			initializeView: function() {
				setTimeout(() => {
					let today = DateTime.now().toISODate();
					let todayDiv = document.getElementById(today);
					if (todayDiv) {
						todayDiv.scrollIntoView({ behavior: 'smooth' });
					}
				}, 500);
			},

			getNumberWithOrdinal(n) {
				let s = ['th', 'st', 'nd', 'rd'],
					v = n % 100;
				return n + (s[(v - 20) % 10] || s[v] || s[0]);
			},
		},

		computed: {
			height: function() {
				if (this.hasMetrics) {
					return 'calc(var(--vh) - 275px)';
				} else {
					return 'calc(var(--vh) - 240px)';
				}
			},
		},

		watch: {
			days: function() {
				this.initializeView();
			},
		},
	};
</script>

<style scoped lang="scss"></style>
