<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			Custom fields
		</page-header>

		<div class="row-format gap-3 pb-4">
			<div
					:class="`page-link ${path === page.value ? 'page-link-active' : ''}`"
					v-for="page in pages"
					:key="page.value"
					@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
			<v-btn class="ml-auto super-action" @click="$refs.Editor.addField()"><v-icon size="20">add</v-icon> Add field</v-btn>
		</div>
		<custom-fields-editor ref="Editor" :type="type" :max-per-card="cardCount" :key="path"></custom-fields-editor>
	</div>
</template>

<script>
	import CustomFieldsEditor from "@/modules/fields/CustomFieldsEditor";
	import PageHeader from "@/components/PageHeader";
	export default {
		name: "CustomFieldRouter",

		props: ['path'],

		components: {CustomFieldsEditor, PageHeader},

		data: function () {
			return {
				pages: [
					{ label: 'Clients', value: 'clients' },
					{ label: 'Contacts', value: 'contacts' },
					{ label: 'Opportunities', value: 'pipeline' }
				]
			}
		},

		mounted() {
			if(!this.path){
				this.$router.push('/custom-fields/clients');
			}
		},

		beforeDestroy() {
		},

		methods: {
			setView: function(view){
				this.$router.push(`/custom-fields/${view}`);
			}
		},

		computed: {
			cardCount: function(){
				if(this.type === 'Client'){
					return 0
				}else if(this.type === 'Contact'){
					return 0
				}else if(this.type === 'Project'){
					return 0
				}else if(this.type === 'Pipeline'){
					return 0
				}else if(this.type === 'Deliverable'){
					return 0
				}else{
					return 0;
				}
			},


			type: function(){
				if(this.path === 'clients') {
						return 'Client';
				}else if(this.path === 'contacts'){
						return 'Contact';
				}else if(this.path === 'projects'){
						return 'Project'
				}else if(this.path === 'tasks') {
					return 'Deliverable';
				}else if(this.path === 'pipeline'){
					return 'Pipeline'
				}else{
					return 'Client';
				}
			}
		},

	}
</script>

<style scoped lang="scss"></style>
