<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Dashboard' }]">
			{{pageName}}
		</page-header>

		<div>
			<focus
				:full-user="$store.getters.hasFeatureAccess('any')"
				:key="'focus-' + refreshKey"
				ref="Focus"
				v-show="currentView === 'focus'"
				:is-visible="currentView === 'focus'"
			></focus>
			<calendar
				:key="'calendar-' + refreshKey"
				ref="Calendar"
				v-show="currentView === 'calendar'"
				:is-visible="currentView === 'calendar'"
				@set-view="setView($event)"
			></calendar>
			<insights
				v-if="hasInsightsAccess"
				:key="'insights-' + refreshKey"
				v-show="currentView === 'insights'"
				:is-visible="currentView === 'insights'"
			></insights>
			<bookings
				v-if="hasInsightsAccess"
				:key="'bookings-' + refreshKey"
				v-show="currentView === 'bookings'"
				:is-visible="currentView === 'bookings'"
			></bookings>
			<executive-summary
				:key="'summary-' + refreshKey"
				v-show="currentView === 'summary'"
				:is-visible="currentView === 'summary'"
			></executive-summary>
		</div>
	</div>
</template>

<script>
	import Calendar from '@/modules/calendar/Calendar';
	import Focus from '@/modules/home/Focus';
	import Insights from '@/modules/home/Insights';
	import Bookings from '@/modules/home/Bookings';
	import ExecutiveSummary from '@/modules/home/ExecutiveSummary';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'HomeBase',

		props: ['v'],

		components: { Bookings, Focus, Calendar, Insights, ExecutiveSummary, PageHeader },

		data: function() {
			return {
				currentView: 'Focus',
				refreshKey: 0,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.accountChanged);
			if (!this.v) {
				this.getLastView();
			} else {
				this.currentView = this.v.toLowerCase();
			}
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.accountChanged);
		},

		methods: {
			accountChanged: function() {
				this.refreshKey++;
			},

			getLastView: function() {
				let lastView = localStorage.getItem('HOME_CURRENT_VIEW');
				if (lastView) {
					this.$router.push(`/home/${lastView.toLowerCase()}`);
				} else {
					this.$router.push('/home/focus');
				}
			},

			setView: function(view) {
				if (!view) {
					view = 'Focus';
				}
				localStorage.setItem('HOME_CURRENT_VIEW', view);
				this.currentView = view;
			},
		},

		watch: {
			'$route.path': function(){
				if(this.$route.path === '/home'){
					this.getLastView();
				}
			},
			v: function(newVal) {
				this.setView(newVal);
			},
			filter: {
				deep: true,
				handler: function(val) {
					this.saveFilter(val);
				},
			},
		},

		computed: {
			pageName: function(){
				switch(this.currentView){
					case "focus": return "Focus";
					case "calendar": return "Calendar";
					case "insights": return "Insights";
					case "bookings": return "Bookings";
					case "summary": return "Business summary";
					default:  return "?";
				}
			},

			hasInsightsAccess: function() {
				return this.$store.getters.hasInvoiceAccess || this.$store.getters.hasFeatureAccess('timesheets');
			},

			unreadNotificationCount: function() {
				return this.$store.getters.unreadNotificationCount;
			},

			isCollaborator: function() {
				return this.$store.getters.getUserType === 'COLLABORATOR';
			},
		},
	};
</script>

<style scoped lang="scss">
	.umi {
		position: absolute;
		top: -4px;
		left: 22px;
		width: 16px;
		height: 16px;
		border-radius: 10px;
		background-color: var(--v-accent-base);
		color: var(--v-black-base);
		font-size: 12px;
		font-weight: 500;
	}
</style>
