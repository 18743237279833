<template>
	<div>
		<v-menu
				:nudge-bottom="34"
				min-width="150"
				max-width="150"
				:close-on-content-click="true"
				content-class="filter-menu-class"
				transition="slide-y-transition"
				v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${isFilterActive ? '' : 'font-gray_60'} brand-medium`" v-html="filterLabel"></span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
				<div class="filter-option-box">
					<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="filter.sort">
						<v-radio :label="view === 'CARD' ? 'Kanban' : 'Stage'" value="Stage"></v-radio>
						<v-radio label="Name" value="Name"></v-radio>
						<v-radio label="Value" value="Value"></v-radio>
						<v-radio label="Close date" value="Close date"></v-radio>
						<v-radio label="Last activity" value="Last activity"></v-radio>
						<v-radio label="Next due" value="Next due"></v-radio>
						<v-radio label="Confidence" value="Confidence"></v-radio>
					</v-radio-group>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: "PipelineSort",

	props: ['filter','view'],

	components: {},

	data: function () {
		return {
			showMenu: false,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		updated: function() {
			this.$emit('updated', this.filter);
		},
	},

	computed: {
		isFilterActive: function() {
			return this.filter.sort && this.filter.sort !== 'Stage'
		},

		filterLabel: function() {
			if(this.view === 'CARD' && this.filter.sort === 'Stage'){
				return 'Sort by kanban';
			}
			return 'Sort by ' + this.filter.sort.toLowerCase();
		},
	},

}
</script>

<style scoped lang="scss">

</style>