import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";

export default {

    data: function(){
        return {

        }
    },

    methods:{
        getDeliverableShareLink: function(deliverable) {
            if (deliverable) {
                return document.location.protocol + '//' + document.location.host + '/deliverable/' + deliverable.id;
            } else {
                return null;
            }
        },

        processDuplicateDeliverable: function(deliverable){
            return new ProjectDeliverableService().duplicate(deliverable.id);
        },
    },

    computed: {}


}