<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t(`connected-apps.${integrationType.toLowerCase()}.desc`) }}</div>
				<div v-if="!starterAccount">
					<connected v-if="isConnected"></connected>
					<div v-else class="font-primary brand-medium pointer" @click="connectApi">Enable {{ integrationType }} Integration</div>
				</div>
				<div v-else>
					<div class="font-14 pointer font-primary" @click="$router.push('/subscription')"><v-icon class="material-symbols-rounded">lock</v-icon>This integration is not available on your plan.  If you would like to enable this feature, please upgrade to Pro or Teams.</div>
				</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected && !starterAccount">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div
				v-for="(conn, index) in connections"
				:key="conn.id"
				class="pt-5 pb-5 mb-5"
				style="border-top: 1px solid var(--v-gray_50-base)"
			>
				<input :id="`key${index}`" :name="`key${index}`" :value="conn.apiKey" type="hidden" />
				<input :id="`platform${index}`" :name="`platform${index}`" :value="type === 'Zapier' ? platform : baseUrl" type="hidden" />
				<v-container class="font-14 ma-0 pa-0">
					<v-row class="mb-2">
						<v-col cols="2" align="left"> API Key: </v-col>
						<v-col cols="9" class="font-gray_70" align="left">
							<div style="word-wrap: anywhere; overflow-wrap: break-word">{{ conn.apiKey }}</div>
						</v-col>
						<v-col cols="1" align="right"><v-icon @click="copy(conn.apiKey)">$duplicate</v-icon></v-col>
					</v-row>
					<v-row>
						<v-col cols="2" align="left"> {{type === 'Zapier' ? 'Platform' : 'Base URL'}}: </v-col>
						<v-col cols="9" class="font-gray_70" align="left">
							{{ type === 'Zapier' ? platform : baseUrl }}
						</v-col>
						<v-col cols="1" align="right"><v-icon @click="copy(type === 'Zapier' ? platform : baseUrl)">$duplicate</v-icon></v-col>
					</v-row>
					<v-row>
						<v-col cols="12"> </v-col>
					</v-row>
				</v-container>

				<div class="py-3">&nbsp;</div>

				<div class="column-format gap-2" v-if="type === 'Public'">
					<v-select
						:items="['None', 'Basic', 'Bearer', 'Custom']"
						label="Rest hook authentication type"
						v-model="conn.restHookAuth.authType"
						dense
						hide-details
						persistent-placeholder
						outlined
					></v-select>
					<div class="row-format gap-2" v-if="conn.restHookAuth.authType === 'Basic'">
						<v-text-field
							label="User name"
							v-model="conn.restHookAuth.username"
							dense
							hide-details
							persistent-placeholder
							outlined
						></v-text-field>
						<v-text-field
							label="Password"
							v-model="conn.restHookAuth.password"
							dense
							hide-details
							persistent-placeholder
							outlined
							type="password"
						></v-text-field>
					</div>
					<div class="row-format gap-2" v-if="conn.restHookAuth.authType === 'Bearer'">
						<v-textarea
							label="Bearer token"
							v-model="conn.restHookAuth.value"
							dense
							hide-details
							persistent-placeholder
							outlined
							rows="3"
							no-resize
						></v-textarea>
					</div>
					<div class="row-format gap-3" v-if="conn.restHookAuth.authType === 'Custom'">
						<v-text-field
							style="max-width: 200px"
							label="HTTP header name"
							v-model="conn.restHookAuth.headerName"
							dense
							hide-details
							persistent-placeholder
							outlined
						></v-text-field>
						<v-text-field
							label="HTTP header value"
							v-model="conn.restHookAuth.value"
							dense
							hide-details
							persistent-placeholder
							outlined
						></v-text-field>
					</div>
				</div>
				<v-container v-if="conn.hooks.length" class="mt-2 gap-2 column-format font-14">
					<v-row v-for="hook in conn.hooks" :key="hook.id" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="py-2">
						<v-col cols="2">{{hook.label}}</v-col>
						<v-col cols="6" style="word-break: break-word">{{hook.hookUrl}}</v-col>
						<v-col cols="2" style="word-break: break-word">{{DateTime.fromISO(hook.statusTime).toLocaleString(DateTime.DATETIME_SHORT)}} - {{hook.statusMessage}}</v-col>
						<v-col cols="2" class="row-format align-center">
							<v-switch v-model="hook.enabled" hide-details dense class=" ml-auto mt-n1" @change="updateHookStatus(conn,hook)"></v-switch>
							<v-icon size="20" class="material-symbols-rounded pointer" @click="confirmDelete(conn,hook)">delete</v-icon>
						</v-col>
					</v-row>
				</v-container>

				<div class="row-format mt-4 gap-2">
					<v-btn v-if="canAddRestHooks" class="super-action" @click="saveRestHookAuth(conn)">Save</v-btn>
					<v-btn v-if="canAddRestHooks" class="primary-action" @click="newRestHook(conn)">Add REST hook</v-btn>
					<v-btn class="delete" @click="disconnectApi(conn)"><v-icon class="material-symbols-rounded">delete</v-icon> Delete connection</v-btn>
				</div>
			</div>
			<div class="pt-16">
				<v-btn outlined large color="gray_80" @click="connectApi()"
					>Create additional API key</v-btn
				>
			</div>
		</div>

		<basic-modal v-if="addRestHook" :dialog="addRestHook">
			<div class="rest-hook-create-div" style="min-width: 500px">
				<div class="row-format">
					<div class="pt-3 pl-4">
						Add new REST hook
					</div>
					<v-btn class="close-dialog" icon rounded @click.prevent="addRestHook = false">
						<v-icon size="20">$close</v-icon>
					</v-btn>
				</div>
				<div class="modal-padding column-format gap-3">
					<v-autocomplete
						:items="hookTypes"
						v-model="newHookType"
						item-text="label"
						item-value="type"
						dense
						hide-details
						persistent-placeholder
						label="Event type"
						outlined
					></v-autocomplete>
					<v-text-field
						label="Endpoint"
						v-model="newHookUrl"
						placeholder="https://www.domain.com/endpoint"
						dense
						hide-details
						persistent-placeholder
						outlined
					></v-text-field>
					<v-btn class="super-action" :disabled="!isValid" @click="saveNewRestHook()">{{ $t('global.save') }}</v-btn>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import IntegrationService from '@/modules/account/apps/IntegrationService';
	import ConfirmModal from '@/components/ConfirmModal';
	import BasicModal from '@/components/BasicModal';
	import HDateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'PublicApi',

		props: ['type'],

		components: { BasicModal, Connected },

		data: function() {
			return {
				DateTime: HDateTime,
				integrationService: new IntegrationService(),
				connections: [],
				expanded: false,
				isReady: false,

				addRestHook: false,
				newHookConnection: null,
				newHookType: null,
				newHookUrl: null,

				hookTypes: [
					{ type: 'ClientCreate', label: 'Client created' },
					{ type: 'ClientUpdate', label: 'Client updated' },
					{ type: 'ClientDelete', label: 'Client deleted' },
					{ type: 'InvoiceSent', label: 'Invoice sent' },
					{ type: 'PaymentReceived', label: 'Payment received' },
					{ type: 'AgreementSent', label: 'Agreement (v2) sent' },
					{ type: 'AgreementViewed', label: 'Agreement (v2) viewed' },
					{ type: 'AgreementSigned', label: 'Agreement (v2) signed' },
					{ type: 'ProjectCreate', label: 'Project created' },
					{ type: 'ProjectUpdate', label: 'Project updated' },
					{ type: 'ProjectComplete', label: 'Project completed' },
					{ type: 'TicketCreate', label: 'Ticket created' },
					{ type: 'TicketUpdate', label: 'Ticket updated' },
					{ type: 'TicketClose', label: 'Ticket closed' },
					{ type: 'TicketDelete', label: 'Ticket deleted' },
					{ type: 'TicketComment', label: 'Ticket comment' },
					{ type: 'TimerCreate', label: 'Timer created' },
					{ type: 'TimerUpdate', label: 'Timer updated' },
					{ type: 'TimerDelete', label: 'Time deleted' },
					{ type: 'FormCompleted', label: 'Form submitted', filter: { label: 'Form id', value: 'formId' } },
					{ type: 'MeetingScheduled', label: 'Meeting scheduled' },
					{ type: 'MeetingUpdated', label: 'Meeting updated' },
					{ type: 'MeetingCancelled', label: 'Meeting cancelled' },
					{ type: 'DeliverableApproval', label: 'Client task approval' },
					{ type: 'DeliverableCreate', label: 'Task created' },
					{ type: 'DeliverableUpdate', label: 'Task updated' },
					{ type: 'DeliverableDelete', label: 'Task deleted' },
					{ type: 'OpportunityCreate', label: 'Opportunity created' },
					{ type: 'OpportunityUpdate', label: 'Opportunity updated' },
					{ type: 'OpportunityDelete', label: 'Opportunity deleted' },
					{ type: 'ProposalSent', label: 'Agreement (v1) sent' },
					{ type: 'ProposalViewed', label: 'Agreement (v1) viewed' },
					{ type: 'ProposalSigned', label: 'Agreement (v1) signed' },
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.getConnections();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		methods: {
			translateHookType: function(type){
				let result = this.hookTypes.find(t => t.type === type);
				return result ? result.label : type;
			},

			newRestHook: function(conn) {
				this.newHookConnection = conn;
				this.addRestHook = true;
				this.newHookType = null;
				this.newHookUrl = null;
			},

			updateHookStatus: function(conn,hook){
				this.integrationService.updateHookStatus(conn.id,hook.id,hook.enabled).then((res) => {
					let ix = conn.hooks.findIndex(h => h.id === hook.id);
					if(ix > -1){
						let hook = res.data;
						hook.label = this.translateHookType(hook.type);
						conn.hooks.splice(ix,1,res.data);
						ix = this.connections.findIndex(c => c.id === conn.id);
						if(ix > -1){
							this.connections.splice(ix,1,conn);
						}
					}
				})
			},

			saveRestHookAuth: function(conn){
				let auth = conn.restHookAuth;
				this.$store.commit('startLoading');
				this.integrationService.updateAuthentication(conn.id,auth).then((res) => {
					let ix = this.connections.findIndex(c => c.id === conn.id);
					let connection = res.data;
					this.sortHooks(connection)
					this.connections.splice(ix,1,connection);
				}).finally(() => {
					this.$store.commit('stopLoading');
				});
			},

			saveNewRestHook: function() {
				let connection = this.newHookConnection;

				try {
					new URL(this.newHookUrl);

					let hook = {
						type: this.newHookType,
						hookUrl: this.newHookUrl,
					};

					this.integrationService.subscribeHook(this.newHookConnection.id,hook).then((res) => {
						connection.hooks.push(res.data);
						this.sortHooks(connection);
						let ix = this.connections.findIndex(c => c.id === connection.id);
						if(ix > -1){
							this.connections.splice(ix,1,connection);
						}
						this.addRestHook = false;
					});
				} catch (err) {
					this.$store.commit('error', 'The URL you provided is not valid');
				}
			},

			confirmDelete(conn,hook){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this REST hook endpoint?'
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.$store.commit('startLoading');
						this.integrationService.unSubscribeHook(conn.id,hook.id).then(() => {
							let ix = conn.hooks.findIndex(h => h.id === hook.id);
							if(ix > -1){
								conn.hooks.splice(ix,1);
								ix = this.connections.findIndex(c => c.id === conn.id);
								if(ix > -1){
									this.connections.splice(ix,1,conn);
								}
							}
						}).finally(() => this.$store.commit('stopLoading'));
					}
				})
			},

			sortHooks(connection){
				connection.hooks.forEach(h => h.label = this.translateHookType(h.type));
				connection.hooks.sort((a,b) => a.label.localeCompare(b.label));
			},

			handleAccountChange: function() {
				this.connections.splice(0, this.connections.length);
				this.getConnections();
			},

			getConnections: function() {
				this.integrationService
					.getConnections(this.type)
					.then((res) => {
						this.connections.splice(0, this.connections.length);
						this.connections.push(...res.data);
						this.connections.forEach(c => this.sortHooks(c));
					})
					.finally(() => (this.isReady = true));
			},

			connectApi: function() {
				this.integrationService.createConnection(this.type).then((res) => {
					this.connections.push(res.data);
					this.expanded = true;
				});
			},

			disconnectApi: function(conn) {
				let binding = {
					headingText: 'Confirm',
					bodyText:
						'Are you sure you want to remove this API Key?  This will delete all REST hooks and prevent future actions from being processed.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.integrationService.deleteConnection(conn.id).then(() => {
							let ix = this.connections.findIndex((c) => c.id === conn.id);
							if (ix > -1) {
								this.connections.splice(ix, 1);
							}
						});
					}
				});
			},

			copy(value) {
				navigator.clipboard.writeText(value);
				this.$store.commit('success', 'Copied to clipboard!');
			},
		},

		computed: {
			starterAccount(){
				return this.$store.getters.isStarterAccount;
			},

			isValid: function() {
				return this.newHookType && this.newHookUrl && this.newHookUrl.toLowerCase().startsWith('https://');
			},

			isConnected: function() {
				return this.connections.length > 0;
			},

			baseUrl: function() {
				return `https://${this.account.pod.podId}.${process.env.VUE_APP_BASE_URL}/api/public`;
			},

			platform: function(){
				return this.account.pod.podId;
			},

			account: function() {
				return this.$store.getters.getAccount;
			},

			integrationType: function(){
				if(this.type === 'Public'){
					return 'Custom';
				}else{
					return this.type;
				}
			},

			canAddRestHooks: function(){
				return this.type !== 'Zapier' && this.type !== 'Make';
			}
		},
	};
</script>

<style lang="scss">
	.rest-hook-create-div {
		background-color: var(--v-white-base) !important;
		color: var(--v-black-base) !important;
		position: relative;
		border-radius: 4px !important;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
