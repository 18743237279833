import HttpClient from '../../services/HttpClient';

export default class ProjectTypeService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getProjectTypesForAccount(){
		return this.httpClient.get("/projectTypes")
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectType(id){
		return this.httpClient.get(`/projectTypes/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	initializeProjectType(){
		return this.httpClient.post("/projectTypes")
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateProjectType(id,projectType){
		return this.httpClient.put(`/projectTypes/${id}`,projectType)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteProjectType(id){
		return this.httpClient.delete(`/projectTypes/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
