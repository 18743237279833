<template>
	<div class="row-format gap-3">
		<v-text-field
				outlined
				hide-details
				dense
				v-model="textFilter"
				placeholder="Search..."
				style="max-width: 200px; "
				class="standard-input"
				@input="updated"
		>
			<template v-slot:prepend-inner
			><span class="material-symbols-rounded font-gray_50">search</span></template
			>
		</v-text-field>

		<v-menu
			:nudge-bottom="34"
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${isFilterActive ? '' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
				<div class="filter-option-box">
					<div class="row-format">
						<div class="font-14">Status</div>
					</div>
					<div class="my-2">
						<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="status">
							<v-radio label="Active" value="Active"></v-radio>
							<v-radio label="Archived" value="Archive"></v-radio>
							<v-radio label="All" value="All"></v-radio>
						</v-radio-group>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format">
						<div class="font-14">Record type</div>
					</div>
					<div class="my-2">
						<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="clientType">
							<v-radio label="Client" value="Client"></v-radio>
							<v-radio label="Prospect" value="Prospect"></v-radio>
							<v-radio label="All" value="All"></v-radio>
						</v-radio-group>
					</div>
				</div>

				<div class="mx-3 mt-3 mb-3">
					<v-btn
						text
						block
						color="secondary"
						elevation="0"
						style="font-weight:500"
						class="mt-2"
						@click="clearFilter()"
						><v-icon small>$clear</v-icon> Clear filters</v-btn
					>
          <v-btn block color="primary" class="super-action" elevation="0" style="font-weight:500" @click="applyFilter()"
          >Apply</v-btn
          >
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'PermissionFilter',

		props: ['filter'],

		data() {
			return {
				showMenu: false,
				textFilter: null,
				status: 'Active',
				clientType: 'All'
			};
		},

		mounted() {
			if(this.filter){
				this.status = this.filter.status;
				this.clientType = this.filter.clientType;
			}
		},

		methods: {
			updated: function() {
				this.$emit('filter', {
					textFilter: this.textFilter,
					status: this.status,
					clientType: this.clientType
				});
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.textFilter = null;
				this.status = 'Active';
				this.clientType = 'All';
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				return this.textFilter || this.status !== 'Active' || this.clientType !== 'All';
			},

			filterLabel: function() {
				let label = [];

				if(this.status !== 'Active'){
					label.push(this.status)
				}

				if(this.clientType !== 'All'){
					label.push(this.clientType);
				}

				if (label.length === 0) {
					return 'Filter...';
				} else {
					return label.join(' | ');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
