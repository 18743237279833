<template>
	<div v-if="filter" id="expense-filter" class="row-format gap-3">
		<v-text-field
				outlined
				hide-details
				dense
				class="standard-input"
				v-model="filter.search"
				placeholder="Search..."
				:style="`max-width: 200px;`"
				color=""
		>
			<template v-slot:prepend-inner
			><span class="material-symbols-rounded font-gray_50">search</span></template
			>
		</v-text-field>

		<v-menu
			max-height="80vh"
			max-width="250"
			nudge-bottom="34"
			v-model="showMenu"
			:close-on-content-click="false"
			content-class="filter-menu-class"
			style="overflow-y: auto"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${!isDefaultFilter ? '' : 'font-gray_60'} brand-medium`">{{filterLabel}}</span>
					<v-icon x-small class="mx-1" :color="!isDefaultFilter ? '' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format open-menu">
				<div class="filter-option-box">
					<div class="row-format" @click="reimbursableExpanded = !reimbursableExpanded">
						<div class="font-14">Reimbursable status</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							reimbursableExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>
					<div class="my-2" v-if="reimbursableExpanded">
						<v-radio-group v-model="filter.reimbursable" class="mt-2" dense>
							<v-radio
								v-for="item in reimbursableOptions"
								:value="item.value"
								:key="item.value"
								hide-details
								:label="item.text"
								class=""
							>
							</v-radio>
						</v-radio-group>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format" @click="paidExpanded = !paidExpanded">
						<div class="font-14">Paid status</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							paidExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>
					<div class="my-2" v-if="paidExpanded">
						<v-radio-group v-model="filter.paid" class="mt-2" dense>
							<v-radio
								v-for="item in paidOptions"
								:value="item.value"
								:key="item.value"
								hide-details
								:label="item.text"
								class=""
							>
							</v-radio>
						</v-radio-group>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format" @click="clientExpanded = !clientExpanded">
						<div class="font-14">{{ $t('invoice.filter.client') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							clientExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>
					<div v-if="clientExpanded">
						<v-checkbox
							v-model="clientsSelected"
							hide-details
							:indeterminate="clientsIndeterminate"
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div class="client-label row-format pointer" style="align-content: center;">
									<div class="tree-label">
										{{ $t('invoice.filter.select-deselect-clients') }}
									</div>
								</div>
							</template>
						</v-checkbox>

						<v-checkbox
							v-model="filter.clients"
							v-for="client in clientList"
							:value="client.id"
							:key="client.id"
							hide-details
							class="mx-0 my-2 pa-0"
						>
							<template v-slot:label>
								<div class="client-label row-format pointer" style="align-content: center;">
									<client-avatar :small="true" left :client="client" :disable-click="true"></client-avatar>
									<div class="tree-label">{{ client.name }}</div>
								</div>
							</template>
						</v-checkbox>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format" @click="dateExpanded = !dateExpanded">
						<div class="font-14">{{ $t('invoice.filter.specify-dates') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							dateExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="dateExpanded">
						<v-select
							v-model="filter.dateSpecifier"
							:items="dateOptions"
							:return-object="true"
							dense
							hide-details
							:single-line="true"
							:menu-props="{ contentClass: 'text-left' }"
							style="font-size: 14px; text-align: left !important;"
							@change="handleDateChange"
						></v-select>

						<div class="" v-if="showEarliest">
							<date-selector
									content-class="my-5"
									:date="earliest"
									@change="earliest = $event"
									label="Earliest"
							></date-selector>
						</div>

						<div class="" v-if="showLatest">
							<date-selector
									content-class="my-5"
									:date="latest"
									@change="latest = $event"
									label="Latest"
							></date-selector>
						</div>
					</div>
				</div>

				<div class="mx-3 mt-3 mb-2">
					<v-btn text block color="secondary" elevation="0" class="filter-clear mt-2 mb-1" @click="clearFilter()"
						><v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn
					>
					<v-btn class="super-action" block @click="showMenu = false" elevation="0">{{ $t('global.done') }}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateSelector from "@/components/DateSelector";

	export default {
		name: 'ExpenseFilter',

		props: ['filter', 'clientList', 'isDefaultFilter'],

		components: { ClientAvatar, DateSelector },

		data: function() {
			return {
				showMenu: false,
				earliestMenu: false,
				latestMenu: false,
				dateOptions: FilterHelpers.getDateOptions([
					'this-month',
					'last-month',
					'past-30-days',
					'past-60-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'between',
				]),
				maxDate: null, // Limits for date picker
				minDate: null,
				clients: null,
				earliest: null,
				latest: null,

				reimbursableExpanded: false,
				reimbursableOptions: [
					{ value: 1, text: 'Reimbursable - All' },
					{ value: 2, text: 'Reimbursable - Not invoiced' },
					{ value: 3, text: 'Reimbursable - Invoiced' },
					{ value: 0, text: 'Non-reimbursable' },
					{ value: -1, text: 'Any' },
				],

				paidExpanded: false,
				paidOptions: [
					{ value: 1, text: 'Paid' },
					{ value: 0, text: 'Unpaid' },
					{ value: -1, text: 'Both' },
				],

				clientExpanded: false,
				dateExpanded: false,
			};
		},

		mounted() {
			this.earliest = this.filter.earliest;
			this.latest = this.filter.latest;
			this.maxDate = this.$DateTime
				.local()
				.plus({ year: 1 })
				.toISO()
				.substr(0, 10);

			const earliest = this.$luxon.Duration.fromObject({ years: 5 });
			this.minDate = this.$DateTime
				.local()
				.minus(earliest)
				.toISO()
				.substr(0, 10);
		},

		beforeDestroy() {},

		methods: {
			handleDateChange() {
				if (this.filter.dateSpecifier.value !== 'between') {
					this.filter.earliest = null;
					this.filter.latest = null;
				}
				console.log('updated date filters');

				this.$emit('updated', this.filter);
			},

			customDatesChanged() {
				let earliest = this.$DateTime.fromISO(this.earliest);
				let latest = this.$DateTime.fromISO(this.latest);

				if (!earliest || !earliest.isValid) {
					earliest = this.$DateTime.local();
				}

				if (!latest || !latest.isValid) {
					latest = this.$DateTime.local();
				}

				if (latest < earliest) {
					latest = earliest
						.plus({ day: 1 })
						.toISO()
						.substring(0, 10);
				}

				this.filter.earliest = earliest.toISO().substr(0, 10);
				this.filter.latest = latest.toISO().substr(0, 10);

				this.handleDateChange();
			},

			clearFilter: function() {
				this.$emit('clear-filter');
			},

			addAllClients() {
				let clientIds = [];
				for (let i = 0; i < this.clientList.length; i++) {
					clientIds.push(this.clientList[i].id);
				}
				this.filter.clients = clientIds;
			},

			removeAllClients() {
				this.filter.clients = [];
			},

			removeClient: function(id) {
				let ix = this.filter.clients.indexOf(id);
				if (ix > -1) {
					this.filter.clients.splice(ix, 1);
				}
			},

			sort(a, b) {
				if (a.name > b.name) {
					return 1;
				} else if (a.name < b.name) {
					return -1;
				} else {
					return 0;
				}
			},

			getStatusText(status) {
				const tmp = this.statusOptions.find((v) => v.value == status);
				if (!tmp) return '';
				return tmp.text;
			},
		},

		watch: {
			earliest: function() {
				this.customDatesChanged();
			},

			latest: function() {
				this.customDatesChanged();
			},

			menu: function(newVal) {
				if (!newVal) {
					console.log('updating');
					this.$emit('updated');
				}
			},
		},

		computed: {
			showEarliest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}
				if (this.filter.dateSpecifier.value === 'after' || this.filter.dateSpecifier.value === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier.value === 'before' || this.filter.dateSpecifier.value === 'between') {
					return true;
				} else {
					return false;
				}
			},

			clientsSelected: {
				set: function(val) {
					if (val) return this.addAllClients();
					return this.removeAllClients();
				},
				get: function() {
					if (this.filter.clients.length > 0) return true;
					return false;
				},
			},

			clientsIndeterminate: function() {
				if (this.filter.clients.length == this.clientList.length) return false;
				if (this.filter.clients.length == 0) return false;
				return true;
			},

			filterLabel: function() {
				if (!this.filter) {
					return '';
				}
				// console.log('from', this.filter.earliest.substr(0, 10));
				// console.log('to', this.filter.latest.substr(0, 10));
				let filterList = [];

				// if (this.filter.search && this.filter.search.length > 0) {
				// 	filterList.push('"' + this.filter.search + '"');
				// }

				if (this.filter.dateSpecifier && this.filter.dateSpecifier.value !== null) {
					let value = this.filter.dateSpecifier.text;

					if (this.filter.dateSpecifier.value === 'between') {
						if (this.filter.earliest === this.filter.latest) {
							value = this.$DateTime.humanDate(this.filter.earliest);
						} else {
							value =
								'Between ' +
								this.$DateTime.humanDate(this.filter.earliest) +
								' & ' +
								this.$DateTime.humanDate(this.filter.latest);
						}
					}

					filterList.push(value);
				}

				if (this.filter.paid > -1) {
					if (this.filter.paid) {
						filterList.push('Paid');
					} else {
						filterList.push('Unpaid');
					}
				}

				if (this.filter.reimbursable > -1) {
					if (this.filter.reimbursable === 1) {
						filterList.push('Reimbursable - All');
					} else if (this.filter.reimbursable === 2) {
						filterList.push('Reimbursable - Not invoiced');
					} else if (this.filter.reimbursable === 3) {
						filterList.push('Reimbursable - Invoiced');
					} else {
						filterList.push('Non-reimbursable');
					}
				}

				if (this.filter.clients.length > 0) {
					filterList.push(
						this.filter.clients.length +
							' ' +
							this.$t('invoice.filter.client') +
							(this.filter.clients.length > 1 ? 's' : '')
					);
				}

				if (filterList.length > 0) {
					return filterList.join(' | ');
				} else {
					return this.$t('invoice.filter.filter');
				}
			},
		},
	};
</script>

<style lang="scss">
	#expense-filter {
		position: relative;
		.clear-filter-in-activator {
			position: absolute;
			right: 6px;
		}
	}
	.open-menu {
		background-color: var(--v-white-base);
		max-width: 250px;
		.client-label .tree-label {
			text-overflow: ellipsis !important;
			overflow: hidden;
			white-space: nowrap;
			max-width: 160px;
		}
	}
</style>
