<template>
	<div id="migrate-project">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="modal-padding" v-if="migrationPlan">
			<div class="modal-title">Update project type for: {{ project.name }}</div>
			<div class="pb-3 column-format gap-2">
				<v-container class="fill-width">
					<v-row class="mb-4 mt-2">
						<v-col cols="3"></v-col>
						<v-col cols="9" class="row-format">
							<v-select
								class="ml-auto"
								hide-details
								dense
								outlined
								persistent-placeholder
								label="New project type"
								:items="projectTypes"
								v-model="migrationPlan.newProjectTypeId"
								item-value="id"
								item-text="name"
							></v-select>
						</v-col>
					</v-row>
					<v-row v-for="status in migrationPlan.statusList" :key="status.id" class="py-1">
						<v-col cols="3" class="row-format align-center brand-box" :style="`background-color: ${status.hexColor}`">
							<div class="ml-auto nowrap font-14 brand-bold">{{ status.label }}</div>
						</v-col>
						<v-col cols="9">
							<v-select
								:items="newProjectType.statusList"
								v-model="status.newId"
								placeholder="Maps to..."
								item-text="label"
								item-value="id"
								hide-details
								dense
								outlined
							></v-select>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" width="130" @click="handleClose()">
				<span class="font-gray_70">{{ $t('global.cancel') }}</span>
			</v-btn>
			<v-btn :disabled="!isValid" class="super-action ml-1" @click="migrateProject()">
				Update project type
			</v-btn>
		</div>
	</div>
</template>

<script>
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'ProjectTypeMigration',

		props: ['project'],

		components: {},

		data: function() {
			return {
				migrationPlan: null,
			};
		},

		mounted() {
			this.setupMigrationPlan();
		},

		beforeDestroy() {},

		methods: {
			handleClose: function() {
				this.$emit('result');
			},
			setupMigrationPlan: function() {
				this.migrationPlan = {
					newProjectTypeId: this.projectTypes[0].id,
					statusList: [],
				};
				this.currentProjectType.statusList.forEach((s) => {
					this.migrationPlan.statusList.push({
						id: s.id,
						label: s.label,
						hexColor: s.hexColor,
						newId: null,
					});
				});
			},
			migrateProject: function() {
				let binding = {
					headingText: 'Confirm?',
					bodyText: `Continue with migration of project from [${this.currentProjectType.name}] to [${this.newProjectType.name}]`
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.$emit('result',this.migrationPlan);
					}
				})
			},
		},

		computed: {
			isValid: function() {
				if (!this.migrationPlan?.newProjectTypeId) {
					return false;
				}
				for (let i = 0; i < this.migrationPlan.statusList.length; i++) {
					if (!this.migrationPlan.statusList[i].newId) {
						return false;
					}
				}
				return true;
			},

			currentProjectType: function() {
				return this.$store.getters.getProjectType(this.project.projectTypeId);
			},

			newProjectType: function() {
				return this.$store.getters.getProjectType(this.migrationPlan.newProjectTypeId);
			},

			projectTypes: function() {
				return this.$store.state.projectTypes.filter((p) => p.id !== this.currentProjectType?.id);
			},
		},
	};
</script>

<style scoped lang="scss">
	#migrate-project {
		min-width: 600px;
		background-color: var(--v-white-base);
	}
</style>
