<template>
	<div>
		<v-menu
			:nudge-bottom="34"
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${isFilterActive ? '' : 'font-gray_60'} brand-medium`">{{
						filterLabel
					}}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? '' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
				<div style="max-height: calc(90vh - 90px); overflow-y: auto">
					<div class="filter-option-box">
						<div class="row-format" @click="clientExpanded = !clientExpanded">
							<div class="font-14">Client</div>
							<v-icon style="margin-left: auto; margin-top: 1px" small>{{
								clientExpanded ? '$arrowUp' : '$arrowDown'
							}}</v-icon>
						</div>

						<div v-if="clientExpanded" class="assigned-user ellipsis">
							<v-checkbox
								class="my-0"
								dense
								hide-details
								v-model="filter.clientList"
								v-for="client in clientList"
								:key="client.value"
								:value="client.value"
								@change="updated"
							>
								<template v-slot:label>
									<span style="font-size: 14px" class="font-14 brand-semilight">{{ client.text }}</span>
								</template>
							</v-checkbox>
						</div>
					</div>
					<div class="filter-option-box">
						<div class="row-format" @click="projectOwnerExpanded = !projectOwnerExpanded">
							<div class="font-14">{{ $t('projects.filter.owner') }}</div>
							<v-icon style="margin-left: auto; margin-top: 1px" small>{{
								projectOwnerExpanded ? '$arrowUp' : '$arrowDown'
							}}</v-icon>
						</div>

						<div v-if="projectOwnerExpanded" class="assigned-user ellipsis">
							<v-checkbox
								v-model="filter.projectOwner"
								v-for="user in userOptions.filter((u) => u.userId !== 'unassigned')"
								:value="user.userId"
								:key="user.userId"
								hide-details
								dense
							>
								<template v-slot:label>
									<assigned-user v-if="user.userId !== 'unassigned'" :assigned-to="user.userId"></assigned-user>
									<span v-else>{{ $t('projects.unassigned') }}</span>
								</template>
							</v-checkbox>
						</div>
					</div>
					<div class="filter-option-box">
						<div class="row-format" @click="projectTypeExpanded = !projectTypeExpanded">
							<div class="font-14">Project type</div>
							<v-icon style="margin-left: auto; margin-top: 1px" small>{{
									projectTypeExpanded ? '$arrowUp' : '$arrowDown'
								}}</v-icon>
						</div>

						<div v-if="projectTypeExpanded" class="assigned-user ellipsis">
							<v-checkbox
									v-model="filter.projectType"
									v-for="type in $store.state.projectTypes"
									:value="type.id"
									:key="type.id"
									hide-details
									dense
							>
								<template v-slot:label>
									<span style="font-size: 14px" class="font-14 brand-semilight">{{ type.name }}</span>
								</template>
							</v-checkbox>
						</div>
					</div>
				</div>

				<div class="mx-3 mb-3 mt-3">
					<v-btn text block color="secondary" elevation="0" style="font-weight: 500" class="mt-2" @click="clearFilter()"
						><v-icon small>$clear</v-icon> Clear Filters</v-btn
					>
					<v-btn class="super-action mt-1" @click="showMenu = false" color="primary" block>{{
						$t('global.done')
					}}</v-btn>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import AssignedUser from "@/components/AssignedUser";

	export default {
		name: 'ProjectListFilter',

		props: ['filter'],

		components: {
			AssignedUser
		},

		data() {
			return {
				showMenu: false,
				clientExpanded: false,
				projectOwnerExpanded: false,
				projectTypeExpanded: false,
			};
		},

		mounted() {
			this.validateFilterStructure();
		},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			validateFilterStructure: function() {
				if (!Array.isArray(this.filter.clientList)) {
					this.filter.assignedTo = [];
				}
				if (!Array.isArray(this.filter.projectOwner)) {
					this.filter.projectOwner = [];
				}
				if (!Array.isArray(this.filter.projectType)) {
					this.filter.projectType = [];
				}
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.filter.search = null;
				this.filter.clientList.splice(0, this.filter.clientList.length);
				this.filter.projectOwner.splice(0,this.filter.projectOwner.length);
				this.filter.projectType.splice(0,this.filter.projectType.length);
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			clientList: function() {
				let result = [];
				this.$store.getters.clients.forEach((c) => {
					result.push({
						text: c.name,
						value: c.id,
					});
				});
				return result;
			},

			userOptions: function() {
				const unassigned = {
					firstName: this.$t('projects.unassigned'),
					userId: 'unassigned',
				};
				const options = [...this.$store.state.usersForAccount];
				options.push(unassigned);
				return options;
			},

			isFilterActive: function() {
				return this.filter.clientList.length;
			},

			filterLabel: function() {
				let label = [];

				if (this.filter.clientList && this.filter.clientList.length) {
					label.push(`${this.filter.clientList.length} clients`);
				}

				if (this.filter.projectOwner && this.filter.projectOwner.length) {
					label.push(`${this.filter.projectOwner.length} owners`);
				}

				if (this.filter.ownerList && this.filter.ownerList.length) {
					label.push(`${this.filter.projectType.length} types`);
				}

				if (label.length === 0) {
					return 'Filter';
				} else {
					return label.join(" & ")
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
