<template>
	<div id="project-kanban" class="row-format fill-height">
		<div class="my-0 py-0 fill-height" id="kanban-container">
			<div id="kanban-v-row" class="mr-3">
				<div class="row-format">
					<div
						v-if="cards['unknown'].length > 0"
						class="kanban-v-col"
						:style="'--kanban-width: ' + statusList.length * 272 + 'px'"
					>
						<div class="kanban-column" id="id.unknown">
							<div class="row-format">
								<span class="kanban-label" style="white-space: nowrap; padding-bottom: 10px"
									>[ {{ $t('projects.no-status') }} ]</span
								>
							</div>
							<div id="unknown">
								<draggable
									:list="cards['unknown']"
									:group="{ name: 'card', pull: 'clone' }"
									tag="section"
									:emptyInsertThreshold="100"
									:animation="200"
									:handle="$store.state.isMobile ? 'disabled' : '.cardWrapper'"
									ghost-class="ghost"
									:move="cardDragHandler"
									@end="endDragHandler"
								>
									<div
										v-for="deliverable in cards['unknown']"
										:key="deliverable.id"
										@click.stop="editDeliverable(deliverable)"
									>
										<kanban-card
											:deliverable="deliverable"
											:status-list="statusList"
											:custom-fields="customFields"
											:single-project-mode="singleProjectMode"
											:visible-fields="visibleFields"
											@navigate-to-project="navigateToProject($event)"
											@edit-subtask="editDeliverable($event)"
											@add-subtask="addSubtask(deliverable)"
										></kanban-card>
									</div>
								</draggable>
							</div>
						</div>
					</div>
					<draggable
						:disabled="isCollaborator"
						:list="statusList"
						handle=".columnDragHandle"
						:animation="200"
						ghost-class="ghost"
						@end="endColumnDragHandler"
						class="row-format"
					>
						<div v-for="column in projectType.statusList" :key="column.id">
							<div class="kanban-v-col" v-if="isInStatusFilter(filter, column.id)">
								<div
									class="kanban-column pointer"
									:id="`id.${column.id}`"
									:style="
										`--r: ${convertToRGB(column.hexColor)[0]}; --g: ${
											convertToRGB(column.hexColor)[1]
										}; --b: ${convertToRGB(column.hexColor)[2]};`
									"
									@click="addDeliverable(column)"
								>
									<kanban-header
										:all-columns="statusList"
										:column="column"
										:is-collaborator="isCollaborator"
										:current-view="currentView"
										@add-deliverable="addDeliverableNoWait($event)"
										@confirm-delete-column="confirmDeleteColumn($event)"
										@update-deliverable-status-list="updateDeliverableStatusList()"
									></kanban-header>
									<draggable
										:list="cards['id' + column.id]"
										:group="{ name: 'card', pull: 'clone' }"
										tag="section"
										:style="
											`height: calc(var(--vh) - ${
												singleProjectMode ? '350px' : '175px'
											}); overflow-y: auto;`
										"
										:emptyInsertThreshold="100"
										:animation="200"
										:handle="$store.state.isMobile ? 'disabled' : '.cardWrapper'"
										ghost-class="ghost"
										:class="`draggable-container ${$store.getters.scroll}`"
										:move="cardDragHandler"
										@end="endDragHandler"
									>
										<div
											v-for="deliverable in cards['id' + column.id]"
											:key="deliverable.id"
											@click.stop="editDeliverable(deliverable)"
											class="mb-3"
										>
											<kanban-card
												:deliverable="deliverable"
												:status-list="statusList"
												:custom-fields="customFields"
												:single-project-mode="singleProjectMode"
												:visible-fields="visibleFields"
												@navigate-to-project="navigateToProject($event)"
												@add-new="addDeliverable(column)"
												@add-new-empty="addDeliverableNoWait(column)"
												@edit-subtask="editDeliverable($event)"
												@add-subtask="addSubtask(deliverable)"
											></kanban-card>
										</div>

										<div
											class="row-format align-center font-14 font-gray_50 mt-3 mb-16"
											@click="addDeliverable(column)"
										>
											<div>+ Add task</div>
										</div>
									</draggable>
								</div>
							</div>
						</div>
					</draggable>

					<div>
						<div
							v-if="!isCollaborator"
							class="row-format align-center pointer font-14 font-gray_70 mt-3 ml-2 mr-6"
							@click="addDeliverableStatus()"
						>
							<v-icon size="16" color="gray_70">add</v-icon>
							<div style="white-space: nowrap">Add new status</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<confirm-dialog
			v-if="!isCollaborator"
			:dialog="deleteColumnDialog"
			@close="cancelDeleteColumn"
			@confirm="deleteColumn()"
			heading-text="Confirm"
			yes-text="Delete"
			no-text="Cancel"
			:body-text="$t('projects.delete-column')"
		/>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';
	import KanbanCard from './KanbanCard';
	import ProjectMixin from './ProjectMixin';
	import KanbanHeader from './KanbanHeader';
	import ConfirmDialog from '@/components/ConfirmDialog';

	export default {
		name: 'ProjectKanban',

		mixins: [ProjectMixin],

		props: [
			'deliverables',
			'updateFlag',
			'filter',
			'isCollaborator',
			'currentView',
			'headerHeight',
			'singleProjectMode',
			'statusList',
			'customFields',
			'projectType',
			'visibleFields'
		],

		components: {
			KanbanHeader,
			KanbanCard,
			Draggable,
			ConfirmDialog,
		},

		data: function() {
			return {
				projectSelectorDialog: false,
				newProjectStatus: null,
				selectedCard: null,
				targetSortIndex: null,
				fromStatusId: null,
				toStatusId: null,

				clickCount: 0,
				clickTimer: null,
				startTime: 0,
			};
		},

		beforeMount() {
			this.startTime = Date.now();
		},

		mounted() {
			console.log('ProjectKanban mounted in', Date.now() - this.startTime);
			this.$store.commit('stopLoading');
			this.$track.record('page-view', { module: 'project-kanban' });
		},

		methods: {
			convertToRGB: function(hex) {
				let aRgbHex = hex.replace('#', '').match(/.{1,2}/g);
				let aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
				return aRgb;
			},

			addDeliverable: function(column) {
				this.clickCount++;
				if (this.clickCount === 1) {
					this.clickTimer = setTimeout(() => {
						this.clickCount = 0;
					}, 500);
					return;
				}

				clearTimeout(this.clickTimer);
				this.clickCount = 0;

				this.$emit('add-deliverable', column);
			},

			addDeliverableNoWait: function(column) {
				this.$emit('add-deliverable', column);
			},

			editDeliverable: function(deliverable) {
				this.$emit('edit-deliverable', deliverable);
			},

			addSubtask: function(deliverable){
				let defaults = {
					id: null,
					defaultStatusId: this.projectType.statusList[0].id,
					projectTypeId: deliverable.projectTypeId,
					parentTaskId: deliverable.id,
				}

				this.$emit('add-subtask', defaults);
			},

			cardDragHandler: function(event) {
				if (event.to.parentNode.id === 'unknown') {
					this.selectedCard = null;
					this.targetSortIndex = null;
					return false;
				}

				this.selectedCard = event.draggedContext.element;
				this.targetSortIndex = event.draggedContext.futureIndex;
				return true;
			},

			endDragHandler: function(event) {
				if (this.selectedCard === null) {
					return;
				}

				let updates = [];

				this.fromStatusId = event.from.parentElement.id.split('.')[1];
				this.toStatusId = event.to.parentElement.id.split('.')[1];

				let sortArray = this.cards['id' + this.toStatusId];

				for (let i = 0; i < sortArray.length; i++) {
					if (sortArray[i].kanbanSort !== i || sortArray[i].id === this.selectedCard.id) {
						let update = {
							deliverableId: sortArray[i].id,
							projectId: sortArray[i].project ? sortArray[i].project.id : null,
							kanbanSort: i,
							statusId: this.toStatusId,
							statusLabel: null,
						};

						if (sortArray[i].statusId !== this.toStatusId) {
							update.statusLabel = this.$store.getters.getDeliverableStatusById(this.toStatusId).label;
						}

						updates.push(update);
					}
					sortArray[i].kanbanSort = i;
					sortArray[i].statusId = this.toStatusId;
				}

				this.$emit('update-kanban-props', updates);

				this.updateCounter++;
				this.targetSortIndex = null;
				this.selectedCard = null;
				this.fromStatusId = null;
				this.toStatusId = null;
			},
		},

		computed: {
			cards: function() {
				if (this.updateFlag) {
					//do nothing - just reference for updating ui
				}

				let columns = [...this.statusList];
				let cards = {
					unknown: [],
				};

				for (let i = 0; i < columns.length; i++) {
					cards['id' + columns[i].id] = [];
				}

				if (this.deliverables.length === 0) {
					let colId = 'id' + columns[0].id;
					cards[colId].push({
						emptyCard: true,
					});
				} else {
					for (let i = 0; i < this.deliverables.length; i++) {
						let colId = 'id' + this.deliverables[i].statusId;
						if (Object.prototype.hasOwnProperty.call(cards, colId)) {
							cards[colId].push(this.deliverables[i]);
						} else {
							cards.unknown.push(this.deliverables[i]);
						}
					}
				}

				return cards;
			},
		},
	};
</script>

<style lang="scss">
	#project-kanban {
		//::-webkit-scrollbar {
		//	display: none; // Inherits width and height from brand.scss
		//}
		.add-column {
			width: 36px;
			height: 36px;
			min-width: 36px;
			min-height: 36px;
			cursor: pointer;
			background-color: var(--v-gray_20-base);
			border-radius: 8px;
		}
		#kanban-container {
			overflow-x: auto;
			overflow-y: hidden;
			//height: calc(100% - 50px);

			#kanban-v-row {
				.kanban-v-col {
					margin-left: 0;
					margin-right: 16px;
					//height: calc(100% - 200px);
					min-width: 260px;
					max-width: 260px;
					overflow-y: scroll;
					.kanban-column {
						background: rgba(var(--r), var(--g), var(--b), 0.09);
						padding: 8px;
						border-radius: 8px;
						text-align: left;
						filter: none;

						.draggable-container {
							//&::-webkit-scrollbar {
							//	display: none!important; // Inherits width and height from brand.scss
							//}
						}

						.hidden-scroll {
							&::-webkit-scrollbar {
								display: none !important; // Inherits width an d height from brand.scss
							}
						}
					}

					.new-column {
						height: 160px;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f9f9f7 100%), #f0efea;
						padding: 8px;
						border-radius: 8px;
						text-align: left;
						filter: none;
					}
				}
			}
		}
	}
</style>
