var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","position":"relative"}},[_c('v-data-table',{attrs:{"id":"subtask-table","items":_vm.filteredSubtasks,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":true},scopedSlots:_vm._u([{key:"header.select",fn:function(){return [_c('div',{staticClass:"column-format align-center"},[_c('input',{attrs:{"type":"checkbox"},on:{"input":function($event){return _vm.allChecked($event)}}})])]},proxy:true},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"column-format align-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSubtasks),expression:"selectedSubtasks"}],attrs:{"type":"checkbox"},domProps:{"value":item.id,"checked":Array.isArray(_vm.selectedSubtasks)?_vm._i(_vm.selectedSubtasks,item.id)>-1:(_vm.selectedSubtasks)},on:{"change":function($event){var $$a=_vm.selectedSubtasks,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedSubtasks=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedSubtasks=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedSubtasks=$$c}}}})])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format task-name",staticStyle:{"width":"100%","max-width":"200px"}},[_c('div',{staticClass:"edit-task-name",staticStyle:{"min-width":"100%"},attrs:{"contenteditable":"true"},on:{"blur":function($event){return _vm.setName(item, $event)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"ml-auto row-format align-center open-icon font-14 pointer",on:{"click":function($event){return _vm.editSubtask(item)}}},[_c('v-icon',{staticClass:"material-symbols-rounded",attrs:{"size":"18","color":"black"}},[_vm._v("expand_content")]),_c('div',{staticStyle:{"margin-left":"2px"}},[_vm._v("Open")])],1)])]}},{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"row-format"},[_c('div',_vm._g({staticClass:"deliverableStatus row-format align-center pointer",style:(("--status-color:" + (_vm.status(item.statusId).hexColor)))},on),[_c('div',{staticClass:"statusBox"}),_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.status(item.statusId).label))]),(item.archived)?_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({content:'Archived task'}),expression:"{content:'Archived task'}"}],staticClass:"ml-2",attrs:{"size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.unarchiveTask(item)}}},[_vm._v("archived")]):_vm._e()],1)])]}}],null,true)},[_c('div',{staticClass:"text-left pa-3 font-14",staticStyle:{"background-color":"var(--v-white-base)"}},_vm._l((_vm.statusList),function(status){return _c('div',{key:status.id,staticClass:"py-1 pointer",on:{"click":function($event){return _vm.setStatus(item, status)}}},[_c('div',{staticClass:"deliverableStatus row-format align-center highlight-hover",style:(("--status-color:" + (status.hexColor)))},[_c('div',{staticClass:"statusBox"}),_c('div',[_vm._v(_vm._s(status.label))])])])}),0)])]}},{key:"item.taskPriority",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"deliverableStatus row-format align-center highlight-hover pr-1 pointer",style:(("--status-color:" + (_vm.priorityColor(item.taskPriority))))},on),[_c('div',{staticClass:"statusBox"}),_c('div',[_vm._v(_vm._s(item.taskPriority))])])]}}],null,true)},[_c('div',{staticClass:"text-left pa-3 font-14",staticStyle:{"background-color":"var(--v-white-base)"}},_vm._l((_vm.priorityList),function(priority){return _c('div',{key:priority,staticClass:"py-1 pointer",on:{"click":function($event){return _vm.setPriority(item, priority)}}},[_c('div',{staticClass:"row-format align-center highlight-hover"},[_c('div',[_vm._v(_vm._s(priority))])])])}),0)])]}},{key:"item.assignedToList",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"ml-auto pointer"},on),[(item.assignedToList.length)?_c('div',{staticClass:"row-format align-center"},_vm._l((item.assignedToList),function(assignedTo,index){return _c('assigned-user',_vm._g({key:assignedTo,style:(((index > 0 ? 'margin-left: -6px' : '') + "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " + index)),attrs:{"show-name":false,"small":true,"assigned-to":assignedTo}},on))}),1):_c('div',[_vm._v(" -- ")])])]}}],null,true)},[_c('div',{staticClass:"text-left pa-3 font-14",staticStyle:{"background-color":"var(--v-white-base)"}},_vm._l((_vm.userList),function(user){return _c('div',{key:user.userId,staticClass:"row-format align-center text-left py-1 pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.assignedToList),expression:"item.assignedToList"}],staticStyle:{"width":"20px","height":"20px","border-radius":"4px"},attrs:{"type":"checkbox"},domProps:{"value":user.userId,"checked":Array.isArray(item.assignedToList)?_vm._i(item.assignedToList,user.userId)>-1:(item.assignedToList)},on:{"change":[function($event){var $$a=item.assignedToList,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=user.userId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "assignedToList", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "assignedToList", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "assignedToList", $$c)}},function($event){return _vm.setAssignedToList(item, item.assignedToList)}]}}),(user.userId)?_c('assigned-user',{staticClass:"mx-2",attrs:{"assigned-to":user.userId,"show-name":true}}):_vm._e()],1)}),0)])]}},{key:"item.startDate",fn:function(ref){
									var item = ref.item;
return [_c('date-selector',{attrs:{"micro":true,"micro-font":"font-14","date":item.startDate,"label":"&nbsp;"},on:{"change":function($event){return _vm.setStartDate(item, $event)}}})]}},{key:"item.dueDate",fn:function(ref){
									var item = ref.item;
return [_c('date-selector',{attrs:{"micro":true,"micro-font":"font-14","date":item.dueDate,"label":"&nbsp;"},on:{"change":function($event){return _vm.setDueDate(item, $event)}}})]}}])}),_c('div',{staticClass:"row-format mt-2"},[_c('div',{staticClass:"font-14 font-gray_50 pointer",staticStyle:{"width":"fit-content"},on:{"click":_vm.createSubtask}},[_c('v-icon',{staticClass:"mb-1",attrs:{"size":"14"}},[_vm._v("$plus")]),_vm._v(" Add subtask ")],1),_c('div',{staticClass:"ml-auto font-14 font-gray_50 pointer",staticStyle:{"width":"fit-content"},on:{"click":function($event){_vm.showArchived = !_vm.showArchived}}},[_vm._v(" "+_vm._s(_vm.showArchived ? 'Hide' : 'Show')+" archived ")])]),_c('div',{staticClass:"column-format centered"},[(_vm.showBulkDialog)?_c('bulk-actions',{attrs:{"tasks":_vm.selectedTaskList,"label":"Subtasks","status-list":_vm.statusList,"user-list":_vm.userList},on:{"task-updated":function($event){return _vm.handleSubtaskUpdated($event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }