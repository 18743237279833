<template>
	<div class="">
		Loading...
	</div>
</template>

<script>
export default {
	name: 'Loading',
	methods: {

	},
};
</script>

<style scoped>

</style>