import { render, staticRenderFns } from "./ProjectGroup.vue?vue&type=template&id=4462b15a&scoped=true&"
import script from "./ProjectGroup.vue?vue&type=script&lang=js&"
export * from "./ProjectGroup.vue?vue&type=script&lang=js&"
import style0 from "./ProjectGroup.vue?vue&type=style&index=0&id=4462b15a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4462b15a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VIcon,VMenu,VRadio,VRadioGroup})
