<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Settings' }]">
			Workflow automations
		</page-header>

		<div class="row-format mb-4">
			<v-text-field
					outlined
					hide-details
					dense
					v-model="search"
					placeholder="Search..."
					style="max-width: 200px;"
					class="standard-input"
			>
				<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
			</v-text-field>
			<v-btn class="ml-auto super-action" @click="addWorkflow()"><v-icon size="20">add</v-icon> Add workflow</v-btn>
		</div>

		<v-data-table
			:headers="headers"
			:items="filteredWorkflows"
			@click:row="openEditor($event)"
			sort-by="name"
			class="pointer"
			:items-per-page="-1"
			:hide-default-footer="true"
		>
			<template v-slot:item.ruleLabel="{ item }">
				<span>{{ item.ruleLabel }}</span>
			</template>

			<template v-slot:item.publishedTimestamp="{ item }">
				<div class="row-format gap-1">
					<div v-if="item.publishedTimestamp">{{ $formatters.formatForTimeAgo(item.publishedTimestamp) }}</div>
					<div class="font-12 font-gray_70" v-if="item.draft">(In draft)</div>
				</div>
			</template>

			<template v-slot:item.executions="{ item }">
				<div
					style="color: var(--v-primary-base); border: 2px solid var(--v-gray_60-base); width: fit-content; padding-left: 4px; padding-right: 4px; text-align: center; border-radius: 4px;"
					v-tippy="{ content: 'View events' }"
					@click.stop="viewEvents(item)"
				>
					{{ item.executions }}
				</div>
			</template>

			<template v-slot:item.delete="{ item }">
				<div class="row-format gap-2">
					<v-icon
						@click.stop="addWorkflow(item)"
						v-tippy="{ content: 'Duplicate workflow' }"
						size="20"
						class="material-symbols-rounded"
						>content_copy</v-icon
					>
					<v-icon
						@click.stop="confirmDelete(item)"
						v-tippy="{ content: 'Delete workflow' }"
						size="20"
						class="material-symbols-rounded"
						>delete</v-icon
					>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import WorkflowEditor from '@/modules/workflow/WorkflowEditor';
	import PipelineSettingsService from '@/modules/pipeline/settings/PipelineSettingsService';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import NewWorkflow from '@/modules/workflow/NewWorkflow';
	import ConfirmModal from '@/components/ConfirmModal';
	import AutomationModal from '@/modules/workflow/AutomationModal';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'WorkflowList',

		props: [],

		components: { PageHeader },

		data: function() {
			return {
				search: null,
				workflows: [],
				counts: {},
				workflowService: new WorkflowService(),
				pipelineSettingService: new PipelineSettingsService(),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.initialize);
			this.initialize();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
		},

		methods: {
			initialize: function(){
				this.loadPipelineSettings();
				this.getAutomationCounts().then(() => {
					this.loadWorkflows();
				});
			},

			confirmDelete: function(workflow) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to delete this workflow?  This cannot be undone.  Please enter "${workflow.name}" to confirm`,
					confirmText: workflow.name,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.workflowService.deleteWorkflow(workflow.id).then(() => {
							let ix = this.workflows.findIndex((w) => w.id === workflow.id);
							this.workflows.splice(ix, 1);
						});
					}
				});
			},

			ruleLabel: function(entryRule) {
				if (entryRule.eventType === 'OpportunityEntersStage') {
					let stage = this.stages.find((s) => s.id === entryRule.referenceId);
					if (stage) {
						return 'Opportunity enters: ' + stage.label;
					}
				}

				return '--';
			},

			getCountForWorkflow: function(workflowId) {
				if (Object.prototype.hasOwnProperty.call(this.counts, workflowId)) {
					return this.counts[workflowId];
				} else {
					return 0;
				}
			},

			viewEvents: function(workflow) {
				let binding = {
					workflowId: workflow.id,
				};
				this.$store.state.globalModalController.openModal(AutomationModal, binding);
			},

			getAutomationCounts: function() {
				return new Promise((resolve, error) => {
					this.workflowService
						.getAutomationCounts()
						.then((res) => {
							this.counts = res.data;
							resolve(this.counts);
						})
						.catch((err) => error(err));
				});
			},

			loadWorkflows: function() {
				this.workflowService.getWorkflows().then((res) => {
					this.workflows.splice(0, this.workflows.length);
					this.workflows.push(...res.data);
					this.workflows.forEach((w) => {
						w.ruleLabel = this.ruleLabel(w.entryRule);
						w.executions = this.getCountForWorkflow(w.id);
					});
				});
			},

			loadPipelineSettings: function() {
				this.pipelineSettingService.getPipelineStages().then((res) => {
					this.$store.state.pipelineStages.splice(0, this.$store.state.pipelineStages.length);
					this.$store.state.pipelineStages.push(...res.data.stages);
				});
			},

			addWorkflow: function(clone) {
				let binding = {
					defaultName: clone ? `Copy of: ${clone.name}` : null,
					flow: clone ? clone.flow : null,
				};
				this.$store.state.globalModalController.openModal(NewWorkflow, binding).then((res) => {
					if (res) {
						this.$onBoarding.track('pipeline_create_workflow');
						this.workflows.push(res);
						this.openEditor(res);
					}
				});
			},

			openEditor: function(workflow) {
				let binding = {
					id: workflow.id,
				};
				this.$store.state.globalModalController
					.openModal(WorkflowEditor, binding, false, true, true, true)
					.then((res) => {
						let ix = this.workflows.findIndex((w) => w.id === workflow.id);

						res.ruleLabel = this.ruleLabel(res.entryRule);
						res.executions = this.getCountForWorkflow(res.id);

						this.workflows.splice(ix, 1, res);
					});
			},
		},

		computed: {
			stages: function() {
				return this.$store.state.pipelineStages;
			},
			filteredWorkflows: function() {
				if (this.search) {
					return this.workflows.filter((t) => {
						let search = this.search.toLowerCase();
						if(t.name?.toLowerCase().includes(search)){
							return true;
						}else if(t.ruleLabel?.toLowerCase().includes(search)){
							return true;
						}
						return false;
					});
				} else {
					return this.workflows;
				}
			},
			headers: function() {
				return [
					{ text: 'Name', value: 'name', class: 'nowrap' },
					{ text: 'Entry type', value: 'ruleLabel', class: 'nowrap' },
					{ text: 'Status', value: 'status', class: 'nowrap' },
					{ text: 'Published', value: 'publishedTimestamp', class: 'nowrap' },
					{ text: 'Executions', value: 'executions', class: 'nowrap' },
					{ text: '', value: 'delete', class: 'nowrap', align: 'right' },
				];
			},
		},
	};
</script>

<style lang="scss"></style>
