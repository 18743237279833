<template>
	<div class="cardWrapper">
		<div v-if="deliverable.emptyCard" class="blankCard mx-0 my-2 pl-4 pr-0 pt-2" @click.stop="$emit('add-new-empty')">
			<v-icon size="34">$duplicate</v-icon>
			<span class="font-grey">{{ $t('projects.add-first-deliverable') }}</span>
		</div>
		<div v-else>
			<div class="card pa-4">
				<div class="column-format gap-2 flex-grow-1">
					<div class="row-format align-center" v-if="!singleProjectMode && deliverable.client && isVisible('project')">
						<client-avatar :small="true" :client="deliverable.client" v-if="deliverable.client"></client-avatar>
						<div class="font-12 column-format text-truncate" style="margin-left: 6px;">
							<div class="brand-bold">{{ deliverable.client ? deliverable.client.name : '' }}</div>
							<div v-if="projectName" class="mt-n1">{{ projectName }}</div>
						</div>
					</div>
					<div class="font-14 column-format gap-1">
						<div style="line-height: 1.1">{{ deliverableName }}</div>
					</div>

					<div v-if="isVisible('status')">
						<div class="deliverableStatus row-format align-center" :style="`--status-color:${status.hexColor}`">
							<div class="statusBox"></div>
							<div class="text-no-wrap">{{ status.label }}</div>
						</div>
					</div>

					<div class="column-format gap-1">
						<div
							class="font-12"
							v-for="field in activeCustomFields"
							v-show="getCustomValue(field.id)"
							:key="field.id"
						>
							<span class="font-gray_60">{{ field.name }}: </span>
							<span class="">{{ getCustomValue(field.id) }}</span>
						</div>

						<div
							v-if="isVisible('priority')"
							class="deliverableStatus row-format align-center highlight-hover pr-1"
							:style="`--status-color:${priorityColor}`"
						>
							<div><span class="font-gray_60">Priority:</span> {{ deliverable.taskPriority }}</div>
						</div>

						<div class="font-12" v-if="isVisible('rank')">
							<div><span class="font-gray_60">Rank:</span> {{ deliverable.priority }}</div>
						</div>
					</div>

					<div class="row-format align-center gap-2">
						<v-icon v-if="deliverable.archived" size="20" color="gray_70" v-tippy="{ content: 'Archived' }"
							>lock</v-icon
						>
						<v-icon
							color="success"
							small
							class="ml-auto"
							v-if="deliverable.invoiceId"
							v-tippy="{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
							>attach_money</v-icon
						>
						<div class="row-format align-center" v-if="deliverable.assignedToList.length && isVisible('assignedTo')">
							<assigned-user
								v-for="(assignedTo, index) in deliverable.assignedToList"
								:key="assignedTo"
								:show-name="false"
								:small="true"
								:assigned-to="assignedTo"
								:style="
									`${
										index > 0 ? 'margin-left: -6px' : ''
									}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
								"
							></assigned-user>
						</div>
						<div
							class="font-12 font-grey row-format align-center"
							v-if="
								(deliverable.dueDate || deliverable.startDate) && (isVisible('startDate') || isVisible('dueDate'))
							"
						>
							{{ timeline }}
						</div>
						<div
							class="ml-auto"
							style="position: relative"
							v-if="deliverable.comments.length && isVisible('comments')"
						>
							<v-icon color="gray_70" size="20">comment</v-icon>
							<div
								class="row-format centered brand-bold"
								style="
												position: absolute;
												top: 0;
												left: 12px;
												width: 16px;
												height: 16px;
												border-radius: 10px;
												color: var(--v-white-base);
												background-color: var(--v-secondary-base);
											"
							>
								<div class="font-12">{{ deliverable.comments.length }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row-format mt-1" style="justify-content: space-between">
					<div class="row-format align-center" v-if="isVisible('toDos')">
						<div
							class="font-12 font-grey pointer row-format align-center"
							v-if="deliverable.tasks.length > 0"
							@click.stop="showTasks = !showTasks"
						>
							<v-icon size="16">check</v-icon>
							<div class="ml-1">{{ getTaskCountLabel(deliverable.tasks) }}</div>
						</div>
						<div
							class="font-12 font-grey pointer row-format align-center"
							v-if="deliverable.tasks.length === 0"
							@click.stop="addFirstTask()"
						>
							<v-icon size="16">check</v-icon>
							<v-icon size="16">add</v-icon>
						</div>
					</div>
					<div>
						<div v-if="currentTimer" class="row-format align-center gap-1">
							<v-icon class="material-symbols-rounded" size="20" color="gray_50" @click.stop="stopRunningTimer()">stop_circle</v-icon>
							<div class="font-12 font-gray_70">{{$store.state.currentTimerDisplay}}</div>
						</div>
						<div v-else class="show-on-hover">
							<v-icon class="material-symbols-rounded" size="20" color="gray_30" @click.stop="startRunningTimer()">play_circle</v-icon>
						</div>
					</div>
					<div
						v-if="!deliverable.parentTaskId"
						class="row-format align-center"
						style=""
						@click.stop="showSubtasks = !showSubtasks"
					>
						<div v-if="deliverable.subtasks && deliverable.subtasks.length" class="font-12 row-format centered pa-1">
							<div>{{ deliverable.subtasks.length }}</div>
						</div>
						<v-icon
							class="material-symbols-rounded"
							size="20"
							:color="deliverable.subtasks && deliverable.subtasks.length ? 'black' : 'gray_30'"
							>graph_2</v-icon
						>
					</div>
					<div v-else>&nbsp;</div>
				</div>
				<div class="" v-show="showTasks && deliverable.tasks.length > 0">
					<tasks
						ref="tasks"
						v-model="deliverable.tasks"
						@empty="showTasks = false"
						@change="updateTasks($event)"
					></tasks>
				</div>
			</div>
			<div v-if="showSubtasks" class="column-format ml-4 mb-5" style="border-left: 1px solid var(--v-gray_30-base);">
				<div
					v-for="subtask in deliverable.subtasks"
					:key="subtask.id"
					class="row-format align-center mt-2"
					@click.stop="$emit('edit-subtask', subtask)"
				>
					<div style="border-top: 1px solid var(--v-gray_30-base); height:1px; width:18px; max-width: 18px;"></div>
					<kanban-card
						style="flex-grow: 1"
						:deliverable="subtask"
						:status-list="statusList"
						:custom-fields="customFields"
						:single-project-mode="true"
						:visible-fields="visibleFields"
					></kanban-card>
				</div>
				<div class="row-format align-center mt-2" style="margin-bottom: -9px;">
					<div style="border-top: 1px solid var(--v-gray_30-base); height:1px; width:18px; max-width: 18px;"></div>
					<div
						class="pointer highlight-hover font-12 pl-1"
						style="width: fit-content; color: var(--v-gray_50-base)"
						@click.stop="$emit('add-subtask')"
					>
						+ Add subtask
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
	import DateTime from '@/modules/utils/HDateTime';
	import ClientAvatar from '@/components/ClientAvatar';
	import AssignedUser from '@/components/AssignedUser';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';

	export default {
		name: 'KanbanCard',

		props: ['deliverable', 'showStatus', 'statusList', 'customFields', 'singleProjectMode', 'visibleFields'],

		components: {
			AssignedUser,
			ClientAvatar,
			Tasks,
		},

		data: function() {
			return {
				DateTime: HDateTime,
				showTasks: false,
				showSubtasks: false,
				deliverableService: new ProjectDeliverableService(),
				defaultVisibleFields: ['project','status','assignedTo','dueDate'],
				timeRefreshTimer: null
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('kanban-card-show-tasks', this.setShowTasks);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('kanban-card-show-tasks', this.setShowTasks);
		},

		methods: {
			isVisible: function(fieldType) {
				if(this.visibleFields) {
					return this.visibleFields.includes(fieldType);
				}else{
					return this.defaultVisibleFields.includes(fieldType);
				}
			},

			expandTasks: function() {
				this.showTasks = true;
			},

			collapseTasks: function() {
				this.showTasks = false;
			},

			getCustomValue: function(id) {
				let value = this.deliverable.customValues.find((c) => c.fieldId === id);
				if (value) {
					return this.$formatters.customFieldFormat(value.value);
				} else {
					return null;
				}
			},

			getTaskCountLabel: function(tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					for (let i = 0; i < tasks.length; i++) {
						if (tasks[i].complete) {
							completedCount++;
						}
					}

					return `${completedCount}/${taskCount}`;
				}
			},

			setShowTasks: function(showTasks) {
				this.showTasks = showTasks;
			},

			addFirstTask: function() {
				this.$refs.tasks.addNewTask(0);
				this.showTasks = true;
			},

			updateTasks() {
				const patch = [{ op: 'replace', path: '/tasks', value: this.deliverable.tasks }];
				this.deliverableService.patchDeliverable(this.deliverable.id, patch).catch((err) => {
					console.log(err);
				});
			},

			startRunningTimer: function() {
				let defaults = {
					clientId: this.deliverable.clientId,
					projectId: this.deliverable.projectId,
					deliverableId: this.deliverable.id,
					hideMenu: true,
				};

				this.$store.state.eventBus.$emit('start-timer', defaults);
			},

			stopRunningTimer: function() {
				this.$store.state.eventBus.$emit('stop-timer');
			},

			isTimerInScope: function(timer) {
				if (!timer || !this.deliverable) {
					return false;
				}

				return timer.deliverableId === this.deliverable.id;
			},
		},

		watch: {},

		computed: {
			priorityColor: function() {
				const colors = {
					Low: '#69B3F2', // Soft blue
					Normal: '#A8D5BA', // Soft green
					Medium: '#F7E380', // Soft yellow
					High: '#FF9F43', // Bright orange
					Urgent: '#D72638', // Deep crimson
				};

				return colors[this.deliverable.taskPriority] || 'var(--v-white-base)'; // Default to black if priority is not found
			},

			projectName: function() {
				if (this.deliverable.name && this.deliverable.project) {
					return this.deliverable.project.name;
				} else {
					return null;
				}
			},

			deliverableName: function() {
				if (this.deliverable.name) {
					return this.deliverable.name;
				} else if (this.deliverable.project) {
					return this.deliverable.project.name;
				} else {
					return null;
				}
			},

			currentTimer: function() {
				let timer = this.$store.state.currentTimer;
				if (timer && this.isTimerInScope(timer)) {
					return timer;
				} else {
					return null;
				}
			},

			timeline: function() {
				if (this.deliverable.startDate && this.deliverable.dueDate) {
					let start = DateTime.fromISO(this.deliverable.startDate).toLocaleString({
						month: 'short',
						day: 'numeric',
					});
					let due = DateTime.fromISO(this.deliverable.dueDate).toLocaleString({
						month: 'short',
						day: 'numeric',
					});
					return `${start} - ${due}`;
				} else if (this.deliverable.startDate) {
					let start = DateTime.fromISO(this.deliverable.startDate).toLocaleString({
						month: 'short',
						day: 'numeric',
					});
					return `Start ${start}`;
				} else if (this.deliverable.dueDate) {
					let due = DateTime.fromISO(this.deliverable.dueDate).toLocaleString({
						month: 'short',
						day: 'numeric',
					});
					return `Due ${due}`;
				} else {
					return null;
				}
			},

			status: function() {
				return this.statusList.find((s) => s.id === this.deliverable.statusId);
			},

			activeCustomFields: function() {
				if(this.customFields) {
					return this.customFields.filter((c) => this.isVisible('Custom.' + c.mappingKey));
				}else{
					return [];
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.card-wrapper {
		padding: 8px 8px 8px 8px;
		&:active {
			cursor: grabbing;
		}
	}

	.deliverableStatus {
		white-space: nowrap;
		font-size: 12px;

		.statusBox {
			margin-right: 4px;
			width: 14px;
			height: 14px;
			border-radius: 2px;
			white-space: nowrap;
			background-color: var(--status-color);
		}
	}

	.card {
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_20-base);

		border-radius: 4px;
		display: flex;
		flex-direction: column;


		.show-on-hover {
			visibility: hidden!important;
		}

		&:hover {
			border: 1px solid var(--v-gray_30-base);
			box-sizing: border-box;
			box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);

			.show-on-hover {
				visibility: visible!important;
			}
		}
	}

	.blankCard {
		background-color: var(--v-white-base);
		height: 139px;
		border: 1px solid var(--v-gray_50-base);
		box-sizing: border-box;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.project-name {
		font-size: 12px;
		border: 1px solid var(--v-gray_30-base);
		color: var(--v-gray_80-base);
		padding: 0px 6px;
		border-radius: 4px;
		width: fit-content;
		//width: fit-content;
		//white-space: nowrap;
		//text-overflow: ellipsis;
	}

	.deliverable-name {
		font-size: 14px;
		color: var(--v-gray_90-base);
	}

	.task-box {
		padding: 2px 6px;
		border-radius: 2px;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.no-select {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
</style>
