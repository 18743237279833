<template>
		<kanban-card :show-status="true" :deliverable="deliverable" v-if="deliverable" class="kanban-card" :status-list="statusList"></kanban-card>
</template>

<script>
	import KanbanCard from '@/modules/projects/management/KanbanCard';
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	export default {
		name: 'DeliverableLoader',

		props: ['event'],

		components: { KanbanCard },

		data: function () {
			return {
				deliverable: null,
				id: null,
				projectDeliverableService: new ProjectDeliverableService(),
			};
		},

		mounted() {
			this.id = this.event.meta ? this.event.meta.id : this.event.original.meta.id;
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			initialize: function () {
				this.projectDeliverableService
					.getDeliverable(this.id)
					.then((res) => {
						this.deliverable = res.data.deliverable;
						this.deliverable.client = res.data.client;
						this.deliverable.project = res.data.project;
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},

		computed: {
			statusList: function(){
				return this.$store.getters.getGlobalStatusList;
			}
		},
	};
</script>

<style scoped lang="scss">
	.kanban-card ::v-deep .card {
		box-sizing: inherit;
		box-shadow: none!important;
	}
</style>
