import DateTime from "@/modules/utils/HDateTime";
import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
import AggregateTimerList from '@/modules/calendar/lists/AggregateTimerList';

export default {
	methods: {
		addNewTimer(startDate, events) {
			let timerStart = startDate;
			let timerEnd = startDate.plus({ minutes: 30 });

			let timerEvent = {
				id: null,
				userId: this.$store.getters.getLoggedInUserId,
				timerStart: timerStart.toISO(),
				timerEnd: timerEnd.toISO(),
				duration: timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']),
			};

			this.$store.state.globalModalController.openModal(TimeTrackEdit, { timerEvent: timerEvent }).then((res) => {
				if (res) {
					let newTimer = {
						id: res.id,
						eventSource: 'TIMER_EVENT',
						start: res.timerStart,
						end: res.timerEnd,
						dateOnly: false,
						meta: {
							clientId: res.clientId,
							timerEvent: res,
						},
					};
					events.push(newTimer);
				}
			});
		},

		timerEventClicked(meta, events) {
			let event = events.find((e) => e.eventSource === 'TIMER_EVENT' && e.id == meta.timerEvent.id);

			let binding = {
				timerEvent: event.meta.timerEvent,
			};

			this.$store.state.globalModalController.openModal(TimeTrackEdit, binding, true, false).then((res) => {
				if (!res) return;
				let ix = events.findIndex((e) => e.eventSource === 'TIMER_EVENT' && e.id === res.id);
				if (ix > -1) {
					if (res.action && res.action === 'DELETED') {
						events.splice(ix, 1);
					} else {
						let updated = {
							id: res.id,
							eventSource: 'TIMER_EVENT',
							start: res.timerStart,
							end: res.timerEnd,
							dateOnly: false,
							meta: {
								clientId: res.clientId,
								timerEvent: res,
							},
						};
						events.splice(ix, 1, updated);
						this.refreshKey++;
					}
				}
			});
		},


		aggregateTimerClicked(meta, events) {
			let binding = {
				events: meta.timerEvent.aggregate.events,
			};
			this.$store.state.globalModalController.openModal(AggregateTimerList, binding, true, false).then((res) => {
				if (!res) return;

				//remove all the original events
				meta.timerEvent.aggregate.events.forEach((event) => {
					let ix = events.findIndex((e) => e.eventSource === 'TIMER_EVENT' && e.id === event.id);
					events.splice(ix, 1);
				});

				//put all the events back in
				res.forEach((e) => {
					events.push({
						id: e.id,
						eventSource: 'TIMER_EVENT',
						start: e.timerStart,
						end: e.timerEnd,
						dateOnly: false,
						meta: {
							clientId: e.clientId,
							timerEvent: e,
						},
					});
				});

				this.refreshKey++;
			});
		},


		splitHours: function (events) {
			let split = [];
			let result = [];

			events.forEach((e) => {
				let start = DateTime.fromISO(e.start);
				let end = DateTime.fromISO(e.end);

				if (end > start.endOf('day')) {
					let s = start;

					while (s < end) {
						let timerStart = s;
						let timerEnd;
						let clonedEvent = JSON.parse(JSON.stringify(e));

						if (end > s.endOf('day')) {
							timerEnd = s.endOf('day');
							s = s.plus({ days: 1 }).startOf('day');
						} else {
							timerEnd = end;
							s = end;
						}

						clonedEvent.start = timerStart.toISO();
						clonedEvent.end = timerEnd.toISO();
						clonedEvent.meta.timerEvent.timerStart = timerStart.toISO();
						clonedEvent.meta.timerEvent.timerEnd = timerEnd.toISO();
						clonedEvent.meta.timerEvent.duration = Math.round(timerEnd.diff(timerStart, 'seconds').seconds);
						split.push(clonedEvent);
					}
				} else {
					split.push(e);
				}
			});

			split.forEach((s1) => {
				let c = {
					name: s1.summary,
					start: DateTime.fromISO(s1.start).toJSDate(),
					end: DateTime.fromISO(s1.end).toJSDate(),
					timed: true,
					original: s1,
					source: s1.eventSource,
				};
				result.push(c);
			});

			return result;
		},

		aggregateHours: function (events, byClient) {
			let dates = new Map();
			let result = [];

			events.forEach((e) => {
				let start = DateTime.fromISO(e.start);
				let end = DateTime.fromISO(e.end);
				let key, duration;

				if (end > start.endOf('day')) {
					let s = start;

					while (s < end) {
						key = s.toISODate() + (byClient ? `|${e.meta.timerEvent.clientId}` : '');
						if (end > s.endOf('day')) {
							duration = Math.round(s.endOf('day').diff(s, 'seconds').seconds);
							s = s.plus({ days: 1 }).startOf('day');
						} else {
							duration = Math.round(end.diff(s, 'seconds').seconds);
							s = end;
						}

						if (!dates.has(key)) {
							dates.set(key, { duration: 0, events: [] });
						}

						dates.get(key).duration += duration;
						dates.get(key).events.push(e.meta.timerEvent);
					}
				} else {
					duration = Math.round(end.diff(start, 'seconds').seconds);
					key = start.toISODate() + (byClient ? `|${e.meta.timerEvent.clientId}` : '');

					if (!dates.has(key)) {
						dates.set(key, { duration: 0, events: [] });
					}

					dates.get(key).duration += duration;
					dates.get(key).events.push(e.meta.timerEvent);
				}
			});

			dates.forEach((v, k) => {
				let date = DateTime.fromISO(k.split('|')[0]);
				let clientId = null;

				if (byClient) {
					clientId = k.split('|')[1];
					clientId = clientId === 'null' ? null : clientId;
				}

				let aggregateEvent = {
					name: 'Timer worked ' + date,
					start: date.toJSDate(),
					end: date.toJSDate(),
					timed: true,
					original: {
						eventSource: 'AGGREGATE_TIMER',
						meta: {
							timerEvent: {
								clientId: clientId,
								aggregate: v,
							},
						},
					},
					source: 'AGGREGATE_TIMER',
				};

				result.push(aggregateEvent);
			});

			return result;
		},

		handleTimeMetricClicked: function(metric){
			let timerEvents = this.events
				.filter(e => e.eventSource === 'TIMER_EVENT')
				.filter(e => {
					if(metric){
						return e.meta.clientId === metric.id
					}else{
						return true;
					}
				})
				.filter(e => {
					let start = DateTime.fromISO(e.start);
					let end = DateTime.fromISO(e.end);
					if((start >= this.startDate && start <= this.endDate) || (end >= this.endDate && end <= this.endDate)){
						return true;
					}else{
						return false;
					}
				})
				.map(e => e.meta.timerEvent);

			let binding = {events:timerEvents};

			this.$store.state.globalModalController.openModal(AggregateTimerList, binding, true, false).then((res) => {
				res.forEach(t => {
					let ix = this.events.findIndex(e => e.eventSource === 'TIMER_EVENT' && e.id === t.id);

					let newTimer = {
						id: t.id,
						eventSource: 'TIMER_EVENT',
						start: t.timerStart,
						end: t.timerEnd,
						dateOnly: false,
						meta: {
							clientId: t.clientId,
							timerEvent: t,
						},
					};

					this.events.splice(ix,1,newTimer);
				})
			});
		},
	},

	computed: {
		timeMetricsByClient: function () {
			let result = [];

			let events = this.events
				.filter((e) => e.eventSource === 'TIMER_EVENT')
				.filter((e) => {
					let start = DateTime.fromISO(e.start);
					let end = DateTime.fromISO(e.end);
					return (start >= this.startDate && start <= this.endDate) || (end >= this.endDate && end <= this.endDate);
				}).filter((e) => this.filterByUser(e));

			let clients = new Map();

			events.forEach((e) => {
				let event = e.meta.timerEvent;
				this.incrementMapByKey(clients, event.clientId, event.duration);
			});

			let totalSeconds = 0;
			clients.forEach((v) => (totalSeconds += v));

			clients.forEach((v, k) => {
				let client = this.$store.getters.clients.find((c) => c.id === k);

				result.push({
					id: k,
					heading: client ? client.name : 'No client',
					subheading: this.$formatters.formatSeconds(v),
					value: v,
					color: client ? client.color : 'var(--v-gray_50-base)',
					percentage: Math.round((v / totalSeconds) * 100),
				});
			});

			result = result.filter((r) => r.percentage > 0);

			return {
				defaultHeading: 'Time worked',
				defaultSubheading: this.$formatters.formatSeconds(totalSeconds),
				metrics: result,
			};
		},
	},
};
