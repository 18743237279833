<template>
	<div class="panel-modal" style="width: 600px">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-semibold font-gray_80" style="font-size: 24px">
					<div v-if="isDisabled">{{ nativeEvent.summary }}</div>
					<v-text-field
						v-else
						style="width:300px"
						v-model="nativeEvent.summary"
						hide-details
						dense
						solo
						flat
						:placeholder="$t('calendar.event-title')"
					></v-text-field>
				</div>
				<div class="ml-auto row-format align-center gap-2">
					<pipeline-link-widget
							v-if="nativeEvent"
							:item-id="nativeEvent.id"
							item-type="CalendarEvent"
					></pipeline-link-widget>
					<v-btn class="primary-action" @click="handleClose">{{ $t('global.save') }}</v-btn>
					<v-btn color="secondary" icon @click="confirmDeleteDialog = true"><v-icon size="20">$delete</v-icon></v-btn>
				</div>
			</div>
		</div>

		<div class="panel-modal-body py-2 show-scrollbar">
			<v-container fluid v-if="isReady" style="background-color: var(--v-white-base); width: 700px" class="pa-0 ma-0">
				<v-row dense>
					<v-col cols="12" class="mb-3">
						<create-time-entry :start="startDate" :end="endDate" :notes="nativeEvent.summary"></create-time-entry>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="2">
						<v-menu
							ref="menu"
							:disabled="isDisabled"
							v-model="startDateMenu"
							:close-on-content-click="true"
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									:disabled="isDisabled"
									:value="nativeEvent.startDate"
									:label="$t('calendar.start-date')"
									readonly
									v-on="on"
									dense
									:hide-details="true"
								></v-text-field>
							</template>
							<v-date-picker
								:first-day-of-week="$store.getters.firstDayOfWeek"
								v-if="startDateMenu"
								v-model="nativeEvent.startDate"
								no-title
								scrollable
							>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="2" v-if="!nativeEvent.dateOnly">
						<v-menu
							ref="menu"
							:disabled="isDisabled"
							v-model="startTimeMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									:disabled="isDisabled"
									:value="timeTo12Hour(nativeEvent.startTime)"
									:label="$t('calendar.start-time')"
									readonly
									v-on="on"
									dense
									hide-details
								></v-text-field>
							</template>
							<v-time-picker
								v-if="startTimeMenu"
								v-model="nativeEvent.startTime"
								full-width
								:format="is12HourFormat ? 'ampm' : '24hr'"
							></v-time-picker>
						</v-menu>
					</v-col>
					<v-col cols="1" style="color: var(--v-gray_70-base)">To</v-col>
					<v-col cols="2">
						<v-menu
							ref="menu"
							:disabled="isDisabled"
							v-model="endDateMenu"
							:close-on-content-click="true"
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									:disabled="isDisabled"
									:value="nativeEvent.endDate"
									:label="$t('calendar.end-date')"
									readonly
									v-on="on"
									dense
									:hide-details="true"
								></v-text-field>
							</template>
							<v-date-picker
								:first-day-of-week="$store.getters.firstDayOfWeek"
								v-if="endDateMenu"
								v-model="nativeEvent.endDate"
								no-title
								scrollable
							>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="2" v-if="!nativeEvent.dateOnly">
						<v-menu
							ref="menu"
							:disabled="isDisabled"
							v-model="endTimeMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									:disabled="isDisabled"
									:value="timeTo12Hour(nativeEvent.endTime)"
									:label="$t('calendar.end-time')"
									readonly
									v-on="on"
									dense
									hide-details
								></v-text-field>
							</template>
							<v-time-picker
								v-if="endTimeMenu"
								v-model="nativeEvent.endTime"
								full-width
								:format="is12HourFormat ? 'ampm' : '24hr'"
							>
							</v-time-picker>
						</v-menu>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" class="text-left font-12">{{nativeEvent.timezone}}</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<v-checkbox
							hide-details
							dense
							:disabled="isDisabled"
							v-model="nativeEvent.dateOnly"
							:label="$t('calendar.all-day')"
							class="mt-2 pt-0"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" align="left">
						<div v-if="isDisabled">{{ nativeEvent.description ? nativeEvent.description : ' -- ' }}</div>
						<editor
							v-else
							ref="Description"
							:tabindex="2"
							v-model="nativeEvent.description"
							:id="nativeEvent.id"
							:placeholder="$t('calendar.event-description')"
							:input-only-mode="true"
							@update="nativeEvent.description = $event"
							:api-key="$store.getters.getTinyMceKey"
							:inline="true"
							:init="mceConfig"
							style="border-bottom: 1px solid var(--v-gray_30-base); padding-bottom: 10px"
						>
						</editor>
					</v-col>
				</v-row>
				<v-row dense align="center" class="mt-0 pt-4">
					<v-col cols="3">
						<v-checkbox
							:disabled="isDisabled"
							hide-details
							dense
							v-model="nativeEvent.reminder"
							:label="$t('calendar.reminder')"
							class="mt-0 pt-0"
						></v-checkbox>
					</v-col>
					<v-col cols="1" v-if="nativeEvent.reminder">
						<v-text-field
							:disabled="isDisabled"
							hide-details
							dense
							type="number"
							v-model="nativeEvent.remindTime"
						></v-text-field>
					</v-col>
					<v-col cols="2" v-if="nativeEvent.reminder">
						<v-select
							:disabled="isDisabled"
							hide-details
							dense
							:items="timeUnits"
							v-model="nativeEvent.remindTimeUnit"
						></v-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<v-checkbox
							:disabled="isDisabled"
							hide-details
							dense
							v-model="nativeEvent.busyEvent"
							:label="$t('calendar.busy-event')"
							class="mt-2 pt-0"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row align="center" class="mt-4" v-if="!isDisabled">
					<v-col cols="3" align="left" style="color: var(--v-gray_70-base)">
						{{ $t('calendar.shared-event') }}
						<v-icon v-if="!nativeEvent.sharedEvent">$lock</v-icon>
					</v-col>
					<v-col cols="4" align="right">
						<pill-selector
							option1="Private"
							option2="Shared"
							:dense="true"
							:defaultSelection="sharedFromBoolean(nativeEvent.sharedEvent)"
							:selectedBackground="this.$vuetify.theme.currentTheme.primary"
							selectedColor="white"
							@option-selected="nativeEvent.sharedEvent = sharedToBoolean($event)"
						></pill-selector>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="confirmDeleteDialog = false"
			@confirm="deleteEvent()"
			heading-text="Confirm"
			yes-text="Yes"
			no-text="No"
			:body-text="$t('calendar.delete-event-confirm')"
		>
		</confirm-dialog>
	</div>
</template>

<script>
	//import Markdown from '@/components/Markdown';
	import PillSelector from '@/components/PillSelector';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import CreateTimeEntry from '@/components/CreateTimeEntry';
	import HDateTime from '@/modules/utils/HDateTime';
	import editor from '@tinymce/tinymce-vue';
	import marked from 'marked';

	export default {
		name: 'NativeEventDetail',
		isRightModal: true,

		props: ['event'],

		components: {
			//Markdown,
			CreateTimeEntry,
			PillSelector,
			ConfirmDialog,
			editor,
			PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget')
		},

		data: function() {
			return {
				nativeEvent: this.event,
				confirmDeleteDialog: false,
				startDateMenu: false,
				startTimeMenu: false,
				endDateMenu: false,
				endTimeMenu: false,
				isReady: false,
				timeUnits: ['Minutes', 'Hours', 'Days'],
				mceConfig: {
					menubar: false,
					inline: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Add description',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
          toolbar: [
            'undo redo ' +
            'bold underline backcolor forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist formatting_group',
          ],
          content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",
          toolbar_groups: {
            formatting_group: {
              icon: 'format',
              tooltip: 'Formatting',
              items: 'italic fontsizeselect fontselect | numlist indent outdent table | removeformat',
            },
					fontsize_formats: '10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 40px 48px',
					},
				},
			};
		},

		mounted() {
			this.isReady = true;
			this.convertMarkdown();
		},

		beforeDestroy() {},

		methods: {
			convertMarkdown: function() {
				if (this.nativeEvent.format === 'Markdown') {
					this.nativeEvent.description = this.formatMarkdown(this.nativeEvent.description);
					this.nativeEvent.format = 'HTML';
				}
			},

			formatMarkdown: function(description) {
				let m = marked(description, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},

			sharedFromBoolean: function(val) {
				if (val) {
					return 'Shared';
				} else {
					return 'Private';
				}
			},

			sharedToBoolean: function(val) {
				if (val === 'Shared') {
					return true;
				} else {
					return false;
				}
			},

			timeTo24Hour: function(time) {
				let parts = time.split(' ');
				let hhmm = parts[0].split(':');
				let ampm = parts[1];

				let hour = parseInt(hhmm[0], 10);
				let min = parseInt(hhmm[1], 10);

				if (ampm === 'PM' && hour != 12) {
					hour = hour + 12;
				} else if (ampm == 'AM' && hour == 12) {
					hour = 0;
				}

				let finalTime = new String(hour).padStart(2, '0') + ':' + new String(min).padStart(2, '0') + ':00';
				return finalTime;
			},

			timeTo12Hour: function(time) {
				if (!this.is12HourFormat) {
					return time;
				}

				let parts = time.split(':');
				let hour = parseInt(parts[0], 10);
				let min = parts[1];
				let suffix = 'AM';

				if (hour == 0) {
					hour = '12';
				} else if (hour == 12) {
					suffix = 'PM';
				} else if (hour > 12) {
					hour = hour - 12;
					suffix = 'PM';
				}

				return hour + ':' + min + ' ' + suffix;
			},

			handleClose: function() {
				if (this.isDisabled) {
					this.$emit('result');
				} else {
					this.$emit('result', this.nativeEvent);
				}
			},

			deleteEvent: function() {
				this.confirmDeleteDialog = false;
				this.$emit('result', { action: 'DELETED', event: this.nativeEvent });
			},

			handleInput: function(targetProp, refName) {
				this.nativeEvent[targetProp] = this.$refs[refName].editor.getContent();
			},
		},

		computed: {
			startDate: function() {
				return this.nativeEvent.startDate + 'T' + this.nativeEvent.startTime;
			},

			endDate: function() {
				return this.nativeEvent.endDate + 'T' + this.nativeEvent.endTime;
			},

			isDisabled: function() {
				if (!this.nativeEvent.userId) {
					return false;
				}
				return this.nativeEvent.userId !== this.$store.getters.getLoggedInUserId;
			},

			is12HourFormat() {
				let now = HDateTime.now()
					.toLocaleString(HDateTime.TIME_SIMPLE)
					.toUpperCase();
				return now.includes('AM') || now.includes('PM');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
