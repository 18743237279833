<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Client management' }]">
			Client list
		</page-header>

		<div
			class="column-format fill-height"
			v-if="isReady"
		>
			<input id="copyToClip" name="copyToClip" :value="copyToClip" type="hidden" />

			<div class="pb-4 row-format align-center flex-wrap gap-3">
				<div class="row-format align-center gap-2">
					<v-text-field
						outlined
						hide-details
						dense
						class="standard-input"
						v-model="filter.search"
						placeholder="Search..."
						style="max-width: 200px; background-color: var(--v-white-base)"
					>
						<template v-slot:prepend-inner
							><span class="material-symbols-rounded font-gray_50">search</span></template
						>
					</v-text-field>
					<div>
						<client-list-filter :filter="filter" v-if="filter" @updated="saveCurrentFilter"></client-list-filter>
					</div>
					<div>
						<client-list-sort :filter="filter" v-if="filter" @updated="saveCurrentFilter"></client-list-sort>
					</div>
					<div>
						<custom-field-filter
								:fields="allClientFields"
								:filter="customFieldFilter"
								@changed="saveCustomFieldFilters($event)"
						></custom-field-filter>
					</div>
				</div>

				<div class="ml-auto row-format align-center">
					<pagination v-model="currentPage" :total-pages="totalPages" v-if="totalPages > 0"></pagination>
					<user-list-settings v-if="$store.state.userListSettings" v-model="$store.state.userListSettings.clientList" :fields="fields" @input="$store.dispatch('saveUserListSettings')"></user-list-settings>
					<v-btn class="ml-2 super-action" @click="createNewClient()"><v-icon size="20">add</v-icon> Add client</v-btn>
				</div>
			</div>

			<div v-if="!clientList.length" class="row-format centered" style="flex: 1; min-height: calc(var(--vh) - 130px)">
				<empty-view
					header="Everything starts with a client..."
					body="Once you start adding your clients to Moxie, you can find and manage them all here. Ready to add your first one? Let’s do it! "
					cta="Create your first client"
					video-header="See how it works"
					video-body="See how to create clients and then view all the details for their hours, invoices, proposals, deposits, and much more."
					video-cta="Watch the tutorial"
					video-id="ZQoOlfiMVAk"
					@cta-clicked="createNewClient()"
				></empty-view>
			</div>

			<div v-else :class="`pa-0`" :key="refreshKey" style="background-color: var(--v-white-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px">
				<v-container fluid class="pa-0 ma-0" style="max-width: 100%; overflow: hidden">
					<v-row
						v-for="client in paginatedItems"
						:key="client.id"
						dense
						class="text-left client-row"
						:style="client.archive ? 'color: var(--v-gray_50-base)!important' : ''"
						@click="routeToClient(client, $event)"
					>
						<v-col cols="5" class="row-format align-center">
							<v-icon
								@click.stop="toggleFavorite(client.id)"
								:color="client.favorite ? 'accent' : 'gray_50'"
								size="20"
								>{{ client.favorite ? 'star' : 'star_outline' }}</v-icon
							>
							<div :style="client.archive ? 'opacity: 0.3' : ''">
								<client-avatar class="ml-3 mr-2" :client="client"></client-avatar>
							</div>
							<div>
								<div class="font-18 brand-medium row-format align-center">
									<div>{{ client.name }}</div>
									<v-icon v-if="client.archive" color="gray_30" class="ml-3" v-tippy="{ content: 'Archived' }"
										>archive</v-icon
									>
								</div>
								<div :class="`font-14 ${client.archive ? 'font-gray_50' : 'font-gray_70'}`">
									{{ client.clientType === 'Prospect' ? 'Prospect | ' : '' }}{{ client.projectCount }}
									{{ client.projectCount === 1 ? 'project' : 'projects' }}
								</div>
							</div>
						</v-col>
						<v-col cols="2" class="row-format align-center">
							<div>
								<div :class="`font-14 ${client.archive ? 'font-gray_50' : 'font-gray_70'} ml-n1`">Contacts</div>
								<div class="row-format">
									<contacts :client="client" v-if="client.contacts.length"></contacts>
									<div v-if="!client.contacts.length">-</div>
								</div>
							</div>
						</v-col>
						<v-col cols="2" class="row-format align-center">
							<div>
								<div :class="`font-14 ${client.archive ? 'font-gray_50' : 'font-gray_70'}`">Hours worked</div>
								<div>{{ parseFloat(client.timeWorked / 60 / 60).toFixed(2) }}</div>
							</div>
						</v-col>
						<v-col cols="2" class="row-format align-center">
							<div v-if="$store.getters.hasInvoiceAccess">
								<div :class="`font-14 ${client.archive ? 'font-gray_50' : 'font-gray_70'}`">Revenue to date</div>
								<div>{{ $formatters.dollars(client.revenue) }}</div>
							</div>
						</v-col>
						<v-col cols="1" class="row-format align-center">
							<v-menu
								bottom
								left
								rounded
								offset-overflow
								offset-y
								:close-on-content-click="true"
								:close-on-click="true"
							>
								<template v-slot:activator="scope">
									<div class="ml-auto">
										<v-btn icon class="menu-activator" v-on="scope.on">
											<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
										</v-btn>
									</div>
								</template>

								<div class="more-menu">
									<div class="more-menu-item" @click="routeToClient(client)">View details</div>
									<div class="more-menu-item" @click="editClient(client)">Edit</div>
									<div class="more-menu-item" v-if="!client.archive" @click="archiveClient(client.id, true)">
										Archive
									</div>
									<div class="more-menu-item" v-if="client.archive" @click="archiveClient(client.id, false)">
										Un-archive
									</div>
								</div>
							</v-menu>
						</v-col>
						<v-col cols="12" v-if="visibleOptionalFields.length" class="row-format gap-2 flex-wrap" style="min-height: 0;">
							<template v-for="field in visibleOptionalFields">
								<div :key="field.value" class="data-chip" v-if="client[field.value]">
									<div v-if="field.value.startsWith('Custom.')">{{field.text}}: {{$formatters.customFieldFormat(client[field.value])}}</div>
									<div v-else class="">{{client[field.value]}}</div>
								</div>
							</template>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientService from './ClientService';
	import EmptyView from '@/components/EmptyView';
	import ConfirmModal from '@/components/ConfirmModal';
	import ClearSampleModal from '@/modules/clients/ClearSampleModal';
	import MetricService from '@/modules/reports/metrics/MetricService';
	import ClientAvatar from '@/components/ClientAvatar';
	import ClientListFilter from '@/modules/clients/ClientListFilter';
	import ClientListSort from '@/modules/clients/ClientListSort';
	import Contacts from '@/modules/clients/Contacts';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import ClientEdit from '@/modules/clients/detail/ClientEdit';
	import Pagination from '@/components/Pagination';
	import PageHeader from '@/components/PageHeader';
	import CustomFieldFilterMixin from "@/components/CustomFieldFilterMixin";
	import CustomFieldFilter from "@/components/CustomFieldFilter";
	import UserListSettings from "@/components/UserListSettings";

	export default {
		name: 'ClientListView',
		components: {
			Contacts,
			ClientListFilter,
			ClientListSort,
			ClientAvatar,
			EmptyView,
			Pagination,
			PageHeader,
			CustomFieldFilter,
			UserListSettings
		},

		props: [],

		mixins: [CustomFieldFilterMixin],

		data() {
			return {
				clientList: [],
				revenue: [],
				timeWorked: [],
				projects: [],
				clientService: new ClientService(),
				metricService: new MetricService(),
				createView: false,
				isReady: false,
				copyToClip: null,
				refreshKey: 0,
				filter: null,
				filterDefault: { search: null, status: 'active', sort: 'Name' },
				customFieldFilter: null,

				filterOptions: [
					{ text: this.$t('global.states.active'), value: 'active' },
					{ text: this.$t('client.archive'), value: 'archive' },
					{ text: this.$t('global.states.all'), value: 'all' },
				],

				currentPage: 1,
				pageSize: 20,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'client-list' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.getSelectedFilters();
			this.getCustomFieldFilters();
			this.getClientList();
			this.getMetrics();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			copyToClipboard(value) {
				navigator.clipboard.writeText(value);
				this.$store.commit('success', 'Copied to clipboard!');
			},

			createNewClient: function() {
				let binding = { clientList: this.clientList };
				this.$store.state.globalModalController.openModal(ClientCreate, binding, false, false, true, true).then((res) => {
					if (res) {
						setTimeout(() => this.routeToClient(res), 500);
					}
				});
			},

			routeToClient(client, event) {
				if (event && (event.ctrlKey || event.metaKey)) {
					window.open(`/client/${client.id}`, '_blank');
				} else {
					this.$router.push(`/client/${client.id}`);
				}
			},

			editClient: function(client) {
				this.clientService.getClientDetail(client.id).then((res) => {
					this.$store.state.globalModalController.openModal(ClientEdit, { client: res.data }).then((res) => {
						if (res) {
							if (res._update) {
								this.updateClient(res);
							} else if (res._delete) {
								this.deleteClient(res);
							}
						}
					});
				});
			},

			updateClient(client) {
				this.$store.commit('startLoading');
				this.clientService
					.putClient(client.id, client)
					.then((res) => {
						let ix = this.clientList.findIndex((c) => c.id === client.id);
						if (ix > -1) {
							this.clientList.splice(ix, 1, res.data);
						}
						this.alert('info', this.$t('global.saved-successfully'));
					})
					.catch((err) => {
						this.alert('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			deleteClient(client) {
				this.$store.commit('startLoading');
				this.clientService
					.deleteClient(client.id)
					.then(() => {
						let ix = this.clientList.findIndex((c) => c.id === client.id);
						if (ix > -1) {
							this.clientList.splice(ix, 1);
						}
						this.alert('success', 'Client successfully deleted');
					})
					.catch((err) => {
						this.alert('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			toggleFavorite: function(id) {
				this.$store.state.eventBus.$emit('toggle-favorite-client',id);
			},

			handleAddNew() {
				this.createView = true;
			},

			handleAccountChange: function() {
				this.getClientList();
				this.getSelectedFilters();
			},

			getMetrics() {
				if (this.$store.getters.hasFeatureAccess('invoices')) {
					this.metricService.getTotalRevenueGroupedByClient().then((res) => {
						this.revenue.splice(0, this.revenue.length);
						this.revenue.push(...res.data);
					});
				}

				this.metricService.getTotalTimeGroupedByClient().then((res) => {
					this.timeWorked.splice(0, this.timeWorked.length);
					this.timeWorked.push(...res.data);
				});

				this.metricService.getProjectCountGroupedByClient().then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
				});
			},

			getClientList() {
				this.$store.commit('startLoading');
				this.clientService = new ClientService();
				this.clientService
					.getClientMiniList(this.isArchived)
					.then((res) => {
						this.clientList.splice(0, this.clientList.length);
						this.clientList.push(...res.data);
						this.isReady = true;
						this.refreshKey++;
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			sortByName: function(a, b) {
				return a.name.localeCompare(b.name);
			},

			sortByRevenue: function(a, b) {
				return b.revenue - a.revenue;
			},

			sortByTimeWorked: function(a, b) {
				return b.timeWorked - a.timeWorked;
			},

			sortByFavorites: function(a, b) {
				let fav1 = a.favorite ? 1 : 0;
				let fav2 = b.favorite ? 1 : 0;
				return fav2 - fav1;
			},

			clientCreated(client) {
				this.$router.push(`/client/${client.id}`);
			},

			archiveClient: function(id, status) {
				if (this.sampleMode) {
					this.$store.state.globalModalController.openModal(ClearSampleModal);
				} else {
					let action = status ? 'Archive' : 'Un-archive';
					let binding = {
						headingText: action + ' client?',
						bodyText: 'Are you sure you want to ' + action.toLowerCase() + ' this client?',
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
						if (res) {
							const patch = [{ op: 'replace', path: '/archive', value: status }];

							this.clientService
								.patchClient(id, patch)
								.then((res) => {
									let ix = this.clientList.findIndex((c) => c.id === id);
									this.clientList.splice(ix, 1, res.data);
								})
								.catch((err) => {
									this.$store.commit('error', err.response.data.message);
								});
						}
					});
				}
			},

			saveCurrentFilter(event) {
				this.filter = event;
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			getSelectedFilters() {
				try {
					this.filter = JSON.parse(localStorage.getItem(this.filterStateKey));
					if (!this.filter) {
						this.saveCurrentFilter(this.filterDefault);
					}
				} catch (err) {
					console.log(err);
				}
			},

			getCustomFieldFilters() {
				try {
					let customFieldFilter = JSON.parse(localStorage.getItem('CUSTOM_' + this.filterStateKey));
					if (customFieldFilter) {
						this.customFieldFilter = customFieldFilter;
					}
				} catch (err) {
					console.log(err);
				}
			},

			saveCustomFieldFilters: function(value) {
				this.customFieldFilter = value;
				try {
					localStorage.setItem('CUSTOM_' + this.filterStateKey, JSON.stringify(this.customFieldFilter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			isInCustomFieldFilter: function(project) {
				if (!this.customFieldFilter) {
					return true;
				} else {
					return this._isInCustomFieldFilters(project.customValues, this.customFieldFilter);
				}
			},

			formatAddress: function(client){
				let result = [];
				result.push(client.address1);
				result.push(client.city);
				result.push(client.locality);
				result.push(client.postal);
				return result.filter(r => !!r).join(" ");
			},
		},

		watch: {
			currentTimerClientId: function() {
				this.refreshKey++;
			},

			isArchived: function() {
				console.log('is archived is changing');
				this.getClientList();
			},

			'filter.search': function() {
				this.currentPage = 1;
			},
		},

		computed: {
			totalPages() {
				return Math.ceil(this.filteredList.length / this.pageSize);
			},

			paginatedItems() {
				const start = (this.currentPage - 1) * this.pageSize;
				const end = start + this.pageSize;
				return this.filteredList.slice(start, end);
			},

			allClientFields: function() {
				let result = [...this.$store.state.clientFields.fields];
				const unique = [...new Map(result.map((item) => [item.mappingKey, item])).values()];
				unique.sort((a, b) => a.name.localeCompare(b.name));
				return unique;
			},

			visibleOptionalFields: function(){
				if(this.$store.state.userListSettings) {
					return this.fields.filter(f => !f.required && this.$store.state.userListSettings.clientList.includes(f.value));
				}else{
					return [];
				}
			},

			fields: function(){
				let result = [
					{ text: 'Name', value: 'name', required: true },
					{ text: 'Contacts', value: 'contacts', required: true },
					{ text: 'Hours', value: 'hours', required: true },
					{ text: 'Revenue', value: 'revenue', required: true },
					{ text: 'Address', value: 'address'},
					{ text: 'Country', value: 'country'},
					{ text: 'Lead source', value: 'leadSource'},
					{ text: 'Website', value: 'website' },
					{ text: 'Phone', value: 'phone' },
					{ text: 'Tax Id', value: '' },
				];

				this.allClientFields.forEach((c) => {
					result.push({
						text: c.name,
						value: 'Custom.' + c.mappingKey,
					});
				});

				return result;
			},

			isArchived: function() {
				if (this.filter && this.filter.status === 'archive') {
					return 'yes';
				} else if (this.filter && this.filter.status === 'all') {
					return 'all';
				} else {
					return 'no';
				}
			},

			filterStateKey: function() {
				return 'CLIENT_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			filterMessage() {
				let active = this.clientList.filter((c) => !c.archive).length;
				let archive = this.clientList.filter((c) => c.archive).length;

				if (this.showTooHard) {
					return this.$t('global.filtered-too-hard');
				} else if (this.showNoActive) {
					return `You current have no active clients and ${archive} archived clients.  Click to show archived clients.`;
				} else if (this.showNoArchive) {
					return `You current have no archived clients and ${active} active clients.  Click to show active clients.`;
				} else {
					return this.$t('global.filtered-too-hard');
				}
			},

			showTooHard() {
				return !this.$validations.isEmpty(this.filter);
			},

			showNoActive() {
				return !this.showTooHard && this.clientList.filter((c) => !c.archive).length === 0;
			},

			showNoArchive() {
				return !this.showTooHard && this.clientList.filter((c) => c.archive).length === 0;
			},

			filteredList() {
				let result = this.clientList;

				result.forEach((c) => {
					let r = this.revenue.find((r) => r.clientId === c.id);
					let t = this.timeWorked.find((t) => t.clientId === c.id);
					let p = this.projects.find((p) => p.id === c.id);

					c.revenue = r ? r.revenue : 0;
					c.timeWorked = t ? t.seconds : 0;
					c.projectCount = p ? p.projectCount : 0;
					c.contacts = this.$store.getters.getContactsByClientId(c.id);
					c.favorite = this.$store.state.favoriteClients.includes(c.id);
					c.address = this.formatAddress(c);

					c.customValues.forEach(v => {
						c['Custom.' + v.mappingKey] = this.$formatters.formatDateIfPresent(v.value);
					})
				});

				result = result
					.filter((client) => {
						// search filter
						if (this.$validations.isEmpty(this.filter.search) || this.$validations.isEmpty(client.name)) {
							return true;
						} else {
							if (client.name && client.name.toLowerCase().includes(this.filter.search.toLowerCase())) return true;
							if (client.address1 && client.address1.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;
							if (client.address2 && client.address2.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;
							if (client.city && client.city.toLowerCase().includes(this.filter.search.toLowerCase())) return true;
							if (client.locality && client.locality.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;
							if (client.postal && client.postal.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;
							if (client.country && client.country.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;
							if (client.website && client.website.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;
							if (client.phone && client.phone.toLowerCase().includes(this.filter.search.toLowerCase()))
								return true;

							let customValues = client.customValues;
							for (let j = 0; j < customValues.length; j++) {
								let cv = customValues[j];
								if (
									cv.value &&
									cv.value
										.toString()
										.toLowerCase()
										.includes(this.filter.search.toLowerCase())
								) {
									return true;
								}
							}

							for (let i = 0; i < client.contacts.length; i++) {
								let email = client.contacts[i].email ?? '';
								let lastName = client.contacts[i].lastName ?? '';
								let firstName = client.contacts[i].firstName ?? '';
								let customValues = client.contacts[i].customValues;

								if (
									lastName.toLowerCase().startsWith(this.filter.search.toLowerCase()) ||
									firstName.toLowerCase().startsWith(this.filter.search.toLowerCase()) ||
									email.toLowerCase().startsWith(this.filter.search.toLowerCase())
								) {
									return true;
								}

								for (let j = 0; j < customValues.length; j++) {
									let cv = customValues[j];
									if (
										cv.value &&
										cv.value
											.toString()
											.toLowerCase()
											.includes(this.filter.search.toLowerCase())
									) {
										return true;
									}
								}
							}

							return false;
						}
					})
					.filter((client) => {
						// status filter
						switch (this.filter.status) {
							case 'active':
								return !client.archive;
							case 'archive':
								return client.archive;
							case 'favorite':
								return client.favorite;
							default:
								return true;
						}
					})
					.filter((client) => {
						if (this.filter.clientType && this.filter.clientType !== 'all') {
							return client.clientType === this.filter.clientType;
						} else {
							return true;
						}
					}).filter((client) => this.isInCustomFieldFilter(client));

				result.sort(this.sortByName);

				switch (this.filter.sort) {
					case 'Revenue': {
						result.sort(this.sortByRevenue);
						break;
					}
					case 'Hours': {
						result.sort(this.sortByTimeWorked);
						break;
					}
					case 'Favorites': {
						result.sort(this.sortByFavorites);
						break;
					}
				}
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.data-chip{
		background-color: var(--v-gray_5-base);
		border: 1px solid var(--v-gray_10-base);
		padding: 2px 8px;
		border-radius: 4px;
		font-size: 12px;
		max-width: fit-content;
	}
</style>

<style lang="scss">
	.client-row {
		min-height: 60px;
		padding: 12px 24px;

		cursor: pointer;
		border-bottom: 1px solid var(--v-gray_30-base);

		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}
	.empty-filtered-results {
		width: 100%;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
	}
	input::placeholder {
		color: var(--v-gray_60-base);
	}
	textarea:focus,
	input:focus {
		outline: 0;
	}
</style>
