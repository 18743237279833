<template>
	<div id="create-invoice" class="pa-0 ma-0" style="min-width: 500px">
		<div class="row-format pt-4 px-6 align-center">
			<div class="text-left font-20 brand-medium">{{ $t('invoice.create-modal.unbilled-heading') }}</div>
			<v-icon class="ml-auto pointer" @click="handleSubmitBillables">$close</v-icon>
		</div>
		<div class="px-6 pt-2 text-left font-14 brand-semilight">
			<div>{{ $t('invoice.create-modal.unbilled-subheading') }}</div>

			<v-select
					v-if="hasAnyHourly"
					:items="billingBreakdowns"
					v-model="billingBreakdown"
					hide-details
					persistent-placeholder
					item-text="label"
					item-value="value"
					class="h-outline"
					label="Hourly line grouping"
					@change="setBillingBreakdownByClient"
					v-tippy="{content:'This setting determines how billable hours are grouped.  One line for total, or broken down by day, week, month'}"
			>
			</v-select>

			<div class="row-format align-center my-3" style="border-top: 1px solid var(--v-gray_50-base)">
				<v-checkbox dense hide-details label="Hide un-finished projects" v-model="hideUnfinished"></v-checkbox>
				<div class="pointer brand-medium mt-2 ml-2" @click="showUnFinishedHelp = !showUnFinishedHelp">(?)</div>
			</div>

			<div v-if="showUnFinishedHelp" class="mb-3 mt-n3 font-italic font-12" style="max-width: 550px">
				Hide projects which have outstanding tasks that are not yet complete. Tasks are considered complete
				once they are moved into the Kanban column which contains the green "complete" check mark or any column to the
				right of the "complete" column.
			</div>

		</div>

		<v-container class="px-6  mt-0">
			<div id="select-billables">
				<v-row>
					<v-col cols="12">
						<template>
							<div v-if="hasItems">
								<div id="projects">
									<!-- Hourly -->
									<div class="project-body hourly">
										<!--					@input="updateProjectQtyAndEventIds($event, i)"-->
										<v-treeview
											v-model="selectedHours"
											selection-type="leaf"
											open-on-click
											selectable
											selected-color="primary"
											item-children="items"
											item-disabled="disabled"
											item-text="name"
											dense
											expand-icon="$chevronDown"
											:items="hourlyProjects"
										>
											<template v-slot:label="{ item }">
												<div :class="'item ' + item.class">
													<!-- Client -->
													<template v-if="item.class == 'client'">
														<div
															class="label"
															@click.stop="client.hourlyAmount ? null : handleClientFeesDialog()"
														>
															<div class="name">
																<span>{{ item.name }}</span>
																<span class="fee-type">{{
																	formatFeeType(item.feeSchedule.feeType)
																}}</span>
																<div
																	v-if="item.projectHours.length === 0"
																	class="nothing-to-bill"
																>
																	{{ $t('invoice.create-modal.no-client-hours') }}
																</div>
															</div>
															<div
																class="hours"
																v-if="client.hourlyAmount"
																@click.stop="handleClientFeesDialog()"
															>
																{{ getClientSubtotalDisplay(item) }}
															</div>
															<div
																class="setup-fees-link"
																v-else
																@click.stop="handleClientFeesDialog()"
															>
																<v-icon color="primary" small class="mr-1 mb-1">$alert</v-icon
																>{{ $t('invoice.create-modal.set-up-client-fees') }}
															</div>
														</div>
													</template>
													<!-- Project -->
													<div class="label" v-else-if="item.class == 'project'">
														<div class="name">
															<span>{{ item.name }}</span>
															<span class="fee-type">{{
																formatFeeType(item.feeSchedule.feeType)
															}}</span>
															<div v-if="item.projectHours.length === 0" class="nothing-to-bill">
																{{ $t('invoice.create-modal.no-project-hours') }}
															</div>
														</div>
														<div
															class="hours"
															v-if="item.feeSchedule.amount"
															@click.stop="handleProjectFeesDialog(item)"
														>
															{{ getHourlyProjectSubtotalDisplay(item) }}
														</div>
														<div
															class="setup-fees-link"
															v-else
															@click.stop="handleProjectFeesDialog(item)"
														>
															<v-icon color="primary" small class="mr-1 mb-1">$alert</v-icon
															>{{ $t('invoice.create-modal.set-up-client-fees') }}
														</div>
													</div>
													<!-- Month, Week, Day -->
													<div class="label" v-else-if="item.class">
														<div class="name">
															{{ item.name }}
														</div>
														<div class="hours">{{ item.durationSubtotalDisplay }}</div>
													</div>
													<!-- Timer Event -->
													<div class="label" v-else>
														<!-- Hours display -->
														<div class="name">{{ item.notes | noteFormat }}</div>
														<div class="hours">{{ item.durationDisplay }}</div>
													</div>
												</div>
											</template>
										</v-treeview>
									</div>
									<!-- End Hourly -->

									<!-- Fixed Price -->
									<div v-for="(project, i) in fixedPriceProjects" :key="project.id" class="project fixed-price">
										<div class="project-head row-format align-center">
											<v-btn icon rounded small class="chevron" @click="toggleOpenProject(project)">
												<v-icon v-if="inOpenProjects(project.id)">$chevronDown</v-icon>
												<v-icon v-else>$chevronRight</v-icon>
											</v-btn>
											<div style="display: block" class="ml-1 mr-n2 mt-1">
												<v-simple-checkbox color="primary" :value="inSelectedProjects(project.id)" @input="toggleSelectedProject(project)"></v-simple-checkbox>
											</div>
											<div :class="`name ${$vuetify.breakpoint.xs ? 'truncate' : ''}`">
												<span>{{ project.name }}</span>
												<span class="fee-type">{{ formatFeeType(project.feeSchedule.feeType) }}</span>
											</div>
											<div
												class="hours"
												v-if="project.feeSchedule.feeType && project.feeSchedule.amount"
												@click.stop="handleProjectFeesDialog(project)"
											>
												{{ project.subtotalDisplay }}
											</div>
											<div class="setup-fees-link" v-else @click.stop="handleProjectFeesDialog(project)">
												<v-icon color="primary" small class="mr-1 mb-1">$alert</v-icon
												>{{ $t('invoice.create-modal.set-up-client-fees') }}
											</div>
										</div>

										<template v-if="inOpenProjects(project.id)">
											<div class="project-body fixed-price">
												<!--											<div class="line-item">-->
												<!--												<div class="left">{{ $t('invoice.create-modal.fixed-price') }}</div>-->
												<!--												<div class="right">-->
												<!--													{{ $formatters.dollars(project.feeSchedule.amount, 1, 0) }}-->
												<!--												</div>-->
												<!--											</div>-->
												<template v-if="project.paymentHistory.length">
													<div
														class="line-item"
														v-for="payment in project.paymentHistory"
														:key="payment.invoiceId"
													>
														<div
															class="left"
															v-tippy="{ content: paymentTip(payment) }"
															v-html="formatPaymentHistory(payment)"
														></div>
														<div class="right">
															{{ $formatters.dollars(payment.amount, 1, 0, payment.currency) }}
														</div>
													</div>
												</template>
												<!--											<template v-if="project.billableRetainerPeriods.length">-->
												<!--												<div-->
												<!--													class="line-item"-->
												<!--													v-for="period in project.billableRetainerPeriods"-->
												<!--													:key="period.invoiceId"-->
												<!--												>-->
												<!--													<div class="left" v-html="formatRetainerPeriod(period)"></div>-->

												<!--													<div class="right">-->
												<!--														{{ $formatters.dollars(project.feeSchedule.amount, 1, 0) }}-->
												<!--													</div>-->
												<!--												</div>-->
												<!--											</template>-->
												<div :class="`line-item ${$vuetify.breakpoint.xs ? 'mt-2' : ''}`">
													<div class="left">
														{{ $t('invoice.create-modal.invoice-today') }}
													</div>
													<div class="right pb-2">
														<v-text-field
															dense
															type="number"
															:width="128"
															:class="$vuetify.breakpoint.width < 426 ? 'mt-4' : ''"
															:prefix="$formatters.currencySymbol(invoice.currency)"
															hide-details
															v-model="fixedPriceProjects[i].invoiceAmount"
															class="text-right"
														></v-text-field>
													</div>
												</div>
											</div>
										</template>
									</div>
									<!-- End Fixed Price -->

									<!-- Retainer -->
									<div class="project-body retainer">
										<v-treeview
											v-model="selectedRetainers"
											selection-type="leaf"
											open-on-click
											selectable
											selected-color="primary"
											item-children="items"
											item-disabled="disabled"
											item-text="name"
											dense
											expand-icon="$chevronDown"
											:items="retainerProjects"
										>
											<template v-slot:label="{ item }">
												<div :class="'item ' + item.class">
													<div class="label" v-if="item.class == 'project'">
														<div
															class="name"
															@click="
																item.feeSchedule.amount ? null : handleProjectFeesDialog(item)
															"
														>
															<span>{{ item.name }}</span>
															<span class="fee-type">{{
																formatFeeType(item.feeSchedule.feeType)
															}}</span>
															<div
																v-if="item.billableRetainerPeriods.length === 0"
																class="nothing-to-bill"
															>
																{{ $t('invoice.create-modal.no-retainer-due') }}
															</div>
														</div>
														<div
															class="hours"
															v-if="item.feeSchedule.amount >= 0"
															@click.stop="handleProjectFeesDialog(item)"
														>
															{{ getRetainerProjectSubtotalDisplay(item) }}
														</div>
														<div
															class="setup-fees-link"
															v-else
															@click.stop="handleProjectFeesDialog(item)"
														>
															<v-icon color="primary" small class="mr-1 mb-1">$alert</v-icon
															>{{ $t('invoice.create-modal.set-up-client-fees') }}
														</div>
													</div>
													<div class="label" v-if="item.class == 'retainer-period'">
														<div class="name">
															<span>{{ item.name }}</span>
														</div>
														<div class="hours">
															{{ $formatters.decimal(item.amount) }}
														</div>
													</div>
												</div>
											</template>
										</v-treeview>
									</div>
									<!-- End Retainer -->

									<!-- Per Item -->
									<div class="project-body per-item">
										<v-treeview
											v-model="selectedPerItems"
											selection-type="leaf"
											open-on-click
											selectable
											selected-color="primary"
											item-children="items"
											item-text="name"
											item-disabled="disabled"
											dense
											expand-icon="$chevronDown"
											:items="perItemProjects"
										>
											<template v-slot:label="{ item }">
												<div :class="'item ' + item.class">
													<div class="label" v-if="item.class === 'project'">
														<div>{{item.name}}</div>
													</div>
													<div class="label" v-if="item.class === 'deliverable'">
														<div class="name d-flex align-center gap-2">
															<div>
																<div>{{ item.name }}</div>
																	<div class="font-12 font-gray_80">
																		{{ formattedFee(item) }}
																	</div>
															</div>
														</div>
														<div class="hours d-flex align-center">
															{{ $formatters.decimal(item.amount) }}
														</div>
													</div>
												</div>
											</template>
										</v-treeview>
									</div>
									<!-- End Per Item -->
								</div>
							</div>
							<div v-else-if="!hasItems && isReady">
								<div class="my-6 font-18">{{ $t('invoice.create-modal.unbilled-empty') }}</div>
							</div>
						</template>
					</v-col>
				</v-row>
			</div>
			<basic-modal
				v-if="clientFeesDialog"
				:dialog="clientFeesDialog"
				:persistent="true"
				@close="cancelClientFeesDialog"
				:max-width="400"
			>
				<client-hourly-amount-modal
					@close="clientFeesDialog = false"
					:client="client"
					@update-client-fees="handleUpdateClientFees($event)"
				>
				</client-hourly-amount-modal>
			</basic-modal>
			<modal
				v-if="projectFeesDialog"
				:dialog="projectFeesDialog"
				:persistent="true"
				@close="cancelProjectFeesDialog"
				:max-width="450"
			>
				<project-fee-schedule-modal
					:project-id="feesDialogProjectId"
					@update-project-fees="handleUpdateProjectFees"
					v-if="feesDialogProjectId"
				>
				</project-fee-schedule-modal>
			</modal>
		</v-container>

		<div class="pt-3 pb-2" style="border-top: 1px solid var(--v-gray_50-base)">
			<v-btn id="submit-billables" width="200" class="primary-action" @click="handleSubmitBillables">{{
				submitBillablesVerb
			}}</v-btn>
		</div>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import TimeTrack from '@/modules/timetracking/TimeTrack';
	import modal from '@/components/Modal';
	import ProjectFeeScheduleModal from '@/modules/invoices/ProjectFeeScheduleModal';
	import InvoiceMixin from '@/modules/invoices/InvoiceMixin';
	import ClientHourlyAmountModal from '@/modules/invoices/ClientHourlyAmountModal';
	import BasicModal from '@/components/BasicModal';
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'EditInvoiceModal',
		components: { BasicModal, ProjectFeeScheduleModal, modal, ClientHourlyAmountModal },
		mixins: [InvoiceMixin],
		props: {
			invoice: { type: Object, required: false },
		},

		//props: ['value', 'label', 'placeholder', 'prependInnerIcon', 'prependIcon'],

		data: function() {
			return {
				isReady: false,
				invoiceService: new InvoiceService(),
				timeTrack: new TimeTrack(),
				client: null,
				clientId: null, // Selected client. May be preset using selectedClient prop (called from client detail for example)
				clientItemArray: [], // Array of clients from which to choose
				clientItemsReady: false, // Client select is ready
				invoiceType: 'STANDARD',
				depositAmount: null,
				billable: null, // Items returned by billable api
				showUnFinishedHelp: false,
				hideUnfinished: false,

				projects: [],
				tickets: [],
				view: null,
				openProjects: [],
				selectedTickets: [],
				selectedProjects: [],
				selectedHours: [],
				createInvoicePost: {}, // Object to be passed into InvoiceDetails
				projectFeesDialog: false,
				feesDialogProjectId: null,
				clientFeesDialog: false,
				clientCreateDialog: false,

				selectedRetainers: [],
				selectedPerItems: [],

				perItemProjectsKey: 0,
				billingBreakdown: 'TOTAL',

				billingBreakdowns: [
					{label: 'Daily', value:'DAY'},
					{label: 'Weekly', value:'WEEK'},
					{label: 'Monthly', value:'MONTH'},
					{label: 'Total', value:'TOTAL'},
				],

				billingBreakdownByClient: {}
			};
		},

		mounted() {
			this.clientId = this.invoice.clientId;
			this.getBillable();
			this.getBillingBreakdownByClient();
		},

		methods: {
			getBillingBreakdownByClient(){
				let result = localStorage.getItem('INVOICE_BILLING_BREAKDOWN_' + this.invoice.accountId);
				if(result){
					this.billingBreakdownByClient = JSON.parse(result);
					if(this.clientId in this.billingBreakdownByClient){
						this.billingBreakdown = this.billingBreakdownByClient[this.clientId];
					}
				}
			},

			setBillingBreakdownByClient(){
				this.billingBreakdownByClient[this.clientId] = this.billingBreakdown;
				localStorage.setItem('INVOICE_BILLING_BREAKDOWN_' + this.invoice.accountId,JSON.stringify(this.billingBreakdownByClient));
			},

			// This function sets the selectedHours, selectedRetainers, selectedProjects arrays
			// based on the contents of invoice.items.
			setSelectedFromInvoice() {
				for (let i = 0; i < this.invoice.items.length; i++) {
					let item = this.invoice.items[i];
					let projectIndex, project;
					switch (item.type) {
						case 'HOURS':
							this.selectedHours.push(...item.timerEventIds);
							break;
						case 'RETAINER':
							this.selectedRetainers.push(item.projectId + ':' + item.retainerPeriod.start);
							break;
						case 'PROJECT':
							this.selectedProjects.push(item.projectId);
							projectIndex = this.projects.findIndex((p) => p.id === item.projectId);
							if (projectIndex > -1) {
								project = this.projects[projectIndex];
								project.invoiceAmount = this.$formatters.decimal(item.price, 2, 2, false);
								// console.log('project', project);
								this.projects.splice(projectIndex, 1, project);
							}
							break;
						case 'DELIVERABLE':
							// console.log('DELIVERABLE', item);
							this.selectedPerItems.push(item.projectId + ':' + item.projectDeliverableId);
							break;
						default:
							// console.log(item.type);
							break; // Not even the fee for the gaming license, senator.
					}
				}
			},

			handleSubmitBillables() {
				let newItems = this.buildItemsForPost();
				this.mergeItemsIntoInvoice(newItems);
				this.$emit('save');
				this.$emit('close');
			},

			handleUpdateClientFees(client) {
				this.cancelClientFeesDialog();
				let fauxProject = this.projects[0]; // if this function is being called, the index is always 0

				if (client.hourlyAmount) fauxProject.disabled = false;
				fauxProject.hourlyAmount = client.hourlyAmount;
				this.client.hourlyAmount = client.hourlyAmount;

				this.projects.splice(0, 1, fauxProject);
			},

			handleUpdateProjectFees(project) {
				this.cancelProjectFeesDialog();
				this.getBillableByProject(project.id);
			},

			handleProjectFeesDialog(project) {
				this.feesDialogProjectId = project.id;
				this.projectFeesDialog = true;
			},

			handleClientFeesDialog() {
				this.clientFeesDialog = true;
			},

			handleChangeItemQty(event, item) {
				let num = event;
				if (event.target) {
					num = event.target._value;
				}
				num = parseFloat(num);
				for (let i = 0; i < this.perItemProjects.length; i++) {
					let project = this.perItemProjects[i];
					let projectIndex = this.projects.findIndex((p) => p.id == project.id);

					let itemIndex = project.items.findIndex((j) => j.id == item.id);
					if (itemIndex > -1) {
						this.projects[projectIndex].items[itemIndex].qty = num;
						this.projects[projectIndex].items[itemIndex].amount =
							num * this.projects[projectIndex].items[itemIndex].product.rate;
						break;
					}
				}
			},

			cancelProjectFeesDialog() {
				this.feesDialogProjectId = null;
				this.projectFeesDialog = false;
			},

			cancelClientFeesDialog() {
				this.clientFeesDialog = false;
			},

			// This function takes all the local properties that are controlled by vuetify elements
			// and converts it to an array of invoice items suitable for posting to the backend APIs
			buildItemsForPost() {
				let items = [];
				for (let i = 0; i < this.retainerProjects.length; i++) {
					let project = this.retainerProjects[i];
					if (!project.items) continue;
					for (let j = 0; j < project.items.length; j++) {
						let item = project.items[j];
						if (!this.selectedRetainers.includes(item.id)) continue;
						items.push({
							price: item.amount,
							projectId: project.id,
							retainerPeriod: { start: item.start, end: item.end },
							qty: 1,
							description: `<strong>${item.description}</strong>`,
							taxable: project.feeSchedule.taxable,
							type: 'RETAINER',
						});
					}
					// console.log('project', project);
				}

				for (let i = 0; i < this.perItemProjects.length; i++) {
					let project = this.perItemProjects[i];
					if (!project.items) continue;
					// let timerEventIds = this.getProjectTimerEventIds(project, true);

					for (let j = 0; j < project.items.length; j++) {
						let item = project.items[j];
						if (!this.selectedPerItems.includes(item.id)) continue;

						let desc = `<strong>${item.name}</strong>`;
						if(item.description){
							desc = desc + '<br>' + item.description;
						}

						items.push({
							description: desc,
							projectId: project.id,
							projectDeliverableId: item.projectDeliverableId,
							qty: item.qty ? item.qty : 0,
							unitLabel: item.product.unit,
							price: item.product.rate,
							taxable: item.product.taxable,
							type: 'DELIVERABLE',
							product: item.product,
						});
					}
				}

				for (let i = 0; i < this.fixedPriceProjects.length; i++) {
					let project = this.fixedPriceProjects[i];
					if (!this.inSelectedProjects(project.id)) continue;
					let item = this.invoice.items.find(i => i.projectId === project.id);
					items.push({
						price: project.invoiceAmount ? project.invoiceAmount : 0,
						projectId: project.id,
						qty: 1,
						description: `<strong>${item ? item.description : project.name}</strong>`,
						taxable: project.feeSchedule.taxable,
						type: 'PROJECT',
					});
				}

				for (let i = 0; i < this.hourlyProjects.length; i++) {
					let project = this.hourlyProjects[i];
					let timerEventIds = this.getProjectTimerEventIds(project, true);
					if (timerEventIds.length) {
						if (project.isClient) {
							if(this.billingBreakdown !== 'TOTAL') {
								let projectHours = this.groupProjectHours(project,true);

								for (const p of projectHours) {
									let item = this.invoice.items.find(i => !i.projectId && i.type === 'HOURS' && i.retainerPeriod && i.retainerPeriod.start === p.retainerPeriod.start);
									if(item && (p.timerEventIds.sort().join('|') === item.timerEventIds.sort().join('|'))) {
										items.push(item);
									}else {
										items.push({
											price: this.client.hourlyAmount ? this.client.hourlyAmount : 0,
											timerEventIds: p.timerEventIds,
											retainerPeriod: p.retainerPeriod,
											qty: p.qty,
											description: `<strong>${p.description}</strong>`,
											taxable: this.hasDefaultTax,
											type: 'HOURS',
										});
									}
								}
							}else{
								let item = this.invoice.items.find(i => !i.projectId && i.type === 'HOURS');
								if(item && (timerEventIds.sort().join('|') === item.timerEventIds.sort().join('|'))) {
									items.push(item);
								}else{
									items.push({
										price: this.client.hourlyAmount ? this.client.hourlyAmount : 0,
										timerEventIds: timerEventIds,
										qty: this.getHourlyProjectQty(project),
										description: `<strong>${this.client.name + ' hours'}</strong>`,
										taxable: this.hasDefaultTax,
										type: 'HOURS',
									});
								}
							}
						} else {
							if(this.billingBreakdown !== 'TOTAL'){
								let projectHours = this.groupProjectHours(project);

								for (const p of projectHours) {
									let item = this.invoice.items.find(i => i.projectId === p.projectId && i.type === 'HOURS' && i.retainerPeriod && i.retainerPeriod.start === p.retainerPeriod.start);
									if(item && (p.timerEventIds.sort().join('|') === item.timerEventIds.sort().join('|'))) {
										items.push(item);
									}else {
										items.push({
											price: project.feeSchedule.amount ? project.feeSchedule.amount : 0,
											timerEventIds: p.timerEventIds,
											clientId: this.client.id,
											projectId: project.id,
											retainerPeriod: p.retainerPeriod,
											qty: p.qty,
											taxable: project.feeSchedule.taxable,
											description: `<strong>${p.description}</strong>`,
											type: 'HOURS',
										});
									}
								}
							}else{
								let item = this.invoice.items.find(i => i.projectId === project.id);

								if(item && (timerEventIds.sort().join('|') === item.timerEventIds.sort().join('|'))){
									items.push(item);
								}else{
									items.push({
										price: project.feeSchedule.amount ? project.feeSchedule.amount : 0,
										timerEventIds: timerEventIds,
										clientId: this.client.id,
										projectId: project.id,
										qty: this.getHourlyProjectQty(project),
										taxable: project.feeSchedule.taxable,
										description: `<strong>${item ? item.description : project.name}</strong>`,
										type: 'HOURS',
									});
								}
							}
						}
					}
				}
				return items;
			},

			groupProjectHours(project, isClient = false){
				let result = [];

				let projectHours = [... project.projectHours.filter(p => this.selectedHours.includes(p.id))].sort((a,b) => a.timerStart.localeCompare(b.timerStart));
				let earliest = DateTime.fromISO(projectHours[0].timerStart).startOf(this.billingBreakdown.toLowerCase());
				let latest = DateTime.fromISO(projectHours[projectHours.length-1].timerStart).startOf(this.billingBreakdown.toLowerCase());

				while(earliest <= latest) {
					let item;
					if(isClient){
						item = this.invoice.items.find(i => i.projectId === null && i.type === 'HOURS' && i.retainerPeriod && i.retainerPeriod.start === earliest.toISODate());
					}else{
						item = this.invoice.items.find(i => i.projectId === project.id && i.retainerPeriod && i.retainerPeriod.start === earliest.toISODate());
					}

					let endOfPeriod = earliest.endOf(this.billingBreakdown.toLowerCase());
					let hours = projectHours.filter(h => DateTime.fromISO(h.timerStart).startOf(this.billingBreakdown.toLowerCase()).toISODate() === earliest.toISODate());

					if(hours.length){
						result.push({
							projectId: project.id,
							timerEventIds: hours.map(h => h.id),
							retainerPeriod: { start: earliest.toISODate(), end: endOfPeriod.toISODate() },
							qty: this.sumHours(hours),
							description: `<strong>${item ? item.description : project.name}</strong>`,
						});
					}

					let increment = {}
					increment[this.billingBreakdown.toLowerCase()] = 1;
					earliest = earliest.plus(increment);
				}

				return result;
			},

			// The function is only used when updating an existing draft invoice.
			// It looks at the array of items produced by buildItemsForPost() and compares it
			// to the invoice.items array, adding, updating, and deleting invoice.items as needed
			mergeItemsIntoInvoice(items) {
				this.invoice.items = this.invoice.items.filter(
					// (i) => i.type !== 'HOURS' && i.type !== 'PROJECT' && i.type !== 'RETAINER'
					(i) => !['HOURS', 'PROJECT', 'RETAINER', 'DELIVERABLE'].includes(i.type)
				);
				this.invoice.items.push(...items);
			},

			inOpenProjects(id) {
				return this.openProjects.indexOf(id) > -1;
			},

			inSelectedProjects(id) {
				return this.selectedProjects.indexOf(id) > -1;
			},

			inSelectedHours(id) {
				return this.selectedHours.indexOf(id) > -1;
			},

			toggleOpenProject(project) {
				const selectedIndex = this.openProjects.indexOf(project.id);
				if (selectedIndex > -1) {
					this.openProjects.splice(selectedIndex, 1);
					return;
				}
				this.openProjects.push(project.id);
			},

			toggleSelectedProject(project) {
				const selectedIndex = this.selectedProjects.indexOf(project.id);
				if (selectedIndex > -1) {
					this.selectedProjects.splice(selectedIndex, 1);
					return;
				}
				this.selectedProjects.push(project.id);
			},

			getBillable() {
				// console.log('getBillable fired');
				this.invoiceService
					.getBillable(this.clientId, this.invoice ? this.invoice.id : '')
					.then((res) => {
						this.client = res.data.client;
						let projects = res.data.projects;

						if(res.data.deliverables && res.data.deliverables.length){
							projects.unshift(this.createClientDeliverableProject(res.data.deliverables));
						}

						this.projects = this.fixupProjectList(projects);
						let clientFauxProject = this.createClientProject(res.data.clientHours);
						let ticketFauxProjects = this.createTicketHours(res.data.ticketHours);

						if (clientFauxProject) {
							this.projects.splice(0, 0, clientFauxProject);
						}

						if(ticketFauxProjects){
							this.projects.push(... ticketFauxProjects);
						}

						if (this.invoice) {
							this.setSelectedFromInvoice();
						}
						// console.log('this.projects', this.projects);
						this.isReady = true;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			getBillableByProject(projectId) {
				// console.log('getBillable fired');
				this.invoiceService
					.getBillable(this.clientId, '', projectId)
					.then((res) => {
						console.log(res.data);
						let project = this.fixupProject(res.data.projects[0]);
						let index = this.projects.findIndex((p) => p.id === projectId);
						if (index > -1) this.projects.splice(index, 1, project);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			feeScheduleDefault() {
				return { feeType: '', amount: 0 };
			},

			scrubFeeSchedule(project) {
				if (!project.feeSchedule) return this.feeScheduleDefault();
				return project.feeSchedule;
			},

			fixupProjectList(projects) {
				for (let i = 0; i < projects.length; i++) {
					projects[i] = this.fixupProject(projects[i]);
				}
				return projects;
			},

			getProjectRetainerIds(project, selectedOnly = false) {
				let ids = [];
				for (let i = 0; i < project.items.length; i++) {
					if (!selectedOnly || this.selectedRetainers.includes(project.items[i].id)) {
						ids.push(project.items[i].id);
					}
				}
				return ids;
			},

			getRetainerProjectSubtotalDisplay(project) {
				const selected = this.getProjectRetainerIds(project, true);
				let subtotal = 0;
				for (let i = 0; i < project.items.length; i++) {
					let item = project.items[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selected.includes(item.id) || selected.length == 0) {
						subtotal += item.amount;
					}
				}
				return this.$formatters.dollars(subtotal, true, true, this.invoice.currency);
			},

			getProjectPerItemIds(project, selectedOnly = false) {
				let ids = [];
				for (let i = 0; i < project.items.length; i++) {
					if (!selectedOnly || this.selectedPerItems.includes(project.items[i].id)) {
						ids.push(project.items[i].id);
					}
				}
				return ids;
			},

			getPerItemProjectSubtotalDisplay(project) {
				console.log(project);

				const selected = this.getProjectPerItemIds(project, true);
				let subtotal = 0;
				for (let i = 0; i < project.items.length; i++) {
					let item = project.items[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selected.includes(item.id) || selected.length == 0) {
						subtotal += item.amount;
					}
				}

				return this.$formatters.dollars(subtotal, true, true, this.invoice.currency);
			},

			getProjectTimerEventIds(project, selectedOnly = false) {
				let ids = [];
				for (let i = 0; i < project.projectHours.length; i++) {
					if (!selectedOnly || this.selectedHours.includes(project.projectHours[i].id)) {
						ids.push(project.projectHours[i].id);
					}
				}
				return ids;
			},

			getClientSubtotalDisplay(client) {
				const selectedHours = this.getProjectTimerEventIds(client, true);
				let hours = 0;
				for (let i = 0; i < client.projectHours.length; i++) {
					let projectHour = client.projectHours[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selectedHours.includes(projectHour.id) || selectedHours.length === 0) {
						hours += projectHour.durationHoursDecimal;
					}
				}
				return this.$formatters.dollars(
						hours  * this.client.hourlyAmount,
						true,
						true,
						this.invoice.currency
				);
			},

			getHourlyProjectSubtotalDisplay(project) {
				const selectedHours = this.getProjectTimerEventIds(project, true);
				let hours = 0;
				for (let i = 0; i < project.projectHours.length; i++) {
					let projectHour = project.projectHours[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selectedHours.includes(projectHour.id) || selectedHours.length === 0) {
						hours += projectHour.durationHoursDecimal;
					}
				}
				return this.$formatters.dollars(
					hours * project.feeSchedule.amount,
					true,
					true,
					this.invoice.currency
				);
			},

			getHourlyProjectQty(project) {
				const selectedHours = this.getProjectTimerEventIds(project, true);
				let hours = 0;
				for (let i = 0; i < project.projectHours.length; i++) {
					let projectHour = project.projectHours[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selectedHours.includes(projectHour.id)) {
						hours += projectHour.durationHoursDecimal;
					}
				}
				return hours;
			},

			sumHours(hourList){
				let hours = 0;
				for (let i = 0; i < hourList.length; i++) {
					hours += hourList[i].durationHoursDecimal;
				}
				return hours;
			},

			createClientDeliverableProject(deliverables){
				let project = {};
				project.id = null;
				project.name =  'Client deliverables';
				project.active = true;
				project.allDeliverablesComplete = true;
				project.hasDeliverables = true;
				project.projectHours = [];
				project.feeSchedule = {
					feeType: 'Per Item'
				}
				project.deliverables = deliverables;
				return project;
			},

			// build out a faux project that acts like an hourly project and
			// contains all hours not assigned to a specific project
			createClientProject(clientHours) {
				let project = {};
				project.projectHours = [];
				project.class = 'client';
				project.isClient = true;
				project.id = this.client.id;

				for (let j = 0; j < clientHours.length; j++) {
					project.projectHours[j] = this.timeTrack.fixupEvent(clientHours[j]);
				}

				project.name = 'Client hours worked';
				// if (!this.client.hourlyAmount) project.disabled = true;
				project.feeSchedule = { amount: this.client.hourlyAmount, feeType: 'Hourly' };
				project.items = this.getHourlyProjectTree(project);
				if (!project.items.length || !project.feeSchedule.amount) {
					project.disabled = false;
				}
				if (project.items.length) return project;
				// return project;
			},

			createTicketHours(ticketBillables){
				let result = [];

				for(let i=0; i < ticketBillables.length; i++){
					let ticket = ticketBillables[i];
					let project = {};
					project.projectHours = [];
					project.class = 'project';
					project.isClient = false;
					project.id = ticket.ticketId;
					project.name = ticket.ticketName;
					project.feeSchedule = { amount: this.client.hourlyAmount, feeType: 'Hourly' };

					for (let j = 0; j < ticket.ticketHours.length; j++) {
						project.projectHours[j] = this.timeTrack.fixupEvent(ticket.ticketHours[j]);
					}

					project.items = this.getHourlyProjectTree(project);
					if (!project.items.length || !project.feeSchedule.amount) {
						project.disabled = false;
					}

					result.push(project);
				}

				return result;
			},

			fixupProject(project) {
				for (let j = 0; j < project.projectHours.length; j++) {
					project.projectHours[j] = this.timeTrack.fixupEvent(project.projectHours[j]);
				}
				// console.log('projectHours', project.projectHours);
				project.class = 'project';
				project.feeSchedule = this.scrubFeeSchedule(project);
				switch (project.feeSchedule.feeType.toLowerCase()) {
					case 'hourly':
						project.items = this.getHourlyProjectTree(project);
						if (!project.items.length || !project.feeSchedule.amount) {
							project.disabled = false;
						}
						// if (!project.feeSchedule.amount) project.disabled = true;
						break;
					case 'fixed price':
						project.subtotalDisplay = this.$formatters.dollars(
							project.feeSchedule.amount,
							true,
							true,
							this.invoice.currency
						);
						project.invoiceAmount = this.getProjectInvoiceAmount(project);
						if (!project.feeSchedule.amount) {
							project.disabled = true;
						}
						break;
					case 'retainer':
						project.items = this.getRetainerProjectTree(project,this.invoice);
						// project.subtotalDisplay = this.$formatters.dollars(project.feeSchedule.amount);
						// project.invoiceAmount = this.$formatters.dollars(project.feeSchedule.amount, 1, 0).replace(',', '');
						if (!project.items.length) {
							project.disabled = true;
						}
						break;
					case 'per item':
						project.items = this.getPerItemProjectTree(project, this.invoice);

						// project.subtotalDisplay = this.$formatters.dollars(project.feeSchedule.amount);
						// project.invoiceAmount = this.$formatters.dollars(project.feeSchedule.amount, 1, 0).replace(',', '');
						if (!project.items.length) {
							project.disabled = true;
						}

						break;
					default:
						project.disabled = true;
						project.subtotalDisplay = '$ TBD';
				}
				return project;
			},

			formatRetainerPeriod(period) {
				return this.$DateTime.humanDate(period.start) + ' - ' + this.$DateTime.humanDate(period.end);
			},

			formatPaymentHistory(payment) {
				if (payment.retainerPeriod) {
					return (
						this.$DateTime.humanDate(payment.retainerPeriod.start) +
						' - ' +
						this.$DateTime.humanDate(payment.retainerPeriod.end)
					);
				}
				return this.$t('invoice.create-modal.previously-invoiced', {
					invoiceNumber: payment.invoiceNumber,
				});
			},

			formattedFee(deliverable) {
				let arr = [];

				if (deliverable.product.hourly) {
					arr.push(deliverable.qty);
				}
				// arr.push('heel');

				if (deliverable.product && deliverable.product.unit) {
					arr.push(deliverable.product.unit);
				} else {
					arr.push('items');
				}
				if (deliverable.product && deliverable.product.rate) {
					arr.push('@' + this.$formatters.dollars(deliverable.product.rate));
				}

				return arr.join(' ');
			},

			paymentTip(payment) {
				let msg = [];
				msg.push(this.$t('invoice.invoice-number-appended', { invoiceNumber: payment.invoiceNumber }));
				msg.push(this.$t('global.date') + ': ' + this.$DateTime.humanDate(payment.invoiceDate));
				msg.push(this.$t('invoice.status') + ': ' + this.$t('invoice.statuses.' + payment.invoiceStatus));
				return msg.join('<br>');
			},

			getProjectInvoiceAmount(project) {
				let amountDue =
					project.feeSchedule.amount -
					project.paymentHistory.reduce((accumulator, item) => {
						return accumulator + item.amount;
					}, 0);

				return amountDue > 0 ? amountDue : 0;
			},

			formatFeeType: function(val) {
				if (!val) return '';
				return '(' + this.$t('invoice.fee-type.' + val.replace(/ /, '-').toLowerCase()) + ')';
			},
		},
		filters: {
			noteFormat(note) {
				return note ? note : '--';
			},
		},
		computed: {
			hasAnyHourly(){
				return this.projects.filter(p => p.feeSchedule.feeType === 'Hourly').length > 0
			},

			hasDefaultTax() {
				return (
					this.invoice.accountInfo.accountPreferences.defaultTaxRate &&
					this.invoice.accountInfo.accountPreferences.invoiceUseTax
				);
			},

			filteredProjects() {
				if (this.hideUnfinished) {
					return this.projects.filter((p) => p.allDeliverablesComplete || p.isClient);
				} else {
					return this.projects;
				}
			},

			hourlyProjects() {
				return this.filteredProjects.filter((v) => {
					if (v.isClient) return true;
					return v.feeSchedule.feeType.toLowerCase() === 'hourly';
				});
			},

			fixedPriceProjects() {
				return this.filteredProjects.filter((v) => v.feeSchedule.feeType.toLowerCase() === 'fixed price');
			},

			retainerProjects() {
				return this.filteredProjects.filter(
					(v) => !v.feeSchedule || !v.feeSchedule.feeType || v.feeSchedule.feeType.toLowerCase() === 'retainer'
				);
			},

			perItemProjects() {
				return this.filteredProjects.filter(
					(v) => !v.feeSchedule || !v.feeSchedule.feeType || v.feeSchedule.feeType.toLowerCase() === 'per item'
				);
			},

			hasItems() {
				if (this.hourlyProjects.length || this.fixedPriceProjects.length || this.retainerProjects.length || this.perItemProjects.length) {
					return true;
				} else {
					return false;
				}
			},

			submitBillablesVerb() {
				return this.hasItems ? this.$t('global.done') : this.$t('global.ok');
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	#create-invoice {
		padding: 0;
		#select-client {
		}
		#select-billables,
		#select-expenses {
			.select-type {
				display: flex;
				justify-content: center;
				.wrapper {
					flex: 1 1 auto;
					max-width: 250px;
				}
			}
			// Treeview is all hourly projects
			.v-treeview {
				.v-treeview-node {
					padding: 16px 0;
					border-top: 1px solid var(--v-gray_50-base) !important; // not sure why it doesn't work
					.v-treeview-node__root {
						padding: 0;
						.v-treeview-node__content {
							.v-treeview-node__toggle {
								margin: 0;
								width: 28px;
							}
							.v-treeview-node__checkbox {
								margin: 0;
								width: 28px;
							}
							.item {
								display: flex;
								align-content: center;
								align-items: center;
								justify-content: space-between;
								padding: 4px 0;

								&.project,
								&.client {
								}

								div.label {
									flex: 1 0 auto;
									display: flex;
									justify-content: space-between;

									div.name {
										span {
											margin-right: 4px;

											&.fee-type {
												white-space: nowrap;
											}
										}

										flex: 1 1 auto;
										text-align: left;
										.nothing-to-bill {
											font-size: 12px;
										}
									}

									div.hours {
										flex: 0 1 auto;
										text-align: right;
									}

									.v-input {
										margin: 0;
										padding: 0;
									}
									.setup-fees-link {
										display: block;
										font-size: 14px;
										line-height: 24px;
										color: var(--v-primary-base) !important;
										font-weight: 600;
									}
								}
							}
						}
					}
				}
			}
			.v-treeview-node__children {
				margin-top: 0;
				.v-treeview-node {
					padding: 0;
					border-top: none !important;
					.v-treeview-node__root {
						//height: 32px;
					}
				}
			}

			.client-name {
				display: flex;
				justify-content: center;
				margin-bottom: 16px;

				div {
					align-self: center;
					flex: 0 0 auto;
				}
			}

			.back-button {
				position: absolute;
				top: 10px;
				left: 10px;
			}

			.truncate {
				max-width: 120px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.project.fixed-price,
			.expense {
				border-top: 1px solid var(--v-gray_50-base);
				padding: 16px 0;
				font-size: 16px;
				letter-spacing: 0.2px;

				.project-head {
					//height: 52px;
					margin-bottom: 6px;
				}
				//.expense-head {
				//	height: 40px;
				//}
				.project-head,
				.expense-head {
					clear: both;
					height: 40px;
					display: flex;
					div,
					button {
						align-self: center;
					}
					button {
						justify-content: center;
						&.chevron,
						&.checkbox {
							flex: 0 0 auto;
						}
						&.checkbox {
						}
					}
					div {
						flex: 1 1 auto;
						font-weight: 300;
						&.name {
							text-align: left;
							margin-left: 6px;
							span {
								margin-right: 4px;
								&.fee-type {
									white-space: nowrap;
								}
							}
						}
						&.hours {
							cursor: pointer;
							text-align: right;
						}
					}
				}
				.setup-fees-link {
					color: var(--v-gray_70-base);
					cursor: pointer;
					font-size: 14px;
					line-height: 24px;
					font-family: Inter;
					font-weight: 500;
					text-align: right;
					padding-right: 0;
					color: var(--v-primary-base);
				}
				.project-body,
				.expense-body {
					.line-item {
						padding-left: 62px;
						display: flex;
						justify-content: space-between;
						div {
							align-self: center;
							&.left {
								flex: 1 1 auto;
								text-align: left;
							}
							&.right {
								flex: 0 0 90px;
								//width: 100px;
								text-align: right;
								input {
									text-align: right;
								}
								/* Hide spin buttons for alignment */
								/* Chrome, Safari, Edge, Opera */
								input::-webkit-outer-spin-button,
								input::-webkit-inner-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
								/* Firefox */
								input[type='number'] {
									-moz-appearance: textfield;
								}
							}
						}
					}
				}
				.project-body {
					.line-item {
						height: 52px;
					}
				}
				.expense-body {
					.line-item {
						height: 40px;
						div {
							&.left {
							}
							&.right {
								flex: 0 0 230px;
								input {
									text-align: left;
								}
							}
						}
					}
				}
			}
			.buttons {
				margin: 16px 0;
				display: flex;
				justify-content: center;
				button.v-btn {
					flex: 0 1 80%;
					justify-self: center;
					&#add-expenses {
						//margin-right: 8px;
					}
					&#preview-invoice {
						//margin-left: 8px;
					}
				}
			}
		}
	}
</style>
