import DiscoveryList from "@/modules/discovery/DiscoveryList";
import FormSubmissions from "@/modules/discovery/FormSubmissions";

export default [
	{
		path: '/forms',
		redirect: '/forms/submissions'
	},
	{
		path: '/forms/builder',
		name: 'FormBuilder',
		component: DiscoveryList,
		title: 'Client List',
	},
	{
		path: '/forms/submissions',
		name: 'clientList',
		component: FormSubmissions,
		title: 'Client List',
	},

];
