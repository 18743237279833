<template>
	<div v-if="isReady && invoices.length > 0">
		<h4>{{ $t('subscription.payment-history') }}</h4>
		<v-list style="background-color:transparent">
			<v-list-item v-if="pendingPlan && !$emit('isFreePlan', pendingPlan.plan.code)">
				<v-list-item-content>
					<v-list-item-title style="font-size:14px;">
						{{ $t('subscription.next-scheduled-payment') + ' ' + $DateTime.longDate(customerPlan.currentTermEndsAt) }}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action> </v-list-item-action>
			</v-list-item>
			<v-list-item v-else-if="customerPlan.currentTermEndsAt && !$emit('isFreePlan', customerPlan.plan.code)">
				<v-list-item-content>
					<v-list-item-title style="font-size:14px;">
						{{ $t('subscription.next-scheduled-payment') + ' ' + $DateTime.longDate(customerPlan.currentTermEndsAt) }}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action> </v-list-item-action>
			</v-list-item>
			<v-list-item v-for="invoice in invoicesPage" :key="invoice.id">
				<v-list-item-content>
					<v-list-item-title style="font-size:14px;" v-text="$DateTime.longDate(invoice.createdAt)"></v-list-item-title>
					<v-list-item-subtitle
						style="font-size:14px;"
						class="mt-3"
						v-text="$formatters.dollars(invoice.total,true,true,'USD')"
					></v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>
					<v-btn
						text
						color="primary"
						@click="handleClick(invoice.id, invoice.downloadSignature)"
						style="font-size:12px"
					>
						View
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</v-list>
		<div class="mt-3">
			<v-pagination
				class="float-none float-sm-left"
				color="none"
				v-model="page"
				:total-visible="5"
				:length="Math.ceil(invoices.length / rowsPerPage)"
				v-if="invoices.length > rowsPerPage"
			></v-pagination>
		</div>
		<Modal :dialog="invoiceModal" v-on:close="invoiceModal = false" ref="invoiceModal" :max-width="800">
			<Invoice
				:key="invoiceModal"
				v-on:alert="$refs.invoiceModal.setAlert($event)"
				v-on:close="invoiceModal = false"
				:invoiceId="invoiceId"
				:downloadSignature="downloadSignature"
			></Invoice>
		</Modal>
	</div>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';
	import Invoice from '../Invoice';
	import Modal from '@/components/Modal';

	export default {
		name: 'Invoices',
		props: { customerPlan: { type: Object }, pendingPlan: { type: Object } },
		components: { Modal, Invoice },

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				invoices: null,
				invoiceModal: false,
				invoiceId: 0,
				downloadSignature: null,
				page: 1,
				rowsPerPage: 4,
			};
		},

		mounted() {
			this.getInvoices();
			// Get invoice
		},

		methods: {
			getInvoices() {
				this.subscriptionService
					.getInvoices()
					.then((response) => {
						if (response) this.invoices = this.filterInvoices(response);
						//console.log(this.invoices);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			filterInvoices(invoices) {
				let ret = [];
				for (const invoice of invoices) {
					if (invoice.total !== 0) {
						ret.push(invoice);
					}
				}
				return ret;
			},
			handleClick(id, downloadSignature) {
				this.invoiceId = id;
				this.downloadSignature = downloadSignature;
				this.invoiceModal = true;
			},
		},
		computed: {
			isReady: {
				get: function() {
					return this.invoices !== null;
				},
			},
			invoicesPage: {
				get: function() {
					const start = (this.page - 1) * this.rowsPerPage;
					const end = start + this.rowsPerPage;
					let ret = [];
					for (let i = start; i < end; i++) {
						if (this.invoices[i] == undefined) break;
						ret.push(this.invoices[i]);
					}
					return ret;
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	h4 {
		font-size: 14px;
		letter-spacing: 0.4px;
	}
	.v-list-item {
		margin-top: 1em;
		padding-bottom: 0.4em;
		border-bottom: 1px solid var(--v-gray_50-base);
	}
</style>
