<template>
	<div class="row-format gap-2">
		<v-text-field
			outlined
			hide-details
			dense
			class="standard-input"
			v-model="filter.search"
			placeholder="Search..."
			:style="`max-width: 160px;`"
			color=""
			@input="updated"
		>
			<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
		</v-text-field>

		<v-menu
			max-height="90vh"
			:nudge-bottom="34"
			max-width="400"
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					class="standard-action"
					elevation="0"
					outlined
					color=""
				>
					<span :class="`font-14 ${isFilterActive ? '' : 'font-gray_60'} brand-medium`">{{
						filterLabel
					}}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
				<filter-option
					:label="$t('client.filter.client-list')"
					:option-list="clientList"
					v-model="filter.clients"
					@change="updated"
				></filter-option>

				<filter-option
					:label="$t('timetrack.list.user')"
					:option-list="userList"
					v-model="filter.users"
					@change="updated"
				></filter-option>

				<filter-option
					:label="$t('client.filter.timer-status')"
					:option-list="timeEntryStatus"
					v-model="filter.timeEntryStatus"
					@change="updated"
				></filter-option>

				<div class="filter-option-box">
					<div class="row-format" @click="dateExpanded = !dateExpanded">
						<div class="font-14">{{ $t('client.filter.date-range') }}</div>
						<v-icon style="margin-left: auto; margin-top: 1px" small>{{
							dateExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
					</div>

					<div v-if="dateExpanded">
						<v-radio-group v-model="filter.dateSpecifier" @change="updated()">
							<v-radio v-for="option in dateOptions" :key="option.value" :value="option.value">
								<template v-slot:label>
									<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
								</template>
							</v-radio>
						</v-radio-group>

						<div class="" v-if="showEarliest">
							<date-selector
								content-class="my-5"
								:date="filter.earliest"
								@change="
									filter.earliest = $event;
									updated();
								"
								label="Earliest"
							></date-selector>
						</div>

						<div class="" v-if="showLatest">
							<date-selector
								content-class="my-5"
								:date="filter.latest"
								@change="
									filter.latest = $event;
									updated();
								"
								label="Latest"
							></date-selector>
						</div>
					</div>
				</div>

				<div class="mx-3 mb-3">
					<v-btn text block color="secondary" elevation="0" style="font-weight: 500" class="mt-2" @click="clearFilter()"
						><v-icon small>$clear</v-icon> Clear filters</v-btn
					>
					<v-btn class="super-action mt-1" block @click="showMenu = false">{{ $t('global.done') }}</v-btn>
				</div>
			</div>
		</v-menu>

		<v-menu
			v-model="groupMenu"
			max-height="90vh"
			:nudge-bottom="34"
			max-width="400"
			style="overflow: visible"
			transition="slide-y-transition"
			:close-on-content-click="false"
			:close-on-click="true"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" class="standard-action" elevation="0" outlined color="">
					<span :class="`font-14 ${filter.groupBy ? '' : 'font-gray_60'} brand-medium`">{{ groupByLabel }}</span>
					<v-icon x-small class="mx-1" :color="filter.groupBy ? '' : 'gray_60'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<v-list class="text-left">
				<v-list-item @click="setGroupBy(null)">
					<v-list-item-content>
						<div class="font-14 brand-medium">No group</div>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-for="group in groupByOptions" :key="group.field" @click="setGroupBy(group)">
					<v-list-item-content>
						<div class="font-14 brand-medium">{{ group.label }}</div>
					</v-list-item-content>

					<!-- Nested v-menu for the sub-menu -->
					<v-menu
						offset-x
						v-if="group.subItems && group.subItems.length"
						:close-on-click="true"
						:close-on-content-click="true"
					>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" @click.stop>expand_more</v-icon>
						</template>

						<v-list class="text-left font-12">
							<v-list-item
								v-for="(subItem, subIndex) in group.subItems"
								:key="subIndex"
								@click="setGroupBy(subItem)"
							>
								<div class="font-14 brand-medium">{{ subItem.label }}</div>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
	import FilterOption from '@/components/FilterOption';
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateSelector from '@/components/DateSelector';

	export default {
		name: 'TimeTrackListFilter',

		props: ['filter'],

		components: { FilterOption, DateSelector },

		data() {
			return {
				groupMenu: false,
				showMenu: false,
				dateExpanded: false,
				timeEntryStatus: [
					{ text: 'Billed', value: 'BILLED' },
					{ text: 'Un-billed', value: 'UN-BILLED' },
					{ text: 'Non-billable', value: 'NON-BILLABLE' },
				],
				dateOptions: FilterHelpers.getDateOptions([
					'today',
					'yesterday',
					'this-week',
					'last-week',
					'this-month',
					'last-month',
					'past-30-days',
					'past-60-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'between',
				]),
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.$emit('clear-filter');
				this.showMenu = false;
			},

			setGroupBy: function(groupBy) {
				this.filter.groupBy = groupBy;
				this.updated();
				this.groupMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				return this.filter.search || this.filter.status !== 'active';
			},

			groupByOptions: function() {
				let result = [];
				let client = { field: 'clientName', label: 'Client', subItems: [] };
				let project = { field: 'projectName', label: 'Project', subItems: [] };
				let task = { field: 'deliverableName', label: 'Task', subItems: [] };

				result.push({ field: 'userFullName', label: 'User' });
				result.push(client);
				result.push(project);
				result.push(task);

				if (this.$store.state.clientFields && this.$store.state.clientFields.fields) {
					this.$store.state.clientFields.fields.forEach((f) => {
						client.subItems.push({ field: `Client.${f.mappingKey}`, label: `${f.name}`, customField: true });
					});
				}

				this.$store.getters.getGlobalProjectFields.forEach((f) => {
					project.subItems.push({ field: `Project.${f.mappingKey}`, label: `${f.name}`, customField: true });
				});

				this.$store.getters.getGlobalDeliverableFields.forEach((f) => {
					task.subItems.push({ field: `Deliverable.${f.mappingKey}`, label: `${f.name}`, customField: true });
				});

				return result;
			},

			clientList: function() {
				let result = [];
				this.$store.getters.clients.forEach((c) => {
					result.push({
						text: c.name,
						value: c.id,
					});
				});
				result.sort((a, b) => a.text.localeCompare(b.text));
				return result;
			},

			userList: function() {
				let result = [];
				this.$store.state.usersForAccount.forEach((u) => {
					result.push({
						text: u.firstName + ' ' + u.lastName,
						value: u.userId,
					});
				});

				result.sort((a, b) => a.text.localeCompare(b.text));
				return result;
			},

			showEarliest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			groupByLabel: function() {
				if (this.filter.groupBy) {
					return `Group by ${this.filter.groupBy.label}`;
				} else {
					return 'Group by';
				}
			},

			filterLabel: function() {
				let label = [];

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'custom') {
						label.push(this.earliest + '-' + this.latest);
					} else {
						let d = this.dateOptions.find((d) => d.value === this.filter.dateSpecifier);
						label.push(d.text);
					}
				}

				if (this.filter.clients && this.filter.clients.length) {
					label.push(`${this.filter.clients.length} clients`);
				}

				if (this.filter.users && this.filter.users.length) {
					label.push(`${this.filter.users.length} users`);
				}

				// if (this.filter.search) {
				// 	label.push('"' + this.filter.search + '"');
				// }

				if (this.filter.status && this.filter.status !== 'active') {
					label.push('Status: ' + this.filter.status[0].toUpperCase() + this.filter.status.slice(1));
				}

				if (label.length === 0) {
					return 'Filter';
				} else {
					return label.join(' & ');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
