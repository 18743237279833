<template>
	<div id="" style="width: 600px; max-width: 600px;">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="modal-padding">
			<div class="modal-title">
				Select new parent task
			</div>
			<div class="column-format gap-6 text-left font-14">
				<v-text-field hide-details dense outlined class="standard-input" v-model="filter" placeholder="Search...">
					<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
				</v-text-field>
				<div class="column-format text-left">
					<div v-for="task in filteredTasks" @click="selectTask(task)" :key="task.id" :class="`column-format text-left py-2 px-2 task-item ${selectedTaskId === task.id ? 'selected' : ''}`" style="">
						<div class="text-left"><span class="font-gray_70 font-12" v-if="task.client">{{ task.client.name }}</span> <span class="font-gray_70 font-12" v-if="task.project">&gt;&gt; {{ task.project.name }}</span></div>
						<div class="text-left">{{ task.name }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer row-format gap-2 centered">
			<v-btn class="secondary-action mr-1" @click="$emit('result')">
				<span class="font-gray_70">{{ $t('global.cancel') }}</span>
			</v-btn>
			<v-btn class="super-action" @click="transferTask()" :disabled="!selectedTaskId">
				Confirm
			</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ParentTaskTransfer',

		props: ['deliverable', 'parentTasks'],

		components: {},

		data: function() {
			return {
				filter: null,
				selectedTaskId: null,
				tasks: [],
			};
		},

		mounted() {
			if (this.parentTasks) {
				this.tasks.push(...this.parentTasks);
			}
		},

		beforeDestroy() {},

		methods: {
			transferTask: function() {
				this.$emit('result',this.selectedTaskId);
			},

			selectTask: function(task){
				this.selectedTaskId = task.id;
			},

			isInNameFilter: function(task) {
				if (!this.filter) {
					return true;
				} else if (task.name && task.name.toLowerCase().includes(this.filter.toLowerCase())) {
					return true;
				} else if(task.project && task.project.name && task.project.name.toLowerCase().startsWith(this.filter.toLowerCase())){
					return true;
				} else if(task.client && task.client.name && task.client.name.toLowerCase().startsWith(this.filter.toLowerCase())){
					return true;
				}else{
					return false;
				}
			}
		},

		computed: {
			filteredTasks: function(){
				return this.tasks.filter(this.isInNameFilter);
			}
		},
	};
</script>

<style scoped lang="scss">
	.selected {
		background-color: var(--v-gray_10-base);
		font-weight: 600;
	}
	.task-item {
		cursor: pointer;
		border-bottom: 1px solid var(--v-gray_30-base);
		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}
</style>
