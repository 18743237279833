<template>
	<custom-field-table
		ref="Table"
		v-if="customFields"
		v-model="customFields.fields"
		:max-per-card="maxPerCard"
		@input="saveFields()"
	></custom-field-table>
</template>

<script>
	import CustomFieldsService from '@/modules/fields/CustomFieldsService';
	import CustomFieldTable from '@/modules/fields/CustomFieldTable';

	export default {
		name: 'CustomFieldsEditor',

		props: ['type', 'maxPerCard'],

		components: { CustomFieldTable },

		data: function() {
			return {
				refreshKey: 0,
				customFields: null,
				customFieldService: null,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.init);
			this.init();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.init);
		},

		methods: {
			init: function() {
				this.customFieldService = new CustomFieldsService(this.apiPath, this.apiVersion);
				this.getCustomFields();
			},

			getCustomFields: function() {
				this.customFieldService.getCustomFields().then((res) => (this.customFields = res.data));
			},

			addField: function() {
				this.$refs.Table.addField();
			},

			saveFields: function() {
				this.customFieldService.updateCustomFields(this.customFields).then((res) => {
					this.$store.commit(`set${this.type}Fields`, res.data);
				});
			},
		},

		computed: {
			apiVersion: function() {
				if (this.type === 'Pipeline') {
					return 2;
				} else {
					return 1;
				}
			},

			apiPath: function() {
				if (this.type === 'Client') {
					return '/clientFields';
				} else if (this.type === 'Contact') {
					return '/contactFields';
				} else if (this.type === 'Project') {
					return '/projectFields';
				} else if (this.type === 'Pipeline') {
					return '/pipeline/fields';
				} else if (this.type === 'Deliverable') {
					return '/deliverableFields';
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.drag {
		cursor: grab;
		&:active {
			cursor: grabbing;
		}
	}

	#fields-table {
		text-align: left;
		border-collapse: collapse;
		width: 100%;

		thead {
			tr {
				th {
					padding: 8px;
					font-size: 12px;
					font-weight: 600;
					margin: 0px;
					border-top: 1px solid var(--v-gray_30-base);
					border-bottom: 1px solid var(--v-gray_30-base);
					border-right: 1px solid var(--v-gray_30-base);

					&:first-of-type {
						padding-left: 20px !important;
						border-left: 1px solid var(--v-gray_30-base);
					}

					&:last-of-type {
						padding-right: 12px !important;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_30-base);
					border-right: 1px solid var(--v-gray_30-base);

					&:first-of-type {
						padding-left: 20px !important;
						border-left: 1px solid var(--v-gray_30-base);
					}
					&:last-of-type {
						padding-right: 12px !important;
					}
				}

				&:hover {
					td {
						background-color: var(--v-gray_10-base);
					}
				}
			}
		}
	}
</style>
